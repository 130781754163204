import LogHelper from 'ui-tdm-app/utils/logger'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { Toast } from 'ui-lib/components/Toast'
import { WeighBridgeDuc } from 'ui-tdm-app/modules/WeighBridge/duc'
import { getCoreEndPoint } from 'ui-tdm-app/config'
import { CallWithRefreshCheck } from 'ui-tdm-app/modules/Auth/AuthSaga'
import { DocumentListing } from 'ui-tdm-app/modules/App/AppSaga'
import request from 'ui-tdm-app/utils/request'
import { getProdList } from 'ui-tdm-app/utils/helpers'
import { getIn } from 'timm'
import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'

const logger = LogHelper('client:weighBridgeSaga')

function* fetchWeighBridgeListing() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('weighBridge-listing'))

		const requestUrl = `${getCoreEndPoint()}entities-attachments/all`
		const meta = 'meta=eq(%20category-%3Eweighbridge)&sort=desc(createdAt)'
		const { data } = yield DocumentListing(requestUrl, meta)
		const weighbridgeSlipList = getIn(data, ['list'])

		yield put(
			WeighBridgeDuc.creators.fetchWeighBridgeListingSuccess(
				weighbridgeSlipList
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('weighBridge-listing'))
	}
}

// API call to get data for WB Slip View Page
function* fetchSingleWBSlip() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('view-weighBridge-slip'))

		yield put(WeighBridgeDuc.creators.flushSingleWBSlipState())

		const locationState = yield select(WeighBridgeDuc.selectors.location)
		const { payload = {}, query = {} } = locationState
		const { attachmentID } = payload
		const { documentReference } = query

		// API call to get weightDetails, checkedBy & createdAt
		const requestUrl = `${getCoreEndPoint()}entities/${documentReference}/attachments/${attachmentID}`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		const weightDetails = getIn(data, ['meta', 'weighbridge'])
		const editable = getIn(data, ['meta', 'editable'])
		const createdAt = getIn(data, ['createdAt'])

		// API call to get doNumber, driverName, vehicleNumber & dispatchDate from entityAPI
		const entitiesUrl = `${getCoreEndPoint()}entities/${documentReference}`
		const { data: entitiesResponse = {} } = yield CallWithRefreshCheck(
			entitiesUrl
		)

		const doNumber = getIn(entitiesResponse, ['number'])
		const dispatchDate = getIn(entitiesResponse, ['createdAt'])
		const driverName = getIn(entitiesResponse, [
			'meta',
			'transporter',
			'driverName',
		])
		const vehicleNumber = getIn(entitiesResponse, [
			'meta',
			'transporter',
			'vehicleNumber',
		])

		const weighBridgeSlipDetails = {
			...weightDetails,
			editable,
			createdAt,
			dispatchDate,
			driverName,
			vehicleNumber,
			doNumber,
		}

		yield put(
			WeighBridgeDuc.creators.fetchSingleWBSlipSuccess(
				weighBridgeSlipDetails
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('view-weighBridge-slip'))
	}
}

// API call to get the entity details to be displayed during WB Slip creation
function* fetchEntityInfoforWBSlip(action) {
	try {
		const { docReference } = action
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AppDuc.creators.showGlobalLoader('fetch-initialState-wbSlip'))

		yield put(WeighBridgeDuc.creators.flushEntityInfo())
		yield put(WeighBridgeDuc.creators.flushSingleWBSlipState())

		const requestUrl = `${getCoreEndPoint()}entities/${docReference}`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		const productInfo = yield getProdList(data)

		const addQuantityFromProductsArray = data?.products?.reduce(
			(prev, current) => {
				return prev + current.quantity
			},
			0
		)

		const totalQty = getIn(data, ['meta', 'quantityNonMetric'])
			? getIn(data, ['meta', 'quantityNonMetric'])
			: addQuantityFromProductsArray || 0
		const productUOM = getIn(data, ['meta', 'uomNonMetric'])
			? getIn(data, ['meta', 'uomNonMetric'])
			: getIn(data, ['products', 0, 'uom']) || '---'
		const doNumber = getIn(data, ['number'])
		const entityID = getIn(data, ['id'])
		const dispatchDate = getIn(data, ['createdAt'])
		const driverName = getIn(data, ['meta', 'transporter', 'driverName'])
		const vehicleNumber = getIn(data, [
			'meta',
			'transporter',
			'vehicleNumber',
		])
		const receivingPartyID = getIn(data, ['receivingPartyID'])

		const initialStateforWBSlip = {
			productInfo,
			entityID,
			doNumber,
			dispatchDate,
			driverName,
			vehicleNumber,
			totalQty,
			productUOM,
			receivingPartyID,
		}
		yield put(
			WeighBridgeDuc.creators.setEntityInfoforWBSlip(
				initialStateforWBSlip
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-initialState-wbSlip'))
	}
}

// POST request that actually creates a WB slip
export function* createWeighBridgeSlip(action) {
	try {
		const {
			wbSlipDetails,
			isIncomingDO,
			actor,
			toastMessage,
			vehicleNumber,
		} = action
		const { entityID } = wbSlipDetails

		yield put(AppDuc.creators.showGlobalLoader('create-weighBridge'))

		const weightDetails = {
			type: 'user-input',
			file: getIn(wbSlipDetails, ['file']),
			meta: {
				weighbridge: {
					checkedBy: getIn(wbSlipDetails, ['checkedBy']),
					ticketNumber: getIn(wbSlipDetails, ['ticketNumber']),
					netWeight: getIn(wbSlipDetails, ['netWeight']),
					productID: getIn(wbSlipDetails, ['productID']),
					uom: getIn(wbSlipDetails, ['uom']),
					wrongVehicleNumber:
						vehicleNumber !== getIn(wbSlipDetails, ['vehicleNum']),
				},
				category: 'weighbridge',
				vehicleNumber: getIn(wbSlipDetails, ['vehicleNum']),
			},
		}

		if (isIncomingDO && actor[0].includes('palmoil_mill')) {
			weightDetails.meta.weighbridge.enableBuffer = true
		}

		if (wbSlipDetails.grossWeight !== '') {
			weightDetails.meta.weighbridge.grossWeight = getIn(wbSlipDetails, [
				'grossWeight',
			])
		}

		if (wbSlipDetails.tareWeight !== '') {
			weightDetails.meta.weighbridge.tareWeight = getIn(wbSlipDetails, [
				'tareWeight',
			])
		}

		if (
			wbSlipDetails?.deductionWeight !== '' &&
			(actor[0]?.includes('palmoil_mill') ||
				actor[0]?.includes('palmoil_ffbdealer') ||
				actor[0]?.includes('palmoil_ffbsubdealer'))
		) {
			weightDetails.meta.weighbridge.deductionWeight = getIn(
				wbSlipDetails,
				['deductionWeight']
			)
		}
		const requestUrl = `${getCoreEndPoint()}entities/${entityID}/attachments`
		const options = {
			method: 'POST',
			body: JSON.stringify(weightDetails),
		}
		const { data } = yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		if (toastMessage) {
			Toast({
				type: 'success',
				message: toastMessage,
				isMobile: isMobile || isTablet,
			})
		}
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
				{
					rootModule: 'delivery-order',
					documentReference: data.entityID,
				},
				{},
				{},
				true
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('create-weighBridge'))
	}
}

// API calls to get the entityInfo & weightDetails that should be prepopulated on Edit screen
function* initiateEditSlip(action) {
	try {
		const { docReference, attachmentID } = action

		yield put(
			WeighBridgeDuc.creators.fetchEntityInfoforWBSlip(docReference)
		)

		yield put(WeighBridgeDuc.creators.flushInitialFormValues())

		const requestUrl = `${getCoreEndPoint()}entities/${docReference}/attachments/${attachmentID}`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		const weightDetails = getIn(data, ['meta', 'weighbridge'])
		yield put(
			WeighBridgeDuc.creators.setInitialFormValuesforEdit(weightDetails)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

// API request to update an existing WB Slip
function* editWeighBridgeSlip(action) {
	try {
		const { wbSlipDetails, toastMessage } = action
		const { entityID, attachmentID } = wbSlipDetails

		const weightDetails = {
			type: 'user-input',
			meta: {
				weighbridge: {
					checkedBy: getIn(wbSlipDetails, ['checkedBy']),
					ticketNumber: getIn(wbSlipDetails, ['ticketNumber']),
					grossWeight: getIn(wbSlipDetails, ['grossWeight']),
					tareWeight: getIn(wbSlipDetails, ['tareWeight']),
					productID: getIn(wbSlipDetails, ['productID']),
					wrongVehicleNumber: getIn(wbSlipDetails, [
						'wrongVehicleNumber',
					]),
				},
				category: 'weighbridge',
				vehicleNumber: getIn(wbSlipDetails, ['vehicleNum']),
			},
		}

		const requestUrl = `${getCoreEndPoint()}entities/${entityID}/attachments/${attachmentID}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(weightDetails),
		}
		const { data } = yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.WEIGHBRIDGE$DOCUMENTREFERENCE,
				{
					action: 'view',
					attachmentID: data.id,
				},
				{
					documentReference: data.entityID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export default function* WeighBridgeSaga() {
	try {
		yield all([
			takeLatest(
				WeighBridgeDuc.creators.fetchWeighBridgeListing().type,
				fetchWeighBridgeListing
			),
			takeLatest(
				WeighBridgeDuc.creators.fetchSingleWBSlip().type,
				fetchSingleWBSlip
			),
			takeLatest(
				WeighBridgeDuc.creators.fetchEntityInfoforWBSlip().type,
				fetchEntityInfoforWBSlip
			),
			takeLatest(
				WeighBridgeDuc.creators.createWeighBridgeSlip().type,
				createWeighBridgeSlip
			),
			takeLatest(
				WeighBridgeDuc.creators.initiateEditSlip().type,
				initiateEditSlip
			),
			takeLatest(
				WeighBridgeDuc.creators.editWeighBridgeSlip().type,
				editWeighBridgeSlip
			),
		])
	} catch (err) {
		logger.log(err)
	}
}

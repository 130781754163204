import React, { useState } from 'react'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { useTranslation } from 'react-i18next'
import { Collapsible } from 'grommet'
import { DocumentViewLeadCard } from './DocumentLead'
import { TraceReportTable } from './TraceReportTable'
import {
	getVerificationStatus,
	getHeader,
	traceDetails,
	originatingOrganizationDetails,
	destinationOrganizationDetails,
	handleCertificateData,
} from './TraceReportComponents'
import { colorArray } from '../helpers'

const DocumentTreeBlock = ({
	isOpen: isOpenFromProp,
	getExplorerLink,
	children,
	isActive,
	handleChange,
	config = {},
	activeDocument = {},
	hederaMessages = {},
	currentEntityID = '',
	forTraceReport = false,
}) => {
	const { t } = useTranslation()
	const [isOpen, setOpen] = useState(isOpenFromProp)
	const hasChildren = !!children

	const colorArrayIndex = config?.levelCount < 5 ? config?.levelCount : 0

	return (
		<>
			<Box>
				{forTraceReport ? (
					<Box overflow>
						<div style={{ width: '100%' }}>
							<TraceReportTable
								blockChainVerification={getVerificationStatus(
									activeDocument,
									hederaMessages,
									currentEntityID,
									true
								)}
								hederaMessages={hederaMessages}
								headerBackGroundColor={
									colorArray[colorArrayIndex]
								}
								header={
									getHeader(
										activeDocument,
										config?.component,
										t
									)?.name
								}
								virtualTraceData={
									getHeader(
										activeDocument,
										config?.component,
										t
									)?.virtualTraceData
								}
								receiverOrgId={
									config?.component?.destinationOrg?.id
								}
								showVerifiedText
								traceDetails={traceDetails(
									activeDocument,
									config?.component,
									true
								)}
								originatingOrganizationDetails={originatingOrganizationDetails(
									config?.component
								)}
								destinationOrganizationDetails={destinationOrganizationDetails(
									config?.component
								)}
								onCertificateclick={certificateData => {
									handleCertificateData(certificateData)
								}}
								secondaryTrace
								isOpen={isOpen}
								hasChildren={hasChildren}
								onCollapseAction={() =>
									setOpen(_isOpen => !_isOpen)
								}
								getExplorerLink={getExplorerLink}
							/>
						</div>
					</Box>
				) : (
					<Box overflow padding={4}>
						<div>
							<DocumentViewLeadCard
								config={config}
								onClick={handleChange}
								isActive={isActive}
								isOpen={isOpen}
								hasChildren={hasChildren}
								onCollapseAction={() =>
									setOpen(_isOpen => !_isOpen)
								}
							/>
						</div>
					</Box>
				)}
			</Box>
			{children && (
				<Collapsible open={isOpen}>
					<div
						style={{
							display: isOpen ? 'block' : 'none',
							paddingLeft: 12,
							borderLeft: `2px dotted ${
								forTraceReport ? theme.color.grey18 : '#f0f0f0'
							}`,
							marginLeft: 28,
						}}
					>
						{children}
					</div>
				</Collapsible>
			)}
		</>
	)
}

DocumentTreeBlock.defaultProps = {
	handleChange: () => {},
	getExplorerLink: () => {},
	config: {},
}

export { DocumentTreeBlock }

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { WeighBridgeDuc } from 'ui-tdm-app/modules/WeighBridge/duc'
import { BoxShadowTable } from 'ui-tdm-app/modules/TradeDocumentManager/components/BoxShadowTable'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { getColumnConfig } from 'ui-tdm-app/modules/WeighBridge/components/Columns'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Box } from 'ui-lib/utils/Box'
import { Icon } from 'ui-lib/icons/components/Icon'
import Weighbridge from 'ui-lib/icons/weighbridge.svg'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'

const MainDashboard = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const listing = useSelector(
		WeighBridgeDuc.selectors.getWeighbridgeSlipsList
	)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.wbSlips'),
			name: 'weighbridge',
			isActive: false,
		},
	]

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			if (actionType === 'open_document' && meta.row) {
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.WEIGHBRIDGE$DOCUMENTREFERENCE,
						{
							action: 'view',
							attachmentID: meta.row.id,
						},
						{
							documentReference: meta.row.entityID,
						}
					)
				)
			}
		},
		[dispatch]
	)

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<Box row style={{ padding: '0 5' }} justifyContent="space-between">
				<Title title={t('weighbridge.title')} icon={Weighbridge} />
			</Box>

			<BoxShadowTable
				size="medium"
				columnConfig={getColumnConfig({
					onChange: handleOnChange,
					t,
				})}
				rowData={listing}
			/>
			<Box padding="25px 2px" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DASHBOARD
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export default MainDashboard

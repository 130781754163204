import React, { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { ModuleIconMap } from 'ui-lib/utils/config'
import weighBridgeIcon from 'ui-lib/icons/weighbridge.svg'
import { NAME_ALIASES } from 'ui-tdm-app/modules/TradeDocumentManager/config'
import { UploadExcel } from 'ui-tdm-app/modules/UploadContent/components/UploadExcel'
import { Box } from 'ui-lib/utils/Box'

const UploadDocuments = () => {
	const { t } = useTranslation()
	const locationState = useSelector(TradeDocDuc.selectors.location)
	const actor = useSelector(AuthDuc.selectors.getActor)
	const isDealer =
		actor.includes('palmoil_ffbdealer') || actor.includes('rice-dealer')
	const isSubDealer = actor.includes('palmoil_ffbsubdealer')
	const TitleIcon = ModuleIconMap['delivery-order']
	const { payload = {} } = locationState
	const { rootModule } = payload

	const breadCrumRootModule = getIn(locationState, [
		'prev',
		'payload',
		'rootModule',
	])
		? getIn(locationState, ['prev', 'payload', 'rootModule'])
		: null

	const getBreadCrumbsList = useCallback(
		({ title }) => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.tradeDocManager'),
				name: 'trade-doc-dashboard',
				isActive: true,
			},
			{
				label:
					rootModule === 'upload-delivery-order'
						? `${t('breadcrumb.new')} ${t(title)}`
						: t('tdmColumnHeaders.uploadWeighbridge'),
				name: 'document-attach',
				isActive: false,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[t]
	)

	const title = NAME_ALIASES['delivery-order']
	const breadCrumbs = useMemo(() => getBreadCrumbsList({ title }), [
		getBreadCrumbsList,
		title,
	])

	const getText = data => {
		let text = ''
		if (data === 'main-header') {
			if (rootModule === 'purchase-order') {
				text = t('tdmDetailsEntry.sendPurchaseOrderToYourSeller')
			} else if (rootModule === 'invoice') {
				text = t('tdmDetailsEntry.sendInvoiceToYourBuyer')
			} else if (rootModule === 'delivery-order') {
				text = t('tdmDetailsEntry.SendDeliveryOrderToYourBuyer')
			} else if (rootModule === 'upload-delivery-order') {
				text = t('tdmColumnHeaders.uploadDeliveryOrder')
			} else if (rootModule === 'upload-weighbridge') {
				text = t('tdmColumnHeaders.uploadWeighbridge')
			}
		}

		return text
	}

	return (
		<Box>
			<UploadExcel
				breadCrumbs={breadCrumbs}
				titleicon={
					rootModule === 'upload-delivery-order'
						? TitleIcon
						: weighBridgeIcon
				}
				title={!(isDealer || isSubDealer) && getText('main-header')}
				type={
					rootModule === 'upload-delivery-order'
						? 'delivery-order'
						: 'weighbridge'
				}
				subModule={breadCrumRootModule}
			/>
		</Box>
	)
}

export { UploadDocuments }

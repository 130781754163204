import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { Box as GrommBox, Layer } from 'grommet'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { Button } from 'ui-lib/components/Button/index'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { P } from 'ui-lib/components/Typography'
import { RadioInputGroup } from 'ui-lib/components/Radio'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import EmployeeIcon from 'ui-lib/icons/employee-help.svg'
import EmployeeSetupIcon from 'ui-lib/icons/employee-setup.svg'
import LanguageIcon from 'ui-lib/icons/language-help.svg'
import LanguageSetupIcon from 'ui-lib/icons/language-setup.svg'
import AssetIcon from 'ui-lib/icons/asset-help.svg'
import AssetSetupIcon from 'ui-lib/icons/assets-setup.svg'
import PartnerIcon from 'ui-lib/icons/partner-help.svg'
import PartnerSetupIcon from 'ui-lib/icons/partner-setup.svg'

const colorIcon = {
	gray: '#7B8088',
	blue: '#3F56C4',
	green: '#158853',
}

const FirstTimeSignInModal = ({
	show,
	isMobile,
	noPadding,
	size = 'large',
	overflow,
	margin,
	onConfirm,
	onSkip,
	components,
	selectLanguage,
}) => {
	const { t } = useTranslation()
	const [language, setLanguage] = useState('eng')
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []

	const firstElement = getIn(components, [0])
	const loggedInUserProfile = useSelector(AuthDuc.selectors.getUserProfile)
	const _language =
		getIn(loggedInUserProfile, ['meta', 'language']) || 'notset'

	const {
		incomingAreaAvailable,
		outgoingAreaAvailable,
		incomingTankAvailable,
		outgoingTankAvailable,
		weighbridgeAvailable,
		partnersAvailable,
		employeesAvailable,
	} = useSelector(AuthDuc.selectors.getFirstTimeStats)

	const orgsWithWeighBridge =
		actor.includes('palmoil_ffbdealer') ||
		actor.includes('palmoil_ffbsubdealer')

	// Incoming Tank, Outgoing Tank and Weighbridge is mandatory
	const orgsWithTanksAndWeighbridge =
		actor.includes('palmoil_refinery') ||
		actor.includes('palmoil_oleochemicals')

	// Incoming Area, Outgoing Area, Outgoing Tank and Weighbridge is mandatory
	const isMill = actor.includes('palmoil_mill')

	// Incoming Area, Outgoing Tank and Weighbridge is mandatory
	const isKernelMill = actor.includes('palmoil-kernelMill')

	const isAssetCreated = () => {
		if (orgsWithWeighBridge) {
			return !weighbridgeAvailable
		}
		if (orgsWithTanksAndWeighbridge) {
			return (
				!incomingTankAvailable ||
				outgoingTankAvailable ||
				!weighbridgeAvailable
			)
		}
		if (isKernelMill) {
			return (
				incomingAreaAvailable ||
				outgoingTankAvailable ||
				!weighbridgeAvailable
			)
		}
		if (isMill) {
			return (
				incomingAreaAvailable ||
				outgoingAreaAvailable ||
				outgoingTankAvailable ||
				!weighbridgeAvailable
			)
		}
	}

	const getLanguageIconColor = () => {
		let languageIconColor

		if (_language === 'notset' && firstElement !== 'chooseLanguage') {
			languageIconColor = colorIcon.gray
		} else if (
			_language === 'notset' &&
			firstElement === 'chooseLanguage'
		) {
			languageIconColor = colorIcon.blue
		} else {
			languageIconColor = colorIcon.green
		}

		return languageIconColor
	}

	const getAssetIconColor = () => {
		let assetIconColor

		if (isAssetCreated() && firstElement !== 'chooseAsset') {
			assetIconColor = colorIcon.gray
		} else if (isAssetCreated() && firstElement === 'chooseAsset') {
			assetIconColor = colorIcon.blue
		} else {
			assetIconColor = colorIcon.green
		}

		return assetIconColor
	}

	const getPartnerIconColor = () => {
		let partnerIconColor

		if (!partnersAvailable && firstElement !== 'choosePartner') {
			partnerIconColor = colorIcon.gray
		} else if (!partnersAvailable && firstElement === 'choosePartner') {
			partnerIconColor = colorIcon.blue
		} else {
			partnerIconColor = colorIcon.green
		}

		return partnerIconColor
	}

	const getEmployeeIconColor = () => {
		let employeeIconColor

		if (!employeesAvailable && firstElement !== 'chooseEmployee') {
			employeeIconColor = colorIcon.gray
		} else if (!employeesAvailable && firstElement === 'chooseEmployee') {
			employeeIconColor = colorIcon.blue
		} else {
			employeeIconColor = colorIcon.green
		}

		return employeeIconColor
	}

	const iconsWithColors = [
		{ id: 1, icon: LanguageIcon, color: getLanguageIconColor() },
		{ id: 2, icon: AssetIcon, color: getAssetIconColor() },
		{ id: 3, icon: PartnerIcon, color: getPartnerIconColor() },
		{ id: 4, icon: EmployeeIcon, color: getEmployeeIconColor() },
	]

	const modalBody = {
		chooseLanguage: [
			{
				icon: LanguageSetupIcon,
				body: (
					<Box style={{ padding: '0 30px' }}>
						<Box row>
							<IconWrapper size={28}>
								<Icon
									glyph={LanguageIcon}
									color={theme.color.primary}
								/>
							</IconWrapper>

							<P
								style={{
									margin: '0 12px 32px',
									fontSize: 20,
									color: '#3F56C4',
								}}
							>
								{t('firstTimeSignInModal.langTitle')}
							</P>
						</Box>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#7B8088',
							}}
						>
							{t('firstTimeSignInModal.langSubTitle')}
						</P>
						<RadioInputGroup
							name="languagePref"
							selected={language}
							options={[
								{
									label: t('changeLanguage.english'),
									name: 'eng',
								},
								{
									label: t('changeLanguage.spanish'),
									name: 'spa',
								},
								{
									label: t('changeLanguage.malay'),
									name: 'may',
								},
								{
									label: t('changeLanguage.indonesian'),
									name: 'ind',
								},
								{
									label: t('changeLanguage.mandarin'),
									name: 'chi',
								},
								{
									label: t('changeLanguage.tamil'),
									name: 'tam',
								},
							]}
							onChange={e => {
								selectLanguage(e.target.value)
								setLanguage(e.target.value)
							}}
						/>
					</Box>
				),
			},
		],
		createIncomingArea: [
			{
				icon: AssetSetupIcon,
				body: (
					<Box style={{ padding: '0 30px' }} height="360px">
						<Box row>
							<IconWrapper size={28}>
								<Icon
									glyph={AssetIcon}
									color={theme.color.primary}
								/>
							</IconWrapper>
							<P
								style={{
									margin: '0 12px 32px',
									fontSize: 20,
									color: '#3F56C4',
								}}
							>
								{t('firstTimeSignInModal.incomingAreaTitle')}
							</P>
						</Box>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#7B8088',
							}}
						>
							{t('firstTimeSignInModal.incomingAreaSubTitle')}
						</P>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#172B4D',
							}}
						>
							{t('firstTimeSignInModal.skipWarning')}
						</P>
					</Box>
				),
			},
		],
		createOutgoingArea: [
			{
				icon: AssetSetupIcon,
				body: (
					<Box style={{ padding: '0 30px' }} height="360px">
						<Box row>
							<IconWrapper size={28}>
								<Icon
									glyph={AssetIcon}
									color={theme.color.primary}
								/>
							</IconWrapper>

							<P
								style={{
									margin: '0 12px 32px',
									fontSize: 20,
									color: '#3F56C4',
								}}
							>
								{t('firstTimeSignInModal.outgoingAreaTitle')}
							</P>
						</Box>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#7B8088',
							}}
						>
							{t('firstTimeSignInModal.outgoingAreaSubTitle')}
						</P>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#172B4D',
							}}
						>
							{t('firstTimeSignInModal.skipWarning')}
						</P>
					</Box>
				),
			},
		],
		createIncomingTank: [
			{
				icon: AssetSetupIcon,
				body: (
					<Box style={{ padding: '0 30px' }} height="360px">
						<Box row>
							<IconWrapper size={28}>
								<Icon
									glyph={AssetIcon}
									color={theme.color.primary}
								/>
							</IconWrapper>

							<P
								style={{
									margin: '0 12px 32px',
									fontSize: 20,
									color: '#3F56C4',
								}}
							>
								{t('firstTimeSignInModal.incomingTankTitle')}
							</P>
						</Box>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#7B8088',
							}}
						>
							{t('firstTimeSignInModal.incomingTankSubTitle')}
						</P>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#172B4D',
							}}
						>
							{t('firstTimeSignInModal.skipWarning')}
						</P>
					</Box>
				),
			},
		],
		createOutgoingTank: [
			{
				icon: AssetSetupIcon,
				body: (
					<Box style={{ padding: '0 30px' }} height="360px">
						<Box row>
							<IconWrapper size={28}>
								<Icon
									glyph={AssetIcon}
									color={theme.color.primary}
								/>
							</IconWrapper>

							<P
								style={{
									margin: '0 12px 32px',
									fontSize: 20,
									color: '#3F56C4',
								}}
							>
								{t('firstTimeSignInModal.outgoingTankTitle')}
							</P>
						</Box>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#7B8088',
							}}
						>
							{t('firstTimeSignInModal.outgoingTankSubTitle')}
						</P>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#172B4D',
							}}
						>
							{t('firstTimeSignInModal.skipWarning')}
						</P>
					</Box>
				),
			},
		],
		createWeighbridge: [
			{
				icon: AssetSetupIcon,
				body: (
					<Box style={{ padding: '0 30px' }} height="360px">
						<Box row>
							<IconWrapper size={28}>
								<Icon
									glyph={AssetIcon}
									color={theme.color.primary}
								/>
							</IconWrapper>

							<P
								style={{
									margin: '0 12px 32px',
									fontSize: 20,
									color: '#3F56C4',
								}}
							>
								{t('firstTimeSignInModal.weighbridgeTitle')}
							</P>
						</Box>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#7B8088',
							}}
						>
							{t('firstTimeSignInModal.weighbridgeSubTitle')}
						</P>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#172B4D',
							}}
						>
							{t('firstTimeSignInModal.skipWarning')}
						</P>
					</Box>
				),
			},
		],
		choosePartner: [
			{
				icon: PartnerSetupIcon,
				body: (
					<Box style={{ padding: '0 30px' }} height="360px">
						<Box row>
							<IconWrapper size={28}>
								<Icon
									glyph={PartnerIcon}
									color={theme.color.primary}
								/>
							</IconWrapper>

							<P
								style={{
									margin: '0 12px 32px',
									fontSize: 20,
									color: '#3F56C4',
								}}
							>
								{t('firstTimeSignInModal.partnerTitle')}
							</P>
						</Box>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#7B8088',
							}}
						>
							{t('firstTimeSignInModal.partnerSubTitle')}
						</P>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#172B4D',
							}}
						>
							{t('firstTimeSignInModal.partnerBody')}
						</P>
					</Box>
				),
			},
		],
		chooseEmployee: [
			{
				icon: EmployeeSetupIcon,
				body: (
					<Box style={{ padding: '0 30px' }} height="360px">
						<Box row>
							<IconWrapper size={28}>
								<Icon
									glyph={EmployeeIcon}
									color={theme.color.primary}
								/>
							</IconWrapper>

							<P
								style={{
									margin: '0 12px 32px',
									fontSize: 20,
									color: '#3F56C4',
								}}
							>
								{t('firstTimeSignInModal.empTitle')}
							</P>
						</Box>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#7B8088',
							}}
						>
							{t('firstTimeSignInModal.empSubTitle')}
						</P>
						<P
							style={{
								margin: '0 0 32px',
								fontSize: 16,
								color: '#172B4D',
							}}
						>
							{t('firstTimeSignInModal.empBody')}
						</P>
					</Box>
				),
			},
		],
	}

	const activeModule = getIn(modalBody, [firstElement, 0]) || {}

	return (
		<>
			{show && (
				<Layer
					modal
					responsive={false}
					animation="fadeIn"
					position={isMobile ? 'bottom' : 'center'}
					margin={margin}
				>
					<GrommBox
						pad={noPadding ? 'none' : '32px 48px'}
						gap={noPadding ? 'none' : 'small'}
						width={size}
						overflow={overflow}
					>
						<Box row>
							<Box
								width="40%"
								justifyContent="center"
								margin="8px 16px 0 0"
							>
								<IconWrapper size={260}>
									<Icon glyph={activeModule.icon} />
								</IconWrapper>
							</Box>
							<Box width="60%" margin="8px 0 0 16px">
								{activeModule.body}
							</Box>
						</Box>
						<Box row>
							<Box row width="40%" justifyContent="space-between">
								{iconsWithColors.map(({ icon, color, id }) => {
									return (
										<IconWrapper
											key={id}
											style={{
												backgroundColor: '#E9ECF0',
												padding: '10px',
												borderRadius: '50%',
												alignItems: 'center',
												width: '43px',
												height: '45px',
											}}
										>
											<Icon glyph={icon} color={color} />
										</IconWrapper>
									)
								})}
							</Box>
							<Box width="60%" row justifyContent="flex-end">
								<Button
									plain
									label={t('common.newSkip')}
									onClick={onSkip}
									extendStyles={{
										marginRight: 72,
										fontSize: theme.fontSize.l,
									}}
								/>
								<Button
									primary
									label={t('breadcrumb.confirm')}
									onClick={onConfirm}
									extendStyles={{
										fontSize: theme.fontSize.l,
										width: 160,
									}}
								/>
							</Box>
						</Box>
					</GrommBox>
				</Layer>
			)}
		</>
	)
}

export { FirstTimeSignInModal }

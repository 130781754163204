import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { CookieDuc } from 'ui-tdm-app/modules/App/cookieDuc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { initiateSort } from 'ui-tdm-app/modules/TradeDocumentManager/helpers'
import { currentPlatform } from 'ui-lib/utils/config'
import {
	getColumnConfig,
	contractsColumnConfig,
	excelUploadColumnConfig,
} from 'ui-tdm-app/modules/TradeDocumentManager/components/Columns'
import { Modal } from 'ui-lib/components/Modal'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { RemarksConfirmation } from 'ui-tdm-app/modules/TradeDocumentManager/components/RemarksConfirmation'
import { DRAFT_TAB_CONFIG } from 'ui-tdm-app/modules/TradeDocumentManager/config'
import { addFirst, getIn, replaceAt } from 'timm'
import { Label, P } from 'ui-lib/components/Typography'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import { Table } from 'ui-lib/components/Table'
import { ButtonWithDrop, Button } from 'ui-lib/components/Button'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { isIframeForTeamsApp } from 'ui-lib/utils/helpers'
import { Box } from 'ui-lib/utils/Box'
import Incoming from 'ui-lib/icons/incoming.svg'
import Outgoing from 'ui-lib/icons/outgoing.svg'
import tradeDocIcon from 'ui-lib/icons/trade.svg'

const CTAWrapper = styled(Box)(p => ({
	padding: 20,
	minHeight: 62,
	background: p.theme.color.white,
	borderBottomLeftRadius: 4,
	borderBottomRightRadius: 4,
	color: p.theme.color.primary,
	border: `1px solid ${p.theme.color.grey4}`,
}))

const getTabComponents = ({
	type,
	sortsMap,
	rows,
	onChange,
	orgDetails,
	loadingStatus = [],
	activeLocale,
	actor,
	t,
	selectedCPID,
	getCurrentOrgID,
	isMobile,
	setFullOrgList,
	currentPlatform,
	uploadDOAcceptedList = [],
	uploadDODraftAcceptedList = [],
	partnerList = [],
	isIframeForTeamsApp = false,
	fullOrgList = [],
	allProducts = [],
}) => ({ name, title }) => () => {
	let columns
	columns = getColumnConfig({
		type,
		docType: name,
		sortsMap: sortsMap[name] || {},
		onChange,
		orgDetails,
		activeLocale,
		actor,
		t,
		selectedCPID,
		getCurrentOrgID,
		isMobile,
		uploadDOAcceptedList,
		isIframeForTeamsApp,
	})
	if (name === 'contracts' && currentPlatform !== 'rice') {
		columns = contractsColumnConfig({
			t,
			type,
			activeLocale,
			actor,
			onChange,
			isMobile,
		})
	}
	if (name === 'excel-uploads') {
		columns = excelUploadColumnConfig({
			t,
			type,
			onChange,
			orgDetails,
			getCurrentOrgID,
			setFullOrgList,
			uploadDODraftAcceptedList,
			fullOrgList,
			partnerList,
			isMobile,
			allProducts,
		})
	}

	return (
		<Box
			style={{
				overflow: 'auto',
				position: 'relative',
				backgroundColor: '#fff',
			}}
		>
			{loadingStatus.includes(name) && <TransparentBgSpinner />}
			<Box overflow style={{ minHeight: 395 }}>
				<Table
					noRoundedBorder
					columnConfig={columns}
					rowData={rows[name] || []}
					mobileViewCSSOn={isMobile}
				/>
				{!(rows[name] || []).length && (
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				)}
			</Box>
			<CTAWrapper>
				{!(name === 'draft' || name === 'contracts') && (
					<Label
						color="inherit"
						bold
						small
						link
						as="a"
						onClick={() =>
							onChange('open_listing', {
								type,
								docType: name,
							})
						}
					>{`${t('common.viewAll')} ${
						title ? title.toUpperCase() : ''
					}`}</Label>
				)}
			</CTAWrapper>
		</Box>
	)
}

/** Main Component */
export const MainDashboard = () => {
	const dispatch = useDispatch()
	const [activeRecord, setActiveRecord] = useState({})
	const [showRemarkModal, setShowRemarkModal] = useState(false)
	const { t } = useTranslation()
	/** Selectors to get from redux */
	const location = useSelector(TradeDocDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)
	const actorArray = useSelector(AuthDuc.selectors.getActor)
	const actor = actorArray[0] || []
	const isDealer =
		actor.includes('palmoil_ffbdealer') || actor.includes('rice-dealer')
	const isSubDealer = actor.includes('palmoil_ffbsubdealer')
	const isCP = actor.includes('palmoil_collectionpoint')
	const isPlantation =
		actor.includes('palmoil_plantation') || actor.includes('rice-farmer')
	const orgDetails = useSelector(AuthDuc.selectors.getAvailableOrgs)
	const {
		incoming: incomingLoadingStates = [],
		outgoing: outgoingLoadingStates = [],
	} = useSelector(TradeDocDuc.selectors.getMainDashboardLoadingStates)
	const activeTabs = useSelector(TradeDocDuc.selectors.getActiveRootModules)
	const uploadDOAcceptedList = useSelector(
		TradeDocDuc.selectors.getUploadDOAcceptedList
	)
	const uploadDODraftAcceptedList = useSelector(
		TradeDocDuc.selectors.getUploadDODraftAcceptedList
	)
	const activeTypes = useSelector(
		TradeDocDuc.selectors.getMainDashBoardActiveTabs
	)
	const partnerList = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const globalOrgs = useSelector(AuthDuc.selectors.getGlobalOrgs)

	const [fullOrgList, setFullOrgList] = useState([])
	const [outgoingActiveTabs, setOutgoingActiveTabs] = useState(activeTypes)
	const [
		currentActiveIncomingDocuments,
		setCurrentActiveIncomingDocuments,
	] = useState([])
	const [
		currentActiveOutgoingDocuments,
		setCurrentActiveOutgoingDocuments,
	] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [showAcceptAllDODraftModal, setShowAcceptAllDODraftModal] = useState(
		false
	)
	const activeTab = useSelector(
		TradeDocDuc.selectors.getMainDashBoardActiveTab
	)

	const [incomingActiveTab, setIncomingActiveTab] = useState(
		activeTab.incoming
	)
	const [outgoingActiveTab, setOutgoingActiveTab] = useState(
		activeTab.outgoing
	)

	const activeIncomingDocuments = useSelector(
		TradeDocDuc.selectors.getMainDashboardActiveIncomingDocuments
	)
	const acceptAllButtonLoading = useSelector(
		TradeDocDuc.selectors.getDOAcceptedLoading
	)
	const acceptAllDraftButtonLoading = useSelector(
		TradeDocDuc.selectors.getDODraftAcceptedLoading
	)

	const activeOutgoingDocuments = useSelector(
		TradeDocDuc.selectors.getMainDashboardActiveOutgoingDocuments
	)

	const activeCounts = useSelector(
		TradeDocDuc.selectors.getMainDashboardActiveCounts
	)
	const activeSorts = useSelector(
		TradeDocDuc.selectors.getMainDashboardActiveSorts
	)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)

	const hasIncoming = activeTabs.includes('incoming')
	const hasOutgoing = activeTabs.includes('outgoing')

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.tradeDocManager'),
			name: 'trade-doc-dashboard',
			isActive: false,
		},
	]

	const _outgoingActiveTab =
		outgoingActiveTab === '' || isDealer || isSubDealer
			? getIn(activeTypes, [0, 'code'])
			: outgoingActiveTab

	const _incomingActiveTab =
		incomingActiveTab === '' || isDealer || isSubDealer
			? getIn(activeTypes, [0, 'code'])
			: incomingActiveTab

	useEffect(() => {
		if (
			parseInt(activeCounts.outgoing.draft, 10) > 0 &&
			!isIframeForTeamsApp()
		) {
			setOutgoingActiveTabs(addFirst(activeTypes, DRAFT_TAB_CONFIG))
		}
		setOutgoingActiveTab(_outgoingActiveTab)
		setIncomingActiveTab(_incomingActiveTab)
	}, [
		activeTypes,
		activeCounts.outgoing.draft,
		_incomingActiveTab,
		_outgoingActiveTab,
	])

	useEffect(() => {
		if (hasIncoming) {
			setCurrentActiveIncomingDocuments({ ...activeIncomingDocuments })
		} else {
			setCurrentActiveOutgoingDocuments({ ...activeOutgoingDocuments })
		}
	}, [activeIncomingDocuments, activeOutgoingDocuments, hasIncoming])

	/** Helper for the components */
	const getTabs = useCallback(
		type => {
			const documentCounts =
				type === 'incoming'
					? activeCounts.incoming
					: activeCounts.outgoing

			let targetTypes = activeTypes

			if (type === 'incoming' && actor.includes('palmoil_tdm')) {
				targetTypes = replaceAt(activeTypes, 0, {
					code: 'purchase-order',
					title: t('breadcrumb.salesOrder'),
				})
			}

			// this draft is saved document. when upload excel drafts implemented this one removed
			// Add drafts tab only if we have some drafts
			// if (
			// 	type === 'outgoing' &&
			// 	parseInt(documentCounts.draft, 10) > 0 &&
			// 	!isIframeForTeamsApp()
			// ) {
			// 	targetTypes = addFirst(activeTypes, DRAFT_TAB_CONFIG)
			// }

			return targetTypes.map(({ code, title }) => ({
				name: code,
				title: t(title),
				IndicatorComponent:
					parseInt(documentCounts[code], 10) > 0 &&
					(({ isActive }) => (
						<DefaultTabIndicator active={isActive}>
							{parseInt(documentCounts[code], 10)}
						</DefaultTabIndicator>
					)),
			}))
		},
		[activeTypes, activeCounts, t, actor]
	)

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'initiate_sort': {
					initiateSort(
						dispatch,
						meta,
						TradeDocDuc.creators.fetchDashboardDocuments,
						location,
						meta.type
					)

					break
				}

				case 'open_document': {
					if (meta.row) {
						const isDraft = meta.row.status.state === 'draft'

						dispatch(TradeDocDuc.creators.setActiveTab('original'))

						dispatch(
							isDraft
								? MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE,
										{
											rootModule: meta.row.type,
											action: 'attachDetails',
											documentReference: meta.row.id,
											documentStatus: 'draft',
										}
								  )
								: MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
										{
											rootModule: meta.row.type,
											documentReference: meta.row.id,
										}
								  )
						)
					}

					break
				}

				case 'change_status': {
					const { type, docType, row, newStatus } = meta || {}

					if (type && newStatus) {
						setActiveRecord({
							type,
							docType,
							document: row,
							newStatus,
						})
						setShowRemarkModal(true)
					}

					break
				}

				case 'open_listing': {
					const { type, docType } = meta || {}

					// get the active record
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$LISTING,
							{
								rootModule: type,
								submodule: docType,
							}
						)
					)
					break
				}

				case 'open_creation_Workflow': {
					const { docType } = meta || {}

					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$WACTION,
							{
								rootModule: docType,
								action: 'attachDetails',
							}
						)
					)
					break
				}

				case 'create_attachment': {
					const { docType } = meta || {}

					dispatch(
						docType === 'weighbridge-slip'
							? MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.WEIGHBRIDGE$WACTION,
									{
										action: 'create',
									},
									{
										parentDocRef: meta.row.id,
									}
							  )
							: MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.MILL$WACTION,
									{
										rootModule: docType,
										action: 'create',
									},
									{
										parentDocRef: meta.row.id,
									}
							  )
					)
					break
				}

				case 'handle_accept_checkbox': {
					const { checkboxData, entity } = meta || {}

					dispatch(
						TradeDocDuc.creators.setUploadDOAcceptedList(
							entity,
							checkboxData
						)
					)

					break
				}

				case 'handle_draft_accept_checkbox': {
					const { checkboxData, entity } = meta || {}

					dispatch(
						TradeDocDuc.creators.setUploadDODraftAcceptedList(
							entity,
							checkboxData
						)
					)

					break
				}

				case 'handle_draft_change': {
					const { data, currentUploadDODraftAcceptedList = [] } =
						meta || {}

					const excelUploads = hasIncoming
						? [...currentActiveIncomingDocuments['excel-uploads']]
						: [...currentActiveOutgoingDocuments['excel-uploads']]
					const foundIndex = excelUploads.findIndex(
						x => x.id === data.id
					)
					excelUploads[foundIndex] = data
					if (currentUploadDODraftAcceptedList?.length > 0) {
						const tempArray = [...currentUploadDODraftAcceptedList]

						const foundIndexAccepted = tempArray.findIndex(
							x => x.id === data.id
						)
						tempArray[foundIndexAccepted] = data
						dispatch(
							TradeDocDuc.creators.setUploadDODraftAcceptedList(
								tempArray,
								{},
								true
							)
						)
					}
					if (hasIncoming) {
						setCurrentActiveIncomingDocuments({
							...currentActiveIncomingDocuments,
							'excel-uploads': excelUploads,
						})
					} else {
						setCurrentActiveOutgoingDocuments({
							...currentActiveOutgoingDocuments,
							'excel-uploads': excelUploads,
						})
					}

					break
				}

				case 'finalize_remark_status': {
					const { remarks } = meta
					const documentStatusPropsList = {
						docRefId: activeRecord.id,
						docType: activeRecord.type,
						newStatus: activeRecord.newStatus,
						remarks,
						successMsg: t('common.statusUpdateSuccess'),
						errorMsg: t('common.statusUpdateError'),
						postChange: () => {
							// retrigger the current route so new data is fetched again.
							dispatch(
								MainRouteDuc.creators.redirect(
									location.type,
									location.payload,
									location.query
								)
							)
						},
					}
					// get the active record
					dispatch(
						TradeDocDuc.creators.changeDocumentStatus(
							documentStatusPropsList
						)
					)

					break
				}

				case 'reset_remark_status': {
					setActiveRecord({})

					break
				}

				case 'open_contract': {
					if (meta.row) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE$CONTRACTS,
								{
									rootModule:
										meta.type === 'incoming'
											? 'seller'
											: 'buyer',
									action: meta.action,
									documentReference: meta.row.id,
								},
								{},
								{},
								true
							)
						)
					}
					break
				}

				case 'view_rfq': {
					if (meta.row.id) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE$ACTION,
								{
									rootModule:
										meta.type === 'incoming'
											? 'seller'
											: 'buyer',
									action: 'view-rfq',
									documentReference: meta.row.id,
								},
								{},
								{},
								true
							)
						)
					}
					break
				}

				case 'view_quote': {
					if (meta.row.id) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE$ACTION,
								{
									rootModule:
										meta.type === 'incoming'
											? 'buyer'
											: 'seller',
									action: 'view-quote',
									documentReference: meta.row.id,
								},
								{},
								{},
								true
							)
						)
					}
					break
				}

				default:
					break
			}
		},
		[
			dispatch,
			location,
			hasIncoming,
			currentActiveIncomingDocuments,
			currentActiveOutgoingDocuments,
			activeRecord.id,
			activeRecord.type,
			activeRecord.newStatus,
			t,
		]
	)

	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	const getItems = () => {
		const items = [
			{
				label: t('tdmColumnHeaders.delivery-order').toUpperCase(),
				name: 'delivery-order',
			},
		]
		if (
			!actor.includes('palmoil_storage') &&
			currentPlatform() === 'palmoil'
		) {
			items.push({
				label: t('tdmColumnHeaders.uploadDeliveryOrder').toUpperCase(),
				name: 'upload-delivery-order',
			})
			items.push({
				label: t('tdmColumnHeaders.uploadWeighbridge').toUpperCase(),
				name: 'upload-weighbridge',
			})
		}
		if (!(isDealer || isSubDealer || isCP)) {
			items.push({
				label: t('tdmColumnHeaders.purchase-order').toUpperCase(),
				name: 'purchase-order',
			})
			items.push({
				label: t('tdmColumnHeaders.invoice').toUpperCase(),
				name: 'invoice',
			})
		}
		if (!isPlantation) {
			items.push({
				label: t(
					'tdmColumnHeaders.incoming-delivery-order'
				).toUpperCase(),
				name: 'incoming-delivery-order',
			})
		}

		return items
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	useEffect(() => {
		dispatch(TradeDocDuc.creators.setUploadDODraftAcceptedList(null, {}))
	}, [dispatch])

	useEffect(() => {
		const currentList = []
		if (partnerList?.length > 0) {
			partnerList.forEach(element => {
				if (!currentList.find(item => item.id === element?.id)) {
					currentList.push({
						id: element?.id,
						label: element?.name,
						value: element?.id,
					})
				}
			})
		}
		if (globalOrgs?.length > 0) {
			globalOrgs.forEach(element => {
				if (!currentList.find(item => item.id === element?.id)) {
					currentList.push({
						id: element?.id,
						label: element?.label,
						value: element?.id,
					})
				}
			})
		}

		setFullOrgList(currentList)
	}, [partnerList, globalOrgs])

	return (
		<>
			<Box id="tdmMain" padding={8} width="100%" height="100%">
				<Modal
					closeable
					show={showModal}
					heading={t('topBar.logout')}
					body={
						<P large bold>
							{t('topBar.logoutMsg')}
						</P>
					}
					closelabel={t('common.no')}
					confirmlabel={t('common.yes')}
					onClose={() => {
						setShowModal(false)
					}}
					onConfirm={() => {
						dispatch(
							AuthDuc.creators.signOutUser(window.location.href)
						)
					}}
					isMobile={isMobile}
				/>
				<Modal
					closeable
					show={showAcceptAllDODraftModal}
					heading={`${t('common.create')} ${t(
						'tdmTabConfig.delivery-orders'
					)}`}
					body={
						<P large bold>
							{t('tdmDocumentListing.acceptAllDOModalContent')}
						</P>
					}
					closelabel={t('common.no')}
					confirmlabel={t('common.yes')}
					onClose={() => {
						setShowAcceptAllDODraftModal(false)
					}}
					onConfirm={() => {
						dispatch(
							TradeDocDuc.creators.setDODraftAcceptAllLoading(
								true
							)
						)
						const documentPropsList = {
							rootModule: 'excel-uploads',
							document: uploadDODraftAcceptedList,
							currentDocId: '',
							t,
							doSuccessMsg: t('common.doSuccess'),
							fromPlantation: actor.includes(
								'palmoil_plantation'
							),
							actionType: 'submit',
							rootType: hasIncoming ? 'incoming' : 'outgoing',
						}
						dispatch(
							TradeDocDuc.creators.initiateDocumentSave(
								documentPropsList
							)
						)
						setShowAcceptAllDODraftModal(false)
					}}
					isMobile={isMobile}
				/>
				{!isIframeForTeamsApp() && (
					<>
						<Box
							row
							style={{ padding: '0 5' }}
							justifyContent="flex-start"
						>
							<Breadcrumb
								links={breadCrumbs}
								onClick={target =>
									handleBreadCrumbClick(target)
								}
							/>
						</Box>
						<Box
							style={
								isMobile
									? { flexDirection: 'column' }
									: { flexDirection: 'row' }
							}
							justifyContent="space-between"
							alignItems="baseline"
						>
							<Title
								title={t('mainDashboard.tradeDocManager')}
								icon={tradeDocIcon}
							/>
							<Box>
								{!selectedCPID && (
									<Box width={200} id="tdmCreate">
										<ButtonWithDrop
											disabled={
												incomingLoadingStates.length > 0
											}
											label={t(
												'common.createNew'
											).toUpperCase()}
											items={getItems()}
											onChange={active =>
												handleOnChange(
													'open_creation_Workflow',
													{
														docType: active,
													}
												)
											}
										/>
									</Box>
								)}
							</Box>
						</Box>
					</>
				)}
				{isIframeForTeamsApp() && (
					<div
						style={{
							justifyContent: isMobile ? 'center' : 'end',
							display: isMobile ? 'grid' : 'flex',
						}}
					>
						<Box
							style={{
								marginRight: '20px',
								marginBottom: isMobile ? '10px' : '0px',
							}}
						>
							<Box>
								{!selectedCPID && (
									<Box width={150} id="tdmCreate">
										<ButtonWithDrop
											disabled={
												incomingLoadingStates.length > 0
											}
											label={t(
												'common.createNew'
											).toUpperCase()}
											items={getItems()}
											onChange={active =>
												handleOnChange(
													'open_creation_Workflow',
													{
														docType: active,
													}
												)
											}
											noBackground
											borderRadius="5px"
											// backgroundColor: '#2a2364',
										/>
									</Box>
								)}
							</Box>
						</Box>
						<Box
							style={{
								marginRight: '20px',
								alignItems: 'center',
								marginBottom: isMobile ? '10px' : '0px',
							}}
						>
							<Button
								extendStyles={{
									width: '100px',
									borderRadius: '4px',
									fontSize: '16px',
									background: '#2A2364',
									padding: '6px',
									textAlign: 'center',
									textDecoration: 'solid',
								}}
								onClick={() => {
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types
												.TRADE_DOCUMENT_MANAGER,
											{
												rootModule: hasIncoming
													? 'outgoing'
													: 'incoming',
											}
										)
									)
								}}
								label={
									hasIncoming
										? t('common.outgoing')
										: t('common.incoming')
								}
								primary
								plain
							/>
						</Box>
						<Box
							style={{
								marginRight: '20px',
								alignItems: 'center',
							}}
						>
							<Button
								extendStyles={{
									width: '100px',
									borderRadius: '4px',
									fontSize: '16px',
									background: '#2A2364',
									padding: '6px',
									textAlign: 'center',
									textDecoration: 'solid',
								}}
								onClick={() => setShowModal(true)}
								label={t('topBar.logout')}
								primary
								plain
							/>
						</Box>
					</div>
				)}

				<Box id="tdmListing">
					{hasIncoming && (
						<>
							<Box
								row={!isMobile}
								style={{ padding: '0 5', alignItems: 'center' }}
								justifyContent={
									isMobile ? 'center' : 'space-between'
								}
							>
								<Title
									title={t('common.incoming')}
									icon={Incoming}
								/>
								{(incomingActiveTab === 'delivery-order' ||
									incomingActiveTab === 'excel-uploads') && (
									<Button
										label={
											incomingActiveTab ===
											'excel-uploads'
												? `${t('common.create')} ${t(
														'tdmTabConfig.delivery-orders'
												  )}`
												: `${t(
														'tdmDetailsEntry.acceptAllSelected'
												  )} DOs`
										}
										type="submit"
										extendStyles={{
											width: 'auto',
											height: '41px',
											minWidth: '200px',
											marginBottom: isMobile
												? '10px'
												: '0px',
										}}
										primary
										rounded
										disabled={
											incomingActiveTab ===
											'excel-uploads'
												? uploadDODraftAcceptedList.length ===
														0 ||
												  acceptAllDraftButtonLoading
												: uploadDOAcceptedList.length ===
														0 ||
												  acceptAllButtonLoading
										}
										onClick={() => {
											if (
												incomingActiveTab ===
												'excel-uploads'
											) {
												setShowAcceptAllDODraftModal(
													true
												)
											} else {
												dispatch(
													TradeDocDuc.creators.setDOAcceptAllLoading(
														true
													)
												)
												const documentStatusPropsList = {
													docType: 'delivery-order',
													successMsg: t(
														'common.statusUpdateSuccess'
													),
													errorMsg: t(
														'common.statusUpdateError'
													),
													uploadDOAcceptedList,
													fromUploadDoSelect: true,
													postChange: () => {
														// retrigger the current route so new data is fetched again.
														dispatch(
															MainRouteDuc.creators.redirect(
																location.type,
																location.payload,
																location.query
															)
														)
													},
												}
												// get the active record
												dispatch(
													TradeDocDuc.creators.changeDocumentStatus(
														documentStatusPropsList
													)
												)
											}
										}}
									/>
								)}
							</Box>
							<Tabs
								id="TDMTabs"
								tabs={getTabs('incoming')}
								tabComponentsMap={getTabComponents({
									type: 'incoming',
									sortsMap: activeSorts.incoming,
									rows: currentActiveIncomingDocuments,
									onChange: handleOnChange,
									orgDetails,
									loadingStatus: incomingLoadingStates,
									activeLocale,
									actor,
									t,
									getCurrentOrgID,
									isMobile,
									setFullOrgList,
									selectedCPID,
									currentPlatform: currentPlatform(),
									uploadDOAcceptedList,
									uploadDODraftAcceptedList,
									isIframeForTeamsApp: isIframeForTeamsApp(),
									fullOrgList,
									partnerList,
									allProducts,
								})}
								activeTabIndex={outgoingActiveTabs.findIndex(
									type => type.code === incomingActiveTab
								)}
								onChange={({ name }) => {
									setIncomingActiveTab(name)
									dispatch(
										TradeDocDuc.creators.setIncomingActiveTab(
											name
										)
									)
								}}
								isMobile={isMobile}
							/>
						</>
					)}

					{hasOutgoing && (
						<>
							<Box
								row={!isMobile}
								style={{ padding: '0 5', alignItems: 'center' }}
								justifyContent="space-between"
							>
								<Title
									title={t('common.outgoing')}
									icon={Outgoing}
								/>
								{outgoingActiveTab === 'excel-uploads' &&
									!isIframeForTeamsApp() && (
										<Button
											label={
												outgoingActiveTab ===
												'excel-uploads'
													? `${t(
															'common.create'
													  )} ${t(
															'tdmTabConfig.delivery-orders'
													  )}`
													: `${t(
															'tdmDetailsEntry.acceptAllSelected'
													  )} DOs`
											}
											type="submit"
											extendStyles={{
												width: 'auto',
												height: '41px',
												minWidth: '200px',
											}}
											primary
											rounded
											disabled={
												outgoingActiveTab ===
												'excel-uploads'
													? uploadDODraftAcceptedList.length ===
															0 ||
													  acceptAllDraftButtonLoading
													: uploadDOAcceptedList.length ===
															0 ||
													  acceptAllButtonLoading
											}
											onClick={() => {
												if (
													outgoingActiveTab ===
													'excel-uploads'
												) {
													setShowAcceptAllDODraftModal(
														true
													)
												} else {
													dispatch(
														TradeDocDuc.creators.setDOAcceptAllLoading(
															true
														)
													)
													const documentStatusPropsList = {
														docType:
															'delivery-order',
														successMsg: t(
															'common.statusUpdateSuccess'
														),
														errorMsg: t(
															'common.statusUpdateError'
														),
														uploadDOAcceptedList,
														fromUploadDoSelect: true,
														postChange: () => {
															// retrigger the current route so new data is fetched again.
															dispatch(
																MainRouteDuc.creators.redirect(
																	location.type,
																	location.payload,
																	location.query
																)
															)
														},
													}
													// get the active record
													dispatch(
														TradeDocDuc.creators.changeDocumentStatus(
															documentStatusPropsList
														)
													)
												}
											}}
										/>
									)}
							</Box>
							<Tabs
								id="TDMTabs"
								tabs={getTabs('outgoing')}
								tabComponentsMap={getTabComponents({
									type: 'outgoing',
									sortsMap: activeSorts.outgoing,
									rows: currentActiveOutgoingDocuments,
									onChange: handleOnChange,
									orgDetails,
									loadingStatus: outgoingLoadingStates,
									activeLocale,
									isMobile,
									setFullOrgList,
									actor,
									t,
									getCurrentOrgID,
									selectedCPID,
									currentPlatform: currentPlatform(),
									uploadDODraftAcceptedList,
									isIframeForTeamsApp: isIframeForTeamsApp(),
									fullOrgList,
									partnerList,
									allProducts,
								})}
								activeTabIndex={outgoingActiveTabs.findIndex(
									type => type.code === outgoingActiveTab
								)}
								onChange={({ name }) => {
									setOutgoingActiveTab(name)
									dispatch(
										TradeDocDuc.creators.setOutgoingActiveTab(
											name
										)
									)
								}}
								isMobile={isMobile}
							/>
						</>
					)}
				</Box>
			</Box>
			<RemarksConfirmation
				showModal={showRemarkModal}
				handleOnConfirm={remarks => {
					setShowRemarkModal(false)
					handleOnChange('finalize_remark_status', remarks)
				}}
				handleOnClose={() => {
					setShowRemarkModal(false)
					handleOnChange('reset_remark_status')
				}}
			/>
		</>
	)
}

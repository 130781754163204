import PartnerIcon from 'ui-lib/icons/partner.svg'
import Trade from 'ui-lib/icons/trade.svg'
import AdminIcon from 'ui-lib/icons/admin.svg'
import Trust from 'ui-lib/icons/trust.svg'
import Home from 'ui-lib/icons/home.svg'
import Truck from 'ui-lib/icons/delivery-truck.svg'
import Receipt from 'ui-lib/icons/receipt.svg'
import SettingsIcon from 'ui-lib/icons/settings.svg'
import ProductionIcon from 'ui-lib/icons/production.svg'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import Records from 'ui-lib/icons/records.svg'

export const initialState = {
	isTourOpen: false,
	tourPopupOpen: false,
	activeTourModule: '',
	tourStatus: false,
	dashboardVisit: false,
	tdmVisit: false,
	partnerVisit: false,
	productionVisit: false,
	traceVisit: false,
	tripsVisit: false,
	wbtktVisit: false,
	auditVisit: false,
	adminVisit: false,
	recordsVisit: false,
	settingsVisit: false,
	fullTour: false,
	isCreateTourOpen: false,
	languageUpdate: false,
	passwordUpdate: false,
	workCreate: false,
	deliveryCreate: false,
	scheduleCreate: false,
	createIpBatch: false,
	createOpBatch: false,
	createPartner: false,
	createTicket: false,
	createBin: false,
	assetsUpdate: false,
	companyInfoUpdate: false,
	employeeUpdate: false,
	tdmView: false,
}
export const DIbizTourActorBased = actor => {
	let tourArray = []
	if (
		actor === 'rice_trader' ||
		actor === 'rice_packingcompany' ||
		actor === 'rice_packer'
	) {
		tourArray = getDIbizTourGeneric.palmoil_trader
	} else if (actor === 'rice_mill') {
		tourArray = getDIbizTourGeneric.palmoil_mill
	} else if (actor === 'rice_dealer') {
		tourArray = getDIbizTourGeneric.palmoil_ffbdealer
	} else if (
		actor === 'rice_wholesaler' ||
		actor === 'rice_importer' ||
		actor === 'rice_endbuyer'
	) {
		tourArray = getDIbizTourGeneric.palmoil_manufacturer
	} else {
		tourArray = getDIbizTourGeneric[actor]
	}

	return tourArray
}

export const getDIbizTourGeneric = {
	palmoil_plantation: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PLANTATION
			),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'records',
			label: 'records',
			iconName: Records,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PLANTATION$SUBROOT,
				{
					rootModule: 'document',
				}
			),
		},
		{
			index: 4,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 5,
			name: 'createPartner',
			label: 'createPartner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 6,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 7,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 8,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 11,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 12,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_plantation_administrator: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PLANTATION
			),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'records',
			label: 'records',
			iconName: Records,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PLANTATION$SUBROOT,
				{
					rootModule: 'document',
				}
			),
		},
		{
			index: 4,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 5,
			name: 'createPartner',
			label: 'createPartner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 6,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 7,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 8,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 11,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 12,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_plantation_officemgmt: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PLANTATION
			),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'records',
			label: 'records',
			iconName: Records,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PLANTATION$SUBROOT,
				{
					rootModule: 'document',
				}
			),
		},
		{
			index: 4,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 5,
			name: 'createPartner',
			label: 'createPartner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 6,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 7,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 8,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 10,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 11,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_mill: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.MILL),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'production',
			label: 'production',
			iconName: ProductionIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 5,
			name: 'createIpBatch',
			label: 'Create Input Batch',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 6,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 7,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 8,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 11,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 12,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 13,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 14,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_mill_administrator: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.MILL),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'production',
			label: 'production',
			iconName: ProductionIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 5,
			name: 'createIpBatch',
			label: 'Create Input Batch',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 6,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 7,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 8,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 11,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 12,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 13,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 14,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_mill_officemgmt: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.MILL),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'production',
			label: 'production',
			iconName: ProductionIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 5,
			name: 'createIpBatch',
			label: 'Create Input Batch',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 6,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 7,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 8,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 11,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 12,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 13,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_kernelmill: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.MILL),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'production',
			label: 'production',
			iconName: ProductionIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 5,
			name: 'createIpBatch',
			label: 'Create Input Batch',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 6,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 7,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 8,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 11,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 12,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 13,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 14,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_kernelmill_administrator: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.MILL),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'production',
			label: 'production',
			iconName: ProductionIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 5,
			name: 'createIpBatch',
			label: 'Create Input Batch',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 6,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 7,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 8,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 11,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 12,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 13,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 14,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_kernelmill_officemgmt: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.MILL),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'production',
			label: 'production',
			iconName: ProductionIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 5,
			name: 'createIpBatch',
			label: 'Create Input Batch',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 6,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 7,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 8,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 11,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 12,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 13,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_refinery: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY
			),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'production',
			label: 'production',
			iconName: ProductionIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 5,
			name: 'createIpBatch',
			label: 'Create Input Batch',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 6,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 7,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 8,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 11,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 12,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 13,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 14,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_refinery_administrator: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY
			),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'production',
			label: 'production',
			iconName: ProductionIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 5,
			name: 'createIpBatch',
			label: 'Create Input Batch',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 6,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 7,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 8,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 11,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 12,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 13,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 14,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_refinery_officemgmt: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY
			),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'production',
			label: 'production',
			iconName: ProductionIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 5,
			name: 'createIpBatch',
			label: 'Create Input Batch',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.REFINERY$SUBROOT,
				{
					rootModule: 'production',
				}
			),
		},
		{
			index: 6,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 7,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 8,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 11,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 12,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 13,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_storage: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.STORAGE_COMPANY
			),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 5,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 6,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 7,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 8,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 11,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 12,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_storage_administrator: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.STORAGE_COMPANY
			),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 5,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 6,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 7,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 8,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 11,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 12,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_storage_officemgmt: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.STORAGE_COMPANY
			),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 5,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 6,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 7,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 8,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 10,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 11,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_trader: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.TRADER),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 5,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 6,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 7,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 8,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 11,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 12,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_trader_administrator: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.TRADER),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 5,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 6,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 7,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 8,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 11,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 12,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_trader_officemgmt: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.TRADER),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 5,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 6,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 7,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 8,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 10,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 11,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_manufacturer: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.END_MANUFACTURER
			),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 5,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 6,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 7,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 8,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 11,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 12,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_manufacturer_administrator: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.END_MANUFACTURER
			),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 5,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 6,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 7,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 8,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 11,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 12,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_manufacturer_officemgmt: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.END_MANUFACTURER
			),
		},
		{
			index: 1,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 2,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'productTrace',
			label: 'productTrace',
			iconName: Trust,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.PRODUCT_TRACE
			),
		},
		{
			index: 4,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 5,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 6,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 7,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 8,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 9,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 10,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 11,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_ffbdealer: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.DEALER),
		},
		{
			index: 1,
			name: 'workCreate',
			label: 'Create Work',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.DEALER$SUBROOT,
				{
					rootModule: 'work',
				}
			),
		},
		{
			index: 2,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 4,
			name: 'trips',
			label: 'trips',
			iconName: Truck,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.TRIPS),
		},
		{
			index: 5,
			name: 'wbTickets',
			label: 'wbTickets',
			iconName: Receipt,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.WBTICKET
			),
		},
		{
			index: 6,
			name: 'uploadWBTkt',
			label: 'uploadWBTkt',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.WBTICKET
			),
		},
		{
			index: 7,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 8,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 9,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 11,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 12,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 13,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 14,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 15,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_ffbdealer_administrator: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.DEALER),
		},
		{
			index: 1,
			name: 'workCreate',
			label: 'Create Work',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.DEALER$SUBROOT,
				{
					rootModule: 'work',
				}
			),
		},
		{
			index: 2,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 4,
			name: 'trips',
			label: 'trips',
			iconName: Truck,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.TRIPS),
		},
		{
			index: 5,
			name: 'wbTickets',
			label: 'wbTickets',
			iconName: Receipt,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.WBTICKET
			),
		},
		{
			index: 6,
			name: 'uploadWBTkt',
			label: 'uploadWBTkt',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.WBTICKET
			),
		},
		{
			index: 7,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 8,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 9,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 11,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 12,
			name: 'companyInfoHeader',
			label: 'companyInfoHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 13,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 14,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 15,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
	palmoil_ffbdealer_officemgmt: [
		{
			index: 0,
			name: 'dashboard',
			label: 'dashboard',
			iconName: Home,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.DEALER),
		},
		{
			index: 1,
			name: 'workCreate',
			label: 'Create Work',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.DEALER$SUBROOT,
				{
					rootModule: 'work',
				}
			),
		},
		{
			index: 2,
			name: 'tdm',
			label: 'tdm',
			iconName: Trade,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 3,
			name: 'DOCreate',
			label: 'Create Delivery Order',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'incoming',
				}
			),
		},
		{
			index: 4,
			name: 'trips',
			label: 'trips',
			iconName: Truck,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.TRIPS),
		},
		{
			index: 5,
			name: 'wbTickets',
			label: 'wbTickets',
			iconName: Receipt,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.WBTICKET
			),
		},
		{
			index: 6,
			name: 'uploadWBTkt',
			label: 'uploadWBTkt',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.WBTICKET
			),
		},
		{
			index: 7,
			name: 'partnerMgmt',
			label: 'partnerMgmt',
			iconName: PartnerIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 8,
			name: 'createPartner',
			label: 'Create Partner',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER),
		},
		{
			index: 9,
			name: 'admin',
			label: 'admin',
			iconName: AdminIcon,
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 10,
			name: 'assetMgmtHeader',
			label: 'assetMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 11,
			name: 'employeeMgmtHeader',
			label: 'employeeMgmtHeader',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN),
		},
		{
			index: 12,
			name: 'settings',
			label: 'settings',
			iconName: SettingsIcon,
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 13,
			name: 'passwordUpdate',
			label: 'Change Password',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
		{
			index: 14,
			name: 'languageUpdate',
			label: 'Change Language',
			iconName: 'none',
			route: MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.SETTINGS
			),
		},
	],
}

import React, { useMemo, useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn, mergeIn } from 'timm'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { WebTourDuc } from 'ui-tdm-app/modules/WebTour/duc'
import { WebTour } from 'ui-tdm-app/modules/WebTour'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { NAME_ALIASES } from 'ui-tdm-app/modules/TradeDocumentManager/config'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import backArrow from 'ui-lib/icons/left-arrow.svg'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import theme from 'ui-lib/utils/base-theme'
import { DocumentView } from 'ui-tdm-app/modules/TradeDocumentManager/components/DocumentView'

const HeaderWrapper = styled.div`
	display: flex;
	margin-top: 24px;
	margin-bottom: 24px;
`
const Header = styled.div`
	font-size: ${props => (props.isMobile ? '25px' : '30px')};
	font-weight: 500;
	margin-top: -6px;
	padding-left: 28px;
	color: ${theme.color.accent2};
`
const SubText = styled.div`
	padding-top: 3px;
	padding-left: 10px
	color: ${theme.color.blue8};
`

const SubWrapper = styled.div`
	display: flex;
	cursor: pointer;
	color: ${theme.color.blue8};
`

const NewPreview = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const locationState = useSelector(TradeDocDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const actor = useSelector(AuthDuc.selectors.getActor)
	const isDealer =
		actor.some(str => str.includes('palmoil_ffbdealer')) ||
		actor.some(str => str.includes('rice-dealer'))
	const isSubDealer = actor.some(str => str.includes('palmoil_ffbsubdealer'))

	const [attachfileArray, setAttachfileArray] = useState({})
	const [currentDoc, setCurrentDoc] = useState({})
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)
	const activeDocument = useSelector(
		TradeDocDuc.selectors.getDocumentActiveRecord
	)

	const { id: _partnerID } =
		useSelector(TradeDocDuc.selectors.getDocumentActivePartner) || ''

	const receivingPartyDetails = useSelector(state =>
		AuthDuc.selectors.getPartnerOrganizationByID(state, _partnerID)
	)

	const { isLoading } = useSelector(
		TradeDocDuc.selectors.getDocumentLoadingState
	)

	useEffect(() => {
		if (
			locationState?.payload?.documentReference &&
			locationState?.payload?.documentStatus
		) {
			dispatch(
				TradeDocDuc.creators.fetchDocumentAttachments({
					documentReference: locationState.payload.documentReference,
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationState])

	const { payload = {} } = locationState

	const { rootModule, action, documentStatus } = payload

	const breadCrumRootModule = getIn(locationState, [
		'prev',
		'payload',
		'rootModule',
	])
		? getIn(locationState, ['prev', 'payload', 'rootModule'])
		: null
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		} else if (target === 'trade-doc-dashboard') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule:
							breadCrumRootModule === 'incoming' ||
							breadCrumRootModule === 'outgoing' ||
							breadCrumRootModule === 'all'
								? getIn(locationState, [
										'prev',
										'payload',
										'rootModule',
								  ])
								: 'incoming',
					}
				)
			)
		}
	}

	const getBreadCrumbsList = useCallback(
		({ title }) => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.tradeDocManager'),
				name: 'trade-doc-dashboard',
				isActive: true,
			},
			...(action === 'details'
				? [
						{
							label: `${t('breadcrumb.new')} ${t(
								actor.some(str =>
									str.includes('palmoil_tdm')
								) && rootModule === 'purchase-order'
									? t('breadcrumb.salesOrder')
									: title
							)} - ${t('breadcrumb.details')}`,
							name: 'document-details',
							isActive: false,
						},
				  ]
				: [
						{
							label: `${t('breadcrumb.new')} ${t(
								actor.some(str =>
									str.includes('palmoil_tdm')
								) && rootModule === 'purchase-order'
									? t('breadcrumb.salesOrder')
									: title
							)} - ${t('breadcrumb.attach')}`,
							name: 'document-attach',
							isActive: false,
						},
				  ]),
		],
		[action, actor, rootModule, t]
	)

	const title = NAME_ALIASES[rootModule]
	const breadCrumbs = useMemo(() => getBreadCrumbsList({ title }), [
		getBreadCrumbsList,
		title,
	])

	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	useEffect(() => {
		if (tourModule === 'DOCreate') {
			if (rootModule === 'purchase-order') {
				dispatch(WebTourDuc.creators.setActiveTourModule('POCreate'))
			}
			if (rootModule === 'delivery-order') {
				dispatch(WebTourDuc.creators.setActiveTourModule('DOCreate2'))
			}
			if (rootModule === 'invoice') {
				dispatch(
					WebTourDuc.creators.setActiveTourModule('invoiceCreate')
				)
			}
		}
	}, [tourModule, dispatch, rootModule])

	const getText = data => {
		let text = ''
		if (data === 'main-header') {
			if (rootModule === 'purchase-order') {
				text = `${t('tdmColumnHeaders.purchase-order')} - ${t(
					'common.preview'
				)}`
			} else if (rootModule === 'invoice') {
				text = `${t('tdmColumnHeaders.invoice')} - ${t(
					'common.preview'
				)}`
			} else if (rootModule === 'delivery-order') {
				text = `${t('tdmColumnHeaders.delivery-order')} - ${t(
					'common.preview'
				)}`
			}
		}

		return text
	}

	const attachmentDoc = useSelector(TradeDocDuc.selectors.getAttachmentDoc)

	useEffect(() => {
		if (
			locationState?.payload?.documentReference &&
			locationState?.payload?.documentStatus &&
			locationState?.payload?.documentStatus !== 'edit' &&
			attachmentDoc &&
			attachmentDoc.length > 0
		) {
			attachmentDoc.forEach(doc => {
				if (
					getIn(doc, ['type']) === 'file' &&
					getIn(doc, ['organizationID']) === getCurrentOrgID
				) {
					setAttachfileArray(getIn(doc, ['file']))
					const target = {
						attachmentFlow: true,
						attachedEntity: getIn(doc, ['file']),
					}
					const currDoc = mergeIn(activeDocument, ['meta'], target)
					setCurrentDoc(currDoc)
				}
			})
		} else {
			const currDoc = { ...activeDocument }
			const currDocMeta = { ...activeDocument.meta }
			setAttachfileArray(
				getIn(activeDocument, ['meta', 'attachedEntity']) || {}
			)
			if (!getIn(activeDocument, ['meta', 'attachedEntity'])) {
				if (
					!getIn(activeDocument, ['meta', 'attachedEntity']) &&
					currDocMeta.attachmentFlow
				) {
					currDocMeta.attachmentFlow = false
					currDoc.meta = currDocMeta
				}
			}
			setCurrentDoc(currDoc)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attachmentDoc, getCurrentOrgID])

	const getAssociatedRef = type => {
		let data = ''
		if (
			getIn(activeDocument, ['meta', 'docReference']) &&
			getIn(activeDocument, ['meta', 'docReference']).length > 0
		) {
			getIn(activeDocument, ['meta', 'docReference']).forEach(item => {
				if (item.referenceDocType === type) {
					data = item.referenceValue
				}
			})
		}

		return data
	}

	const getSupplyChainModelLabel = () => {
		let label = ''
		if (getIn(activeDocument, ['meta', 'supplyChainModel', 'name'])) {
			label = getIn(activeDocument, ['meta', 'supplyChainModel', 'name'])
		} else if (getIn(activeDocument, ['products', 0, 'supplyChainModel'])) {
			label = t(
				`tdmDetailsEntry.${getIn(activeDocument, [
					'products',
					0,
					'supplyChainModel',
				])}`
			)
		}

		return label
	}

	const toBeShippedTo = useSelector(state =>
		AuthDuc.selectors.getPartnerOrganizationByID(
			state,
			currentDoc?.meta?.productFor
		)
	)

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>

				<Box
					row
					justifyContent="space-between"
					alignItems="baseline"
					style={{
						padding: '10px 0 15px',
					}}
				>
					<HeaderWrapper>
						<SubWrapper
							onClick={() => {
								if (
									locationState?.payload?.documentReference &&
									locationState?.payload?.documentStatus
								) {
									const data = { ...activeDocument }
									if (
										documentStatus &&
										documentStatus === 'draft'
									) {
										data.fromDraft = true
									}
									data.fromPreview = true
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types
												.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE,
											{
												rootModule,
												action: 'attachDetails',
												documentReference:
													locationState.payload
														.documentReference,
												documentStatus:
													locationState.payload
														.documentStatus,
												baseValueSchema: data,
											}
										)
									)
								} else {
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types
												.TRADE_DOCUMENT_MANAGER$WACTION,
											{
												rootModule,
												action: 'attachDetails',
											}
										)
									)
								}
							}}
						>
							<IconWrapper
								size={24}
								style={{
									color: theme.color.blue8,
									cursor: 'pointer',
								}}
							>
								<Icon glyph={backArrow} />
							</IconWrapper>
							<SubText>{t('common.back')}</SubText>
						</SubWrapper>
						{!(isDealer || isSubDealer) && (
							<Header isMobile={isMobile}>
								{getText('main-header')}
							</Header>
						)}
					</HeaderWrapper>
				</Box>

				<Spacer size={8} />

				<DocumentView
					preview
					rootModule={rootModule}
					partner={
						getIn(activeDocument, ['meta', 'receiverEmailID']) ||
						getIn(activeDocument, ['meta', 'enableGlobalPartner'])
							? ''
							: receivingPartyDetails
					}
					brokerEmailID={
						getIn(activeDocument, ['meta', 'brokerEmailID']) || ''
					}
					receiverEmailID={
						getIn(activeDocument, ['meta', 'receiverEmailID']) || ''
					}
					associatedPORef={getAssociatedRef('purchase-order') || ''}
					associatedDORef={getAssociatedRef('delivery-order') || ''}
					associatedInvoiceRef={getAssociatedRef('invoice') || ''}
					supplyChainModel={getSupplyChainModelLabel()}
					productDetails={getIn(activeDocument, ['products']) || []}
					tankDetails={getIn(activeDocument, ['tankDetails']) || []}
					user={
						getIn(activeDocument, [
							'meta',
							'authorization',
							'reportedBy',
						]) || ''
					}
					role={
						getIn(activeDocument, [
							'meta',
							'authorization',
							'reportedByRole',
						]) || ''
					}
					issueDate={
						getIn(activeDocument, ['meta', 'issueDate']) || ''
					}
					attachedFiles={attachfileArray || {}}
					status="DRAFT"
					documentNumber={
						getIn(activeDocument, ['meta', 'entityReference']) || ''
					}
					activeDocument={activeDocument}
					hasInitiatedByCurrentOrg={
						rootModule !== 'incoming-delivery-order'
					}
					actor={actor}
					isMobile={isMobile}
					toBeShippedTo={toBeShippedTo}
				/>
				<Spacer size={32} horizontal={!isMobile} />

				<Box row justifyContent={isMobile ? 'center' : 'flex-end'}>
					<Button
						onClick={() => {
							const documentPropsList = {
								rootModule,
								document: currentDoc,
								currentDocId: '',
								successMsg: t(
									'tdmDetailsEntry.entityCreatedMsg'
								),
								t,
								poSuccessMsg: t('common.poSuccess'),
								invoiceSuccessMsg: t('common.invoiceSuccess'),
								doSuccessMsg: t('common.doSuccess'),
								fromPlantation: actor.some(str =>
									str.includes('palmoil_plantation')
								),
								actionType: 'submit',
							}
							dispatch(
								TradeDocDuc.creators.initiateDocumentSave(
									documentPropsList
								)
							)
						}}
						isLoading={isLoading}
						label={t('common.send')}
						primary
						rounded
						extendStyles={{
							width: '232px',
						}}
					/>
				</Box>
			</Box>
			{(tourModule === 'DOCreate2' ||
				tourModule === 'POCreate' ||
				tourModule === 'invoiceCreate') && <WebTour />}
		</>
	)
}

export { NewPreview }

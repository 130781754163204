import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	NotFoundBlock,
	UnexpectedErrorBlock,
} from 'ui-tdm-app/modules/App/components/Error'
import { useInjectSaga } from 'ui-tdm-app/store/injectSaga'
import saga from 'ui-tdm-app/modules/TradeDocumentManager/TradeDocManagerSaga'
import { TradeDocDuc } from './duc'
import { MainDashboard } from './containers/MainDashboard'
import { SubDashboard } from './containers/SubDashboard'
import { DocumentsListing } from './containers/DocumentListing'
import { DocumentsControl } from './containers/DocumentControl'
import { WebTour } from '../WebTour'
import { WebTourDuc } from '../WebTour/duc'
// eslint-disable-next-line import/extensions
import { AuditReport } from './containers/AuditReport/index.js'
import { CompleteTraceReport } from './containers/DocumentControl/View/CompleteTraceReport'

const modulesMap = {
	dashboard: MainDashboard,
	subDashboard: SubDashboard,
	documentListing: DocumentsListing,
	documentControl: DocumentsControl,
	documentView: DocumentsControl,
	completeTraceReportView: CompleteTraceReport,
	'audit-report': AuditReport,
	'general-report': AuditReport,
}

const TradeDocumentManager = () => {
	const dispatch = useDispatch()
	const activeModule = useSelector(TradeDocDuc.selectors.activeModule)
	const { error } = useSelector(TradeDocDuc.selectors.getModuleStatus)
	// inject saga hook
	useInjectSaga({ key: 'trade-doc-manager', saga })

	let Component = modulesMap[activeModule] || NotFoundBlock

	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	// if explicitly set error by someone, then show error block
	if (error) Component = UnexpectedErrorBlock
	useEffect(() => {
		if (tourModule === 'tdm') {
			dispatch(WebTourDuc.creators.setTdmVisit(true))
		}
		if (tourModule === 'DOCreate') {
			dispatch(WebTourDuc.creators.setCreateDODetails(true))
		}
		if (tourModule === 'tdmView') {
			dispatch(WebTourDuc.creators.setTDMView(true))
		}
	}, [tourModule, dispatch])

	// if we are here, we have a submodule, route it to the module

	return (
		<>
			<Component />
			{(tourModule === 'tdm' ||
				tourModule === 'DOCreate' ||
				tourModule === 'tdmView') && <WebTour />}
		</>
	)
}

export default TradeDocumentManager

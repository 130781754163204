import React, { useState, useRef } from 'react'
import { getIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { H4, P } from 'ui-lib/components/Typography'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Drop } from 'grommet'
import theme from 'ui-lib/utils/base-theme'
import EditIcon from 'ui-lib/icons/edit.svg'
import Documents from 'ui-lib/icons/documents.svg'
import EditEllipsis from 'ui-lib/icons/editEllipsis.svg'
import DeleteIcon from 'ui-lib/icons/delete_outline.svg'

const AttachmentMenu = ({
	data,
	currentOrgID,
	selectedCPID,
	handleEdit,
	handleRename,
	handleDelete,
}) => {
	const attachmentMenuRef = useRef()
	const [showAttachmentMenu, setShowAttachmentMenu] = useState(false)

	return (
		<>
			<Box key={data?.attachmentID} margin="5px 30px 30px" width="100px">
				<Box alignItems="center" justifyContent="center">
					<Box
						onClick={() => {
							window.open(
								getIn(data, ['file', 'meta', 'fullURL']),
								'_blank'
							)
						}}
						style={{
							backgroundColor: '#ffeed4',
							cursor: 'pointer',
						}}
						alignItems="center"
						justifyContent="center"
						padding="30px 30px"
					>
						<Icon
							color="#9e797a"
							glyph={Documents}
							style={{
								height: 50,
								width: 40,
							}}
						/>
					</Box>
					{currentOrgID === getIn(data, ['organizationID']) &&
						currentOrgID ===
							getIn(data, ['meta', 'entityInitiatorID']) &&
						!selectedCPID && (
							<Button
								extendStyles={{
									borderRadius: 3,
									padding: '0 5px 0 0',
								}}
								rounded
								customIcon={
									<ButtonIconWrapper
										size={30}
										ref={attachmentMenuRef}
										lightBG
									>
										<Icon
											color={theme.color.grey15}
											glyph={EditEllipsis}
										/>
									</ButtonIconWrapper>
								}
								onClick={() => {
									handleEdit()
									setShowAttachmentMenu(true)
								}}
							/>
						)}
				</Box>
				<P
					style={{
						margin: 0,
						'text-overflow': 'ellipsis',
						'white-space': 'nowrap',
						overflow: 'hidden',
						'text-align': 'center',
					}}
					color={theme.color.grey15}
				>
					{data?.file?.meta?.name}
				</P>
			</Box>
			{showAttachmentMenu && !selectedCPID && (
				<Box
					style={{
						width: '44px',
					}}
				>
					<Drop
						stretch={false}
						onEsc={() => setShowAttachmentMenu(false)}
						onClickOutside={() => setShowAttachmentMenu(false)}
						target={attachmentMenuRef.current}
						align={{
							top: 'bottom',
							right: 'right',
						}}
					>
						<Box
							alignItems="flex-start"
							padding="10px 10px"
							style={{
								width: '265px',
								borderBottom: `1px solid ${theme.color.grey4}`,
								cursor: 'pointer',
							}}
							row
							onClick={() => {
								setShowAttachmentMenu(false)
								handleRename()
							}}
						>
							<Box
								padding="10px 10px"
								style={{
									background: theme.color.blue0,
								}}
							>
								<IconWrapper size={25}>
									<Icon
										color={theme.color.primary}
										glyph={EditIcon}
									/>
								</IconWrapper>
							</Box>
							<Box flex overflow padding="0px 20px">
								<H4 color={theme.color.primary}>Rename File</H4>
								<P color={theme.color.grey15}>
									Rename the file attached with the document
								</P>
							</Box>
						</Box>

						<Box
							alignItems="flex-start"
							padding="10px 10px"
							style={{
								width: '265px',
								borderBottom: `1px solid ${theme.color.grey4}`,
							}}
							row
							onClick={() => {
								setShowAttachmentMenu(false)
								handleDelete()
							}}
						>
							<Box
								padding="10px 10px"
								style={{
									background: '#e9bdbb',
								}}
							>
								<IconWrapper size={25}>
									<Icon
										color={theme.color.error}
										glyph={DeleteIcon}
									/>
								</IconWrapper>
							</Box>
							<Box flex overflow padding="0px 20px">
								<H4 color={theme.color.error}>Delete File</H4>
								<P color={theme.color.grey15}>
									Remove the file from attachment section of
									the document
								</P>
							</Box>
						</Box>
					</Drop>
				</Box>
			)}
		</>
	)
}

export { AttachmentMenu }

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { MillDuc } from 'ui-tdm-app/modules/Mill/duc'
import { KeyValueSegment } from 'ui-tdm-app/modules/TradeDocumentManager/components/KeyValueSegment'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'

const ViewFFBQuality = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	const ffbQualityDetails = useSelector(
		MillDuc.selectors.getSingleFFBQualityReport
	)

	const dispatchDate = getIn(ffbQualityDetails, ['entityDate'])
	const issuedBy = getIn(ffbQualityDetails, ['orgName'])
	const doNumber = getIn(ffbQualityDetails, ['doNumber'])
	const quantity = getIn(ffbQualityDetails, ['quantity'])
	const uom = getIn(ffbQualityDetails, ['uom'])
	const ripeBunches = getIn(ffbQualityDetails, ['ripeBunches'])
	const overripeBunches = getIn(ffbQualityDetails, ['overripeBunches'])
	const underripeBunches = getIn(ffbQualityDetails, ['underripeBunches'])
	const emptyBunches = getIn(ffbQualityDetails, ['emptyBunches'])
	const unripeBunches = getIn(ffbQualityDetails, ['unripeBunches'])
	const wetBunches = getIn(ffbQualityDetails, ['wetBunches'])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.quality'),
			name: 'quality',
			isActive: true,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'quality') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MILL$SUBROOT,
					{
						rootModule: 'quality',
					}
				)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={t('viewFFBQuality.title')} />
			</Box>
			<Box>
				<BoxShadowWrapper
					style={{
						width: isMobile ? 325 : 960,
						maxWidth: 960,
						minWidth: 325,
					}}
				>
					<Box row padding="0 15px">
						<Box padding="20px 0 " justifyContent="flex-start">
							<KeyValueSegment
								name={t('viewFFBQuality.doNumber')}
								description={doNumber || '---'}
							/>
							<KeyValueSegment
								name={t('viewFFBQuality.issuedBy')}
								description={issuedBy || '---'}
							/>
							<KeyValueSegment
								name={t('viewFFBQuality.dispatchDate')}
								description={
									getDateByFormat(
										dispatchDate,
										null,
										activeLocale
									) || '---'
								}
							/>
						</Box>
						<Box padding="20px 0 " justifyContent="flex-end">
							<KeyValueSegment
								name={t('viewFFBQuality.product')}
								description="Fresh fruit bunch"
							/>
							<KeyValueSegment
								name={t('viewFFBQuality.quantity')}
								description={`${quantity} ${uom}` || '---'}
							/>
						</Box>
					</Box>
					<Box style={{ borderTop: '1px solid #ddd' }}>
						<Box
							row
							style={{
								flexWrap: 'wrap',
								paddingBottom: '16px',
								paddingTop: '16px',
								paddingLeft: 15,
								paddingRight: 60,
							}}
						>
							<Box padding="0 12px 0 0">
								<KeyValueSegment
									name={t('viewFFBQuality.ripeBunches')}
									description={ripeBunches || '---'}
								/>
								<KeyValueSegment
									name={t('viewFFBQuality.overripeBunches')}
									description={overripeBunches || '0'}
								/>
								<KeyValueSegment
									name={t('viewFFBQuality.underripeBunches')}
									description={underripeBunches || '---'}
								/>
								<KeyValueSegment
									name={t('viewFFBQuality.emptyBunches')}
									description={emptyBunches || '---'}
								/>
								<KeyValueSegment
									name={t('viewFFBQuality.unripeBunches')}
									description={unripeBunches || '---'}
								/>
								<KeyValueSegment
									name={t('viewFFBQuality.wetBunches')}
									description={wetBunches || '---'}
								/>
							</Box>
						</Box>
					</Box>
				</BoxShadowWrapper>
			</Box>

			<Box padding="25px 0" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MILL$SUBROOT,
								{
									rootModule: 'quality',
								}
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { ViewFFBQuality }

import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { merge, getIn } from 'timm'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { Address } from 'ui-tdm-app/modules/TradeDocumentManager/components/Fragments'
import { KeyValueSegment } from 'ui-tdm-app/modules/TradeDocumentManager/components/KeyValueSegment'
import { AdditionalMetaBlock } from 'ui-tdm-app/modules/TradeDocumentManager/components/AdditionalMetaBlock'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Label } from 'ui-lib/components/Label'
import { Input } from 'ui-lib/components/Input'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { applyMediaQueries } from 'ui-lib/utils/helpers'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import RightArrowIcon from 'ui-lib/icons/right-arrow.svg'
import theme from 'ui-lib/utils/base-theme'

const SegmentSeparatorWrapper = styled.div({
	position: 'relative',
	height: 'inherit',
})

const SegmentSeparatorLine = styled.div({
	height: '100%',
	width: 'auto',
	border: '1px solid #eae9e9',
	position: 'absolute',
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)',
	...applyMediaQueries(['mobile'], {
		height: 'auto',
		width: '100%',
	}),
})

const SegmentSeparatorIconWrap = styled.div({
	border: '1px solid #ccc',
	padding: 8,
	borderRadius: '50%',
	position: 'absolute',
	backgroundColor: 'white',
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)',
})

const UnCoreStaticSection = ({
	isLoading,
	DocumentKey = 'DocumentNumber',
	DocumentReference,
	issuedOn,
	dispatchedAt,
	parentDocType,
	additionalMeta = [],
}) => {
	const assets = useSelector(AuthDuc.selectors.getOrgAssets)
	const binList = getIn(assets, ['storageunits']) || []
	const bin = binList.filter(_bin => _bin.id === DocumentReference)
	const binName = getIn(bin, [0, 'name']) || 'NA'

	const activeLocale = useSelector(AppDuc.selectors.activeLocale)
	const { t } = useTranslation()
	const _documentReference =
		parentDocType === 'plantation' ? binName : DocumentReference

	return (
		<BoxShadowWrapper>
			{isLoading && <TransparentBgSpinner />}

			<Box
				padding="4px 15px"
				style={{
					borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
				}}
			>
				<KeyValueSegment
					name={DocumentKey}
					description={_documentReference}
				/>
				{issuedOn && (
					<KeyValueSegment
						name={
							parentDocType === 'plantation'
								? t('tdmViewDocument.createdDate')
								: t('tdmPreviewDetails.issuedOn')
						}
						description={
							issuedOn
								? getDateByFormat(issuedOn, null, activeLocale)
								: '---'
						}
					/>
				)}
				{dispatchedAt && (
					<KeyValueSegment
						name={t('tdmPreviewDetails.dispatchDate')}
						description={
							dispatchedAt
								? getDateByFormat(
										dispatchedAt,
										null,
										activeLocale
								  )
								: '---'
						}
					/>
				)}
			</Box>
			{!!additionalMeta.length && (
				<Box style={{ borderTop: '1px solid #ddd' }}>
					<AdditionalMetaBlock meta={additionalMeta} />
				</Box>
			)}
		</BoxShadowWrapper>
	)
}

const CoreStaticSection = ({
	isMobile,
	DocumentKey = 'DocumentNumber',
	DocumentReference,
	issuedBy,
	issuedOn,
	issuedTo,
	toAddress,
	fromAddress,
	expectedOn,
	additionalMeta = [],
}) => {
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)
	const { t } = useTranslation()

	return (
		<>
			<BoxShadowWrapper>
				{DocumentReference && (
					<Box
						padding="4px 15px"
						style={{
							borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
						}}
					>
						<KeyValueSegment
							name={DocumentKey}
							limitKeyWidth
							description={DocumentReference}
						/>
					</Box>
				)}
				<Box row={!isMobile}>
					<Box
						width={isMobile ? '100%' : '50%'}
						alignItems="flex-start"
					>
						<Box
							alignItems="flex-start"
							padding="20px 15px"
							style={!isMobile ? { paddingRight: 30 } : {}}
							width="100%"
						>
							<KeyValueSegment
								name={t('tdmPreviewDetails.issuedOn')}
								limitKeyWidth
								description={
									issuedOn
										? getDateByFormat(
												issuedOn,
												null,
												activeLocale
										  )
										: '---'
								}
							/>
							<KeyValueSegment
								name={t('tdmPreviewDetails.issuedBy')}
								limitKeyWidth
								description={issuedBy}
							/>
							<KeyValueSegment
								name={t('tdmPreviewDetails.address')}
								limitKeyWidth
								description={<Address address={fromAddress} />}
							/>
						</Box>
					</Box>
					<SegmentSeparatorWrapper>
						<SegmentSeparatorLine />
						<SegmentSeparatorIconWrap>
							<IconWrapper size={15}>
								<Icon
									glyph={RightArrowIcon}
									style={
										isMobile
											? {
													transform: 'rotate(90deg)',
											  }
											: {}
									}
								/>
							</IconWrapper>
						</SegmentSeparatorIconWrap>
					</SegmentSeparatorWrapper>
					<Box
						width={isMobile ? '100%' : '50%'}
						alignItems="flex-end"
					>
						<Box
							alignItems="flex-start"
							padding="20px 15px"
							style={!isMobile ? { paddingLeft: '10%' } : {}}
							width="100%"
						>
							<KeyValueSegment
								name={t('tdmPreviewDetails.issuedTo')}
								limitKeyWidth
								description={issuedTo}
							/>
							<KeyValueSegment
								name={t('tdmPreviewDetails.address')}
								limitKeyWidth
								description={<Address address={toAddress} />}
							/>
							<KeyValueSegment
								name={t(
									'tdmPreviewDetails.expectedDeliveryDate'
								)}
								limitKeyWidth
								description={
									expectedOn
										? getDateByFormat(
												expectedOn,
												null,
												activeLocale
										  )
										: '---'
								}
							/>
						</Box>
					</Box>
				</Box>
				{!!additionalMeta.length && (
					<Box style={{ borderTop: '1px solid #ddd' }}>
						<AdditionalMetaBlock meta={additionalMeta} />
					</Box>
				)}
			</BoxShadowWrapper>
		</>
	)
}

const CoreSection = ({
	title,
	rootModule,
	values,
	errors,
	onChange,
	newTDM = false,
	isEdit = false,
	needsTransportDetails,
	entityRefKey,
}) => {
	// hack to fix date change affecting the entire
	// values to go empty, its preserving initial values state inside callbacks.
	// I have no fckn idea why
	const [dateProxy, setDateProxy] = useState({})
	const [entityRefError, setEntityRefError] = useState(false)

	const handleChange = useCallback(
		keyValue => {
			if (keyValue.vehicleNumber || keyValue.driverName) {
				onChange(
					['meta', 'transporter', getIn(Object.keys(keyValue), [0])],
					getIn(Object.values(keyValue), [0])
				)
			} else {
				onChange('meta', merge(values, keyValue))
			}
		},
		[values, onChange]
	)
	const date = new Date(values.issueDate) || new Date()
	const minIssueDate = date.setDate(date.getDate() - 7)
	const maxDeliveryDate = date.setDate(date.getDate() + 90)
	const { t } = useTranslation()
	useEffect(() => {
		handleChange(dateProxy)
	}, [dateProxy, handleChange])

	return (
		<>
			<Box
				width={newTDM ? 445 : 350}
				overflow
				style={{ display: newTDM ? 'block' : 'flex' }}
			>
				<Label
					required={!!newTDM}
					style={{
						marginBottom: newTDM && '10px',
						marginTop:
							rootModule === 'delivery-order' ? '-160px' : 0,
						color: newTDM && theme.color.accent2,
						fontSize: newTDM && '14px',
						fontWeight: newTDM && 500,
					}}
				>
					{newTDM
						? `${t(title)} ${t('tdmViewDocument.number')}`
						: 'Enter Reference Number'}
				</Label>
				<Input
					name="entityReference"
					value={values.entityReference}
					returnKeyValue
					error={entityRefError && errors.entityReference}
					onChange={handleChange}
					onBlur={() => setEntityRefError(true)}
					placeholder={
						newTDM
							? `${t('tdmDetailsEntry.enter')} ${t(
									'tdmDetailsEntry.reference'
							  )} ${t('tdmViewDocument.number')} `
							: `${t('tdmDetailsEntry.enter')} ${t(title)} ${t(
									'tdmDetailsEntry.reference'
							  )}`
					}
					extendStyles={{
						border: newTDM && 'none',
						borderRadius: newTDM && '0px',
						borderBottom:
							newTDM && `1px solid ${theme.color.blue8}`,
						marginTop:
							newTDM &&
							(rootModule === 'delivery-order' ||
								rootModule === 'incoming-delivery-order')
								? '12px'
								: '7px',
					}}
					disabled={isEdit}
					key={newTDM ? entityRefKey : ''}
				/>
				<Spacer size={4} />
			</Box>
			{(rootModule === 'delivery-order' ||
				rootModule === 'incoming-delivery-order') && (
				<Spacer size={6} />
			)}
			<BoxShadowWrapper
				style={{
					backgroundColor: 'white',
					border: newTDM && 'none',
					padding: newTDM ? '0px' : '16px 20px',
				}}
			>
				<Box id="input-date-picker">
					<Box width={newTDM ? 445 : 350} overflow>
						{!newTDM && <Spacer size={24} />}
						<DatePicker
							enableCurrentTime
							toDate
							required
							noBorder
							name="issueDate"
							error={errors.issueDate}
							returnKeyValue
							maxDate={new Date()}
							minDate={
								!newTDM &&
								new Date(
									new Date(minIssueDate).setHours(0, 0, 0, 0)
								)
							}
							value={values.issueDate || new Date()}
							onChange={keyValue => setDateProxy(keyValue)}
							label={
								newTDM
									? t('common.date')
									: t('tdmDetailsEntry.issuingDate')
							}
						/>
					</Box>
					{rootModule !== 'purchase-order' && !newTDM && (
						<Box width={350} overflow>
							<Spacer size={8} />
							<DatePicker
								noBorder
								required
								toDate
								name="expectedDeliveryDate"
								error={errors.expectedDeliveryDate}
								minDate={values.issueDate || new Date()}
								maxDate={
									new Date(
										new Date(maxDeliveryDate).setHours(
											23,
											59,
											59,
											999
										)
									)
								}
								returnKeyValue
								value={
									values.expectedDeliveryDate || new Date()
								}
								onChange={keyValue => setDateProxy(keyValue)}
								label={t(
									'tdmDetailsEntry.expectedDeliveryDate'
								)}
							/>
						</Box>
					)}
				</Box>
				<Spacer size={24} />
				{needsTransportDetails && rootModule !== 'invoice' && !newTDM && (
					<Box width={350} overflow>
						<Input
							name="vehicleNumber"
							label={t('tdmDetailsEntry.vehicleNumber')}
							returnKeyValue
							error={
								getIn(values, [
									'transporter',
									'vehicleNumber',
								]) && errors.vehicleNumber
							}
							value={getIn(values, [
								'transporter',
								'vehicleNumber',
							])}
							onChange={handleChange}
						/>
						<Input
							name="driverName"
							label={t('tdmDetailsEntry.driverName')}
							returnKeyValue
							error={
								getIn(values, ['transporter', 'driverName']) &&
								errors.driverName
							}
							value={getIn(values, ['transporter', 'driverName'])}
							onChange={handleChange}
						/>
					</Box>
				)}
			</BoxShadowWrapper>
		</>
	)
}

CoreSection.defaultProps = {
	values: {},
	errors: {},
	touched: {},
	onChange: () => {},
}

export { CoreStaticSection, CoreSection, UnCoreStaticSection }

/* @flow */
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { merge } from 'timm'
import { H4, Label } from '../Typography'
import { Card } from '../Card'
import { Box } from '../../utils/Box'
import { slugify } from '../../utils/helpers'
import { Icon, IconWrapper } from '../../icons/components/Icon'
import { ModuleIconMap } from '../../utils/config'

type Props = {
	name?: string,
	label: string,
	statuses: { [string]: number },
	redirectLink?: string,
	ctaLabel?: string,
	onClick: (string, { [string]: string }) => void,
}

const Wrapper = styled.div`
	display: inline-block;
	margin-top: 16px;
	margin-right: 16px;
`

const CardContent = styled.p(p => {
	let color = p.theme.color.neutral

	if (p.color) color = p.color // eslint-disable-line prefer-destructuring
	if (p.type === 'warn') color = p.theme.color.warn
	if (p.type === 'error') color = p.theme.color.error
	if (p.type === 'success') color = p.theme.color.success

	return {
		fontSize: p.theme.fontSize.xxxxl,
		marginBottom: 0,
		position: 'relative',
		color,
		'&:after': {
			opacity: 0.7,
			content: '""',
			width: '35%',
			height: '1px',
			position: 'absolute',
			bottom: '5px',
			backgroundColor: color,
			left: '50%',
			transform: 'translateX(-50%)',
		},
	}
})

const CardGrid = styled.div`
	display: inline-block;
	& :not(:first-child) {
		border-left: 1px solid #e7e7e7;
	}
	padding: 5px 20px;
`

const COLOR_TYPE_MAPPING = {
	accepted: 'success',
	'on-hold': 'warn',
	submitted: 'neutral',
	rejected: 'error',
}

const defaultStatuses = {
	rejected: 0,
	submitted: 0,
}

const DashboardStatusWidget = ({
	name = '',
	label = '',
	statuses,
	ctaLabel,
	onClick,
}: Props) => {
	const _icon = ModuleIconMap[slugify(label)]

	const _statuses = useMemo(() => {
		return merge(defaultStatuses, statuses)
	}, [statuses])

	return (
		<Wrapper>
			<Card>
				<Box alignItems="flex-start" style={{ padding: 15 }}>
					<Box
						row
						center
						style={{
							marginLeft: 5,
							marginBottom: 10,
						}}
					>
						{_icon && (
							<IconWrapper size={20} style={{ marginRight: 5 }}>
								<Icon glyph={_icon} />
							</IconWrapper>
						)}
						<H4 primary>{label}</H4>
					</Box>

					<Box row>
						{Object.keys(_statuses).map(title => {
							return (
								<CardGrid
									key={title}
									onClick={() => {
										onClick('status_clicked', {
											status: slugify(title),
											name,
										})
									}}
								>
									<CardContent
										type={
											COLOR_TYPE_MAPPING[
												slugify(title)
											] || 'neutral'
										}
									>
										{parseInt(statuses[title], 10) || 0}
									</CardContent>
									<Label
										primary
										style={{ textTransform: 'capitalize' }}
									>
										{title}
									</Label>
								</CardGrid>
							)
						})}
					</Box>
				</Box>
			</Card>

			<Label
				primary
				as="a"
				onClick={() => {
					onClick('cta_clicked', { label, name })
				}}
				style={{
					display: 'block',
					marginTop: 10,
					marginBottom: 10,
					marginLeft: 5,
					textDecoration: 'underline',
				}}
			>
				{ctaLabel}
			</Label>
		</Wrapper>
	)
}

export { DashboardStatusWidget }

import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'

import { ReactReduxContext, useStore } from 'react-redux'
import LogHelper from 'ui-tdm-app/utils/logger'
import getInjectors from './injectSagaHelper'

const logger = LogHelper('saga:injectors')

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.RESTART_ON_REMOUNT) the saga will be started on component mount and
 * cancelled with `task.cancel()` on component un-mount for improved performance. Another two options:
 *   - constants.DAEMON—starts the saga on component mount and never cancels it or starts again,
 *   - constants.ONCE_TILL_UNMOUNT—behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 *
 */
export default ({ key, saga, mode }) => WrappedComponent => {
	class InjectSaga extends React.Component {
		static WrappedComponent = WrappedComponent

		static contextType = ReactReduxContext

		static displayName = `withSaga(${WrappedComponent.displayName ||
			WrappedComponent.name ||
			'Component'})`

		constructor(props, context) {
			super(props, context)

			this.injectors = getInjectors(context.store)
			if (__DEV__)
				logger.log(
					'Mounting:',
					`${key} - ${WrappedComponent.displayName ||
						WrappedComponent.name ||
						'Unknown'} saga`
				)
			this.injectors.injectSaga(key, { saga, mode }, this.props)
		}

		componentWillUnmount() {
			if (__DEV__)
				logger.log(
					'Unmounting:',
					`${key} - ${WrappedComponent.displayName ||
						WrappedComponent.name ||
						'Unknown'} saga`
				)
			this.injectors.ejectSaga(key)
		}

		render() {
			return <WrappedComponent {...this.props} />
		}
	}

	return hoistNonReactStatics(InjectSaga, WrappedComponent)
}

/*
 * @param {Object} params
 * @param {string} params.key The key to inject the saga under
 * @param {function} params.saga The saga that will be injected
 * @param {string} [params.mode] The injection behaviour to use. The default is
 * `SagaInjectionModes.DAEMON` which causes the saga to be started on component
 * instantiation and never canceled or started again. @see
 * {@link SagaInjectionModes} for the other possible modes.
 *
 * @example
 *
 * function BooksManager() {
 *   useInjectSaga({ key: "books", saga: booksSaga })
 *
 *   return null;
 * }
 *
 * @public
 */
export const useInjectSaga = ({ key, saga, mode }) => {
	const store = useStore()

	const isInjected = React.useRef(false)

	if (!isInjected.current) {
		getInjectors(store).injectSaga(key, { saga, mode })
		isInjected.current = true
	}

	React.useEffect(
		() => () => {
			getInjectors(store).ejectSaga(key)
		},
		[key, store]
	)
}

import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { createSelector } from 'reselect'

export const NAME_ALIASES = {
	create: 'common.create',
	edit: 'common.edit',
	view: 'common.viewBtn',
}

const initialState = {
	activeModule: 'dashboard',
	loading: false,
	error: false,
	modules: {
		listing: {
			activeDocuments: [],
		},
		singleWBSlipInfo: {},
		initialFormValues: {
			netWeight: 0,
			grossWeight: '',
			tareWeight: '',
			ticketNumber: '',
			checkedBy: '',
			wrongVehicleNumber: false,
		},
		entityInfoforWBSlip: {},
	},
}

export const WeighBridgeDuc = new Duck({
	namespace: 'weighBridge',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_WEIGHBRIDGE_LISTING',
		'FETCH_WEIGHBRIDGE_LISTING_SUCCESS',
		'FETCH_SINGLE_WEIGHBRIDGE_SLIP',
		'FETCH_SINGLE_WEIGHBRIDGE_SLIP_SUCCESS',
		'FLUSH_SINGLE_WEIGHBRIDGE_SLIP_STATE',
		'FLUSH_ENTITY_INFO_STATE',
		'FLUSH_INITIAL_FORM_VALUES',
		'FETCH_ENTITY_INFO_FOR_WEIGHBRIDGE_SLIP',
		'SET_ENTITY_INFO_FOR_WEIGHBRIDGE_SLIP',
		'CREATE_WEIGHBRIDGE_SLIP',
		'INITIATE_EDIT_SLIP',
		'SET_INITIAL_FORM_VALUES_WB_EDIT',
		'EDIT_WEIGHBRIDGE_SLIP',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.FETCH_WEIGHBRIDGE_LISTING_SUCCESS: {
				return setIn(
					state,
					['modules', 'listing', 'activeDocuments'],
					action.wbSlipList
				)
			}

			case duck.types.FETCH_SINGLE_WEIGHBRIDGE_SLIP_SUCCESS: {
				return setIn(
					state,
					['modules', 'singleWBSlipInfo'],
					action.wbSlipDetails
				)
			}

			case duck.types.FLUSH_SINGLE_WEIGHBRIDGE_SLIP_STATE: {
				return setIn(
					state,
					['modules', 'singleWBSlipInfo'],
					initialState.modules.singleWBSlipInfo
				)
			}

			case duck.types.SET_ENTITY_INFO_FOR_WEIGHBRIDGE_SLIP: {
				return setIn(
					state,
					['modules', 'entityInfoforWBSlip'],
					action.entityInfo
				)
			}

			case duck.types.FLUSH_ENTITY_INFO_STATE: {
				return setIn(
					state,
					['modules', 'entityInfoforWBSlip'],
					initialState.modules.entityInfoforWBSlip
				)
			}

			case duck.types.SET_INITIAL_FORM_VALUES_WB_EDIT: {
				return setIn(
					state,
					['modules', 'initialFormValues'],
					action.initialFormValuesforEdit
				)
			}

			case duck.types.FLUSH_INITIAL_FORM_VALUES: {
				return setIn(
					state,
					['modules', 'initialFormValues'],
					initialState.modules.initialFormValues
				)
			}
			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['weighBridge', 'activeModule']) || 'Error',
		weighbridgeListingState: state =>
			getIn(state, ['weighBridge', 'modules', 'listing']) || {},
		getWeighbridgeSlipsList: new Duck.Selector(selectors =>
			createSelector(
				selectors.weighbridgeListingState,
				list => list.activeDocuments || []
			)
		),
		getWBSlipInformation: state =>
			getIn(state, ['weighBridge', 'modules', 'singleWBSlipInfo']) || [],
		getInitialFormValues: state =>
			getIn(state, ['weighBridge', 'modules', 'initialFormValues']) || {},
		getEntityInfo: state =>
			getIn(state, ['weighBridge', 'modules', 'entityInfoforWBSlip']) ||
			{},
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchWeighBridgeListing: () => ({
			type: duck.types.FETCH_WEIGHBRIDGE_LISTING,
		}),
		fetchWeighBridgeListingSuccess: wbSlipList => ({
			type: duck.types.FETCH_WEIGHBRIDGE_LISTING_SUCCESS,
			wbSlipList,
		}),
		fetchSingleWBSlip: () => ({
			type: duck.types.FETCH_SINGLE_WEIGHBRIDGE_SLIP,
		}),
		fetchSingleWBSlipSuccess: wbSlipDetails => ({
			type: duck.types.FETCH_SINGLE_WEIGHBRIDGE_SLIP_SUCCESS,
			wbSlipDetails,
		}),
		flushSingleWBSlipState: () => ({
			type: duck.types.FLUSH_SINGLE_WEIGHBRIDGE_SLIP_STATE,
		}),
		fetchEntityInfoforWBSlip: docReference => ({
			type: duck.types.FETCH_ENTITY_INFO_FOR_WEIGHBRIDGE_SLIP,
			docReference,
		}),
		setEntityInfoforWBSlip: entityInfo => ({
			type: duck.types.SET_ENTITY_INFO_FOR_WEIGHBRIDGE_SLIP,
			entityInfo,
		}),
		flushEntityInfo: () => ({
			type: duck.types.FLUSH_ENTITY_INFO_STATE,
		}),
		createWeighBridgeSlip: (
			wbSlipDetails,
			isIncomingDO,
			actor,
			toastMessage
		) => ({
			type: duck.types.CREATE_WEIGHBRIDGE_SLIP,
			wbSlipDetails,
			isIncomingDO,
			actor,
			toastMessage,
		}),
		initiateEditSlip: (docReference, attachmentID) => ({
			type: duck.types.INITIATE_EDIT_SLIP,
			docReference,
			attachmentID,
		}),
		setInitialFormValuesforEdit: initialFormValuesforEdit => ({
			type: duck.types.SET_INITIAL_FORM_VALUES_WB_EDIT,
			initialFormValuesforEdit,
		}),
		flushInitialFormValues: () => ({
			type: duck.types.FLUSH_INITIAL_FORM_VALUES,
		}),
		editWeighBridgeSlip: (wbSlipDetails, toastMessage) => ({
			type: duck.types.EDIT_WEIGHBRIDGE_SLIP,
			wbSlipDetails,
			toastMessage,
		}),
	}),
})

import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'

import { getIn } from 'timm'
import saga from 'ui-tdm-app/modules/TradeDocumentManager/TradeDocManagerSaga'

const fetchRouteMatches = type => {
	const isMainDashboard = MainRouteDuc.types.TRADE_DOCUMENT_MANAGER === type
	const isSubDashboard =
		MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$SUBROOT === type
	const isDocumentTypeListing =
		MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$LISTING === type
	const isDocumentCreateType =
		type === MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$WACTION
	const isDocumentEditorUpdateType =
		type === MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE
	const isDocumentViewType =
		type === MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE ||
		type === MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$COMPLETETRADEREPORT

	const isDocumentTypeControl =
		isDocumentCreateType || isDocumentEditorUpdateType
	const isProductTrace =
		MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$PRODUCT_TRACE === type
	const isAuditReport = MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$AUDIT_REPORT

	const isProductTraceGenerate =
		MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$GENERATE_PRODUCT_TRACE ===
		type

	const isProductTraceControl = isProductTrace || isProductTraceGenerate

	return {
		isMainDashboard,
		isSubDashboard,
		isDocumentTypeListing,
		isDocumentViewType,
		isDocumentTypeControl,
		isProductTraceControl,
		isAuditReport,
	}
}

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])

	const { prev = {} } = getIn(action, ['meta', 'location'])
	const { type: prevType } = prev

	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState
	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('trade-doc-manager', { saga })

	// reset the error status
	dispatch(TradeDocDuc.creators.setModuleAsErrored(false))

	const {
		isMainDashboard,
		isSubDashboard,
		isDocumentTypeListing,
		isDocumentViewType,
		isDocumentTypeControl,
		isAuditReport,
	} = fetchRouteMatches(type)

	const previousRouteMatches = fetchRouteMatches(prevType)

	let activeModule = 'error'

	// fetch the active user roles
	dispatch(TradeDocDuc.creators.getDashboardActiveTDMRules())
	dispatch(AuthDuc.creators.fetchActor())

	if (isMainDashboard) {
		// load the dashboard of the ui-tdm module
		activeModule = 'dashboard'
		dispatch(TradeDocDuc.creators.flushDocumentListing())
		dispatch(
			TradeDocDuc.creators.flushStorageDetailsBasedOnSupplyChainModel()
		)

		dispatch(
			TradeDocDuc.creators.fetchDashboardDocuments(
				'all',
				payload.rootModule,
				locationState
			)
		)
	} else if (isSubDashboard) {
		activeModule = 'subDashboard'
		dispatch(TradeDocDuc.creators.fetchDashboardStats(payload.rootModule))
	} else if (isDocumentTypeListing) {
		activeModule = 'documentListing'
		dispatch(
			TradeDocDuc.creators.fetchDocumentListing(
				payload.submodule,
				[payload.rootModule],
				locationState
			)
		)
	} else if (isDocumentTypeControl) {
		activeModule = 'documentControl'

		// reset the status of the document
		dispatch(TradeDocDuc.creators.documentLoading())
		dispatch(AuthDuc.creators.fetchActor())
		dispatch(AuthDuc.creators.fetchCertificates())
		dispatch(TradeDocDuc.creators.checkIfSupplyChainIsEnforced())

		// decide on how to fetch the data if its exists already
		const parentDocRef = getIn(locationState, ['query', 'parentDocRef'])

		const currentActiveParent = TradeDocDuc.selectors.getDocumentParentDocRef(
			getState()
		)

		// flush the doc state based on set criteria

		// if from a different route other than documenttypecontrol, flush the state
		if (!previousRouteMatches.isDocumentTypeControl) {
			dispatch(TradeDocDuc.creators.flushDocumentState())
		}
		if (
			!(
				payload.action === 'confirm' ||
				payload.action === 'attach-details-confirm'
			)
		) {
			if (
				!payload.documentReference &&
				parentDocRef &&
				`${currentActiveParent.type}~${currentActiveParent.id}` !==
					parentDocRef
			) {
				// we have a parent reference to derive
				dispatch(
					TradeDocDuc.creators.fetchParentSource(
						payload.rootModule,
						parentDocRef
					)
				)
			} else if (payload.documentReference) {
				dispatch(
					TradeDocDuc.creators.fetchActiveDocument(
						payload.documentReference,
						payload.rootModule,
						payload.documentStatus === 'draft',
						payload.documentStatus,
						payload.baseValueSchema
					)
				)
			} else {
				// set initial active sections
				dispatch(
					TradeDocDuc.creators.initializeBaseActiveSections(
						payload.rootModule
					)
				)
				dispatch(TradeDocDuc.creators.initiateDocumentStep())
			}
		}
	} else if (isDocumentViewType && payload.documentReference) {
		if (
			payload.traceReportStatus &&
			payload.traceReportStatus === 'completeTraceReport'
		) {
			activeModule = 'completeTraceReportView'
		} else {
			activeModule = 'documentView'
			dispatch(TradeDocDuc.creators.flushDocumentState())

			dispatch(
				TradeDocDuc.creators.initiateDocumentView(
					payload.rootModule,
					payload.documentReference,
					locationState
				)
			)
			dispatch(TradeDocDuc.creators.checkIfSupplyChainIsEnforced())
		}
	} else if (isAuditReport) {
		activeModule = payload.rootModule
		if (payload.rootModule === 'audit-report') {
			dispatch(
				TradeDocDuc.creators.fetchAuditReport(
					'audit-report',
					locationState,
					true
				)
			)
		}
	}
	dispatch(TradeDocDuc.creators.setActiveModule(activeModule))

	// based on the payload or action, initiate saga's
}

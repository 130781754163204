import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { removeLast, getIn } from 'timm'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { Status } from 'ui-tdm-app/modules/TradeDocumentManager/components/Status'
import {
	mapLabelsToStatusActions,
	getStateBasedTargetActions,
} from 'ui-tdm-app/modules/TradeDocumentManager/helpers'
import { useDateByFormat, getDateByFormat } from 'ui-tdm-app/utils/date'
import { Label, P } from 'ui-lib/components/Typography'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { ZoomFtr } from 'ui-lib/components/FileUpload'
import { ButtonWithNoBorder, ButtonWithDrop } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { applyMediaQueries, chunkArrayInGroups } from 'ui-lib/utils/helpers'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import ExpandIcon from 'ui-lib/icons/expand-circle.svg'
import CollapseIcon from 'ui-lib/icons/collapse-circle.svg'
import { isEmptyObject } from 'ui-tdm-app/utils/helpers'

export const BlockWrapper = styled(Box)(p => ({
	cursor: p.plain ? 'initial' : 'pointer',
	border: '1.5px solid transparent',
	borderRadius: 4,
	'&:hover': p.plain
		? {}
		: {
				border: `1.5px solid ${p.theme.color.green2}`,
		  },
	...(!p.plain &&
		p.isActive && {
			background: theme.color.green3,
			border: `1.5px solid ${theme.color.green2}`,
			boxShadow: `${theme.color.green2} 0px 2px 8px`,
		}),
	...(!p.plain &&
		!p.isActive && {
			border: `1.5px solid ${theme.color.grey4}`,
		}),
}))

const SummaryWrapper = styled(Box)({
	paddingRight: 40,
	'&:last-child': {
		paddingRight: 'initial',
	},
	...applyMediaQueries(['mobile'], {
		paddingTop: 4,
		paddingBottom: 4,
	}),
})

const Line = styled.div(p => ({
	borderRight: p.isMobile ? 0 : '1px solid #c9c9c9',
	borderBottom: p.isMobile ? '1px solid #c9c9c9' : 0,
	height: p.isMobile ? 0 : '150px',
	width: p.isMobile ? '316px' : 0,
}))

export const SummaryBlock = ({ name, detail, onClick, isLink, width }) => (
	<SummaryWrapper>
		<Label color="#7680A7" fontSize={theme.fontSize.m}>
			{name}
		</Label>
		<Box>
			{typeof detail === 'string' ? (
				<P
					style={{
						maxWidth: width || '84px',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
					bold
					link={isLink}
					onClick={onClick}
					title={detail}
				>
					{detail}
				</P>
			) : (
				detail
			)}
		</Box>
	</SummaryWrapper>
)

SummaryBlock.defaultProps = {
	onClick: () => {},
}

const DocumentViewLeadCard = ({
	hasChildren,
	onCollapseAction,
	isOpen,
	isActive,
	onClick,
	config = {},
}) => {
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { t } = useTranslation()
	const remarksTtl = getIn(config, ['messages']) || []
	const remarkCount = remarksTtl.reduce((n, x) => {
		const testArray = 'comment' in x

		return n + (testArray === true)
	}, 0)

	const CollapsibleIcon = (
		<Box
			center
			style={{ paddingRight: 20 }}
			height={!isMobile ? '100%' : 'auto'}
			onClick={e => {
				e.stopPropagation()
				if (hasChildren && onCollapseAction) onCollapseAction()
			}}
		>
			<IconWrapper size={20}>
				{hasChildren && (
					<Icon glyph={isOpen ? CollapseIcon : ExpandIcon} />
				)}
			</IconWrapper>
		</Box>
	)

	return (
		<BoxShadowWrapper
			style={{
				display: 'flex',
				border: 'none',
				boxShadow: isActive && `${theme.color.green2} 0px 2px 8px`,
			}}
		>
			<BlockWrapper
				padding={16}
				overflow="auto"
				width="100%"
				row
				justifyContent={isMobile ? 'flex-end' : 'space-between'}
				wrap
				isActive={isActive}
			>
				<Box
					row={!isMobile}
					alignItems="center"
					width={isMobile ? '100%' : ''}
				>
					{!isMobile && CollapsibleIcon}
					<Box row wrap>
						<SummaryBlock
							name={t('tdmViewDocument.entityType')}
							detail={config.entityName}
							width={112}
						/>
						<SummaryBlock
							name={t('tdmViewDocument.documentID')}
							detail={config.entityID}
							width={192}
							onClick={e => {
								e.stopPropagation()
								onClick('open-document', {
									id: config.id,
									type: config.entityType,
								})
							}}
						/>

						<SummaryBlock
							name={t('tdmViewDocument.sentDate')}
							detail={getDateByFormat(config.sentDate)}
							width={80}
						/>
						<SummaryBlock
							name={t('tdmViewDocument.actionDate')}
							detail={getDateByFormat(config.actionDate)}
							width={80}
						/>
						<SummaryBlock
							name={t('tdmViewDocument.messages')}
							detail={
								<Box row>
									<P bold link>
										{remarkCount}
									</P>
								</Box>
							}
						/>
					</Box>
				</Box>
				<Box
					width={isMobile ? '100%' : ''}
					row
					center
					padding={!isMobile ? '0 20px' : ''}
					justifyContent={isMobile ? 'space-between' : ''}
				>
					{isMobile && CollapsibleIcon}
					<Box>
						<ButtonWithNoBorder
							label={t('common.viewBtn').toUpperCase()}
							onClick={e => {
								e.stopPropagation()
								onClick('open-document', {
									id: config.id,
									type: config.entityType,
								})
							}}
						/>
						<SummaryBlock
							detail={<Status currentStatus={config.status} />}
						/>
					</Box>
				</Box>
			</BlockWrapper>
		</BoxShadowWrapper>
	)
}

const DocumentLeadCard = ({
	rootModule,
	isDocumentOwner,
	partner,
	amount,
	sentDate,
	actionedDate,
	docStatus,
	messagesCount,
	onMessagesLinkClick,
	onActionChange,
	netWeight,
}) => {
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { t } = useTranslation()
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []
	const isTraderOrEndManufacturer =
		actor.includes('palmoil_trader') ||
		actor.includes('palmoil_manufacturer') ||
		actor.includes('rice-trader')

	const activeActions = mapLabelsToStatusActions(
		t,
		isDocumentOwner,
		getStateBasedTargetActions(
			rootModule,
			docStatus,
			isDocumentOwner,
			isTraderOrEndManufacturer
		)
	)

	return (
		<Box>
			<BoxShadowWrapper
				style={{
					display: 'flex',
					padding: 8,
				}}
			>
				<BlockWrapper
					plain
					padding={isMobile ? '' : 16}
					overflow="auto"
					width="100%"
					row
					justifyContent={isMobile ? 'flex-end' : 'space-between'}
					wrap
				>
					<Box
						width="100%"
						row={!isMobile}
						justifyContent="space-between"
					>
						<Box row wrap>
							<SummaryBlock
								name={t('tdmViewDocument.sentDate')}
								detail={useDateByFormat(sentDate)}
							/>
							<SummaryBlock
								name={t('tdmViewDocument.partner')}
								detail={partner || '---'}
							/>
							{amount && (
								<SummaryBlock
									name={t('tdmViewDocument.amount')}
									detail={amount}
								/>
							)}
							<SummaryBlock
								name={t('tdmViewDocument.status')}
								detail={
									<Status
										docType={rootModule}
										isDocumentOwner={isDocumentOwner}
										currentStatus={docStatus}
									/>
								}
							/>
							<SummaryBlock
								name={t('tdmViewDocument.actionDate')}
								detail={useDateByFormat(actionedDate)}
							/>
							{netWeight && (
								<SummaryBlock
									name={`WB ${t('tdmViewDocument.weight')}`}
									detail={netWeight}
								/>
							)}
							<SummaryBlock
								name={t('tdmViewDocument.messages')}
								detail={
									<Box row center>
										<Label
											bold
											link
											onClick={onMessagesLinkClick}
											style={{
												marginTop: -2,
											}}
										>
											{messagesCount}
										</Label>
									</Box>
								}
							/>
						</Box>
						{!!activeActions.length && (
							<Box
								center
								padding="0 24px"
								alignItems={isMobile ? 'center' : 'flex-end'}
								style={{ marginTop: isMobile ? '10px' : '0px' }}
							>
								<ButtonWithDrop
									padding="8px 40px"
									primary
									noBackground
									label={t(
										'tdmViewDocument.action'
									).toUpperCase()}
									onChange={onActionChange}
									items={activeActions}
								/>
							</Box>
						)}
					</Box>
				</BlockWrapper>
			</BoxShadowWrapper>
		</Box>
	)
}

DocumentLeadCard.defaultProps = {
	onMessagesLinkClick: () => {},
	onActionChange: () => {},
	messagesCount: 0,
	changeableStatesList: [],
}

const KeyValueSegment = ({ name, description }) => {
	return (
		<Box row padding="8px 0px">
			<Box width={150}>
				<Label color={theme.color.accent2} small bold>
					{name}:
				</Label>
			</Box>
			<Box>
				<P>{description}</P>
			</Box>
		</Box>
	)
}

const ALIASES_KEYS = {
	issuedBy: 'tdmViewDocument.issuedBy',
	doNumber: 'tdmViewDocument.doNumber',
	driverName: 'tdmViewDocument.driverName',
	dispatchDate: 'tdmViewDocument.dispatchDate',
	vehicleNumber: 'tdmViewDocument.vehicleNumber',
	product: 'tdmViewDocument.product',
	grossWeight: 'tdmViewDocument.grossWeight',
	tareWeight: 'tdmViewDocument.tareWeight',
	netWeight: 'tdmViewDocument.netWeight',
}

const getPairsAndOddOne = arr => {
	let targetArr = arr
	let lastOdd = null
	if (targetArr.length % 3 > 0) {
		lastOdd = targetArr[targetArr.length - 1]
		targetArr = removeLast(targetArr)
	}

	return { pairs: chunkArrayInGroups(targetArr, 3), lastOdd }
}

const { pairs, lastOdd } = getPairsAndOddOne(Object.keys(ALIASES_KEYS))

const PreviewBinBlock = ({ values, isMobile }) => {
	const { t } = useTranslation()

	return (
		<>
			<Box justifyContent="space-between" row={!isMobile}>
				{pairs.map(pair => (
					<>
						<Box>
							{pair.map(_key => (
								<KeyValueSegment
									key={_key}
									name={t(ALIASES_KEYS[_key])}
									description={values[_key]}
								/>
							))}
						</Box>
						<Spacer size={30} horizontal />
					</>
				))}
			</Box>
			{lastOdd && (
				<Box>
					<KeyValueSegment
						name={t(ALIASES_KEYS[lastOdd])}
						description={values[lastOdd]}
					/>
				</Box>
			)}
		</>
	)
}

const DocumentInformationCard = ({ title, additionalMeta }) => {
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	return (
		<Box padding={5} overflow>
			<BoxShadowWrapper>
				<Box
					padding={8}
					style={{
						backgroundColor: '#D8DDEF',
					}}
				>
					<P bold color="#263b97">
						{title}
					</P>
				</Box>

				<Box padding={16}>
					<Box row={!isMobile}>
						<PreviewBinBlock
							values={additionalMeta}
							isMobile={isMobile}
						/>
					</Box>
				</Box>
			</BoxShadowWrapper>
		</Box>
	)
}

export const FFBQualityReportCard = ({ title, additionalMeta, isMobile }) => {
	const { t } = useTranslation()

	const {
		issuedBy,
		dispatchDate,
		createdDate,
		product,
		ripeBunches,
		underripeBunches,
		overripeBunches,
		emptyBunches,
		unripeBunches,
		wetBunches,
		file,
	} = additionalMeta

	return (
		<Box padding={5} overflow>
			<BoxShadowWrapper>
				<Box
					padding={8}
					style={{
						backgroundColor: theme.color.accent3,
					}}
				>
					<P bold color={theme.color.accent2}>
						{title}
					</P>
				</Box>

				<Box padding={16} row={!isMobile}>
					<Box margin={isMobile ? '20px' : '0 20px 0 0'}>
						<KeyValueSegment
							name={t('tdmViewDocument.issuedBy')}
							description={issuedBy}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.dispatchDate')}
							description={dispatchDate}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.createdDate')}
							description={createdDate}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.wetBunches')}
							description={wetBunches}
						/>
					</Box>
					<Line isMobile={isMobile} />
					<Box margin={isMobile ? '20px' : '0 20px'}>
						<KeyValueSegment
							name={t('tdmViewDocument.product')}
							description={product}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.ripeBunches')}
							description={ripeBunches}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.underripeBunches')}
							description={underripeBunches}
						/>
					</Box>
					<Line isMobile={isMobile} />
					<Box margin={isMobile ? '20px' : '0 20px'}>
						<KeyValueSegment
							name={t('tdmViewDocument.overripeBunches')}
							description={overripeBunches}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.emptyBunches')}
							description={emptyBunches}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.unripeBunches')}
							description={unripeBunches}
						/>
					</Box>
					{!isEmptyObject(file) && (
						<>
							<Line isMobile={isMobile} />
							<Box margin={isMobile ? '20px' : '0 20px'}>
								<Label bold style={{ marginBottom: '12px' }}>
									{t('common.attachedFile')}
								</Label>
								<ZoomFtr src={file.fullURL} alt={file.name} />
							</Box>
						</>
					)}
				</Box>
			</BoxShadowWrapper>
		</Box>
	)
}

export const CPOQualityReportCard = ({ title, additionalMeta, isMobile }) => {
	const { t } = useTranslation()

	const {
		issuedBy,
		dispatchDate,
		createdDate,
		product,
		ffa,
		dobi,
		mniValue,
		acidity,
		humidity,
		impurities,
		meltingPoint,
		iodineIndex,
		colour,
	} = additionalMeta

	return (
		<Box padding={5} overflow>
			<BoxShadowWrapper>
				<Box
					padding={8}
					style={{
						backgroundColor: theme.color.accent3,
					}}
				>
					<P bold color={theme.color.accent2}>
						{title}
					</P>
				</Box>

				<Box padding={16} row={!isMobile}>
					<Box margin={isMobile ? '20px' : '0 20px 0 0'}>
						<KeyValueSegment
							name={t('tdmViewDocument.issuedBy')}
							description={issuedBy}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.dispatchDate')}
							description={dispatchDate}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.createdDate')}
							description={createdDate}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.product')}
							description={product}
						/>
					</Box>
					<Line isMobile={isMobile} />
					<Box
						margin={isMobile ? '20px' : '0 20px'}
						justifyContent="flex-start"
					>
						<KeyValueSegment
							name={t('tdmViewDocument.ffa')}
							description={ffa}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.dobi')}
							description={dobi}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.mni')}
							description={mniValue}
						/>
					</Box>
					<Line isMobile={isMobile} />
					<Box
						margin={isMobile ? '20px' : '0 20px'}
						justifyContent="flex-start"
					>
						<KeyValueSegment
							name={t('cpoQualityCreate.acidity')}
							description={acidity}
						/>
						<KeyValueSegment
							name={t('cpoQualityCreate.humidity')}
							description={humidity}
						/>
						<KeyValueSegment
							name={t('cpoQualityCreate.impurities')}
							description={impurities}
						/>
					</Box>
					<Line isMobile={isMobile} />
					<Box
						margin={isMobile ? '20px' : '0 20px'}
						justifyContent="flex-start"
					>
						<KeyValueSegment
							name={t('cpoQualityCreate.meltingPoint')}
							description={meltingPoint}
						/>
						<KeyValueSegment
							name={t('cpoQualityCreate.iodineIndex')}
							description={iodineIndex}
						/>
						<KeyValueSegment
							name={t('cpoQualityCreate.color')}
							description={colour}
						/>
					</Box>
				</Box>
			</BoxShadowWrapper>
		</Box>
	)
}

export const WeighBridgeTicketCard = ({
	actor,
	title,
	additionalMeta,
	isMobile,
	activeDocument,
	allWeighBridgeData,
	initiatingPartyDetails,
	receivingPartyDetails,
}) => {
	const { t } = useTranslation()

	const {
		checkedBy,
		issuedBy,
		doNumber,
		driverName,
		dispatchDate,
		generatedDate,
		vehicleNumber,
		product,
		grossWeight,
		tareWeight,
		deductionWeight,
		netWeight,
		netWeightNumeric,
		ticketNumber,
		file,
		wbCertification,
		organizationID,
		goodsReceivedAt,
		tareWeightTime,
		wrongVehicleNumber,
	} = additionalMeta

	const getNetWeight = () => {
		if (receivingPartyDetails.id === organizationID) {
			const initiatorWeightbridge = allWeighBridgeData.filter(
				item =>
					item.category === 'weighbridge' &&
					item.ticketInfo.organizationID === initiatingPartyDetails.id
			)
			const productQuantity =
				activeDocument?.products?.[0] &&
				activeDocument?.products?.[0].uom === 'MT'
					? activeDocument.products[0].quantity
					: 0
			const initiatorNetWeightNumeric =
				initiatorWeightbridge.length > 0
					? initiatorWeightbridge[0].ticketInfo.netWeightNumeric
					: productQuantity

			const diffInPercent = initiatorNetWeightNumeric
				? (netWeightNumeric / initiatorNetWeightNumeric) * 100 - 100
				: 0

			return diffInPercent > 2 || diffInPercent < -2 ? (
				<>
					{netWeight}
					<span style={{ color: 'red' }}>
						&nbsp;({t('tdmViewDocument.mismatch')})
					</span>
				</>
			) : (
				netWeight
			)
		}

		return netWeight
	}

	return (
		<Box padding={5} overflow>
			<BoxShadowWrapper>
				<Box
					row
					justifyContent="space-between"
					padding={8}
					style={{
						backgroundColor: theme.color.accent3,
					}}
				>
					<P bold color={theme.color.accent2}>
						{title}
					</P>
					<Label bold>{ticketNumber}</Label>
				</Box>
				<Box padding={16} row={!isMobile} style={{ overflow: 'auto' }}>
					<Box margin={isMobile ? '20px' : '0 20px 0 0'}>
						<KeyValueSegment
							name={t('tdmViewDocument.issuedBy')}
							description={issuedBy}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.doNumber')}
							description={doNumber}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.driverName')}
							description={driverName}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.generatedDate')}
							description={generatedDate}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.dispatchDate')}
							description={dispatchDate}
						/>
					</Box>
					<Line isMobile={isMobile} />
					<Box margin={isMobile ? '20px' : '0 20px'}>
						<KeyValueSegment
							name={t('tdmViewDocument.vehicleNumber')}
							description={vehicleNumber}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.product')}
							description={product}
						/>
						<KeyValueSegment
							name={t('createWeighBridgeSlip.checkedBy')}
							description={checkedBy}
						/>
						<KeyValueSegment
							name={t('viewWeighBridgeSlip.dateOfEntry')}
							description={goodsReceivedAt}
						/>
						<KeyValueSegment
							name={t('viewWeighBridgeSlip.dateOfExit')}
							description={tareWeightTime}
						/>
					</Box>
					<Line isMobile={isMobile} />
					<Box margin={isMobile ? '20px' : '0 20px'}>
						<KeyValueSegment
							name={t('tdmViewDocument.grossWeight')}
							description={grossWeight}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.tareWeight')}
							description={tareWeight}
						/>
						<KeyValueSegment
							name={t('tdmViewDocument.netWeight')}
							description={getNetWeight()}
						/>
						{(actor.includes('palmoil_mill') ||
							actor.includes('palmoil_ffbdealer') ||
							actor.includes('palmoil_ffbsubdealer') ||
							actor.includes('rice-mill') ||
							actor.includes('rice-dealer')) && (
							<KeyValueSegment
								name="Deductions"
								description={deductionWeight}
							/>
						)}
					</Box>
					{!isEmptyObject(file) && (
						<>
							<Line isMobile={isMobile} />
							<Box margin={isMobile ? '20px' : '0 20px'}>
								<Label bold style={{ marginBottom: '12px' }}>
									{t('common.attachedFile')}
								</Label>
								<ZoomFtr src={file.fullURL} alt={file.name} />
							</Box>
						</>
					)}
				</Box>
				{wbCertification?.meta?.fullURL && (
					<Box padding={16}>
						<KeyValueSegment
							name={t('tdmViewDocument.calibrationcertificate')}
							description={
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={wbCertification.meta.fullURL}
								>
									{wbCertification.meta.fullURL}
								</a>
							}
						/>
					</Box>
				)}
				{wrongVehicleNumber && (
					<Box
						padding={16}
						style={{ color: 'red', fontSize: 14 }}
					>{`${t('common.note')}: ${t(
						'viewWeighBridgeSlip.truckNumberChanged'
					)}`}</Box>
				)}
			</BoxShadowWrapper>
		</Box>
	)
}

DocumentInformationCard.defaultProps = {
	additionalMeta: [],
	transporterDetails: [],
	productDetails: [],
	certificateInformation: [],
}

export { DocumentViewLeadCard, DocumentLeadCard, DocumentInformationCard }

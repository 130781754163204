import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { createSelector } from 'reselect'

import { initialState } from './config'

export const AdminDuc = new Duck({
	namespace: 'admin',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_ASSETS_LISTING',
		'SET_ASSETS_LISTING',
		'SET_ASSETS_PAGINATION_QUERIES',
		'SET_ASSETS_LOADING_STATUS',
		'CREATE_VEHICLE_AS_ASSET',
		'INITIATE_VEHICLE_EDIT',
		'FLUSH_VEHICLE_FORM_VALUES',
		'SET_INITIAL_FORM_VALUES_VEHICLE',
		'EDIT_VEHICLE',
		'VIEW_VEHICLE_DETAILS',
		'SET_VEHICLE_DETAILS',
		'DELETE_VEHICLE',
		'CREATE_PRODUCTION_LINE_AS_ASSET',
		'INITIATE_PRODUCTION_LINE_EDIT',
		'FLUSH_PRODUCTION_LINE_FORM_VALUES',
		'SET_INITIAL_FORM_VALUES_PRODUCTION_LINE',
		'EDIT_PRODUCTION_LINE',
		'VIEW_PRODUCTION_LINE_DETAILS',
		'SET_PRODUCTION_LINE_DETAILS',
		'DELETE_PRODUCTION_LINE',
		'INITIATE_WEIGH_BRIDGE_EDIT',
		'SET_INITIAL_FORM_VALUES_WEIGH_BRIDGE',
		'FLUSH_WEIGH_BRIDGE_FORM_VALUES',
		'SET_WEIGH_BRIDGE_DETAILS',
		'VIEW_WEIGH_BRIDGE_DETAILS',
		'CREATE_WEIGH_BRIDGE_AS_ASSET',
		'EDIT_WEIGH_BRIDGE',
		'DELETE_WEIGH_BRIDGE',
		'CREATE_STORAGE_UNIT_AS_ASSET',
		'INITIATE_STORAGE_UNIT_EDIT',
		'EMPLOYEE_UPLOAD_USER_VALIDATION',
		'FLUSH_STORAGE_UNIT_FORM_VALUES',
		'SET_INITIAL_FORM_VALUES_STORAGE_UNIT',
		'SET_EMPLOYEE_UPLOAD_LIST',
		'SET_UPDATED_EMPLOYEE_UPLOAD_ARRAY',
		'EDIT_STORAGE_UNIT',
		'VIEW_STORAGE_UNIT_DETAILS',
		'SET_STORAGE_UNIT_DETAILS',
		'DELETE_STORAGE_UNIT',
		'FETCH_COMPANY_INFO',
		'SET_CHILD_ORG_DETAILS',
		'SET_CERTIFICATE_DETAILS',
		'SET_AREA_DETAILS',
		'SET_OTHER_DOCUMENTS_DETAILS',
		'SET_BUSINESS_DETAILS',
		'SET_COMPANY_INFO',
		'FETCH_EMPLOYEES_LISTING',
		'SET_EMPLOYEES_LISTING',
		'FLUSH_EMPLOYEE_STATE_BEFORE_VIEW',
		'VIEW_EMPLOYEE_DETAILS',
		'SET_EMPLOYEE_DETAILS',
		'SET_EMPLOYEE_UPLOAD_EDIT_SUCCESS',
		'SET_INITIAL_EMPLOYEE_DETAILS',
		'SET_UPLOAD_DO_DETAILS',
		'ADD_EMPLOYEE',
		'FLUSH_EMPLOYEE_STATE',
		'INITIATE_EDIT_EMPLOYEE_DETAILS',
		'DELETE_EMPLOYEE',
		'FETCH_EMPLOYEE_LOADING',
		'EMPLOYEE_PAGINATION_QUERIES',
		'UPDATE_ORGANISATION_DETAILS',
		'SAVE_OTHER_SETTINGS',
		'FETCH_DO_UPLOAD_STATUS',
		'UPDATE_ORGANISATION_LOGO',
		'DELETE_SINGLE_CERTIFICATION',
		'UPDATE_SINGLE_CERTIFICATION',
		'ADD_NEW_CERTIFICATION',
		'UPDATE_FARM_DETAILS',
		'SET_ORIGINAL_EMPLOYEE_ROLE',
		'SET_MODIFIED_EMPLOYEE_ROLE',
		'ADD_COLLECTION_POINT',
		'SET_SHOW_ADD_CP_SUCCESS_POPUP',
		'UPDATE_TANK_LOCK_STATUS',
		'SET_TANK_TIMELINE',
		'UPDATE_AVERAGE_BUNCH_WEIGHT',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.SET_ASSETS_LISTING: {
				return setIn(
					state,
					['modules', 'assets', 'assetsListing'],
					action.assetDetails
				)
			}

			case duck.types.SET_ASSETS_PAGINATION_QUERIES: {
				const { query } = action

				return setIn(
					state,
					['modules', 'assets', 'assetsPagination'],
					query
				)
			}

			case duck.types.SET_ASSETS_LOADING_STATUS: {
				return setIn(
					state,
					['modules', 'assets', 'loading'],
					action.loading
				)
			}

			case duck.types.FLUSH_PRODUCTION_LINE_FORM_VALUES: {
				return setIn(
					state,
					['modules', 'assets', 'initialValuesProductionLine'],
					initialState.modules.assets.initialValuesProductionLine
				)
			}

			case duck.types.FLUSH_VEHICLE_FORM_VALUES: {
				return setIn(
					state,
					['modules', 'assets', 'initialVehicleDetails'],
					initialState.modules.assets.initialVehicleDetails
				)
			}

			case duck.types.SET_INITIAL_FORM_VALUES_VEHICLE: {
				return setIn(
					state,
					['modules', 'assets', 'initialVehicleDetails'],
					action.vehicleDetails
				)
			}

			case duck.types.SET_EMPLOYEE_DETAILS: {
				return setIn(
					state,
					['modules', 'employee', 'employeeDetails'],
					action.details
				)
			}

			case duck.types.SET_EMPLOYEE_UPLOAD_EDIT_SUCCESS: {
				return setIn(
					state,
					['modules', 'employee', 'employeeUploadEditSuccess'],
					action.success
				)
			}

			case duck.types.SET_INITIAL_EMPLOYEE_DETAILS: {
				const { employeeDetails } = action

				return setIn(
					state,
					['modules', 'employee', 'initialEmployeeDetails'],
					employeeDetails
				)
			}

			case duck.types.FLUSH_STORAGE_UNIT_FORM_VALUES: {
				return setIn(
					state,
					['modules', 'assets', 'initialValuesStorageUnit'],
					initialState.modules.assets.initialValuesStorageUnit
				)
			}
			case duck.types.SET_INITIAL_FORM_VALUES_PRODUCTION_LINE: {
				return setIn(
					state,
					['modules', 'assets', 'initialValuesProductionLine'],
					action.prodLineDetails
				)
			}
			case duck.types.SET_INITIAL_FORM_VALUES_STORAGE_UNIT: {
				return setIn(
					state,
					['modules', 'assets', 'initialValuesStorageUnit'],
					action.storageUnitDetails
				)
			}

			case duck.types.SET_VEHICLE_DETAILS: {
				return setIn(
					state,
					['modules', 'assets', 'vehicleDetails'],
					action.details
				)
			}

			case duck.types.SET_STORAGE_UNIT_DETAILS: {
				return setIn(
					state,
					['modules', 'assets', 'storageUnitDetails'],
					action.details
				)
			}

			case duck.types.SET_EMPLOYEES_LISTING: {
				return setIn(
					state,
					['modules', 'employee', 'employeeListing'],
					action.employeeDetails
				)
			}
			case duck.types.SET_EMPLOYEE_UPLOAD_LIST: {
				return setIn(
					state,
					['modules', 'employee', 'employeeUploadList'],
					action.employeeList
				)
			}
			case duck.types.SET_UPDATED_EMPLOYEE_UPLOAD_ARRAY: {
				return setIn(
					state,
					['modules', 'employee', 'updatedEmployeeUploadArray'],
					action.arrayList
				)
			}

			// flushing the state before form is loaded
			case duck.types.FLUSH_EMPLOYEE_STATE: {
				return setIn(
					state,
					['modules', 'employee', 'initialEmployeeDetails'],
					initialState.modules.employee.initialEmployeeDetails
				)
			}
			case duck.types.SET_PRODUCTION_LINE_DETAILS: {
				return setIn(
					state,
					['modules', 'assets', 'productionLineDetails'],
					action.details
				)
			}
			case duck.types.SET_INITIAL_FORM_VALUES_WEIGH_BRIDGE: {
				return setIn(
					state,
					['modules', 'assets', 'initialWeighBridgeDetails'],
					action.weighbridgeDetails
				)
			}
			case duck.types.FLUSH_WEIGH_BRIDGE_FORM_VALUES: {
				return setIn(
					state,
					['modules', 'assets', 'initialWeighBridgeDetails'],
					initialState.modules.assets.initialWeighBridgeDetails
				)
			}
			case duck.types.SET_WEIGH_BRIDGE_DETAILS: {
				return setIn(
					state,
					['modules', 'assets', 'weighbridgeDetails'],
					action.details
				)
			}
			// flushing the state before viewing Employee Details
			case duck.types.FLUSH_EMPLOYEE_STATE_BEFORE_VIEW: {
				return setIn(
					state,
					['modules', 'employee', 'employeeDetails'],
					initialState.modules.employee.employeeDetails
				)
			}

			case duck.types.SET_COMPANY_INFO: {
				return setIn(state, ['companyInformation'], action.companyInfo)
			}
			case duck.types.SET_CHILD_ORG_DETAILS: {
				return setIn(state, ['modules', 'childOrgs'], action.details)
			}
			case duck.types.SET_CERTIFICATE_DETAILS: {
				return setIn(state, ['certificateDetails'], action.details)
			}
			case duck.types.SET_AREA_DETAILS: {
				return setIn(state, ['areaDetails'], action.details)
			}
			case duck.types.SET_OTHER_DOCUMENTS_DETAILS: {
				return setIn(state, ['otherDocumentsDetails'], action.details)
			}
			case duck.types.SET_UPLOAD_DO_DETAILS: {
				return setIn(state, ['uploadDODetails'], action.uploadDODetails)
			}
			case duck.types.SET_BUSINESS_DETAILS: {
				return setIn(state, ['businessDetails'], action.details)
			}

			case duck.types.FETCH_EMPLOYEE_LOADING: {
				return setIn(
					state,
					['modules', 'employee', 'loading'],
					action.status || false
				)
			}

			case duck.types.EMPLOYEE_PAGINATION_QUERIES: {
				const { paginate } = action

				return setIn(
					state,
					['modules', 'employee', 'pagination'],
					paginate
				)
			}

			case duck.types.SET_ORIGINAL_EMPLOYEE_ROLE: {
				const { role } = action

				return setIn(
					state,
					['modules', 'employee', 'employeeRole', 'original'],
					role
				)
			}

			case duck.types.SET_MODIFIED_EMPLOYEE_ROLE: {
				const { role } = action

				return setIn(
					state,
					['modules', 'employee', 'employeeRole', 'modified'],
					role
				)
			}

			case duck.types.SET_SHOW_ADD_CP_SUCCESS_POPUP: {
				const { status } = action

				return setIn(state, ['modules', 'showCpSuccessModal'], status)
			}

			case duck.types.SET_TANK_TIMELINE: {
				const { details } = action

				return setIn(
					state,
					['modules', 'assets', 'tankTimeline'],
					details
				)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		employeeState: state =>
			getIn(state, ['admin', 'modules', 'employee']) || {},
		activeModule: state =>
			getIn(state, ['admin', 'activeModule']) || 'Error',
		getAssetsActiveTabs: state =>
			getIn(state, ['admin', 'modules', 'assets', 'tabsConfig']) || [],
		getAssetsListing: state =>
			getIn(state, ['admin', 'modules', 'assets', 'assetsListing']) || {},
		getAssetPaginationQueries: state =>
			getIn(state, ['admin', 'modules', 'assets', 'assetsPagination']) ||
			{},

		getInitialVehicleDetails: state =>
			getIn(state, [
				'admin',
				'modules',
				'assets',
				'initialVehicleDetails',
			]) || {},
		getInitialWeighBridgeDetails: state =>
			getIn(state, [
				'admin',
				'modules',
				'assets',
				'initialWeighBridgeDetails',
			]) || {},
		getVehicleDetails: state =>
			getIn(state, ['admin', 'modules', 'assets', 'vehicleDetails']) ||
			{},
		getInitialProductionLineDetails: state =>
			getIn(state, [
				'admin',
				'modules',
				'assets',
				'initialValuesProductionLine',
			]) || {},
		getProductionLineDetails: state =>
			getIn(state, [
				'admin',
				'modules',
				'assets',
				'productionLineDetails',
			]) || {},
		getInitialStorageUnitDetails: state =>
			getIn(state, [
				'admin',
				'modules',
				'assets',
				'initialValuesStorageUnit',
			]) || {},
		getStorageUnitDetails: state =>
			getIn(state, [
				'admin',
				'modules',
				'assets',
				'storageUnitDetails',
			]) || {},
		getWeighbridgeDetails: state =>
			getIn(state, [
				'admin',
				'modules',
				'assets',
				'weighbridgeDetails',
			]) || {},
		getAssetsLoadingStatus: state =>
			getIn(state, ['admin', 'modules', 'assets', 'loading']) || false,
		getInitialEmployeeDetails: state =>
			getIn(state, [
				'admin',
				'modules',
				'employee',
				'initialEmployeeDetails',
			]) || {},
		getCompanyInfo: state =>
			getIn(state, ['admin', 'companyInformation']) || {},
		getShowCpSucessModel: state =>
			getIn(state, ['admin', 'modules', 'showCpSuccessModal']) || false,
		getChildOrgsDetails: state =>
			getIn(state, ['admin', 'modules', 'childOrgs']) || [],
		getCertificateDetails: state =>
			getIn(state, ['admin', 'certificateDetails']) || [],
		getAreaDetails: state => getIn(state, ['admin', 'areaDetails']) || [],
		getOtherDocumentsDetails: state =>
			getIn(state, ['admin', 'otherDocumentsDetails']) || [],
		getUploadDOdetails: state =>
			getIn(state, ['admin', 'uploadDODetails']) || {},
		getBusinessDetails: state =>
			getIn(state, ['admin', 'businessDetails']) || {},

		getEmployeeLoadingStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.employeeState,
				listing => listing.loading || false
			)
		),
		getEmployeesListing: new Duck.Selector(selectors =>
			createSelector(
				selectors.employeeState,
				listing => listing.employeeListing || false
			)
		),
		getEmployeeUploadList: new Duck.Selector(selectors =>
			createSelector(
				selectors.employeeState,
				listing => listing.employeeUploadList || []
			)
		),
		getUpdateEmployeeUploadList: new Duck.Selector(selectors =>
			createSelector(
				selectors.employeeState,
				listing => listing.updatedEmployeeUploadArray || []
			)
		),
		getEmployeeUploadEditSuccess: new Duck.Selector(selectors =>
			createSelector(
				selectors.employeeState,
				employees =>
					getIn(employees, ['employeeUploadEditSuccess']) || false
			)
		),
		getEmployeeDetails: new Duck.Selector(selectors =>
			createSelector(
				selectors.employeeState,
				listing => listing.employeeDetails || false
			)
		),
		fetchEmployeePagination: new Duck.Selector(selectors =>
			createSelector(
				selectors.employeeState,
				employees => getIn(employees, ['pagination']) || []
			)
		),
		getEmployeeRole: new Duck.Selector(selectors =>
			createSelector(
				selectors.employeeState,
				employees => getIn(employees, ['employeeRole']) || {}
			)
		),
		getTankTimeline: state =>
			getIn(state, ['admin', 'modules', 'assets', 'tankTimeline']) || [],
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchAssetsListing: (name = '') => ({
			type: duck.types.FETCH_ASSETS_LISTING,
			name,
		}),
		setAssetsList: assetDetails => ({
			type: duck.types.SET_ASSETS_LISTING,
			assetDetails,
		}),
		setAssetsPaginationQueries: query => ({
			type: duck.types.SET_ASSETS_PAGINATION_QUERIES,
			query,
		}),
		setAssetsLoadingStatus: loading => ({
			type: duck.types.SET_ASSETS_LOADING_STATUS,
			loading,
		}),
		fetchEmployeesListing: (
			isSkip,
			locationState,
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_EMPLOYEES_LISTING,
			isSkip,
			locationState,
			skipGlobalLoader,
		}),
		employeePaginationQueries: paginate => ({
			type: duck.types.EMPLOYEE_PAGINATION_QUERIES,
			paginate,
		}),
		setEmployeeListing: employeeDetails => ({
			type: duck.types.SET_EMPLOYEES_LISTING,
			employeeDetails,
		}),
		createVehicleAsAsset: (details, toastMessage) => ({
			type: duck.types.CREATE_VEHICLE_AS_ASSET,
			details,
			toastMessage,
		}),
		initiateVehicleEdit: vehicleID => ({
			type: duck.types.INITIATE_VEHICLE_EDIT,
			vehicleID,
		}),
		flushVehicleFormValues: () => ({
			type: duck.types.FLUSH_VEHICLE_FORM_VALUES,
		}),
		setInitialFormValuesforVehicle: vehicleDetails => ({
			type: duck.types.SET_INITIAL_FORM_VALUES_VEHICLE,
			vehicleDetails,
		}),
		editVehicle: (details, toastMessage) => ({
			type: duck.types.EDIT_VEHICLE,
			details,
			toastMessage,
		}),
		viewVehicleDetails: vehicleID => ({
			type: duck.types.VIEW_VEHICLE_DETAILS,
			vehicleID,
		}),
		setVehicleDetails: details => ({
			type: duck.types.SET_VEHICLE_DETAILS,
			details,
		}),
		deleteVehicle: (vehicleID, toastMessage) => ({
			type: duck.types.DELETE_VEHICLE,
			vehicleID,
			toastMessage,
		}),
		createProductionLineAsAsset: (details, toastMessage) => ({
			type: duck.types.CREATE_PRODUCTION_LINE_AS_ASSET,
			details,
			toastMessage,
		}),
		initiateProductionLineEdit: productionLineID => ({
			type: duck.types.INITIATE_PRODUCTION_LINE_EDIT,
			productionLineID,
		}),
		flushProductionLineFormValues: () => ({
			type: duck.types.FLUSH_PRODUCTION_LINE_FORM_VALUES,
		}),
		setInitialFormValuesforProductionLine: prodLineDetails => ({
			type: duck.types.SET_INITIAL_FORM_VALUES_PRODUCTION_LINE,
			prodLineDetails,
		}),
		editProductionLine: (details, toastMessage) => ({
			type: duck.types.EDIT_PRODUCTION_LINE,
			details,
			toastMessage,
		}),
		viewProductionLineDetails: productionLineID => ({
			type: duck.types.VIEW_PRODUCTION_LINE_DETAILS,
			productionLineID,
		}),
		setProductionLineDetails: details => ({
			type: duck.types.SET_PRODUCTION_LINE_DETAILS,
			details,
		}),
		deleteProductionLine: (assetID, toastMessage) => ({
			type: duck.types.DELETE_PRODUCTION_LINE,
			assetID,
			toastMessage,
		}),
		initiateWeighbridgesEdit: weighbridgeID => ({
			type: duck.types.INITIATE_WEIGH_BRIDGE_EDIT,
			weighbridgeID,
		}),
		setInitialFormValuesforWeighbridges: weighbridgeDetails => ({
			type: duck.types.SET_INITIAL_FORM_VALUES_WEIGH_BRIDGE,
			weighbridgeDetails,
		}),
		flushWeighbridgesFormValues: () => ({
			type: duck.types.FLUSH_WEIGH_BRIDGE_FORM_VALUES,
		}),
		setWeighbridgeDetails: details => ({
			type: duck.types.SET_WEIGH_BRIDGE_DETAILS,
			details,
		}),
		viewWeighbridgeDetails: weighbridgeID => ({
			type: duck.types.VIEW_WEIGH_BRIDGE_DETAILS,
			weighbridgeID,
		}),
		createWeighbrigeAsAsset: (details, uploadedFiles, toastMessage) => ({
			type: duck.types.CREATE_WEIGH_BRIDGE_AS_ASSET,
			details,
			uploadedFiles,
			toastMessage,
		}),
		editWeighbridge: (details, uploadedFiles, toastMessage) => ({
			type: duck.types.EDIT_WEIGH_BRIDGE,
			details,
			uploadedFiles,
			toastMessage,
		}),
		deleteWeighbridge: (assetID, toastMessage) => ({
			type: duck.types.DELETE_WEIGH_BRIDGE,
			assetID,
			toastMessage,
		}),
		createStorageUnitAsAsset: (details, toastMessage) => ({
			type: duck.types.CREATE_STORAGE_UNIT_AS_ASSET,
			details,
			toastMessage,
		}),
		initiateStorageUnitEdit: storageUnitID => ({
			type: duck.types.INITIATE_STORAGE_UNIT_EDIT,
			storageUnitID,
		}),
		employeeUploadUserValidation: url => ({
			type: duck.types.EMPLOYEE_UPLOAD_USER_VALIDATION,
			url,
		}),
		flushStorageUnitFormValues: () => ({
			type: duck.types.FLUSH_STORAGE_UNIT_FORM_VALUES,
		}),
		setInitialFormValuesforStorageUnit: storageUnitDetails => ({
			type: duck.types.SET_INITIAL_FORM_VALUES_STORAGE_UNIT,
			storageUnitDetails,
		}),
		setEmployeeUploadList: employeeList => ({
			type: duck.types.SET_EMPLOYEE_UPLOAD_LIST,
			employeeList,
		}),
		setUpdatedEmployeeUploadArray: arrayList => ({
			type: duck.types.SET_UPDATED_EMPLOYEE_UPLOAD_ARRAY,
			arrayList,
		}),
		editStorageUnit: (details, toastMessage) => ({
			type: duck.types.EDIT_STORAGE_UNIT,
			details,
			toastMessage,
		}),
		viewStorageUnitDetails: storageUnitID => ({
			type: duck.types.VIEW_STORAGE_UNIT_DETAILS,
			storageUnitID,
		}),
		deleteStorageUnit: (assetID, toastMessage) => ({
			type: duck.types.DELETE_STORAGE_UNIT,
			assetID,
			toastMessage,
		}),
		setStorageUnitDetails: details => ({
			type: duck.types.SET_STORAGE_UNIT_DETAILS,
			details,
		}),
		fetchCompanyInfo: () => ({
			type: duck.types.FETCH_COMPANY_INFO,
		}),
		setCompanyInfo: companyInfo => ({
			type: duck.types.SET_COMPANY_INFO,
			companyInfo,
		}),
		setChildOrgDetails: details => ({
			type: duck.types.SET_CHILD_ORG_DETAILS,
			details,
		}),
		setCertificateDetails: details => ({
			type: duck.types.SET_CERTIFICATE_DETAILS,
			details,
		}),
		setAreaDetails: details => ({
			type: duck.types.SET_AREA_DETAILS,
			details,
		}),
		setOtherDocumentsDetails: details => ({
			type: duck.types.SET_OTHER_DOCUMENTS_DETAILS,
			details,
		}),
		setBusinessDetails: details => ({
			type: duck.types.SET_BUSINESS_DETAILS,
			details,
		}),
		flushEmployeeStateBeforeView: () => ({
			type: duck.types.FLUSH_EMPLOYEE_STATE_BEFORE_VIEW,
		}),
		viewEmployeeDetails: employeeID => ({
			type: duck.types.VIEW_EMPLOYEE_DETAILS,
			employeeID,
		}),
		setEmployeeDetails: details => ({
			type: duck.types.SET_EMPLOYEE_DETAILS,
			details,
		}),
		setEmployeeUploadEditSuccess: (success = false) => ({
			type: duck.types.SET_EMPLOYEE_UPLOAD_EDIT_SUCCESS,
			success,
		}),
		addEmployee: (values, userID, toastMessage, fromUpload = false) => ({
			type: duck.types.ADD_EMPLOYEE,
			values,
			userID,
			toastMessage,
			fromUpload,
		}),
		flushEmployeeState: () => ({
			type: duck.types.FLUSH_EMPLOYEE_STATE,
		}),
		setInitialEmployeeDetails: employeeDetails => ({
			type: duck.types.SET_INITIAL_EMPLOYEE_DETAILS,
			employeeDetails,
		}),
		setUploadDODetails: uploadDODetails => ({
			type: duck.types.SET_UPLOAD_DO_DETAILS,
			uploadDODetails,
		}),
		initiateEditEmployeeDetails: employeeID => ({
			type: duck.types.INITIATE_EDIT_EMPLOYEE_DETAILS,
			employeeID,
		}),
		deleteEmployee: (employeeID, toastMessage) => ({
			type: duck.types.DELETE_EMPLOYEE,
			employeeID,
			toastMessage,
		}),
		fetchEmployeeLoading: status => ({
			type: duck.types.FETCH_EMPLOYEE_LOADING,
			status,
		}),
		updateOrganisation: (values, successToast) => ({
			type: duck.types.UPDATE_ORGANISATION_DETAILS,
			values,
			successToast,
		}),
		saveOtherSettings: (values, actor, orgDetails, successToast) => ({
			type: duck.types.SAVE_OTHER_SETTINGS,
			values,
			successToast,
			actor,
			orgDetails,
		}),
		fetchDOUploadStatus: () => ({
			type: duck.types.FETCH_DO_UPLOAD_STATUS,
		}),
		updateOrganisationLogo: (logoURL, successToast) => ({
			type: duck.types.UPDATE_ORGANISATION_LOGO,
			logoURL,
			successToast,
		}),
		deleteSingleCertificate: (certID, successToast) => ({
			type: duck.types.DELETE_SINGLE_CERTIFICATION,
			certID,
			successToast,
		}),
		updateSingleCertificate: (values, id, successToast) => ({
			type: duck.types.UPDATE_SINGLE_CERTIFICATION,
			values,
			id,
			successToast,
		}),
		addNewCertificate: (values, successToast) => ({
			type: duck.types.ADD_NEW_CERTIFICATION,
			values,
			successToast,
		}),
		updateFarmDetails: (values, successToast) => ({
			type: duck.types.UPDATE_FARM_DETAILS,
			values,
			successToast,
		}),
		setModifiedEmployeeRole: role => ({
			type: duck.types.SET_MODIFIED_EMPLOYEE_ROLE,
			role,
		}),
		setOriginalEmployeeRole: role => ({
			type: duck.types.SET_ORIGINAL_EMPLOYEE_ROLE,
			role,
		}),
		addCollectionPoint: (details, organizationDetail, helpers) => ({
			type: duck.types.ADD_COLLECTION_POINT,
			details,
			organizationDetail,
			helpers,
		}),
		showCpSuccessModal: status => ({
			type: duck.types.SET_SHOW_ADD_CP_SUCCESS_POPUP,
			status,
		}),
		updateTankLockStatus: (status, assetID, message) => ({
			type: duck.types.UPDATE_TANK_LOCK_STATUS,
			status,
			assetID,
			message,
		}),
		setTankTimeline: details => ({
			type: duck.types.SET_TANK_TIMELINE,
			details,
		}),
		updateAverageBunchWeight: (averageBunchWeight, orgDetails) => ({
			type: duck.types.UPDATE_AVERAGE_BUNCH_WEIGHT,
			averageBunchWeight,
			orgDetails,
		}),
	}),
})

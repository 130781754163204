import { WeighBridgeDuc } from 'ui-tdm-app/modules/WeighBridge/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import saga from 'ui-tdm-app/modules/WeighBridge/WeighBridgeSaga'
import { getIn } from 'timm'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload, query } = locationState

	const docReference = getIn(query, ['parentDocRef'])
	const attachmentID = getIn(locationState, ['payload', 'attachmentID'])

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('WEIGHBRIDGE', { saga })

	const isMainDashboard = MainRouteDuc.types.WEIGHBRIDGE === type
	const isSubDashboard = MainRouteDuc.types.WEIGHBRIDGE$WACTION === type
	const isDocumentView =
		MainRouteDuc.types.WEIGHBRIDGE$DOCUMENTREFERENCE === type

	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the ui-tdm module
		activeModule = 'dashboard'
		dispatch(WeighBridgeDuc.creators.fetchWeighBridgeListing())
	} else if (isSubDashboard || isDocumentView) {
		activeModule = 'subDashboard'
		if (payload.action === 'create') {
			dispatch(
				WeighBridgeDuc.creators.fetchEntityInfoforWBSlip(docReference)
			)
		} else if (payload.action === 'edit') {
			dispatch(
				WeighBridgeDuc.creators.initiateEditSlip(
					docReference,
					attachmentID
				)
			)
		} else if (payload.action === 'view') {
			dispatch(WeighBridgeDuc.creators.fetchSingleWBSlip())
		}
	}
	// load the dashboard of the ui-tdm module
	dispatch(WeighBridgeDuc.creators.setActiveModule(activeModule))
}

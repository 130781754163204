import { Box as GrommBox } from 'grommet'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { getIn } from 'timm'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import {
	ButtonIconWrapper,
	RoundedButton,
} from 'ui-lib/components/Button/index'
import { Input } from 'ui-lib/components/Input'
import { H2 } from 'ui-lib/components/Typography'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import SendSvg from 'ui-lib/icons/send.svg'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import downArrow from 'ui-lib/icons/down.svg'
import upArrow from 'ui-lib/icons/upward-arrow.svg'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import { getRandomNumber } from '../../utils/helpers'
import { MessagesGroup } from './MessagesGroup'

const BoxStyled = styled(Box)`
	&:hover {
		background-color: ${p =>
			p.showChatBox ? theme.color.grey1 : theme.color.blue2} !important;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
	}
`

const ChatHeader = styled.div``

const SendButton = styled(RoundedButton)`
	border-radius: 150px;
	height: 48px;
	width: 48px;
	right: auto;
	bottom: auto;
	position: relative !important;
	background: ${p => p.theme.color.orange};
	box-shadow: none !important;
	color: ${p => (p.primary ? p.theme.color.white : p.theme.color.primary)};
`

const TabComponents = ({
	messages,
	orgId,
	activeTab,
	threadIdentifier,
	handleClick,
	useDateByFormat,
}) => {
	return (
		<MessagesGroup
			msg={messages}
			orgId={orgId}
			activeTab={activeTab}
			threadIdentifier={threadIdentifier}
			handleClick={(id, message) => {
				handleClick(id, message)
			}}
			useDateByFormat={useDateByFormat}
		/>
	)
}

const getTabComponents = (
	messages,
	orgId,
	activeTab,
	threadIdentifier,
	handleClick,
	useDateByFormat
) => () => (
	<TabComponents
		orgId={orgId}
		messages={messages}
		activeTab={activeTab}
		threadIdentifier={threadIdentifier}
		handleClick={(id, message) => {
			handleClick(id, message)
		}}
		useDateByFormat={useDateByFormat}
	/>
)

const ChatModal = ({
	documentId,
	tradeId,
	sellerName,
	buyerName,
	buyerId,
	sellerId,
	chatBoxData,
	isMobile,
	dispatch,
	handleTranslation,
	orgId,
	threadIDs,
	firstDocTypeList,
	contractChatIdentifier,
	documentNumberList,
	getDocumentChatList,
	token,
	unReadCountFromApi,
	firstName,
	userId,
	organization,
	currentOrganization,
	MessagesDuc,
	AppDuc,
	ChatClient,
	AzureCommunicationTokenCredential,
	getChatEndPoint,
	useDateByFormat,
	docType = 'rfq',
}) => {
	const [chatText, setChatText] = useState('')
	const [editMsgIndex, setEditMsgIndex] = useState('')
	const [currentId, setCurrentId] = useState('')
	const [messages, setMessages] = useState([])
	const [chat, setChatClient] = useState(null)
	const [docTypeList, setDocTypeList] = useState(null)
	const [showChatBox, setShowChatBox] = useState(false)
	const [timeLeft, setTimeLeft] = useState(1000)
	const [sortedArrayList, setSortedArrayList] = useState([])
	const [textBoxKey, setTextBoxKey] = useState(0)

	const [unreadCount, setunreadCount] = useState(null)

	const chatTitleName = orgId === sellerId ? buyerName : sellerName

	const threadIdentifier =
		docType === 'contract-broker-seller' ||
		docType === 'contract-buyer-seller'
			? contractChatIdentifier
			: `${documentId}~${docType}`
	const [activeTab, setActiveTab] = useState(threadIdentifier)
	const [currentDocNumber, setCurrentDocNumber] = useState(
		documentNumberList[threadIdentifier]
	)
	const [stopCount, setStopCount] = useState(0)
	const [disableButton, setDisableButton] = useState(false)
	useEffect(() => {
		dispatch(MessagesDuc.creators.setContractChatIdentifier(null))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setDocTypeList(null)
		if (firstDocTypeList.find(ele => ele === threadIdentifier)) {
			if (
				currentOrganization &&
				getIn(currentOrganization, ['categories', 0, 'id']) ===
					'palmoil-broker'
			) {
				if (
					buyerId &&
					sellerId &&
					getIn(currentOrganization, ['id']) === sellerId &&
					firstDocTypeList.find(
						ele => ele === `${documentId}~contract-buyer-broker`
					)
				) {
					setDocTypeList([`${documentId}~contract-buyer-broker`])
				} else if (
					firstDocTypeList.find(
						ele => ele === `${documentId}~contract-broker-seller`
					)
				) {
					setDocTypeList([`${documentId}~contract-broker-seller`])
				}
			} else {
				setDocTypeList(firstDocTypeList)
			}
		} else if (token && tradeId) {
			setTimeout(() => {
				if (stopCount < 5) {
					setStopCount(stopCount + 1)
					dispatch(
						MessagesDuc.creators.getChatThreadId(
							tradeId,
							docType,
							documentId
						)
					)
				}
			}, 1000)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstDocTypeList])

	useEffect(() => {
		const interval = setInterval(() => {
			if (
				token &&
				tradeId &&
				!showChatBox &&
				threadIDs[threadIdentifier]
			) {
				dispatch(
					MessagesDuc.creators.chatCountCallOnly(
						threadIDs[threadIdentifier]
					)
				)
			}
			setTimeLeft(timeLeft - 1)
		}, 20000)

		return () => clearInterval(interval)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeLeft])

	const index = useCallback(() => {
		let indexCount = ''
		if (sortedArrayList && sortedArrayList.length > 0) {
			const currentDoc = threadIdentifier.split('~')
			// eslint-disable-next-line no-shadow
			sortedArrayList.forEach((item, index) => {
				if (getIn(item, ['meta', 'documentID']) === currentDoc[0]) {
					indexCount = index
				}
			})
		}

		return indexCount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortedArrayList])

	const tabTitles = (key, count) => {
		let title = key
		if (key.includes('rfq')) {
			title = count === 0 ? 'rfq' : `rfq ${count}`
		} else if (key.includes('quote')) {
			title = count === 0 ? 'quote' : `quote ${count}`
		} else if (key.includes('purchase-order')) {
			title = count === 0 ? 'po' : `po ${count}`
		} else if (key.includes('invoice')) {
			title = count === 0 ? 'invoice' : `invoice ${count}`
		} else if (key.includes('delivery-order')) {
			title = count === 0 ? 'do' : `do ${count}`
		} else if (key.includes('contract')) {
			title = count === 0 ? 'contract' : `contract ${count}`
		}

		return title.toUpperCase()
	}

	const getListByTime = list => {
		const finalSortedArray = []
		let count = list.length > 1 ? 1 : 0
		// eslint-disable-next-line func-names
		const sortedArray = list.sort(function(a, b) {
			return new Date(a.createdAt) - new Date(b.createdAt)
		})
		sortedArray.forEach(item => {
			const currentItem = { ...item }
			currentItem.listNumber = count
			finalSortedArray.push(currentItem)
			// eslint-disable-next-line no-plusplus
			count++
		})

		return finalSortedArray
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getfilterList = useCallback(
		(listArray, currentDoc, responseArray) => {
			if (listArray && listArray.length > 0) {
				const currentType = currentDoc.split('~')
				const currentDocFilteredList = listArray.filter(item =>
					item.includes(currentType[1])
				)
				const arrayToCheck = listArray.filter(
					item => !item.includes(currentType[1])
				)

				if (
					currentDocFilteredList &&
					currentDocFilteredList.length > 0 &&
					getDocumentChatList &&
					getDocumentChatList.length > 0
				) {
					const docTypeArray = []
					currentDocFilteredList.forEach(item => {
						const currentItem = getDocumentChatList.find(
							ele =>
								getIn(ele, ['meta', 'documentID']) ===
								item.split('~')[0]
						)

						docTypeArray.push(currentItem)
					})
					// eslint-disable-next-line no-param-reassign
					responseArray = responseArray.concat(
						getListByTime(docTypeArray)
					)
				}

				if (arrayToCheck && arrayToCheck.length > 0) {
					const currDoc = arrayToCheck[0]
					getfilterList(arrayToCheck, currDoc, responseArray)
				}

				if (responseArray.length === docTypeList.length) {
					setSortedArrayList(responseArray)
				}
			}
		}
	)

	useEffect(() => {
		if (docTypeList && docTypeList.length > 0 && threadIdentifier) {
			// eslint-disable-next-line no-unused-vars
			const currentDoc =
				docTypeList.find(item => item === threadIdentifier) || ''
			getfilterList(docTypeList, currentDoc, [])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [docTypeList, getDocumentChatList])

	const getTabs = useCallback(() => {
		const tabsArray = []
		if (
			sortedArrayList &&
			sortedArrayList.length > 0 &&
			docTypeList &&
			docTypeList.length
		) {
			sortedArrayList.forEach(key => {
				const docNumber = getIn(key, ['meta', 'documentID']) // assumming there should be only one contract
				const currentChat = docTypeList.find(item =>
					item.includes(docNumber)
				)

				if (currentChat) {
					tabsArray.push({
						name: currentChat,
						title: tabTitles(
							currentChat,
							getIn(key, ['listNumber'])
						),
						IndicatorComponent:
							parseInt(currentChat, 10) > 0 &&
							(({ isActive }) => (
								<DefaultTabIndicator active={isActive}>
									{parseInt(currentChat, 10)}
								</DefaultTabIndicator>
							)),
					})
				}
			})
		}

		return tabsArray
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortedArrayList])

	const loadMessageByID = async id => {
		try {
			const threadID = threadIDs[threadIdentifier]
			if (!threadID || !chat) {
				return
			}
			const chatThreadClient = chat.getChatThreadClient(threadID)

			const message = await chatThreadClient.getMessage(id)
			if (currentId !== id) {
				if (Object.keys(messages).length > 0) {
					setMessages(ms => ({
						...ms,
						[threadIdentifier]: [...ms[threadIdentifier], message],
					}))
				} else {
					setMessages(ms => ({
						...ms,
						[threadIdentifier]: [message],
					}))
				}

				if (showChatBox) {
					dispatch(
						MessagesDuc.creators.sendMessageAction(
							threadID,
							'reset-unread'
						)
					)
				}
			}
		} catch (err) {
			const { message } = err
			dispatch(
				AppDuc.creators.showToast({
					messageType: 'error',
					message,
				})
			)
		}
	}
	const loadMessages = useCallback(async () => {
		try {
			if (docTypeList && docTypeList.length > 0) {
				await Promise.all(
					docTypeList.map(async option => {
						const threadID = threadIDs[option]
						if (!threadID || !chat) {
							return
						}
						const chatThreadClient = await chat.getChatThreadClient(
							threadID
						)
						const msg = []
						for await (const chatMessage of chatThreadClient.listMessages()) {
							msg.push(chatMessage)
						}
						msg.sort((a, b) => a.sequenceId - b.sequenceId)

						const newArray = {}
						newArray[option] = msg
						setMessages(oldArray => ({
							...oldArray,
							...newArray,
						}))
						if (showChatBox) {
							dispatch(
								MessagesDuc.creators.sendMessageAction(
									threadID,
									'reset-unread'
								)
							)
						}
					})
				)
			}
		} catch (err) {
			const { message } = err
			dispatch(
				AppDuc.creators.showToast({
					messageType: 'error',
					message,
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chat, docTypeList, threadIDs])

	const startNotification = useCallback(async () => {
		try {
			const threadID = threadIDs[threadIdentifier]
			if (!chat || chat === null || !threadID) {
				return
			}
			if (!chat?.isRealtimeNotificationsStarted) {
				await chat.startRealtimeNotifications()
				chat.on('chatMessageReceived', ({ id, threadId, metadata }) => {
					if (
						threadId === threadID &&
						getIn(organization, ['id']) ===
							getIn(metadata, ['receiverID'])
					) {
						setCurrentId(id)
						loadMessageByID(id)
					}
				})
				chat.on('chatMessageEdited', list => {
					if (
						getIn(list, ['threadId']) === threadID &&
						getIn(organization, ['id']) ===
							getIn(list, ['metadata', 'receiverID'])
					) {
						loadMessages()
					}
				})
			}
		} catch (err) {
			const { message } = err
			dispatch(
				AppDuc.creators.showToast({
					messageType: 'error',
					message,
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chat, threadIDs])

	useEffect(() => {
		if (token && tradeId) {
			dispatch(
				MessagesDuc.creators.getChatThreadId(
					tradeId,
					docType,
					documentId
				)
			)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tradeId, token])

	useEffect(() => {
		setCurrentDocNumber(documentNumberList[activeTab])

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab, messages])

	useEffect(() => {
		if (showChatBox) {
			const threadID = threadIDs[threadIdentifier]

			if (threadID) {
				dispatch(
					MessagesDuc.creators.sendMessageAction(
						threadID,
						'reset-unread'
					)
				)
			}
			setunreadCount(0)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [threadIDs, showChatBox])

	useEffect(() => {
		startNotification()
		loadMessages()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [threadIDs])

	useEffect(() => {
		if (token && tradeId) {
			const chatClient = new ChatClient(
				getChatEndPoint(),
				new AzureCommunicationTokenCredential(token)
			)
			setChatClient(chatClient)
		}
		setTimeLeft(timeLeft - 1)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, tradeId])

	useEffect(() => {
		setActiveTab(threadIdentifier)
		setCurrentDocNumber(documentNumberList[threadIdentifier])

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [threadIdentifier, showChatBox])

	const sendMessage = async () => {
		try {
			const threadID = threadIDs[threadIdentifier]
			if (!threadID) {
				return
			}
			const chatThreadClient = chat.getChatThreadClient(threadID)

			const sendMessageRequest = {
				content: chatText,
			}

			const sendMessageOptions = {
				senderDisplayName: firstName,
				metadata: {
					senderID: orgId,
					receiverID: orgId === sellerId ? buyerId : sellerId,
					clientID: userId,
				},
				type: 'text',
			}

			if (editMsgIndex !== '') {
				await chatThreadClient.updateMessage(editMsgIndex, {
					content: chatText,
				})
				const msg = await chatThreadClient.getMessage(editMsgIndex)
				const newState = messages[threadIdentifier].map(obj => {
					if (obj.id === editMsgIndex) {
						return msg
					}

					return obj
				})
				setMessages(ms => ({
					...ms,
					[threadIdentifier]: newState,
				}))
			} else {
				const sendMessageResult = await chatThreadClient.sendMessage(
					sendMessageRequest,
					sendMessageOptions
				)
				await loadMessageByID(sendMessageResult.id)
			}

			await dispatch(
				MessagesDuc.creators.sendMessageAction(threadID, 'sent-message')
			)
		} catch (err) {
			const { message } = err
			dispatch(
				AppDuc.creators.showToast({
					messageType: 'error',
					message,
				})
			)
		} finally {
			// loadMessages()
			setEditMsgIndex('')
			setChatText('')
			setTextBoxKey(getRandomNumber())
		}
	}

	useEffect(() => {
		setunreadCount(unReadCountFromApi)
	}, [unReadCountFromApi])

	const getLoader = () => {
		return stopCount < 5 ? (
			<TransparentBgSpinner />
		) : (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: '188px',
				}}
			>
				{handleTranslation('common.noChat')}
			</div>
		)
	}

	const getMeasure = (normalMeasure, measureForMobile) => {
		let measure = normalMeasure
		if (isMobile) {
			measure = measureForMobile
		}

		return measure
	}

	return (
		<>
			<GrommBox
				style={{
					flex: 0.5,
					minWidth: showChatBox
						? getMeasure(560, 393)
						: getMeasure(350, 320),
					maxWidth: getMeasure(560, 320),
					maxHeight: 575,
					minHeight: showChatBox ? 575 : 0,
					border: showChatBox
						? `2px solid ${theme.color.black3}`
						: 'none',
					backgroundColor: showChatBox
						? theme.color.white
						: theme.color.black3,
					borderTopRightRadius: 10,
					borderTopLeftRadius: 10,
					borderBottomRightRadius: showChatBox ? 10 : 0,
					borderBottomLeftRadius: showChatBox ? 10 : 0,
				}}
				overflow
				justify="between"
				width="inherit"
				height="inherit"
				background="#fff"
				showChatBox={showChatBox}
			>
				<BoxStyled
					showChatBox={showChatBox}
					row
					justifyContent="space-between"
					onClick={() => {
						if (!showChatBox && docType.includes('contract')) {
							setTimeout(() => {
								setShowChatBox(!showChatBox)
								chatBoxData(!showChatBox)
							}, 600)
						} else {
							setShowChatBox(!showChatBox)
							chatBoxData(!showChatBox)
						}
					}}
					style={{
						cursor: 'pointer',
						padding: 10,
						borderBottom: showChatBox
							? `2px solid ${theme.color.black3}`
							: 'none',
					}}
				>
					<Box row alignItems="center">
						<H2
							style={{
								color: showChatBox
									? theme.color.primary
									: theme.color.white,
								fontSize: 18,
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								display: 'flex',
							}}
							title={`${handleTranslation(
								'common.chatWith'
							)} ${chatTitleName}`}
						>
							<ChatHeader>{`${handleTranslation(
								'common.chatWith'
							)} ${chatTitleName} `}</ChatHeader>
						</H2>
						{!showChatBox && unreadCount > 0 && (
							<Box
								alignItems="center"
								justifyContent="center"
								style={{
									margin: '0px 10px',
									height: 26,
									width: 26,
									borderRadius: 13,
									color: theme.color.white,
									background: 'red',
								}}
							>
								{unreadCount}
							</Box>
						)}
					</Box>
					<IconWrapper
						size={showChatBox ? 32 : 31}
						style={{
							paddingTop: 0,
							paddingRight: showChatBox ? '10px' : '2px',
						}}
					>
						<Icon
							color={
								showChatBox
									? theme.color.primary
									: theme.color.white
							}
							glyph={showChatBox ? downArrow : upArrow}
						/>
					</IconWrapper>
				</BoxStyled>
				{showChatBox && (
					<Box
						style={{
							minHeight: 515,
							padding: 10,
							paddingTop: 0,
						}}
					>
						<Box flex="1" width="100%" alignSelf="flex-start">
							<>
								{firstDocTypeList &&
								firstDocTypeList.length > 0 &&
								docTypeList &&
								docTypeList.length > 0 ? (
									<Tabs
										tabHeaderwidth="530px"
										style={{
											flex: 1,
										}}
										extendStyles={{
											flex: 1,
											marginTop: '2px',
											position: 'relative',
										}}
										id="TDMTabs"
										tabWidth="70px"
										tabs={getTabs()}
										tabComponentsMap={() =>
											getTabComponents(
												messages,
												orgId,
												activeTab,
												threadIdentifier,
												(id, message) => {
													setEditMsgIndex(id)
													setChatText(message)
												},
												useDateByFormat
											)
										}
										activeTabIndex={index}
										onChange={({ name }) => {
											setActiveTab(name)
										}}
										selectedTab={index() + 1}
										headerOverFlow
									/>
								) : (
									getLoader()
								)}
							</>
						</Box>
						<Box
							padding="14px 0px 0px 0px"
							row
							justifyContent="space-between"
							alignItems="flex-start"
							width="inherit"
						>
							<Box
								style={{
									flex: 1,
									marginRight: '20px',
									height: '60px',
								}}
							>
								<Input
									key={textBoxKey}
									value={chatText}
									name="name"
									type="text"
									placeholder={`${handleTranslation(
										'tdmDetailsEntry.typeHere'
									)}...`}
									disabled={
										activeTab !== threadIdentifier ||
										stopCount > 4
									}
									onChange={text => {
										setDisableButton(false)
										setChatText(text.target.value)
									}}
								/>
							</Box>
							<Box padding="0px 8px 0px 0px">
								<SendButton
									disabled={
										chatText.trim() === '' || disableButton
									}
									action
									primary
									rounded
									onClick={() => {
										sendMessage()
										setDisableButton(true)
									}}
									customIcon={
										<ButtonIconWrapper
											size="24px"
											width="24px"
										>
											<Icon
												color={theme.color.white}
												glyph={SendSvg}
											/>
										</ButtonIconWrapper>
									}
								/>
							</Box>
						</Box>
						<Box row alignItems="center" justifyContent="center">
							<div style={{ display: 'flex' }}>
								<div
									style={{
										color: theme.color.grey10,
										fontWeight: 500,
									}}
								>
									{`${handleTranslation(
										'common.document'
									)} ${handleTranslation(
										'tdmDetailsEntry.reference'
									)} : `}{' '}
									&nbsp;
								</div>
								<div
									style={{
										color: theme.color.themeGrey1,
										fontWeight: 500,
									}}
								>
									{currentDocNumber || ''}
								</div>
							</div>
						</Box>
						<Box row alignItems="center" justifyContent="start">
							<div
								style={{
									fontSize: '10px',
									marginTop: '6px',
									marginLeft: '3px',
									color: theme.color.grey10,
								}}
							>
								<div>
									<span
										style={{
											color: theme.color.themeGrey1,
										}}
									>
										*
									</span>
									<span
										style={{
											marginLeft: '3px',
										}}
									>
										{handleTranslation(
											'common.dibizHasNovisibilityToTheMessages'
										)}
									</span>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'end',
										color: theme.color.grey10,
									}}
								>
									<span
										style={{
											color: theme.color.themeGrey1,
										}}
									>
										*
									</span>
									<span
										style={{
											marginLeft: '3px',
										}}
									>
										{handleTranslation(
											'common.senderAndReceiverAreResponsibleForTheMessageExchanges'
										)}
									</span>
								</div>
							</div>
						</Box>
					</Box>
				)}
			</GrommBox>
		</>
	)
}

export { ChatModal }

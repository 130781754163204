import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spacer } from 'ui-lib/utils/Spacer'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Box } from 'ui-lib/utils/Box'
import { getIn } from 'timm'
import { Input } from 'ui-lib/components/Input'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { isEmptyObject, useDebouncedCallback } from 'ui-tdm-app/utils/helpers'
import request from 'ui-tdm-app/utils/request'
import { getGMAPAddressFetchUrl } from 'ui-tdm-app/config'
import { P, H2 } from 'ui-lib/components/Typography'
import { ButtonWithNoBorder, Button } from 'ui-lib/components/Button'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { FormHeader, FormBody, FormFooter } from '../../../components/Fragments'

const ShippingForm = ({ onSuccess, onClose }) => {
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const validationSchema = yup.object().shape({
		line1: yup.string().required(t('validation.addressLine1required')),
		line2: yup.string(),
		city: yup.string(),
		postalCode: yup
			.string()
			.matches(/^[1-9]\d{5}$/, t('validation.postalCodeFormat')),
		state: yup.string().required(t('validation.stateRequired')),
		country: yup.string().required(t('validation.countryRequired')),
	})

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		setFieldValue,
		setFieldTouched,
		isSubmitting,
		setSubmitting: _setSubmitting,
	} = useFormik({
		initialValues: {
			line1: '',
			line2: '',
			city: '',
			postalCode: '',
			state: '',
			country: '',
		},
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			dispatch(
				AuthDuc.creators.addOrgShippingAddress(
					_values,
					t('common.addressUpdateSuccess'),
					{
						setSubmitting,
						onSuccess,
					}
				)
			)
		},
	})
	const hadFetchFailure = useRef()

	const handleOnPinCodeChange = useDebouncedCallback(
		(name, value, valuesReference, errorReference) => {
			setFieldValue(name, value)

			// if user has already entered data, bail out.
			if (
				hadFetchFailure.current ||
				valuesReference.city ||
				valuesReference.state ||
				valuesReference.country
			) {
				return
			}

			if (!errorReference[name]) {
				_setSubmitting(true)

				request(getGMAPAddressFetchUrl(value))
					.then(res => {
						const result = getIn(res, [
							'data',
							'results',
							0,
							'address_components',
						])
						const city = getIn(result, [1, 'long_name'])
						const state = getIn(result, [2, 'long_name'])
						const country = getIn(result, [3, 'long_name'])

						if (
							valuesReference.city ||
							valuesReference.state ||
							valuesReference.country
						) {
							return
						}

						if (city) setFieldValue('city', city)
						if (state) setFieldValue('state', state)
						if (country) setFieldValue('country', country)
					})
					.catch(() => {
						hadFetchFailure.current = true
					})
					.finally(() => {
						_setSubmitting(false)
					})
			}
		},
		1000
	)

	return (
		<Box>
			<FormHeader>
				<H2 primary>{t('tdmDetailsEntry.enterNewShippingAddress')}</H2>
			</FormHeader>
			<form onSubmit={handleSubmit}>
				<FormBody>
					<Box overflow width="inherit">
						<Input
							name="line1"
							value={values.line1}
							required
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder={t('tdmDetailsEntry.addressLine1')}
							error={touched.line1 && errors.line1}
						/>
						<Input
							name="line2"
							value={values.line2}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder={t('tdmDetailsEntry.addressLine2')}
							error={touched.line2 && errors.line2}
						/>
					</Box>
					<Box row={!isMobile} overflow width="inherit">
						<Input
							name="postalCode"
							required
							value={values.postalCode}
							onChange={({ postalCode }) => {
								handleOnPinCodeChange(
									'postalCode',
									postalCode,
									values,
									errors
								)
							}}
							returnKeyValue
							onBlur={({ postalCode }) =>
								setFieldTouched('postalCode', postalCode)
							}
							error={touched.postalCode && errors.postalCode}
							placeholder={t('tdmDetailsEntry.postalCode')}
						/>
						{!isMobile && <Spacer size={24} horizontal />}
						<Input
							name="city"
							required
							value={values.city}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder={t('tdmDetailsEntry.city')}
							error={touched.city && errors.city}
						/>
					</Box>
					<Box row={!isMobile} overflow width="inherit">
						<Input
							name="state"
							required
							value={values.state}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder={t('tdmDetailsEntry.state')}
							error={touched.state && errors.state}
						/>
						{!isMobile && <Spacer size={24} horizontal />}
						<Input
							value={values.country}
							required
							onChange={handleChange}
							onBlur={handleBlur}
							name="country"
							placeholder={t('tdmDetailsEntry.country')}
							error={touched.country && errors.country}
						/>
					</Box>
					{/** Space to justify for absolute bottom */}
					<Spacer size={64} />
				</FormBody>
				<FormFooter
					row
					justifyContent="flex-end"
					style={{ width: '100%' }}
				>
					<Box center row style={{ maxWidth: 375 }}>
						<ButtonWithNoBorder
							label={t('common.cancel')}
							onClick={() => onClose()}
						/>
						<Box
							justifyContent="center"
							width={150}
							padding={1}
							height="inherit"
							overflow
						>
							<Button
								label={
									<P color="white">
										<strong>{t('common.submit')}</strong>
									</P>
								}
								type="submit"
								isLoading={isSubmitting}
								disabled={!isEmptyObject(errors)}
								primary
							/>
						</Box>
					</Box>
				</FormFooter>
			</form>
		</Box>
	)
}

export { ShippingForm }

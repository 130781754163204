import { getIn, merge, omit, setIn } from 'timm'
import LogHelper from 'ui-tdm-app/utils/logger'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AdminDuc } from 'ui-tdm-app/modules/Admin/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { CookieDuc } from 'ui-tdm-app/modules/App/cookieDuc'
import {
	CallWithRefreshCheck,
	getOrgIDFromLoggedUser,
	fetchOrgAssets,
	fetchOrgDetailsCount,
	fetchCurrentOrgsDetail,
} from 'ui-tdm-app/modules/Auth/AuthSaga'
import { getTargetFilterQueries } from 'ui-tdm-app/modules/TradeDocumentManager/helpers'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import { transformCertforStore } from 'ui-tdm-app/utils/helpers'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { Toast } from 'ui-lib/components/Toast'
import { getIAMEndPoint, getCoreEndPoint } from 'ui-tdm-app/config'
import request from 'ui-tdm-app/utils/request'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { DocumentListing } from 'ui-tdm-app/modules/App/AppSaga'
import { INITIAL_TYPES } from './config'

const logger = LogHelper('client:AdminSaga')

// for fetching all the assets of the organisation
function* fetchAssetsListing(action) {
	try {
		const { name } = action
		yield put(AppDuc.creators.showGlobalLoader('fetch-assets'))
		yield put(AuthDuc.creators.fetchAllProducts())

		yield put(AdminDuc.creators.setAssetsLoadingStatus(true))
		const orgID = yield getOrgIDFromLoggedUser()
		yield fetchCurrentOrgsDetail({
			orgID,
			returnResponse: true,
		})

		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		let vehicleUrl
		let storageUnitUrl
		let productionLineUrl
		let weighbridgeUrl

		if (selectedCPID) {
			vehicleUrl = `${getCoreEndPoint()}assets/vehicles/childorg/${selectedCPID}?state=active`

			storageUnitUrl = `${getCoreEndPoint()}assets/storageunits/childorg/${selectedCPID}?type=neq(production-line)&state=active`

			productionLineUrl = `${getCoreEndPoint()}assets/productionlinelist/childorg/${selectedCPID}?state=active`

			weighbridgeUrl = `${getCoreEndPoint()}assets/weighbridges/childorg/${selectedCPID}?state=active`
		} else {
			vehicleUrl = `${getCoreEndPoint()}assets/vehicles?state=active`
			storageUnitUrl = `${getCoreEndPoint()}assets/storageunits?type=neq(production-line)&state=active`
			productionLineUrl = `${getCoreEndPoint()}assets/productionlinelist?state=active`
			weighbridgeUrl = `${getCoreEndPoint()}assets/weighbridges?state=active`
		}

		let URLs = []
		// for loadMore passing name from onClick
		if (name && name === 'vehicles') {
			URLs = [vehicleUrl]
		} else if (name && name === 'storageunits') {
			URLs = [storageUnitUrl]
		} else if (name && name === 'production-line') {
			URLs = [productionLineUrl]
		} else if (name && name === 'weighbridges') {
			URLs = [weighbridgeUrl]
		} else {
			URLs = [
				vehicleUrl,
				storageUnitUrl,
				productionLineUrl,
				weighbridgeUrl,
			]
		}
		let prevPagination = {}
		let prevData = {}
		let nextStartID = ''
		if (name) {
			prevPagination = yield select(
				AdminDuc.selectors.getAssetPaginationQueries
			)
			prevData = yield select(AdminDuc.selectors.getAssetsListing)
			nextStartID =
				(prevPagination[name] && prevPagination[name].nextStartID) || ''
		}

		const callsPipeLine = URLs.map(url => {
			return CallWithRefreshCheck(`${url}&startID=${nextStartID || ''}`)
		})

		const origResponse = yield all(callsPipeLine)

		if (!name) {
			const pagination = INITIAL_TYPES.reduce((agg, _type, key) => {
				const aggregator = agg
				aggregator[_type] = {}
				aggregator[_type].nextStartID =
					getIn(origResponse[key], ['data', 'nextStartID']) || ''
				aggregator[_type].pageCount =
					getIn(origResponse[key], ['data', 'pageCount']) || ''
				aggregator[_type].pageSize =
					getIn(origResponse[key], ['data', 'pageSize']) || ''
				aggregator[_type].prevStartID =
					getIn(origResponse[key], ['data', 'prevStartID']) || ''
				aggregator[_type].total =
					getIn(origResponse[key], ['data', 'total']) || ''

				return aggregator
			}, {})

			const responses = INITIAL_TYPES.reduce((agg, _type, key) => {
				const aggregator = agg
				aggregator[_type] =
					getIn(origResponse[key], ['data', 'list']) || []

				return aggregator
			}, {})

			yield put(AdminDuc.creators.setAssetsPaginationQueries(pagination))

			yield put(AdminDuc.creators.setAssetsList(responses))
		} else {
			const currPagination = { ...prevPagination }
			const currData = { ...prevData }
			let currDataForName = [...prevData[name]]
			const currentPage = {
				nextStartID:
					getIn(origResponse[0], ['data', 'nextStartID']) || '',
				pageCount: getIn(origResponse[0], ['data', 'pageCount']) || '',
				pageSize: getIn(origResponse[0], ['data', 'pageSize']) || '',
				prevStartID:
					getIn(origResponse[0], ['data', 'prevStartID']) || '',
				total: getIn(origResponse[0], ['data', 'total']) || '',
			}
			currPagination[name] = currentPage

			currDataForName = currDataForName.concat(
				getIn(origResponse[0], ['data', 'list']) || []
			)

			currData[name] = currDataForName

			yield put(
				AdminDuc.creators.setAssetsPaginationQueries(currPagination)
			)

			yield put(AdminDuc.creators.setAssetsList(currData))
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-assets'))
		yield put(AdminDuc.creators.setAssetsLoadingStatus(false))
	}
}

// Create Vehicle as Asset
function* createVehicleAsAsset(vehicle) {
	try {
		const { details, toastMessage } = vehicle

		const {
			type,
			name,
			number,
			make,
			model,
			capacity,
			capacityUOM,
		} = details

		yield put(AppDuc.creators.showGlobalLoader('create-vehicle-asset'))

		const vehicleDetails = {
			number,
			// Vehicle type should be either commute/goods-carrier. and no need to store commute vehicles
			type: 'goods-carrier',
			meta: {
				name,
				type,
				make,
				model,
				capacity: capacity || 0,
				capacityUOM,
			},
		}

		const requestUrl = `${getCoreEndPoint()}assets/vehicles`
		const options = {
			method: 'POST',
			body: JSON.stringify(vehicleDetails),
		}

		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})

		yield fetchOrgAssets()

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
				type: 'vehicles',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('create-vehicle-asset'))
	}
}

// API call to get the vehicle details that should be populated on Edit screen
function* initiateVehicleEdit(action) {
	try {
		const { vehicleID } = action
		yield put(
			AppDuc.creators.showGlobalLoader('initiate-edit-vehicle-details')
		)

		yield put(AdminDuc.creators.flushVehicleFormValues())
		const requestUrl = `${getCoreEndPoint()}assets/vehicles/${vehicleID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)

		const vehicleDetails = {
			name: getIn(data, ['meta', 'name']),
			number: getIn(data, ['number']),
			make: getIn(data, ['meta', 'make']),
			model: getIn(data, ['meta', 'model']),
			capacity: getIn(data, ['meta', 'capacity']),
			capacityUOM: getIn(data, ['meta', 'capacityUOM']),
			type: getIn(data, ['meta', 'type']),
		}

		yield put(
			AdminDuc.creators.setInitialFormValuesforVehicle(vehicleDetails)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('initiate-edit-vehicle-details')
		)
	}
}

// API request to update an existing FFB Quality report
function* editVehicle(action) {
	try {
		const { details, toastMessage } = action
		const {
			type,
			name,
			number,
			make,
			model,
			capacity,
			capacityUOM,
			vehicleID,
		} = details

		yield put(AppDuc.creators.showGlobalLoader('edit-vehicle-asset'))

		const vehicleDetails = {
			number,
			type: 'goods-carrier',
			meta: {
				name,
				type,
				make,
				model,
				capacity,
				capacityUOM,
			},
		}

		if (capacity === '') {
			vehicleDetails.meta.capacity = 0
		}

		if (capacityUOM === undefined) {
			vehicleDetails.meta.capacityUOM = ''
		}

		const requestUrl = `${getCoreEndPoint()}assets/vehicles/${vehicleID}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(vehicleDetails),
		}
		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})

		yield fetchOrgAssets()

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('edit-vehicle-asset'))
	}
}

// for fetching Vehicle Details to display in VIEW page
function* viewVehicleDetails(action) {
	try {
		const { vehicleID } = action
		yield put(AppDuc.creators.showGlobalLoader('vehicle-details'))
		yield put(AdminDuc.creators.setAssetsLoadingStatus(true))
		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		let requestUrl = `${getCoreEndPoint()}assets/vehicles/${vehicleID}`
		if (selectedCPID) {
			requestUrl = `${getCoreEndPoint()}assets/vehicles/${vehicleID}/childorg/${selectedCPID}`
		}
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)

		yield put(AdminDuc.creators.setVehicleDetails(data))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('vehicle-details'))
		yield put(AdminDuc.creators.setAssetsLoadingStatus(false))
	}
}

function* deleteVehicle(action) {
	try {
		const { vehicleID, toastMessage } = action

		const requestUrl = `${getCoreEndPoint()}assets/vehicles/${vehicleID}`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: toastMessage,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

// to create production-line as an asset
function* createProductionLineAsAsset(action) {
	try {
		const { details, toastMessage } = action
		const { name, capacity, capacityUOM } = details

		yield put(
			AppDuc.creators.showGlobalLoader('create-productionLine-asset')
		)

		const productionLineDetails = {
			type: 'production-line',
			name,
			capacity,
			capacityUOM,
		}

		const requestUrl = `${getCoreEndPoint()}assets/productionline`

		const options = {
			method: 'POST',
			body: JSON.stringify(productionLineDetails),
		}

		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})

		yield fetchOrgAssets()

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
				type: 'production-line',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('create-productionLine-asset')
		)
	}
}

// API call to get the prodLine details that should be populated on Edit screen
function* initiateProductionLineEdit(action) {
	try {
		const { productionLineID } = action
		yield put(
			AppDuc.creators.showGlobalLoader('initiate-productionLine-edit')
		)

		yield put(AdminDuc.creators.flushProductionLineFormValues())
		const requestUrl = `${getCoreEndPoint()}assets/productionline/${productionLineID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)

		const prodLineDetails = {
			name: getIn(data, ['name']),
			capacity: getIn(data, ['capacity']),
			capacityUOM: getIn(data, ['capacityUOM']),
		}

		yield put(
			AdminDuc.creators.setInitialFormValuesforProductionLine(
				prodLineDetails
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('initiate-productionLine-edit')
		)
	}
}

// API request to update an existing Production Line
function* editProductionLine(action) {
	try {
		const { details, toastMessage } = action
		const { name, capacity, capacityUOM, productionLineID } = details

		yield put(AppDuc.creators.showGlobalLoader('edit-productionLine-asset'))

		const productionLineDetails = {
			type: 'production-line',
			name,
			capacity,
			capacityUOM,
		}

		const requestUrl = `${getCoreEndPoint()}assets/productionline/${productionLineID}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(productionLineDetails),
		}
		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})

		yield fetchOrgAssets()

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('edit-productionLine-asset'))
	}
}

// for fetching Production Line Details to display in VIEW page
function* viewProductionLineDetails(action) {
	try {
		const { productionLineID } = action
		yield put(AppDuc.creators.showGlobalLoader('productionLine-details'))
		yield put(AdminDuc.creators.setAssetsLoadingStatus(true))

		const requestUrl = `${getCoreEndPoint()}assets/productionline/${productionLineID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)

		yield put(AdminDuc.creators.setProductionLineDetails(data))
		yield put(AuthDuc.creators.fetchAllProducts())
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('productionLine-details'))
		yield put(AdminDuc.creators.setAssetsLoadingStatus(false))
	}
}

function* deleteProductionLine(action) {
	try {
		const { assetID, toastMessage } = action

		const requestUrl = `${getCoreEndPoint()}assets/productionline/${assetID}`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: toastMessage,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* deleteStorageUnit(action) {
	try {
		const { assetID, toastMessage } = action

		const requestUrl = `${getCoreEndPoint()}assets/storageunits/${assetID}`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: toastMessage,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* initiateWeighbridgesEdit(action) {
	try {
		const { weighbridgeID } = action
		yield put(
			AppDuc.creators.showGlobalLoader(
				'initiate-edit-weighbridge-details'
			)
		)

		yield put(AdminDuc.creators.flushWeighbridgesFormValues())
		const requestUrl = `${getCoreEndPoint()}assets/weighbridge/${weighbridgeID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)

		const weighbridgeDetails = {
			name: getIn(data, ['name']),
			manufacturer: getIn(data, ['manufacturer']),
			model: getIn(data, ['model']),
			capacity: getIn(data, ['capacity']),
			capacityUOM: getIn(data, ['capacityUOM']),
			lastcalibrated: getIn(data, [
				'meta',
				'certificate',
				'lastCaliberated',
			]),
			calibrationvalidity: getIn(data, [
				'meta',
				'certificate',
				'calibrationValidity',
			]),
			calibratedby: getIn(data, ['meta', 'certificate', 'calibratedBy']),
			files: getIn(data, ['meta', 'files']),
		}

		yield put(
			AdminDuc.creators.setInitialFormValuesforWeighbridges(
				weighbridgeDetails
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader(
				'initiate-edit-weighbridge-details'
			)
		)
	}
}

function* viewWeighbridgeDetails(action) {
	try {
		const { weighbridgeID } = action
		yield put(AppDuc.creators.showGlobalLoader('weighbridge-details'))
		yield put(AdminDuc.creators.setAssetsLoadingStatus(true))

		const requestUrl = `${getCoreEndPoint()}assets/weighbridge/${weighbridgeID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)

		yield put(AdminDuc.creators.setWeighbridgeDetails(data))
		yield put(AuthDuc.creators.fetchAllProducts())
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('weighbridge-details'))
		yield put(AdminDuc.creators.setAssetsLoadingStatus(false))
	}
}

// to create production-line as an asset
function* createWeighbrigeAsAsset(action) {
	try {
		const { details, uploadedFiles, toastMessage } = action

		yield put(AppDuc.creators.showGlobalLoader('create-weighbridge-asset'))

		const formData = new FormData()

		let files = []
		if (uploadedFiles.length > 0) {
			formData.append('file', uploadedFiles[0])
			formData.append('name', uploadedFiles[0].name)

			const requestUrl = `${getIAMEndPoint()}files/private`

			const options = {
				method: 'POST',
				headers: {
					'content-type': null,
				},
				body: formData,
				formData: true,
			}
			const { data } = yield call(request, requestUrl, options)
			files = [data]
		}

		const weighbridgeRequestUrl = `${getCoreEndPoint()}assets/weighbridge`

		const requestPayload = {
			name: details.name,
			manufacturer: details.manufacturer,
			model: details.model,
			capacity: details.capacity,
			capacityUOM: details.capacityUOM,
			meta: {
				certificate: {
					lastCaliberated: details.lastcalibrated,
					calibrationValidity: details.calibrationvalidity,
					calibratedBy: details.calibratedby,
				},
			},
		}

		if (uploadedFiles.length > 0) {
			requestPayload.meta.files = files
		}

		const weighbridgeOptions = {
			method: 'POST',
			body: JSON.stringify(requestPayload),
		}

		yield call(request, weighbridgeRequestUrl, weighbridgeOptions)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})

		yield fetchOrgDetailsCount()

		yield fetchOrgAssets()

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
				type: 'weighbridges',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('create-weighbridge-asset'))
	}
}

// API request to update an existing Production Line
function* editWeighbridge(action) {
	try {
		const { details, uploadedFiles, toastMessage } = action

		yield put(AppDuc.creators.showGlobalLoader('edit-weighbridge-asset'))

		let { files } = details
		if (uploadedFiles[0]) {
			const formData = new FormData()
			formData.append('file', uploadedFiles[0])
			formData.append('name', uploadedFiles[0].name)

			const requestUrl = `${getIAMEndPoint()}files/private`

			const options = {
				method: 'POST',
				headers: {
					'content-type': null,
				},
				body: formData,
				formData: true,
			}
			const { data } = yield call(request, requestUrl, options)
			files = [data]
		}

		const weighbridgeRequestUrl = `${getCoreEndPoint()}assets/weighbridge/${
			details.weighbridgeID
		}`

		const weighbridgeOptions = {
			method: 'PUT',
			body: JSON.stringify({
				name: details.name,
				manufacturer: details.manufacturer,
				model: details.model,
				capacity: details.capacity,
				capacityUOM: details.capacityUOM,
				meta: {
					certificate: {
						lastCaliberated: details.lastcalibrated,
						calibrationValidity: details.calibrationvalidity,
						calibratedBy: details.calibratedby,
					},
					files,
				},
			}),
		}

		yield call(request, weighbridgeRequestUrl, weighbridgeOptions)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})

		yield fetchOrgAssets()

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
				type: 'weighbridges',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('edit-weighbridge-asset'))
	}
}

function* deleteWeighbridge(action) {
	try {
		const { assetID, toastMessage } = action

		const requestUrl = `${getCoreEndPoint()}assets/weighbridge/${assetID}`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: toastMessage,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

// to create storage-unit as an asset
function* createStorageUnitAsAsset(action) {
	try {
		const { details, toastMessage } = action
		const {
			type,
			name,
			capacity,
			initialQuantity,
			supplyChainModel,
			productID,
			reservedFor,
			inventoryType,
			certificationType,
		} = details

		yield put(AppDuc.creators.showGlobalLoader('create-storageUnit-asset'))

		const requestUrl = `${getCoreEndPoint()}assets/storageunits`

		const storageUnitDetails = {
			capacity,
			capacityUOM: 'mT',
			type,
			name,
			meta: {
				initialProducts: [
					{
						productID,
						initialQuantity: parseFloat(initialQuantity, 2),
					},
				],
				supplyChainModel,
				reservedFor,
				inventoryType,
				certificationType,
			},
		}

		if (type === 'area' || type === 'container') {
			delete storageUnitDetails.capacity
			delete storageUnitDetails.capacityUOM
		}

		if (type === 'area') {
			storageUnitDetails.meta.default = true
		}

		const options = {
			method: 'POST',
			body: JSON.stringify(storageUnitDetails),
		}

		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})

		yield fetchOrgDetailsCount()

		yield fetchOrgAssets()

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
				type: 'storageunits',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('create-storageUnit-asset'))
	}
}

// API call to get the storageunit details that should be populated on Edit screen
function* initiateStorageUnitEdit(action) {
	try {
		const { storageUnitID } = action
		yield put(
			AppDuc.creators.showGlobalLoader('initiate-productionLine-edit')
		)
		yield put(AdminDuc.creators.setAssetsLoadingStatus(true))

		yield put(AdminDuc.creators.flushStorageUnitFormValues())
		const requestUrl = `${getCoreEndPoint()}assets/storageunits/${storageUnitID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)

		const storageUnitDetails = {
			type: getIn(data, ['type']),
			name: getIn(data, ['name']),
			capacity: getIn(data, ['capacity']),
			capacityUOM: getIn(data, ['capacityUOM']),
			productID: getIn(data, ['meta', 'initialProducts', 0, 'productID']),
			inventoryType: getIn(data, ['meta', 'inventoryType']),
			supplyChainModel: getIn(data, ['meta', 'supplyChainModel']),
			reservedFor: getIn(data, ['meta', 'reservedFor']),
			certificationType: getIn(data, ['meta', 'certificationType']),
			initialQuantity: getIn(data, [
				'meta',
				'initialProducts',
				0,
				'initialQuantity',
			]),
		}

		yield put(
			AdminDuc.creators.setInitialFormValuesforStorageUnit(
				storageUnitDetails
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('initiate-productionLine-edit')
		)
		yield put(AdminDuc.creators.setAssetsLoadingStatus(false))
	}
}

function* employeeUploadUserValidation(action) {
	try {
		const { url } = action

		const requestUrl = `${getIAMEndPoint()}clients/uservalidation`
		const payload = {
			fileUrl: url,
		}
		const options = {
			method: 'POST',
			body: JSON.stringify(payload),
		}
		const { data } = yield call(request, requestUrl, options)

		const finalData = []

		if (data && data.length > 0 && data.find(item => !item.valid)) {
			let count = 1
			data.forEach(item => {
				const currentItem = { ...item }
				currentItem.tempKey = count
				finalData.push(currentItem)
				count += 1
			})

			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ADMIN$WACTION,
					{
						rootModule: 'employee-management',
						action: 'preview-employee',
					}
				)
			)
		} else {
			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ADMIN$SUBROOT,
					{
						rootModule: 'employee-management',
					}
				)
			)
		}

		yield put(AdminDuc.creators.setEmployeeUploadList(finalData))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('employee-upload-user-validation')
		)
	}
}

// API request to update an existing Storage Unit
function* editStorageUnit(action) {
	try {
		const { details, toastMessage } = action
		const {
			type,
			name,
			capacity,
			initialQuantity,
			supplyChainModel,
			inventoryType,
			reservedFor,
			storageUnitID,
			productID,
			certificationType,
		} = details

		yield put(AppDuc.creators.showGlobalLoader('edit-storageUnit-asset'))

		const storageUnitDetails = {
			capacity,
			capacityUOM: 'mT',
			type,
			name,
			meta: {
				supplyChainModel,
				reservedFor,
				inventoryType,
				certificationType,
				initialProducts: [
					{
						productID,
						initialQuantity: parseFloat(initialQuantity, 2),
					},
				],
			},
		}

		const requestUrl = `${getCoreEndPoint()}assets/storageunits/${storageUnitID}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(storageUnitDetails),
		}

		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})

		yield fetchOrgAssets()

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
				type: 'storageunits',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('edit-storageUnit-asset'))
	}
}

// for fetching StorageUnit Details to display in VIEW page
function* viewStorageUnitDetails(action) {
	try {
		const { storageUnitID } = action
		yield put(AppDuc.creators.showGlobalLoader('storageunit-details'))
		yield put(AdminDuc.creators.setAssetsLoadingStatus(true))

		const requestUrl = `${getCoreEndPoint()}assets/storageunits/${storageUnitID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)

		const timelineRequestUrl = `${getCoreEndPoint()}assets/status-list?assetID=${storageUnitID}`
		const timelineoptions = {
			method: 'GET',
		}
		const { data: _data = {} } = yield call(
			request,
			timelineRequestUrl,
			timelineoptions
		)
		yield put(AdminDuc.creators.setTankTimeline(_data.list))

		yield put(AdminDuc.creators.setStorageUnitDetails(data))
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchPartnerOrgs())
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('storageunit-details'))
		yield put(AdminDuc.creators.setAssetsLoadingStatus(false))
	}
}

function* fetchCompanyInfo() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-company-info'))
		const orgID = yield getOrgIDFromLoggedUser()
		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)
		const farmAddress =
			getIn(data, ['meta', 'properties', 0, 'other', 'farmAddress']) || {}
		const orgDetails = {
			name: getIn(data, ['name']) || '',
			description: getIn(data, ['description']) || '',
			firstName: getIn(data, ['firstName']) || '',
			type: getIn(data, ['categories', 0, 'name']) || '',
			city: getIn(data, ['primaryAddress', 'city']) || '',
			line1: getIn(data, ['primaryAddress', 'line1']) || '',
			line2: getIn(data, ['primaryAddress', 'line2']) || '',
			postalCode: getIn(data, ['primaryAddress', 'postalCode']) || '',
			state: getIn(data, ['primaryAddress', 'state']) || '',
			country: getIn(data, ['primaryAddress', 'country']) || '',
			locationDetail: getIn(data, ['primaryAddress', 'location']) || {},
			location:
				data && data.primaryAddress && data.primaryAddress.location
					? `${getIn(data, ['primaryAddress', 'location', 'lat']) ||
							''},
			${getIn(data, ['primaryAddress', 'location', 'long']) || ''}`
					: '',
			address: `${getIn(data, ['primaryAddress', 'line1']) ||
				''} ${getIn(data, ['primaryAddress', 'city']) || ''}
 ${getIn(data, ['primaryAddress', 'state']) || ''} ${getIn(data, [
				'primaryAddress',
				'country',
			]) || ''} ${getIn(data, ['primaryAddress', 'postalCode']) || ''}`,
			mobile: getIn(data, ['primaryContact', 'mobile']) || '',
			email: getIn(data, ['primaryContact', 'email']) || '',
			logoURL: getIn(data, ['meta', 'logo']) || '',
			processValue: getIn(data, ['meta', 'processValue']) || '',
			formStatus: getIn(data, ['meta', 'formStatus']) || {},
			meta: getIn(data, ['meta']) || {},
			id: getIn(data, ['id']) || '',
			farmDetails: {
				area: getIn(data, ['meta', 'properties', 0, 'area']) || '',
				plantationArea:
					getIn(data, ['meta', 'properties', 0, 'plantationArea']) ||
					'',
				plantationTime:
					getDateByFormat(
						getIn(data, ['meta', 'properties', 0, 'plantationTime'])
					) || '',
				farmAddress: `${getIn(farmAddress, ['line1']) ||
					''} ${getIn(farmAddress, ['district']) ||
					''} ${getIn(farmAddress, ['city']) || ''}
 ${getIn(farmAddress, ['state']) || ''} ${getIn(farmAddress, ['country']) ||
					''} `,
				city: getIn(farmAddress, ['city']) || '',
				line1: getIn(farmAddress, ['line1']) || '',
				district: getIn(farmAddress, ['district']) || '',
				state: getIn(farmAddress, ['state']) || '',
				country: getIn(farmAddress, ['country']) || '',
			},
		}

		yield put(AdminDuc.creators.setCompanyInfo(orgDetails))

		const certificationsURL = `${getIAMEndPoint()}clients/organizations/-/documents`
		const origResponse = yield CallWithRefreshCheck(certificationsURL)
		const certificateList = getIn(origResponse, ['data', 'list']) || []

		const businessDetails = certificateList.filter(
			cert =>
				getIn(cert, ['type']) === 'tax' ||
				getIn(cert, ['type']) === 'other' ||
				getIn(cert, ['type']) === 'gst'
		)

		const _certificates = certificateList.filter(
			cert => getIn(cert, ['type']) === 'certificate'
		)

		const areaDetails = certificateList.filter(
			cert =>
				getIn(cert, ['type']) === 'map' &&
				getIn(cert, ['meta', 'gisReference'])
		)

		const otherDocuments = certificateList.filter(cert =>
			[
				'land-legality',
				'agriculture-permit',
				'environment-document',
				'environment-permit',
			].includes(getIn(cert, ['type']))
		)

		const certificates = transformCertforStore(_certificates)

		yield put(AdminDuc.creators.setBusinessDetails(businessDetails))

		yield put(AdminDuc.creators.setCertificateDetails(certificates))

		yield put(AdminDuc.creators.setAreaDetails(areaDetails))

		yield put(AdminDuc.creators.setOtherDocumentsDetails(otherDocuments))

		const category = getIn(data, ['categories', 0, 'id']) || []
		const child = getIn(data, ['meta', 'childOrganization']) || []
		const isDealer =
			category === 'palmoil-ffbDealer' ||
			category === 'palmoil-ffbSubDealer' ||
			category === 'rice-dealer'
		if (isDealer && child.length > 0) {
			const callsPipeLine = child.map(({ id }) => {
				const childOrgDocUrl = `${getIAMEndPoint()}clients/childorg/${id}/documents`

				return CallWithRefreshCheck(childOrgDocUrl)
			})
			const childOrgDocResponse = yield all(callsPipeLine)
			const orgIDs = child.map(({ id }) => id)
			const orgUrl = `${getIAMEndPoint()}clients/organizations?limit=100&id=${orgIDs.join(
				','
			)}`
			const childOptions = {
				method: 'GET',
			}
			const { data: childOrgsData } = yield call(
				request,
				orgUrl,
				childOptions
			)
			const childOrgsDatalist = getIn(childOrgsData, ['list']) || []
			let docs = []
			childOrgDocResponse.forEach(d => {
				const res = getIn(d, ['data', 'list']) || []
				docs = [...docs, ...res]
			})
			const combinedOrgData = []
			childOrgsDatalist.forEach(org => {
				const orgid = getIn(org, ['id']) || ''
				const filteredDocs = docs.filter(
					d => d.organizationID === orgid && d.type === 'certificate'
				)
				const transformedDocs = transformCertforStore(filteredDocs)
				const json = { ...org, docs: transformedDocs }
				combinedOrgData.push(json)
			})
			yield put(AdminDuc.creators.setChildOrgDetails(combinedOrgData))
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-company-info'))
	}
}

function* addCollectionPoint(action) {
	const {
		details,
		organizationDetail,
		helpers: { setSubmitting },
	} = action
	try {
		let tempchild = []
		const orgID = getIn(organizationDetail, ['id']) || ''
		const childOrganization =
			getIn(organizationDetail, ['meta', 'childOrganization']) || []
		tempchild = [...tempchild, ...childOrganization]
		details.collectionPoints.map(async org => {
			const {
				email,
				orgName,
				organisationType,
				addressLine1,
				addressLine2,
				state,
				country,
				postalCode,
				certType,
				certNumber,
				certBody,
				issueDate,
				expiryDate,
				certStartDate,
				city,
				supplychainMode,
			} = org

			const values = {
				name: orgName,
				primaryContact: { email },
				categories: [
					{
						name: organisationType.name,
						id: organisationType.id,
					},
				],
				primaryAddress: {
					line1: addressLine1,
					line2: addressLine2,
					postalCode,
					city,
					state,
					country,
				},
				meta: {
					parentOrganization: [organizationDetail],
					processValue: 25,
					formStatus: {
						organization: false,
						business: false,
						certifications: false,
					},
				},
			}

			const docPayload = {
				number: certNumber,
				meta: {
					certificate: {
						issuingBody: certType,
						certificationBody: certBody,
						supplychainModel: supplychainMode,
					},
				},
			}

			if (certStartDate) {
				docPayload.expiryStartDate = new Date(certStartDate)
			}
			if (expiryDate) {
				docPayload.expiryEndDate = new Date(expiryDate)
			}
			if (issueDate) {
				docPayload.issueDate = new Date(issueDate)
			}

			const requestUrl = `${getIAMEndPoint()}clients/organizations`
			const options = {
				method: 'POST',
				body: JSON.stringify(values),
			}
			const { data = {} } = await request(requestUrl, options)
			if (Object.keys(data).length > 0) {
				tempchild = [...tempchild, data]

				const updateRequestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}/updateMeta`
				const uplaodPayload = {
					meta: {
						childOrganization: tempchild,
					},
				}

				const updateOption = {
					method: 'PATCH',
					body: JSON.stringify(uplaodPayload),
				}
				await request(updateRequestUrl, updateOption)

				const childOrrgID = getIn(data, ['id']) || ''
				const docRequestUrl = `${getIAMEndPoint()}clients/organizations/${childOrrgID}/documents`
				const docOption = {
					method: 'POST',
					body: JSON.stringify(docPayload),
				}
				await request(docRequestUrl, docOption)
			}
		})

		yield put(AdminDuc.creators.showCpSuccessModal(true))
	} catch (err) {
		setSubmitting(false)
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* addEmployee(action) {
	const { values, userID, toastMessage, fromUpload } = action
	try {
		let requestUrl = `${getIAMEndPoint()}clients/users`
		if (userID) {
			requestUrl += `/${userID}`
		}
		const options = {
			method: userID ? 'PUT' : 'POST',
			body: JSON.stringify(userID ? { ...values } : { details: values }),
		}

		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: toastMessage,
		})
		if (!fromUpload) {
			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.ADMIN$SUBROOT,
					{
						rootModule: 'employee-management',
					}
				)
			)
		} else {
			yield put(AdminDuc.creators.setEmployeeUploadEditSuccess(true))
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

// to fetch and pre-populate the details of the employee that needs to be edited.
// Also, state is flushed before pre-populating the form
function* initiateEditEmployeeDetails(action) {
	try {
		const { employeeID } = action

		yield put(
			AppDuc.creators.showGlobalLoader('initiate-edit-vehicle-details')
		)

		yield put(AdminDuc.creators.flushEmployeeState())
		const requestUrl = `${getIAMEndPoint()}clients/users/${employeeID}`

		const options = {
			method: 'GET',
		}
		const { data = {} } = yield call(request, requestUrl, options)

		const employeeDetails = {
			employeeName: getIn(data, ['firstName']) || '',
			designation:
				getIn(data, ['meta', 'employeeData', 'designation']) || '',
			email: getIn(data, ['primaryContact', 'email']) || '',
			mobile: getIn(data, ['primaryContact', 'mobile']) || '',
			employeeID: getIn(data, ['meta', 'employeeData', 'id']) || '',
			dibizRole: getIn(data, ['orgRole']) || '',
		}

		yield put(
			AdminDuc.creators.setOriginalEmployeeRole(employeeDetails.dibizRole)
		)
		yield put(AdminDuc.creators.setInitialEmployeeDetails(employeeDetails))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('initiate-edit-vehicle-details')
		)
	}
}

const PAGINATION_LIMIT = 30

// for fetching all the employees of the organisation
function* fetchEmployeesListing(action) {
	try {
		const { skipGlobalLoader, locationState } = action
		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('employee-listing'))
		yield put(AdminDuc.creators.fetchEmployeeLoading(true))
		let requestUrl = `${getIAMEndPoint()}clients/users`
		if (selectedCPID) {
			requestUrl = `${getIAMEndPoint()}clients/userlist/childorg/${selectedCPID}`
		}

		const filterType = 'status'
		const filterValue = 'active'
		const prefix = 'state->'

		const currentQuery = getIn(locationState, ['query']) || {}

		const filterQueries = omit(currentQuery, ['sort', 'q']) // exclude sort and search convention

		const targetQueries = getTargetFilterQueries(
			'',
			filterType,
			filterValue,
			prefix
		)
		const targetFilters = merge(
			currentQuery,
			merge(filterQueries, { [filterType]: targetQueries })
		)
		const { data, serverPaginationQuery } = yield DocumentListing(
			requestUrl,
			setIn(locationState, ['query'], targetFilters),
			PAGINATION_LIMIT
		)
		yield put(
			AdminDuc.creators.employeePaginationQueries(serverPaginationQuery)
		)
		yield put(AdminDuc.creators.setEmployeeListing(data.list))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AdminDuc.creators.fetchEmployeeLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('employee-listing'))
	}
}

// for fetching Employee Details to display in VIEW page
function* viewEmployeeDetails(action) {
	try {
		const { employeeID } = action
		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		yield put(AppDuc.creators.showGlobalLoader('employee-details'))
		yield put(AdminDuc.creators.fetchEmployeeLoading(true))
		yield put(AdminDuc.creators.flushEmployeeStateBeforeView())
		let requestUrl = `${getIAMEndPoint()}clients/users/${employeeID}`
		if (selectedCPID) {
			requestUrl = `${getIAMEndPoint()}clients/users/${employeeID}/childorg/${selectedCPID}`
		}

		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)
		yield put(AdminDuc.creators.setEmployeeDetails(data))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AdminDuc.creators.fetchEmployeeLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('employee-details'))
	}
}

function* deleteEmployee(action) {
	try {
		const { employeeID, toastMessage } = action

		yield put(AppDuc.creators.showGlobalLoader('delete-employee'))
		const requestUrl = `${getIAMEndPoint()}clients/users/${employeeID}`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: toastMessage,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'employee-management',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('delete-employee'))
	}
}

function* updateOrganisation(action) {
	try {
		const { values, successToast } = action
		const {
			city,
			country,
			line1,
			line2,
			state,
			postalCode,
			description,
			locationDetail,
		} = values
		const orgID = yield getOrgIDFromLoggedUser()

		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const payload = {
			primaryAddress: {
				line1,
				line2,
				state,
				country,
				postalCode,
				city,
			},
			description,
		}

		if (Object.keys(locationDetail).length > 0) {
			payload.primaryAddress.location = locationDetail
		}

		const options = {
			method: 'PUT',
			body: JSON.stringify(payload),
		}
		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: successToast,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'company-information',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export function* fetchDOUploadStatus() {
	try {
		const requestUrl = `${getCoreEndPoint()}fileentities/-/doupload-status`

		const options = {
			method: 'GET',
			body: JSON.stringify(),
		}

		const { data } = yield call(request, requestUrl, options)
		yield put(AdminDuc.creators.setUploadDODetails(data))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* saveOtherSettings(action) {
	try {
		const { values, actor, orgDetails, successToast } = action

		const orgId = yield select(AuthDuc.selectors.getUserOrgId)

		if (orgDetails?.id) {
			const currentMeta = orgDetails.meta ? { ...orgDetails.meta } : {}
			if (
				'graderCheck' in values &&
				actor.includes('palmoil_plantation')
			) {
				currentMeta.plantation = { enableGrading: values.graderCheck }
			}

			if ('virtualInventory' in values) {
				currentMeta.enforceSupplyChainModel = values.virtualInventory
			}
			if ('supplyChainModel' in values) {
				currentMeta.supplyChainModel = values.supplyChainModel
			}

			const data = {
				meta: currentMeta,
			}
			const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgId}`

			const options = {
				method: 'PUT',
				body: JSON.stringify(data),
			}

			yield call(request, requestUrl, options)
		}
		yield Toast({
			type: 'success',
			message: successToast,
		})
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateOrganisationLogo(action) {
	try {
		const { logoURL, successToast } = action
		const orgID = yield getOrgIDFromLoggedUser()
		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}/updateMeta`
		const payload = {
			meta: { logo: logoURL },
		}

		const options = {
			method: 'PATCH',
			body: JSON.stringify(payload),
		}
		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: successToast,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'company-information',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* deleteSingleCertificate(action) {
	try {
		const { certID, successToast } = action

		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/documents/${certID}`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: successToast,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'company-information',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}
function* updateSingleCertificate(action) {
	try {
		const { values, id, successToast } = action
		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/documents/${id}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(values),
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: successToast,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'company-information',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* addNewCertificate(action) {
	try {
		const { values, successToast } = action
		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/documents`
		const options = {
			method: 'POST',
			body: JSON.stringify(values),
		}
		yield call(request, requestUrl, options)
		const orgId = yield select(AuthDuc.selectors.getUserOrgId)
		const orgdetails = yield select(AuthDuc.selectors.getCurrentOrgDetails)

		const currentMeta = orgdetails.meta ? { ...orgdetails.meta } : {}

		currentMeta.unCertified = false

		const data = {
			meta: currentMeta,
		}
		const metaRequestUrl = `${getIAMEndPoint()}clients/organizations/${orgId}`

		const metaOptions = {
			method: 'PUT',
			body: JSON.stringify(data),
		}
		yield call(request, metaRequestUrl, metaOptions)
		yield Toast({
			type: 'success',
			message: successToast,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'company-information',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateFarmDetails(action) {
	try {
		const {
			values: {
				area,
				plantationArea,
				plantationTime,
				city,
				line1,
				district,
				state,
				country,
			},
			successToast,
		} = action
		const orgID = yield getOrgIDFromLoggedUser()
		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}/updateMeta`
		const payload = {
			meta: {
				properties: [
					{
						type: 'plot',
						area: parseFloat(area, 4),
						areaUOM: 'ha',
						plantationArea: parseFloat(plantationArea, 4),
						plantationAreaUOM: 'ha',
						plantationTime: new Date(plantationTime),
						other: {
							farmAddress: {
								city,
								line1,
								district,
								state,
								country,
							},
						},
					},
				],
			},
		}

		const options = {
			method: 'PATCH',
			body: JSON.stringify(payload),
		}
		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: successToast,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'company-information',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateTankLockStatus(action) {
	try {
		const { status, assetID, message } = action
		yield put(AppDuc.creators.showGlobalLoader('update-tank-lock-status'))
		const requestUrl = `${getCoreEndPoint()}assets/storageunits/status`

		const options = {
			method: 'POST',
			body: JSON.stringify({ assetID, status, message }),
		}

		yield call(request, requestUrl, options)

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'assets',
				type: 'storageunits',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('update-tank-lock-status'))
	}
}

function* updateAverageBunchWeight(action) {
	try {
		const { averageBunchWeight, orgDetails } = action
		yield put(
			AppDuc.creators.showGlobalLoader('update-average-bunch-weight')
		)
		const orgId = yield select(AuthDuc.selectors.getUserOrgId)
		const data = {
			meta: {
				...orgDetails.meta,
				mill: {
					averageBunchWeight,
				},
			},
		}

		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgId}`

		const options = {
			method: 'PUT',
			body: JSON.stringify(data),
		}

		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: 'Average bunch weight updated',
		})

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.ADMIN$SUBROOT, {
				rootModule: 'other-settings',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('update-average-bunch-weight')
		)
	}
}

export default function* AdminSaga() {
	try {
		yield all([
			takeLatest(
				AdminDuc.creators.fetchAssetsListing().type,
				fetchAssetsListing
			),
			takeLatest(
				AdminDuc.creators.createVehicleAsAsset().type,
				createVehicleAsAsset
			),
			takeLatest(
				AdminDuc.creators.initiateVehicleEdit().type,
				initiateVehicleEdit
			),
			takeLatest(AdminDuc.creators.editVehicle().type, editVehicle),
			takeLatest(
				AdminDuc.creators.viewVehicleDetails().type,
				viewVehicleDetails
			),
			takeLatest(AdminDuc.creators.deleteVehicle().type, deleteVehicle),
			takeLatest(
				AdminDuc.creators.createProductionLineAsAsset().type,
				createProductionLineAsAsset
			),
			takeLatest(
				AdminDuc.creators.initiateProductionLineEdit().type,
				initiateProductionLineEdit
			),
			takeLatest(
				AdminDuc.creators.editProductionLine().type,
				editProductionLine
			),
			takeLatest(
				AdminDuc.creators.viewProductionLineDetails().type,
				viewProductionLineDetails
			),
			takeLatest(
				AdminDuc.creators.deleteProductionLine().type,
				deleteProductionLine
			),
			takeLatest(
				AdminDuc.creators.initiateWeighbridgesEdit().type,
				initiateWeighbridgesEdit
			),
			takeLatest(
				AdminDuc.creators.viewWeighbridgeDetails().type,
				viewWeighbridgeDetails
			),
			takeLatest(
				AdminDuc.creators.createWeighbrigeAsAsset().type,
				createWeighbrigeAsAsset
			),
			takeLatest(
				AdminDuc.creators.editWeighbridge().type,
				editWeighbridge
			),
			takeLatest(
				AdminDuc.creators.deleteWeighbridge().type,
				deleteWeighbridge
			),
			takeLatest(
				AdminDuc.creators.createStorageUnitAsAsset().type,
				createStorageUnitAsAsset
			),
			takeLatest(
				AdminDuc.creators.initiateStorageUnitEdit().type,
				initiateStorageUnitEdit
			),
			takeLatest(
				AdminDuc.creators.employeeUploadUserValidation().type,
				employeeUploadUserValidation
			),
			takeLatest(
				AdminDuc.creators.editStorageUnit().type,
				editStorageUnit
			),
			takeLatest(
				AdminDuc.creators.viewStorageUnitDetails().type,
				viewStorageUnitDetails
			),
			takeLatest(
				AdminDuc.creators.deleteStorageUnit().type,
				deleteStorageUnit
			),
			takeLatest(
				AdminDuc.creators.fetchCompanyInfo().type,
				fetchCompanyInfo
			),
			takeLatest(
				AdminDuc.creators.fetchEmployeesListing().type,
				fetchEmployeesListing
			),
			takeLatest(
				AdminDuc.creators.viewEmployeeDetails().type,
				viewEmployeeDetails
			),
			takeLatest(AdminDuc.creators.addEmployee().type, addEmployee),
			takeLatest(
				AdminDuc.creators.initiateEditEmployeeDetails().type,
				initiateEditEmployeeDetails
			),
			takeLatest(AdminDuc.creators.deleteEmployee().type, deleteEmployee),
			takeLatest(
				AdminDuc.creators.updateOrganisation().type,
				updateOrganisation
			),
			takeLatest(
				AdminDuc.creators.saveOtherSettings().type,
				saveOtherSettings
			),
			takeLatest(
				AdminDuc.creators.fetchDOUploadStatus().type,
				fetchDOUploadStatus
			),
			takeLatest(
				AdminDuc.creators.updateOrganisationLogo().type,
				updateOrganisationLogo
			),
			takeLatest(
				AdminDuc.creators.deleteSingleCertificate().type,
				deleteSingleCertificate
			),
			takeLatest(
				AdminDuc.creators.updateSingleCertificate().type,
				updateSingleCertificate
			),
			takeLatest(
				AdminDuc.creators.addNewCertificate().type,
				addNewCertificate
			),
			takeLatest(
				AdminDuc.creators.updateFarmDetails().type,
				updateFarmDetails
			),
			takeLatest(
				AdminDuc.creators.addCollectionPoint().type,
				addCollectionPoint
			),
			takeLatest(
				AdminDuc.creators.updateTankLockStatus().type,
				updateTankLockStatus
			),
			takeLatest(
				AdminDuc.creators.updateAverageBunchWeight().type,
				updateAverageBunchWeight
			),
		])
	} catch (err) {
		logger.log(err)
	}
}

import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { createSelector } from 'reselect'
import { initialState, TRACE_GROUP_STATUS } from './config'

export const NAME_ALIASES = {
	create: 'common.create',
	edit: 'common.update',
}

export const LabelsMap = {
	'quality-cpo': 'breadcrumb.quality-cpo',
	'quality-ffb': 'breadcrumb.quality-ffb',
	'sourced-batch': 'breadcrumb.sourced-batch',
	'produced-batch': 'breadcrumb.produced-batch',
}

export const MillDuc = new Duck({
	namespace: 'mill',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_INSIGHTS',
		'FETCH_INSIGHTS_SUCCESS',
		'FETCH_PRODUCTION_DOCUMENTS',
		'SET_PRODUCTION_LOADING_STATUS',
		'FETCH_PRODUCTION_DOCUMENTS_SUCCESS',
		'RESET_PRODUCTION_LOADING_STATUS',
		'CREATE_PLOT_INPUT',
		'UPDATE_INVENTORY',
		'UPDATE_PRODUCTION',
		'FETCH_INCOMING_INVENTORY',
		'FETCH_OUTGOING_INVENTORY',
		'FETCH_PRODUCTION',
		'SET_INCOMING_INVENTORY',
		'SET_OUTGOING_INVENTORY',
		'SET_PRODUCTION_DATA',
		'SET_UPDATE_INVENTORY_LOADING',
		'SET_UPDATE_PRODUCTION_LOADING',
		'SET_INCOMING_INVENTORY_LOADING',
		'SET_OUTGOING_INVENTORY_LOADING',
		'SET_PRODUCTION_LOADING',
		'SET_PRODUCTION_ACTIVE_SORTS',
		'CREATE_PLOT_OUTPUT',
		'FETCH_INITIAL_PLOT_INPUT',
		'FETCH_INITIAL_PLOT_INPUT_SUCCESS',
		'FETCH_INITIAL_PLOT_INPUT_CONSIGNMENT_SUCCESS',
		'APPEND_PLOT_INPUT_CONSIGNMENT_SUCCESS',
		'PRODUCTION_LOADING_STATUS',
		'VIEW_PLOT_DOCUMENT',
		'VIEW_PLOT_DOCUMENT_SUCCESS',
		'FETCH_INITIAL_PLOT_OUTPUT',
		'FETCH_DASHBOARD_LISTING',
		'FETCH_DASHBOARD_LISTING_SUCCESS',
		'FETCH_DASHBOARD_LISTING_LOADING',
		'SET_PAGINATION_ENTRIES',
		'SET_ACTIVE_LISTING_FILTERS',
		'SET_ACTIVE_LISTING_SORTS',
		'APPEND_PLOT_INPUT_DOCUMENTS',
		'SET_PLOT_INPUT_PAGINATION_ENTRIES',
		'FETCH_QUALITY_LIST',
		'SET_QUALITY_LIST',
		'FLUSH_ENTITY_INFO_STATE',
		'FETCH_ENTITY_INFO_FOR_FFB_QUALITY_REPORT',
		'SET_ENTITY_INFO_FOR_FFB_QUALITY_REPORT',
		'FETCH_DO_LIST',
		'SET_DO_LIST',
		'TOGGLE_DO_SELECTION_POPUP',
		'CREATE_FFB_QUALITY_REPORT',
		'INITIATE_FFB_QUALITY_REPORT_EDIT',
		'FLUSH_FFB_QUALITY_FORM_VALUES',
		'SET_INITIAL_FORM_VALUES_FFB_EDIT',
		'EDIT_FFB_QUALITY_REPORT',
		'CREATE_CPO_QUALITY_REPORT',
		'INITIATE_CPO_QUALITY_REPORT_EDIT',
		'FLUSH_CPO_QUALITY_FORM_VALUES',
		'SET_INITIAL_FORM_VALUES_CPO_EDIT',
		'EDIT_CPO_QUALITY_REPORT',
		'FETCH_SINGLE_CPO_QUALITY_REPORT',
		'FETCH_SINGLE_FFB_QUALITY_REPORT',
		'FLUSH_CPO_QUALITY_REPORT_STATE',
		'FLUSH_FFB_QUALITY_REPORT_STATE',
		'SET_SINGLE_CPO_QUALITY_REPORT',
		'SET_SINGLE_FFB_QUALITY_REPORT',
		'FETCH_ENTITY_INFO_FOR_CPO_QUALITY_REPORT',
		'SET_ENTITY_INFO_FOR_CPO_QUALITY_REPORT',
		'FLUSH_ENTITY_STATE_CPO_QUALITY_REPORT',
		'FLUSH_LISTING_DATA',
		'SET_STORAGE_TANKS',
		'DELETE_TRACE_GROUP_ID',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}
			case duck.types.SET_INCOMING_INVENTORY: {
				const { incomingInventoy } = action

				return setIn(
					state,
					['modules', 'production', 'incomingInventory'],
					incomingInventoy
				)
			}

			case duck.types.SET_OUTGOING_INVENTORY: {
				const { outgoingInventoy } = action

				return setIn(
					state,
					['modules', 'production', 'outgoingInventory'],
					outgoingInventoy
				)
			}

			case duck.types.SET_PRODUCTION_DATA: {
				const { productionData } = action

				return setIn(
					state,
					['modules', 'production', 'productionData'],
					productionData
				)
			}

			case duck.types.SET_UPDATE_INVENTORY_LOADING: {
				const { status } = action

				return setIn(
					state,
					['modules', 'production', 'updateInventoryLoading'],
					status
				)
			}

			case duck.types.SET_UPDATE_PRODUCTION_LOADING: {
				const { status } = action

				return setIn(
					state,
					['modules', 'production', 'updateProductionLoading'],
					status
				)
			}

			case duck.types.SET_INCOMING_INVENTORY_LOADING: {
				const { status } = action

				return setIn(
					state,
					['modules', 'production', 'incomingInventoryLoading'],
					status
				)
			}

			case duck.types.SET_OUTGOING_INVENTORY_LOADING: {
				const { status } = action

				return setIn(
					state,
					['modules', 'production', 'outgoingInventoryLoading'],
					status
				)
			}
			case duck.types.SET_PRODUCTION_LOADING: {
				const { status } = action

				return setIn(
					state,
					['modules', 'production', 'productionLoading'],
					status
				)
			}
			case duck.types.SET_PRODUCTION_ACTIVE_SORTS: {
				const { sortMap } = action
				let sorts = {}
				;(sortMap.production || []).forEach(key => {
					const arr = key.split('|')
					const lastVal = arr.pop()

					sorts = setIn(sorts, arr, lastVal)
				})

				return setIn(
					state,
					['modules', 'production', 'activeSorts'],
					sorts
				)
			}
			case duck.types.SET_PRODUCTION_LOADING_STATUS: {
				const { _modules } = action
				const rootPath = ['modules', 'production', 'loading']

				return setIn(state, [...rootPath], _modules)
			}

			case duck.types.RESET_PRODUCTION_LOADING_STATUS: {
				const rootPath = ['modules', 'production', 'loading']

				return setIn(state, [...rootPath], false)
			}

			case duck.types.FETCH_PRODUCTION_DOCUMENTS_SUCCESS: {
				const { activeDocuments } = action

				return setIn(
					state,
					['modules', 'production', 'activeDocuments'],
					activeDocuments
				)
			}

			case duck.types.FETCH_INSIGHTS_SUCCESS: {
				const { userInsight } = action

				return setIn(state, ['insightsData'], userInsight)
			}

			case duck.types.FETCH_INITIAL_PLOT_INPUT_SUCCESS: {
				const { response } = action

				return setIn(
					state,
					['modules', 'production', 'activeDocuments', 'plotInput'],
					response
				)
			}

			case duck.types.FETCH_INITIAL_PLOT_INPUT_CONSIGNMENT_SUCCESS: {
				const { response } = action

				return setIn(
					state,
					[
						'modules',
						'production',
						'activeDocuments',
						'plotInputConsignments',
					],
					response
				)
			}

			case duck.types.APPEND_PLOT_INPUT_CONSIGNMENT_SUCCESS: {
				const { response } = action

				const currentDocs =
					getIn(state, [
						'modules',
						'production',
						'activeDocuments',
						'plotInputConsignments',
					]) || []

				const dups = {}

				const finalList = currentDocs
					.concat(response || [])
					.filter(list => {
						if (!dups[list.traceID]) {
							dups[list.traceID] = true

							return list
						}

						return null
					})

				return setIn(
					state,
					[
						'modules',
						'production',
						'activeDocuments',
						'plotInputConsignments',
					],
					finalList
				)
			}

			case duck.types.PRODUCTION_LOADING_STATUS: {
				const { status } = action
				const rootPath = ['modules', 'production', 'loading']

				return setIn(state, [...rootPath], status)
			}

			case duck.types.VIEW_PLOT_DOCUMENT_SUCCESS: {
				const { traceGroup } = action

				return setIn(
					state,
					['modules', 'production', 'activeRecords'],
					traceGroup
				)
			}

			case duck.types.FETCH_DASHBOARD_LISTING_LOADING: {
				return setIn(
					state,
					['modules', 'listing', 'loading'],
					action.status || false
				)
			}

			case duck.types.FETCH_DASHBOARD_LISTING_SUCCESS: {
				const { activeDocuments } = action

				return setIn(
					state,
					['modules', 'listing', 'activeDocuments'],
					activeDocuments
				)
			}

			case duck.types.FLUSH_LISTING_DATA: {
				return setIn(
					state,
					['modules', 'listing', 'activeDocuments'],
					initialState.modules.listing.activeDocuments
				)
			}

			case duck.types.SET_PAGINATION_ENTRIES: {
				const {
					activeIndex,
					limit,
					total,
					nextCursor,
					currentCursor,
				} = action
				const root = ['modules', 'listing', 'pagination']
				let nextState = state

				nextState = setIn(
					nextState,
					[...root, 'activeIndex'],
					activeIndex || 0
				)

				if (limit)
					nextState = setIn(nextState, [...root, 'limit'], limit)

				if (total)
					nextState = setIn(nextState, [...root, 'total'], total)

				if (nextCursor)
					nextState = setIn(
						nextState,
						[...root, 'nextCursor'],
						nextCursor
					)

				if (currentCursor)
					nextState = setIn(
						nextState,
						[...root, 'currentCursor'],
						currentCursor
					)

				return nextState
			}

			case duck.types.SET_PLOT_INPUT_PAGINATION_ENTRIES: {
				const { activeIndex, limit, nextCursor, total } = action

				const root = [
					'modules',
					'production',
					'createPlotInput',
					'pagination',
				]
				let nextState = state

				nextState = setIn(
					nextState,
					[...root, 'activeIndex'],
					activeIndex || 0
				)

				if (total)
					nextState = setIn(nextState, [...root, 'total'], total)

				if (limit)
					nextState = setIn(nextState, [...root, 'limit'], limit)

				if (nextCursor)
					nextState = setIn(
						nextState,
						[...root, 'nextCursor'],
						nextCursor
					)

				return nextState
			}

			case duck.types.SET_ACTIVE_LISTING_FILTERS: {
				const { filters = {} } = action

				return setIn(
					state,
					['modules', 'listing', 'activeFilters'],
					filters
				)
			}

			case duck.types.SET_ACTIVE_LISTING_SORTS: {
				const { sortMap } = action

				let sorts = {}
				;(sortMap.sort || []).forEach(key => {
					const arr = key.split('|')
					arr.shift() // remove the first fragment since type is already decided
					const lastVal = arr.pop()

					sorts = setIn(sorts, arr, lastVal)
				})

				return setIn(
					state,
					['modules', 'listing', 'activeSorts'],
					sorts
				)
			}

			case duck.types.SET_QUALITY_LIST: {
				return setIn(
					state,
					['modules', 'quality', 'listing', 'activeDocuments'],
					action.qualityList
				)
			}

			case duck.types.FLUSH_ENTITY_INFO_STATE: {
				return setIn(
					state,
					[
						'modules',
						'quality',
						'ffbQuality',
						'entityInfoforFFBReport',
					],
					initialState.modules.quality.ffbQuality
						.entityInfoforFFBReport
				)
			}

			case duck.types.SET_ENTITY_INFO_FOR_FFB_QUALITY_REPORT: {
				return setIn(
					state,
					[
						'modules',
						'quality',
						'ffbQuality',
						'entityInfoforFFBReport',
					],
					action.entityInfo
				)
			}

			case duck.types.SET_DO_LIST: {
				return setIn(
					state,
					['modules', 'quality', 'ffbQuality', 'entityList'],
					action.entityList
				)
			}

			case duck.types.TOGGLE_DO_SELECTION_POPUP: {
				return setIn(
					state,
					[
						'modules',
						'quality',
						'ffbQuality',
						'modalStatusDOSelection',
					],
					action.status
				)
			}

			case duck.types.FLUSH_FFB_QUALITY_FORM_VALUES: {
				return setIn(
					state,
					['modules', 'quality', 'ffbQuality', 'initialFormValues'],
					initialState.modules.quality.ffbQuality.initialFormValues
				)
			}

			case duck.types.SET_INITIAL_FORM_VALUES_FFB_EDIT: {
				return setIn(
					state,
					['modules', 'quality', 'ffbQuality', 'initialFormValues'],
					action.ffbQualityDetails
				)
			}

			case duck.types.FLUSH_CPO_QUALITY_FORM_VALUES: {
				return setIn(
					state,
					['modules', 'quality', 'cpoQuality', 'initialFormValues'],
					initialState.modules.quality.cpoQuality.initialFormValues
				)
			}

			case duck.types.SET_INITIAL_FORM_VALUES_CPO_EDIT: {
				return setIn(
					state,
					['modules', 'quality', 'cpoQuality', 'initialFormValues'],
					action.cpoQualityDetails
				)
			}

			case duck.types.FLUSH_CPO_QUALITY_REPORT_STATE: {
				return setIn(
					state,
					['modules', 'quality', 'cpoQuality', 'cpoReport'],
					initialState.modules.quality.cpoQuality.cpoReport
				)
			}

			case duck.types.SET_SINGLE_CPO_QUALITY_REPORT: {
				return setIn(
					state,
					['modules', 'quality', 'cpoQuality', 'cpoReport'],
					action.cpoQualityReport
				)
			}

			case duck.types.SET_ENTITY_INFO_FOR_CPO_QUALITY_REPORT: {
				return setIn(
					state,
					['modules', 'quality', 'cpoQuality', 'entityInfo'],
					action.entityInfo
				)
			}

			case duck.types.FLUSH_ENTITY_STATE_CPO_QUALITY_REPORT: {
				return setIn(
					state,
					['modules', 'quality', 'cpoQuality', 'entityInfo'],
					initialState.modules.quality.cpoQuality.entityInfo
				)
			}

			case duck.types.FLUSH_FFB_QUALITY_REPORT_STATE: {
				return setIn(
					state,
					['modules', 'quality', 'ffbQuality', 'ffbReport'],
					initialState.modules.quality.ffbQuality.ffbReport
				)
			}

			case duck.types.SET_SINGLE_FFB_QUALITY_REPORT: {
				return setIn(
					state,
					['modules', 'quality', 'ffbQuality', 'ffbReport'],
					action.ffbQualityReport
				)
			}

			case duck.types.SET_STORAGE_TANKS: {
				return setIn(
					state,
					['modules', 'production', 'storageTanks'],
					action.storageTanks
				)
			}

			default:
				return state
		}
	},
	helpers: {
		extractDocuments: documents =>
			[...TRACE_GROUP_STATUS].reduce((agg, key) => {
				const aggregator = agg
				aggregator[key] = getIn(documents, [key, 'list']) || []

				return aggregator
			}, {}),
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['mill', 'activeModule']) || 'Error',
		listingState: state =>
			getIn(state, ['mill', 'modules', 'listing']) || {},
		productionState: state =>
			getIn(state, ['mill', 'modules', 'production']) || {},
		qualityState: state =>
			getIn(state, ['mill', 'modules', 'quality']) || {},
		getInsightsData: state => getIn(state, ['mill', 'insightsData']) || {},
		getProductionActiveTimeOffset: state =>
			getIn(state, ['mill', 'modules', 'production', 'activeTimeOffset']),
		getProductionActiveTabs: new Duck.Selector(selectors =>
			createSelector(selectors.productionState, production =>
				getIn(production, ['tabsConfig'])
			)
		),
		getProductionActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production => getIn(production, ['activeSorts']) || {}
			)
		),
		getProductionDocuments: new Duck.Selector(selectors =>
			createSelector(selectors.productionState, production => {
				const incomingDocuments =
					getIn(production, ['activeDocuments']) || {}

				return MillDuc.options.helpers.extractDocuments(
					incomingDocuments
				)
			})
		),
		getActiveRecords: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production => getIn(production, ['activeRecords']) || {}
			)
		),
		getProductionLoadingStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production => getIn(production, ['loading']) || false
			)
		),
		gePlotInputDocuments: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production =>
					getIn(production, ['activeDocuments', 'plotInput']) || {}
			)
		),
		gePlotInputConsignments: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production =>
					getIn(production, [
						'activeDocuments',
						'plotInputConsignments',
					]) || []
			)
		),
		getProductionLoadingStates: new Duck.Selector(selectors =>
			createSelector(selectors.productionState, production =>
				getIn(production, ['loading'])
			)
		),
		getListingDocuments: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => getIn(listing, ['activeDocuments', 'list']) || []
			)
		),
		getListingLoadingStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => listing.loading || false
			)
		),
		getListingPaginationEntries: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => listing.pagination
			)
		),
		getListingActiveFilters: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => listing.activeFilters
			)
		),
		getDocumentListingActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => getIn(listing, ['activeSorts']) || {}
			)
		),
		getPlotInputPaginationConfig: new Duck.Selector(selectors =>
			createSelector(
				selectors.productionState,
				production =>
					getIn(production, ['createPlotInput', 'pagination']) || {}
			)
		),
		getQualityList: new Duck.Selector(selectors =>
			createSelector(
				selectors.qualityState,
				quality => getIn(quality, ['listing', 'activeDocuments']) || {}
			)
		),
		getQualityActiveTabs: new Duck.Selector(selectors =>
			createSelector(
				selectors.qualityState,
				quality => getIn(quality, ['listing', 'tabsConfig']) || []
			)
		),
		getInitialFFBQualityFormValues: new Duck.Selector(selectors =>
			createSelector(
				selectors.qualityState,
				quality =>
					getIn(quality, ['ffbQuality', 'initialFormValues']) || {}
			)
		),
		getEntityInfoForFFBReport: new Duck.Selector(selectors =>
			createSelector(
				selectors.qualityState,
				quality =>
					getIn(quality, ['ffbQuality', 'entityInfoforFFBReport']) ||
					{}
			)
		),
		getDOSelectionModalStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.qualityState,
				quality =>
					getIn(quality, ['ffbQuality', 'modalStatusDOSelection']) ||
					false
			)
		),
		getDOList: new Duck.Selector(selectors =>
			createSelector(
				selectors.qualityState,
				quality => getIn(quality, ['ffbQuality', 'entityList']) || []
			)
		),
		getInitialCPOQualityFormValues: new Duck.Selector(selectors =>
			createSelector(
				selectors.qualityState,
				quality =>
					getIn(quality, ['cpoQuality', 'initialFormValues']) || {}
			)
		),
		getSingleCPOQualityReport: new Duck.Selector(selectors =>
			createSelector(
				selectors.qualityState,
				quality => getIn(quality, ['cpoQuality', 'cpoReport']) || {}
			)
		),
		getEntityInfoForCPOQualityReport: new Duck.Selector(selectors =>
			createSelector(
				selectors.qualityState,
				quality => getIn(quality, ['cpoQuality', 'entityInfo']) || {}
			)
		),
		getSingleFFBQualityReport: new Duck.Selector(selectors =>
			createSelector(
				selectors.qualityState,
				quality => getIn(quality, ['ffbQuality', 'ffbReport']) || {}
			)
		),
		getStorageTanksList: state =>
			getIn(state, ['mill', 'modules', 'production', 'storageTanks']) ||
			[],
		getIncomingInventory: state =>
			getIn(state, [
				'mill',
				'modules',
				'production',
				'incomingInventory',
			]) || {},
		getOutgoingInventory: state =>
			getIn(state, [
				'mill',
				'modules',
				'production',
				'outgoingInventory',
			]) || {},
		getProduction: state =>
			getIn(state, ['mill', 'modules', 'production', 'productionData']) ||
			{},
		getMillLoadingState: state =>
			getIn(state, [
				'mill',
				'modules',
				'production',
				'updateInventoryLoading',
			]) ||
			getIn(state, [
				'mill',
				'modules',
				'production',
				'updateProductionLoading',
			]) ||
			getIn(state, [
				'mill',
				'modules',
				'production',
				'incomingInventoryLoading',
			]) ||
			getIn(state, [
				'mill',
				'modules',
				'production',
				'outgoingInventoryLoading',
			]) ||
			getIn(state, [
				'mill',
				'modules',
				'production',
				'productionLoading',
			]) ||
			false,
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchInsights: () => ({
			type: duck.types.FETCH_INSIGHTS,
		}),
		fetchInsightsSuccess: userInsight => ({
			type: duck.types.FETCH_INSIGHTS_SUCCESS,
			userInsight,
		}),
		fetchProductionDocuments: (
			docType,
			rootModules,
			locationState,
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_PRODUCTION_DOCUMENTS,
			docType,
			rootModules,
			locationState,
			skipGlobalLoader,
		}),
		setProductionLoading: (root, _modules) => ({
			type: duck.types.SET_PRODUCTION_LOADING_STATUS,
			root,
			_modules,
		}),
		fetchProductionDocumentsSuccess: activeDocuments => ({
			type: duck.types.FETCH_PRODUCTION_DOCUMENTS_SUCCESS,
			activeDocuments,
		}),
		resetProductionLoading: () => ({
			type: duck.types.RESET_PRODUCTION_LOADING_STATUS,
		}),
		createPlotInput: (docValue, helpers, toastMessage) => ({
			type: duck.types.CREATE_PLOT_INPUT,
			docValue,
			helpers,
			toastMessage,
		}),
		updateInventory: payload => ({
			type: duck.types.UPDATE_INVENTORY,
			payload,
		}),
		updateProduction: payload => ({
			type: duck.types.UPDATE_PRODUCTION,
			payload,
		}),
		fetchIncomingInventory: (weekNumber, year) => ({
			type: duck.types.FETCH_INCOMING_INVENTORY,
			weekNumber,
			year,
		}),
		fetchOutgoingInventory: (weekNumber, year) => ({
			type: duck.types.FETCH_OUTGOING_INVENTORY,
			weekNumber,
			year,
		}),
		fetchProduction: (weekNumber, year) => ({
			type: duck.types.FETCH_PRODUCTION,
			weekNumber,
			year,
		}),
		setIncomingInventory: incomingInventoy => ({
			type: duck.types.SET_INCOMING_INVENTORY,
			incomingInventoy,
		}),
		setOutgoingInventory: outgoingInventoy => ({
			type: duck.types.SET_OUTGOING_INVENTORY,
			outgoingInventoy,
		}),
		setProduction: productionData => ({
			type: duck.types.SET_PRODUCTION_DATA,
			productionData,
		}),
		setUpdateInventoryLoading: status => ({
			type: duck.types.SET_UPDATE_INVENTORY_LOADING,
			status,
		}),
		setUpdateProductionLoading: status => ({
			type: duck.types.SET_UPDATE_PRODUCTION_LOADING,
			status,
		}),
		setIncomingInventoryLoading: status => ({
			type: duck.types.SET_INCOMING_INVENTORY_LOADING,
			status,
		}),
		setOutgoingInventoryLoading: status => ({
			type: duck.types.SET_OUTGOING_INVENTORY_LOADING,
			status,
		}),
		setNewProductionLoading: status => ({
			type: duck.types.SET_PRODUCTION_LOADING,
			status,
		}),
		setProductionActiveSorts: sortMap => ({
			type: duck.types.SET_PRODUCTION_ACTIVE_SORTS,
			sortMap,
		}),
		createPlotOutput: (docValue, successToast, helpers) => ({
			type: duck.types.CREATE_PLOT_OUTPUT,
			docValue,
			successToast,
			helpers,
		}),
		fetchInitialPlotInput: product => ({
			type: duck.types.FETCH_INITIAL_PLOT_INPUT,
			product,
		}),
		fetchInitialPlotInputSuccess: response => ({
			type: duck.types.FETCH_INITIAL_PLOT_INPUT_SUCCESS,
			response,
		}),
		fetchInitialPlotInputConsignmentSuccess: response => ({
			type: duck.types.FETCH_INITIAL_PLOT_INPUT_CONSIGNMENT_SUCCESS,
			response,
		}),

		appendPlotInputDocuments: (locationState, product) => ({
			type: duck.types.APPEND_PLOT_INPUT_DOCUMENTS,
			locationState,
			product,
		}),
		appendPlotInputConsignmentSuccess: response => ({
			type: duck.types.APPEND_PLOT_INPUT_CONSIGNMENT_SUCCESS,
			response,
		}),
		setPlotInputPaginationEntries: (
			activeIndex,
			total,
			limit,
			nextCursor
		) => ({
			type: duck.types.SET_PLOT_INPUT_PAGINATION_ENTRIES,
			activeIndex,
			total,
			limit,
			nextCursor,
		}),
		productionLoadingStatus: status => ({
			type: duck.types.PRODUCTION_LOADING_STATUS,
			status,
		}),
		viewPlotDocuments: traceGroupID => ({
			type: duck.types.VIEW_PLOT_DOCUMENT,
			traceGroupID,
		}),
		viewPlotDocumentsSuccess: traceGroup => ({
			type: duck.types.VIEW_PLOT_DOCUMENT_SUCCESS,
			traceGroup,
		}),
		fetchInitialPlotOutput: () => ({
			type: duck.types.FETCH_INITIAL_PLOT_OUTPUT,
		}),
		fetchDocumentListing: (
			docType,
			rootModules = [],
			locationState = {},
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_DASHBOARD_LISTING,
			rootModule: rootModules[0],
			submodule: docType,
			locationState,
			skipGlobalLoader,
		}),
		fetchDocumentLoading: status => ({
			type: duck.types.FETCH_DASHBOARD_LISTING_LOADING,
			status,
		}),
		fetchDocumentListingSuccess: activeDocuments => ({
			type: duck.types.FETCH_DASHBOARD_LISTING_SUCCESS,
			activeDocuments,
		}),
		setPaginationEntries: (
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor
		) => ({
			type: duck.types.SET_PAGINATION_ENTRIES,
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor,
		}),
		setActiveListingFilters: filters => ({
			type: duck.types.SET_ACTIVE_LISTING_FILTERS,
			filters,
		}),
		setActiveListingSorts: sortMap => ({
			type: duck.types.SET_ACTIVE_LISTING_SORTS,
			sortMap,
		}),
		fetchQualityListing: () => ({
			type: duck.types.FETCH_QUALITY_LIST,
		}),
		setQualityListing: qualityList => ({
			type: duck.types.SET_QUALITY_LIST,
			qualityList,
		}),
		fetchEntityInfoforFFBQualityReport: docReference => ({
			type: duck.types.FETCH_ENTITY_INFO_FOR_FFB_QUALITY_REPORT,
			docReference,
		}),
		flushEntityInfo: () => ({
			type: duck.types.FLUSH_ENTITY_INFO_STATE,
		}),
		setEntityInfoforFFBReport: entityInfo => ({
			type: duck.types.SET_ENTITY_INFO_FOR_FFB_QUALITY_REPORT,
			entityInfo,
		}),
		fetchDOList: () => ({
			type: duck.types.FETCH_DO_LIST,
		}),
		setDOList: entityList => ({
			type: duck.types.SET_DO_LIST,
			entityList,
		}),
		toggleDOSelectionPopup: status => ({
			type: duck.types.TOGGLE_DO_SELECTION_POPUP,
			status,
		}),
		createFFBQualityReport: (ffbQuality, successToast) => ({
			type: duck.types.CREATE_FFB_QUALITY_REPORT,
			ffbQuality,
			successToast,
		}),
		createCPOQualityReport: (cpoQuality, successToast) => ({
			type: duck.types.CREATE_CPO_QUALITY_REPORT,
			cpoQuality,
			successToast,
		}),
		initiateCPOQualityEdit: (docReference, attachmentID) => ({
			type: duck.types.INITIATE_CPO_QUALITY_REPORT_EDIT,
			docReference,
			attachmentID,
		}),
		initiateFFBQualityEdit: (docReference, attachmentID) => ({
			type: duck.types.INITIATE_FFB_QUALITY_REPORT_EDIT,
			docReference,
			attachmentID,
		}),
		flushFFBQualityFormValues: () => ({
			type: duck.types.FLUSH_FFB_QUALITY_FORM_VALUES,
		}),
		setInitialFormValuesforFFBEdit: ffbQualityDetails => ({
			type: duck.types.SET_INITIAL_FORM_VALUES_FFB_EDIT,
			ffbQualityDetails,
		}),
		editFFBQualityReport: (ffbQuality, successToast) => ({
			type: duck.types.EDIT_FFB_QUALITY_REPORT,
			ffbQuality,
			successToast,
		}),
		flushCPOQualityFormValues: () => ({
			type: duck.types.FLUSH_CPO_QUALITY_FORM_VALUES,
		}),
		setInitialFormValuesforCPOEdit: cpoQualityDetails => ({
			type: duck.types.SET_INITIAL_FORM_VALUES_CPO_EDIT,
			cpoQualityDetails,
		}),
		editCPOQualityReport: (cpoQuality, successToast) => ({
			type: duck.types.EDIT_CPO_QUALITY_REPORT,
			cpoQuality,
			successToast,
		}),
		fetchSingleCPOQualityReport: (docReference, attachmentID) => ({
			type: duck.types.FETCH_SINGLE_CPO_QUALITY_REPORT,
			docReference,
			attachmentID,
		}),
		fetchSingleFFBQualityReport: (docReference, attachmentID) => ({
			type: duck.types.FETCH_SINGLE_FFB_QUALITY_REPORT,
			docReference,
			attachmentID,
		}),
		flushCPOQualityReportState: () => ({
			type: duck.types.FLUSH_CPO_QUALITY_REPORT_STATE,
		}),
		flushFFBQualityReportState: () => ({
			type: duck.types.FLUSH_CPO_QUALITY_REPORT_STATE,
		}),
		setSingleCPOQualityReport: cpoQualityReport => ({
			type: duck.types.SET_SINGLE_CPO_QUALITY_REPORT,
			cpoQualityReport,
		}),
		setSingleFFBQualityReport: ffbQualityReport => ({
			type: duck.types.SET_SINGLE_FFB_QUALITY_REPORT,
			ffbQualityReport,
		}),
		fetchEntityInfoForCPOQualityReport: docReference => ({
			type: duck.types.FETCH_ENTITY_INFO_FOR_CPO_QUALITY_REPORT,
			docReference,
		}),
		setEntityInfoForCPOQualityReport: entityInfo => ({
			type: duck.types.SET_ENTITY_INFO_FOR_CPO_QUALITY_REPORT,
			entityInfo,
		}),
		flushEntityStateCPOQualityReport: () => ({
			type: duck.types.FLUSH_ENTITY_STATE_CPO_QUALITY_REPORT,
		}),
		flushListingData: () => ({
			type: duck.types.FLUSH_LISTING_DATA,
		}),
		setStorageTanks: storageTanks => ({
			type: duck.types.SET_STORAGE_TANKS,
			storageTanks,
		}),
		deleteTraceGroupID: traceGrpID => ({
			type: duck.types.DELETE_TRACE_GROUP_ID,
			traceGrpID,
		}),
	}),
})

import { getIn, omit, merge } from 'timm'
import querySerializer from 'query-string'
import LogHelper from 'ui-tdm-app/utils/logger'
import { Storage } from 'ui-lib/utils/storage'
import {
	isEmptyObject,
	transformDataForBarChart,
	transformDataforPieChart,
	getProdList,
} from 'ui-tdm-app/utils/helpers'
import request from 'ui-tdm-app/utils/request'
import { MillDuc } from 'ui-tdm-app/modules/Mill/duc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import {
	CallWithRefreshCheck,
	getOrgIDFromLoggedUser,
	fetchOrgDetailsCount,
	fetchOrgAssets,
	fetchCurrentOrgsDetail,
} from 'ui-tdm-app/modules/Auth/AuthSaga'
import { AUTH_COOKIE_KEYS, USER_COOKIE_KEYS } from 'ui-lib/utils/config'
import {
	transformSortStringsToBEQueries,
	transformFilterStringsToBEQueries,
	responseDocsMapper,
	extractFilterQueries,
	extractSortQueries,
} from 'ui-tdm-app/modules/TradeDocumentManager/helpers'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import {
	getCoreEndPoint,
	getIAMEndPoint,
	getInsightsEndPoint,
} from 'ui-tdm-app/config'
import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import { Toast } from 'ui-lib/components/Toast'
import {
	TRACE_GROUP_STATUS,
	QUALITY_INITIAL_TYPES,
} from 'ui-tdm-app/modules/Mill/config'

const logger = LogHelper('client:millSaga')

const PAGINATION_LIMIT = 20
const PLOTIO_PAGINATION_LIMIT = 20

function delay(ms) {
	return new Promise(resolve => setTimeout(resolve, ms))
}

function* fetchProductionDocuments(action) {
	try {
		const { rootModule, locationState = {}, skipGlobalLoader } = action

		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('production-document'))
		const orgID = yield getOrgIDFromLoggedUser()
		yield fetchCurrentOrgsDetail({
			orgID,
			returnResponse: true,
		})
		yield put(AuthDuc.creators.fetchAllProducts())

		const initiatingParty = yield select(
			AuthDuc.selectors.getCurrentOrganization
		)
		const { id } = initiatingParty
		const targetTypes = TRACE_GROUP_STATUS

		const { query } = locationState
		const existingQueryFromUrl = query || {}
		const frontendTargetQuery = {} // this would appear in search bar

		const backendTargetQuery = {} // this would go to backend api call
		yield put(MillDuc.creators.setProductionLoading(targetTypes))

		const _recordQuery = existingQueryFromUrl.production || []
		const _sortKeys = Array.isArray(_recordQuery)
			? _recordQuery
			: [_recordQuery]
		const sortQuery =
			_sortKeys && transformSortStringsToBEQueries(_sortKeys)

		const __query = {
			...backendTargetQuery,
		}
		yield put(
			MillDuc.creators.setProductionActiveSorts(existingQueryFromUrl)
		)

		const callsPipeLine = targetTypes.map(_type => {
			const _sortQuery = getIn(sortQuery, [_type]) || ''
			if (_type === 'transformed') {
				return CallWithRefreshCheck(
					`${getCoreEndPoint()}clients/organizations/_/produced-inventory?status=${_type}&${querySerializer.stringify(
						{
							...__query,
							...{ sort: _sortQuery },
						}
					)}`
				)
			}

			return CallWithRefreshCheck(
				`${getCoreEndPoint()}clients/organizations/${id}/tracegroups?status=${_type}&${querySerializer.stringify(
					{
						...__query,
						...{ sort: _sortQuery },
					}
				)}`
			)
		})

		const origResponse = yield all(callsPipeLine)
		const responses = responseDocsMapper(targetTypes, origResponse)

		yield put(MillDuc.creators.fetchProductionDocumentsSuccess(responses))
		const response = getIn(responses, ['data']) || []
		// update the queries as applied in backend to stay in sync
		const sortQueriesFromBE = extractSortQueries(
			{ [rootModule]: response },
			_sortKeys
		)
		if (sortQueriesFromBE.length) {
			frontendTargetQuery.production = sortQueriesFromBE
		}
		if (!isEmptyObject(frontendTargetQuery)) {
			yield put(
				MillDuc.creators.setProductionActiveSorts(frontendTargetQuery)
			)
		}
		const storageUnitUrl = `${getCoreEndPoint()}assets/storageunits?type=neq(production-line)&state=active`
		const storageUnitsresponse = yield CallWithRefreshCheck(storageUnitUrl)
		const storageUnitList =
			getIn(storageUnitsresponse, ['data', 'list']) || []
		const storageTanks = storageUnitList.filter(
			storage => storage.type === 'tank'
		)
		yield put(MillDuc.creators.setStorageTanks(storageTanks))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('production-document'))
		yield put(MillDuc.creators.resetProductionLoading())
	}
}

function* createPlotInput(value) {
	const {
		docValue,
		helpers: { setSubmitting },
		toastMessage,
	} = value

	try {
		const productionLine = getIn(docValue, ['traces', 0, 'productionLine'])

		const traces = docValue.traces.map(trace => {
			return {
				id: trace.productID,
				quantity: parseFloat(trace.quantity, 4),
				storageUnitID: 'virtual-storage',
			}
		})

		const values = {
			traces,
			meta: {
				productionLine: {
					id: productionLine,
				},
			},
		}

		const initiatingParty = yield select(
			AuthDuc.selectors.getCurrentOrganization
		)
		const { id } = initiatingParty

		const requestUrl = `${getCoreEndPoint()}clients/organizations/${id}/products/_/plotinput`
		const options = {
			method: 'POST',
			body: JSON.stringify(values),
		}
		yield call(request, requestUrl, options)
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			customHeading: toastMessage,
			type: 'success',
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.MILL$SUBROOT, {
				rootModule: 'production',
			})
		)
	} catch (err) {
		setSubmitting(false)

		logger.error(err)
		const { message } = err
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)

		return null
	}
}

function* updateInventory(value) {
	const { payload } = value

	try {
		yield put(MillDuc.creators.setUpdateInventoryLoading(true))
		const requestUrl = `${getCoreEndPoint()}inventory/weekly-products`
		const options = {
			method: 'POST',
			body: JSON.stringify(payload),
		}
		yield call(request, requestUrl, options)
		yield put(
			MillDuc.creators.fetchOutgoingInventory(
				payload.weekNumber,
				payload.year
			)
		)
		yield put(AppDuc.creators.showGlobalLoader('update-inventory'))
	} finally {
		yield put(MillDuc.creators.setUpdateInventoryLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('update-inventory'))
	}
}

function* updateProduction(value) {
	const { payload } = value

	try {
		yield put(MillDuc.creators.setUpdateProductionLoading(true))
		const requestUrl = `${getCoreEndPoint()}production/weekly-products`
		const options = {
			method: 'POST',
			body: JSON.stringify(payload),
		}
		yield call(request, requestUrl, options)
		yield call(delay, 3000)
		yield put(
			MillDuc.creators.fetchProduction(payload.weekNumber, payload.year)
		)
		yield put(AppDuc.creators.showGlobalLoader('update-production'))
	} finally {
		yield put(MillDuc.creators.setUpdateProductionLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('update-production'))
	}
}

function* fetchIncomingInventory(value) {
	const { weekNumber, year } = value

	try {
		yield put(MillDuc.creators.setIncomingInventoryLoading(true))
		const requestUrl = `${getCoreEndPoint()}inventory/weekly-products/week/${weekNumber}/year/${year}/type/incoming`
		const { data } = yield CallWithRefreshCheck(requestUrl)
		yield put(MillDuc.creators.setIncomingInventory(data))
		yield put(AppDuc.creators.showGlobalLoader('fetch-incoming-inventory'))
	} finally {
		yield put(MillDuc.creators.setIncomingInventoryLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-incoming-inventory'))
	}
}

function* fetchOutgoingInventory(value) {
	const { weekNumber, year } = value

	try {
		yield put(MillDuc.creators.setOutgoingInventoryLoading(true))
		const requestUrl = `${getCoreEndPoint()}inventory/weekly-products/week/${weekNumber}/year/${year}/type/outgoing`
		const { data } = yield CallWithRefreshCheck(requestUrl)
		yield put(MillDuc.creators.setOutgoingInventory(data))
		yield put(AppDuc.creators.showGlobalLoader('fetch-outgoing-inventory'))
	} finally {
		yield put(MillDuc.creators.setOutgoingInventoryLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-outgoing-inventory'))
	}
}

function* fetchProduction(value) {
	const { weekNumber, year } = value

	try {
		yield put(MillDuc.creators.setNewProductionLoading(true))
		const requestUrl = `${getCoreEndPoint()}production/weekly-products/week/${weekNumber}/year/${year}/type/outgoing`
		const { data } = yield CallWithRefreshCheck(requestUrl)

		if (data?.id && !data?.meta?.processed) {
			yield call(delay, 1000)
			yield put(MillDuc.creators.fetchProduction(weekNumber, year))
		}
		yield put(MillDuc.creators.setProduction(data))
		yield put(AppDuc.creators.showGlobalLoader('fetch-production'))
	} finally {
		yield put(MillDuc.creators.setNewProductionLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-production'))
	}
}

function* createPlotOutput(action) {
	const {
		docValue,
		successToast,
		helpers: { setSubmitting },
	} = action
	try {
		const traces = docValue.traces
			.map(v =>
				v.storageUnits.map(str => {
					return {
						id: v.productID,
						storageUnitID: str.storageUnitID,
						quantity: str.quantity,
						supplyChainModel: str.supplyChainModel,
						inventoryType: str.inventoryType,
						certification: str.certification,
					}
				})
			)
			.flat(1)

		const values = {
			traceGroupID: docValue.traceGroupID,
			traces,
		}

		const [, orgID] = Storage.get({
			name: AUTH_COOKIE_KEYS.CLIENT_ID,
		}).split('@')

		const requestUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products/plotoutput`
		const options = {
			method: 'POST',
			body: JSON.stringify(values),
		}

		const { data } = yield call(request, requestUrl, options)
		const traceGroupID = getIn(data, [0, 'meta', 'traceGroupID'])

		const message = successToast
		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			customHeading: message,
			type: 'success',
			isMobile: isMobile || isTablet,
		})

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.MILL$WDOCREFERENCE,
				{
					rootModule: 'produced-batch',
					action: 'view',
					documentReference: traceGroupID,
				}
			)
		)
	} catch (err) {
		logger.log(err)
		setSubmitting(false)
		const { message } = err
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)

		return null
	}
}

function* fetchInsights() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-mill-insights'))
		yield fetchOrgDetailsCount()
		const requestUrl = `${getInsightsEndPoint()}dashboard/oil-mill/insights`
		const data = yield CallWithRefreshCheck(requestUrl)
		const userInsight = getIn(data, ['data']) || {}

		const bestSuppliers = yield transformDataforPieChart(
			userInsight.bestSuppliers,
			'best-suppliers'
		)

		const topProducts = yield transformDataForBarChart(
			userInsight.topProducts,
			'products'
		)

		const certifiedSupply = yield transformDataForBarChart(
			userInsight.certifiedSupply,
			'certified-suppliers'
		)

		const supplierCategory = yield transformDataForBarChart(
			userInsight.supplierCategory,
			'supplier-categories'
		)

		const finaltransFormedInsight = {
			...userInsight,
			bestSuppliers,
			topProducts,
			certifiedSupply,
			supplierCategory,
		}
		yield put(
			MillDuc.creators.fetchInsightsSuccess(finaltransFormedInsight)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-mill-insights'))
	}
}

function* fetchInitialPlotInput() {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageTank())
		yield fetchOrgAssets()

		yield put(MillDuc.creators.productionLoadingStatus(false))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(MillDuc.creators.productionLoadingStatus(false))
	}
}

function* appendPlotInputDocuments(action) {
	try {
		const { locationState = {}, product } = action
		yield put(AuthDuc.creators.fetchAllProducts())

		const { id: productID, uom } = product

		const [, orgID] = Storage.get({
			name: AUTH_COOKIE_KEYS.CLIENT_ID,
		}).split('@')

		yield put(AppDuc.creators.showGlobalLoader('load-more-consignments'))
		const { query } = locationState
		const existingQueryFromUrl = query || {}
		// eslint-disable-next-line prefer-const
		let backendTargetQuery = {} // this would go to backend api call
		const paginationQuery = {
			limit: Math.min(
				existingQueryFromUrl.limit || PLOTIO_PAGINATION_LIMIT,
				PLOTIO_PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}

		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		const filterQueries =
			omit(existingQueryFromUrl, ['limit', 'nextIndex', 'activeIndex']) ||
			{}

		// form the backend queries form the object
		backendTargetQuery = merge(
			backendTargetQuery,
			transformFilterStringsToBEQueries(filterQueries)
		)

		const __query = {
			...backendTargetQuery,
		}

		const requestUrl = `${getCoreEndPoint()}inventory/products?sort=asc(createdAt)&availableQty=gt(0)&productID=${productID}&${querySerializer.stringify(
			{
				...__query,
			}
		)}`

		const requestUrlProductRead = `${getCoreEndPoint()}clients/organizations/${orgID}/products/${productID}/stats`

		const { data: _data } = yield CallWithRefreshCheck(requestUrl)
		const _productRead = yield CallWithRefreshCheck(requestUrlProductRead)
		const plotData = getIn(_data, ['list']) || []

		const traces = plotData.map(trace => {
			const traceInfo = Object.assign({}, trace)

			traceInfo.number = getIn(trace, ['meta', 'entityNumber'])
			if (!traceInfo.number) traceInfo.createdAt = ''
			traceInfo.uom = uom

			return traceInfo
		})

		const productInfo = getIn(_productRead, ['data']) || []
		yield put(MillDuc.creators.productionLoadingStatus(true))
		const plotInputData = {
			productName: productID,
			quantity: `${productInfo.availableQty} ${uom || ''}`,
			availableQuantity: `${productInfo.unusedQuantity} ${uom || ''}`,
			consignmentLength: plotData.length,
		}

		yield put(MillDuc.creators.fetchInitialPlotInputSuccess(plotInputData))

		yield put(MillDuc.creators.appendPlotInputConsignmentSuccess(traces))

		yield put(
			MillDuc.creators.setPlotInputPaginationEntries(
				0, // ActiveIndex
				getIn(_data, ['total']),
				getIn(_data, ['pageSize']),
				getIn(_data, ['nextStartID'])
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(MillDuc.creators.productionLoadingStatus(false))
		yield put(AppDuc.creators.hideGlobalLoader('load-more-consignments'))
	}
}

function* viewPlotDocuments(action) {
	try {
		const { traceGroupID } = action
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageTank())
		yield fetchOrgAssets()

		const requestUrl = `${getCoreEndPoint()}clients/organizations/_/tracegroups/${traceGroupID}`
		const { data } = yield CallWithRefreshCheck(requestUrl)

		const documentTraces = getIn(data, ['traces']) || []
		const producedDocumentTraces = getIn(data, ['producedTraces']) || []

		const totalQuantity = data.traces
			.map(trace => trace.quantityUtilized)
			.reduce((ttl, trace) => trace + ttl)

		const plotTraceGroup = {
			productName: data.meta.productIDs[0],
			quantity: totalQuantity,
			batchReference: data.batchReference,
			consignmentLength: documentTraces.length,
			documentTraces,
			producedDocumentTraces,
			producedTracesCreatedAt: data.createdAt,
			statusChangeLog: data.statusChangeLog,
		}

		yield put(MillDuc.creators.viewPlotDocumentsSuccess(plotTraceGroup))
	} catch (err) {
		logger.log(err)
	}
}

function* fetchInitialPlotOutput() {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(AuthDuc.creators.fetchAllStorageUnits())
		yield fetchOrgAssets()

		const locationState = yield select(MillDuc.selectors.location)

		const query = getIn(locationState, ['query']) || {}
		const { documentReference } = query
		const requestUrl = `${getCoreEndPoint()}clients/organizations/_/tracegroups/${documentReference}`
		const { data } = yield CallWithRefreshCheck(requestUrl)

		const totalQuantity = data.traces
			.map(trace => trace.quantityUtilized)
			.reduce((ttl, trace) => trace + ttl)
		const plotTraceGroup = {
			traceGroupID: data.id,
			productName: data.meta.productIDs[0],
			quantity: totalQuantity,
			createdAt: getIn(data, ['traces', 0, 'meta', 'date']) || '---',
			batchReference: data.batchReference,
			producedTracesCreatedAt: data.createdAt,
		}
		yield put(MillDuc.creators.viewPlotDocumentsSuccess(plotTraceGroup))
	} catch (err) {
		logger.log(err)
	}
}

function* fetchDocumentListing(action) {
	try {
		const { submodule, locationState = {}, skipGlobalLoader } = action

		yield put(AuthDuc.creators.fetchAllProducts())
		yield put(MillDuc.creators.flushListingData())

		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('dashboard-listing'))

		const { type, payload, query } = locationState
		const existingQueryFromUrl = query || {}
		let frontendTargetQuery = {} // this would appear in search bar
		let backendTargetQuery = {} // this would go to backend api call

		yield put(MillDuc.creators.fetchDocumentLoading(true))
		const orgID = yield getOrgIDFromLoggedUser()

		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}

		// prepare backend query based on the pagination factors
		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.activeIndex > 0 && paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		const searchQuery = existingQueryFromUrl.q || ''

		// prepare the filter query
		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}

		if (!isEmptyObject(filterQueries)) {
			// form the backend queries form the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}
		const sortQuery = existingQueryFromUrl.sort || []
		const _sortKeys = Array.isArray(sortQuery) ? sortQuery : [sortQuery]
		let _sortQuery = _sortKeys && transformSortStringsToBEQueries(_sortKeys)

		_sortQuery = getIn(_sortQuery, [submodule]) || ''
		const __query = {
			...backendTargetQuery,
		}

		const url =
			submodule === 'sourced-batch'
				? `${getCoreEndPoint()}clients/organizations/${orgID}/tracegroups?status=transforming`
				: `${getCoreEndPoint()}clients/organizations/_/produced-inventory?`
		const requestUrl = `${url}
		&${querySerializer.stringify({
			...__query,
			...{ sort: _sortQuery },
			...(searchQuery && { search: searchQuery }),
		})}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data']) || {}
		const serverPaginationQuery = {
			activeIndex: paginationQuery.activeIndex // &&
				? // We should have this check so the sequence of pagination is right.
				  // getIn(response, ['startID']) === backendTargetQuery.startID
				  paginationQuery.activeIndex
				: 0,
			limit: Math.min(getIn(response, ['pageSize']) || PAGINATION_LIMIT),
			nextIndex: getIn(response, ['lastID']),
		}

		// extract pagination queries from response
		yield put(
			MillDuc.creators.setPaginationEntries(
				serverPaginationQuery.activeIndex,
				getIn(response, ['pageSize']),
				getIn(response, ['total']),
				getIn(response, ['prevStartID']),
				getIn(response, ['nextStartID'])
			)
		)
		// extract filter queries
		const { queryTree, stateTree } = extractFilterQueries(response)

		const sortQueriesFromBE = extractSortQueries(
			{ [submodule]: response },
			_sortKeys
		)

		yield put(MillDuc.creators.setActiveListingFilters(stateTree))

		frontendTargetQuery = merge(
			frontendTargetQuery,
			queryTree,
			serverPaginationQuery
		)
		if (sortQueriesFromBE.length) {
			frontendTargetQuery.sort = sortQueriesFromBE
		}

		yield put(MillDuc.creators.fetchDocumentListingSuccess(response))
		if (!isEmptyObject(frontendTargetQuery)) {
			yield put(
				MillDuc.creators.setActiveListingSorts(frontendTargetQuery)
			)
			// we have some query, lets update the url to reflect that.
			yield put(
				MainRouteDuc.creators.redirect(
					type,
					payload,
					frontendTargetQuery,
					{
						skipRouteThunk: true,
					}
				)
			)
		}
		yield put(MillDuc.creators.fetchDocumentLoading(false))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(MillDuc.creators.fetchDocumentLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-listing'))
	}
}

// API call to fetch FFB & CPO quality reports
function* fetchQualityListing() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-quality-listing'))
		const orgID = yield getOrgIDFromLoggedUser()
		yield fetchCurrentOrgsDetail({
			orgID,
			returnResponse: true,
		})
		const ffbQualtiyUrl = `${getCoreEndPoint()}entities-attachments/all?meta=eq(%20category-%3Equality-palmoil-ffb)&sort=desc(createdAt)`

		const cpoQualityUrl = `${getCoreEndPoint()}entities-attachments/all?meta=eq(%20category-%3Equality-palmoil-cpo)&sort=desc(createdAt)`

		const callsPipeLine = QUALITY_INITIAL_TYPES.map(_type => {
			const query =
				_type === 'quality-palmoil-ffb' ? ffbQualtiyUrl : cpoQualityUrl

			return CallWithRefreshCheck(query)
		})

		const origResponse = yield all(callsPipeLine)
		const responses = responseDocsMapper(
			QUALITY_INITIAL_TYPES,
			origResponse
		)

		const extractedDocs = QUALITY_INITIAL_TYPES.reduce((agg, key) => {
			const aggregator = agg
			aggregator[key] = getIn(responses, [key, 'list']) || []

			return aggregator
		}, {})

		yield put(MillDuc.creators.setQualityListing(extractedDocs))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-quality-listing'))
	}
}

// API call to get the entity details to be displayed during FFB Quality report creation
function* fetchEntityInfoforFFBQualityReport(action) {
	try {
		const { docReference } = action

		yield put(
			AppDuc.creators.showGlobalLoader('fetch-initialState-ffb-quality')
		)
		yield put(AuthDuc.creators.fetchAllProducts())

		yield put(MillDuc.creators.flushEntityInfo())
		yield put(MillDuc.creators.flushFFBQualityFormValues())

		const requestUrl = `${getCoreEndPoint()}entities/${docReference}`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		const productInfo = yield getProdList(data)

		const doNumber = getIn(data, ['number'])
		const entityID = getIn(data, ['id'])
		const totalQty = getIn(data, ['meta', 'quantityNonMetric'])
			? getIn(data, ['meta', 'quantityNonMetric'])
			: getIn(data, ['products', 0, 'quantity']) || 0
		const productUOM = getIn(data, ['meta', 'uomNonMetric'])
			? getIn(data, ['meta', 'uomNonMetric'])
			: getIn(data, ['products', 0, 'uom']) || '---'

		const dispatchDate = getIn(data, ['createdAt'])

		const initialStateforFFBReport = {
			productInfo,
			entityID,
			doNumber,
			dispatchDate,
			totalQty,
			productUOM,
		}

		yield put(
			MillDuc.creators.setEntityInfoforFFBReport(initialStateforFFBReport)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('fetch-initialState-ffb-quality')
		)
	}
}

// API call to get the list of DOs' for the popup before FFB quality creation
function* fetchDOList() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-DO-List'))
		const productURL = `${getCoreEndPoint()}clients/organizations/-/products`
		const { data: prodData = {} } = yield CallWithRefreshCheck(productURL)
		const product = prodData.list.filter(p => p.product.code === 'FFB')
		const productID = getIn(product, [0, 'product', 'id']) || ''

		const requestUrl = `${getCoreEndPoint()}entities?type=delivery-order&status=eq(state-%3Edelivered)&products=cnt(id-%3E${productID})`

		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		yield put(MillDuc.creators.setDOList(data.list))
		yield put(MillDuc.creators.toggleDOSelectionPopup(true))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-DO-List'))
	}
}

// POST request that actually creates a FFB quality report
function* createFFBQualityReport(action) {
	try {
		const { ffbQuality, successToast } = action
		const { entityID } = ffbQuality

		yield put(AppDuc.creators.showGlobalLoader('create-ffbquality-report'))

		const ffbQualityDetails = {
			type: 'user-input',
			meta: {
				ffbQuality: {
					ripeBunches: getIn(ffbQuality, ['ripeBunches']),
					overripeBunches: getIn(ffbQuality, ['overripeBunches']),
					underripeBunches: getIn(ffbQuality, ['underripeBunches']),
					emptyBunches: getIn(ffbQuality, ['emptyBunches']),
					unripeBunches: getIn(ffbQuality, ['unripeBunches']),
					wetBunches: getIn(ffbQuality, ['wetBunches']),
					productID: getIn(ffbQuality, ['productID']),
				},
				category: 'quality-palmoil-ffb',
			},
		}

		const requestUrl = `${getCoreEndPoint()}entities/${entityID}/attachments`
		const options = {
			method: 'POST',
			body: JSON.stringify(ffbQualityDetails),
		}

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
				{
					rootModule: 'delivery-order',
					documentReference: entityID,
				},
				{},
				{},
				true
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('create-ffbquality-report'))
	}
}

// POST request that actually creates a CPO quality report
function* createCPOQualityReport(action) {
	try {
		const { cpoQuality, successToast } = action
		const { entityID } = cpoQuality

		const cpoQualityValues = {}

		const cpoKeys = [
			'ffa',
			'dobi',
			'mniValue',
			'acidity',
			'humidity',
			'impurities',
			'meltingPoint',
			'iodineIndex',
			'colour',
		]

		for (const key of cpoKeys) {
			if (cpoQuality[key] !== '') {
				cpoQualityValues[key] = cpoQuality[key]
			}
		}

		yield put(AppDuc.creators.showGlobalLoader('create-cpoquality-report'))

		const cpoQualityDetails = {
			type: 'user-input',
			meta: {
				cpoQuality: cpoQualityValues,
				category: 'quality-palmoil-cpo',
			},
		}

		const requestUrl = `${getCoreEndPoint()}entities/${entityID}/attachments`
		const options = {
			method: 'POST',
			body: JSON.stringify(cpoQualityDetails),
		}

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
				{
					rootModule: 'delivery-order',
					documentReference: entityID,
				},
				{},
				{},
				true
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('create-cpoquality-report'))
	}
}

// API call to get the FFB quality details that should be populated on Edit screen
function* initiateFFBQualityEdit(action) {
	try {
		const { docReference, attachmentID } = action

		yield put(
			AppDuc.creators.showGlobalLoader('initiate-ffb-quality-report-edit')
		)

		yield put(
			MillDuc.creators.fetchEntityInfoforFFBQualityReport(docReference)
		)

		yield put(MillDuc.creators.flushFFBQualityFormValues())

		const requestUrl = `${getCoreEndPoint()}entities/${docReference}/attachments/${attachmentID}`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		const ffbQualityDetails = getIn(data, ['meta', 'ffbQuality'])

		yield put(
			MillDuc.creators.setInitialFormValuesforFFBEdit(ffbQualityDetails)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('initiate-ffb-quality-report-edit')
		)
	}
}

// API request to update an existing FFB Quality report
function* editFFBQualityReport(action) {
	try {
		const { ffbQuality, successToast } = action
		const { entityID, attachmentID } = ffbQuality

		const ffbQualityDetails = {
			type: 'user-input',
			meta: {
				ffbQuality: {
					ripeBunches: getIn(ffbQuality, ['ripeBunches']),
					overripeBunches: getIn(ffbQuality, ['overripeBunches']),
					underripeBunches: getIn(ffbQuality, ['underripeBunches']),
					emptyBunches: getIn(ffbQuality, ['emptyBunches']),
					unripeBunches: getIn(ffbQuality, ['unripeBunches']),
					wetBunches: getIn(ffbQuality, ['wetBunches']),
					productID: getIn(ffbQuality, ['productID']),
				},
				category: 'quality-palmoil-ffb',
			},
		}

		const requestUrl = `${getCoreEndPoint()}entities/${entityID}/attachments/${attachmentID}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(ffbQualityDetails),
		}
		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.MILL$SUBROOT, {
				rootModule: 'quality',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

// API call to get entity details during cpo quality creation & update
function* fetchEntityInfoForCPOQualityReport(action) {
	try {
		const { docReference } = action

		yield put(
			AppDuc.creators.showGlobalLoader(
				'fetch-entity-infor-for-cpo-quality-report'
			)
		)

		yield put(MillDuc.creators.flushEntityStateCPOQualityReport())

		const entitiesUrl = `${getCoreEndPoint()}entities/${docReference}`
		const { data: entitiesResponse = {} } = yield CallWithRefreshCheck(
			entitiesUrl
		)

		const entityDate = getIn(entitiesResponse, [
			'meta',
			'other',
			'core',
			'entityDate',
		])

		const quantity = getIn(entitiesResponse, ['products', 0, 'quantity'])
		const uom = getIn(entitiesResponse, ['products', 0, 'uom'])

		const entityDetails = {
			entityDate,
			quantity,
			uom,
		}

		yield put(
			MillDuc.creators.setEntityInfoForCPOQualityReport(entityDetails)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader(
				'fetch-entity-infor-for-cpo-quality-report'
			)
		)
	}
}

// API call to get the CPO quality details that should be prepopulated on the form
function* initiateCPOQualityEdit(action) {
	try {
		const { docReference, attachmentID } = action

		yield put(
			AppDuc.creators.showGlobalLoader('initiate-cpo-quality-report-edit')
		)

		yield put(MillDuc.creators.flushCPOQualityFormValues())
		yield put(
			MillDuc.creators.fetchEntityInfoForCPOQualityReport(docReference)
		)

		const requestUrl = `${getCoreEndPoint()}entities/${docReference}/attachments/${attachmentID}`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		const cpoQuality = getIn(data, ['meta', 'cpoQuality'])

		yield put(MillDuc.creators.setInitialFormValuesforCPOEdit(cpoQuality))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('initiate-cpo-quality-report-edit')
		)
	}
}

// API request to update an existing CPO Quality Reports
function* editCPOQualityReport(action) {
	try {
		const { cpoQuality, successToast } = action
		const { entityID, attachmentID } = cpoQuality

		const cpoQualityDetails = {
			type: 'user-input',
			meta: {
				cpoQuality: {
					ffa: getIn(cpoQuality, ['ffa']),
					dobi: getIn(cpoQuality, ['dobi']),
					mniValue: getIn(cpoQuality, ['mniValue']),
				},
				category: 'quality-palmoil-cpo',
			},
		}

		const requestUrl = `${getCoreEndPoint()}entities/${entityID}/attachments/${attachmentID}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(cpoQualityDetails),
		}

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.MILL$SUBROOT, {
				rootModule: 'quality',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

// API call to get the details of CPO quality report for VIEW page
function* fetchSingleCPOQualityReport(action) {
	try {
		const { docReference, attachmentID } = action

		yield put(AppDuc.creators.showGlobalLoader('view-cpo-quality-report'))

		yield put(MillDuc.creators.flushCPOQualityReportState())

		const requestUrl = `${getCoreEndPoint()}entities/${docReference}/attachments/${attachmentID}`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		const qualityDetails = getIn(data, ['meta', 'cpoQuality'])
		const cpoCreationDate = getIn(data, ['createdAt'])

		const entitiesUrl = `${getCoreEndPoint()}entities/${docReference}`
		const { data: entitiesResponse = {} } = yield CallWithRefreshCheck(
			entitiesUrl
		)

		const entityDate = getIn(entitiesResponse, [
			'meta',
			'other',
			'core',
			'entityDate',
		])
		const doNumber = getIn(entitiesResponse, ['number'])
		const quantity = getIn(entitiesResponse, ['products', 0, 'quantity'])
		const uom = getIn(entitiesResponse, ['products', 0, 'uom'])

		const cpoQualityDetails = {
			...qualityDetails,
			cpoCreationDate,
			entityDate,
			doNumber,
			quantity,
			uom,
		}

		yield put(MillDuc.creators.setSingleCPOQualityReport(cpoQualityDetails))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('view-cpo-quality-report'))
	}
}

// API call to get the details of FFB quality report for VIEW page
function* fetchSingleFFBQualityReport(action) {
	try {
		const { docReference, attachmentID } = action

		yield put(AppDuc.creators.showGlobalLoader('view-ffb-quality-report'))

		yield put(MillDuc.creators.flushFFBQualityReportState())

		const requestUrl = `${getCoreEndPoint()}entities/${docReference}/attachments/${attachmentID}`
		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		const qualityDetails = getIn(data, ['meta', 'ffbQuality'])
		const orgID = getIn(data, ['organizationID'])

		const orgDetailsUrl = `${getIAMEndPoint()}clients/organizations?limit=100`
		const { data: orgData = {} } = yield CallWithRefreshCheck(orgDetailsUrl)

		const organizations = getIn(orgData, ['list'])
		const organization = organizations.filter(org => org.id === orgID)
		const orgName = getIn(organization, [0, 'name'])

		const entitiesUrl = `${getCoreEndPoint()}entities/${docReference}`
		const { data: entitiesResponse = {} } = yield CallWithRefreshCheck(
			entitiesUrl
		)

		const entityDate = getIn(entitiesResponse, [
			'meta',
			'other',
			'core',
			'entityDate',
		])
		const doNumber = getIn(entitiesResponse, ['number'])
		const quantity = getIn(entitiesResponse, ['products', 0, 'quantity'])
		const uom = getIn(entitiesResponse, ['products', 0, 'uom'])

		const ffbQualityDetails = {
			...qualityDetails,
			orgName,
			entityDate,
			doNumber,
			quantity,
			uom,
		}

		yield put(MillDuc.creators.setSingleFFBQualityReport(ffbQualityDetails))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('view-ffb-quality-report'))
	}
}

function* deleteTraceGroupID(action) {
	try {
		const { traceGrpID } = action

		const initiatingParty = yield select(
			AuthDuc.selectors.getCurrentOrganization
		)
		const { id } = initiatingParty

		const requestUrl = `${getCoreEndPoint()}clients/organizations/${id}/tracegroups/${traceGrpID}`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)

		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.MILL$SUBROOT, {
				rootModule: 'production',
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}
export default function* MillSaga() {
	try {
		yield all([
			takeLatest(
				MillDuc.creators.fetchProductionDocuments().type,
				fetchProductionDocuments
			),
			takeLatest(
				MillDuc.creators.createPlotOutput().type,
				createPlotOutput
			),
			takeLatest(
				MillDuc.creators.createPlotInput().type,
				createPlotInput
			),
			takeLatest(
				MillDuc.creators.updateInventory().type,
				updateInventory
			),
			takeLatest(
				MillDuc.creators.updateProduction().type,
				updateProduction
			),
			takeLatest(
				MillDuc.creators.fetchIncomingInventory().type,
				fetchIncomingInventory
			),
			takeLatest(
				MillDuc.creators.fetchOutgoingInventory().type,
				fetchOutgoingInventory
			),
			takeLatest(
				MillDuc.creators.fetchProduction().type,
				fetchProduction
			),
			takeLatest(MillDuc.creators.fetchInsights().type, fetchInsights),
			takeLatest(
				MillDuc.creators.fetchInitialPlotInput().type,
				fetchInitialPlotInput
			),
			takeLatest(
				MillDuc.creators.viewPlotDocuments().type,
				viewPlotDocuments
			),
			takeLatest(
				MillDuc.creators.fetchInitialPlotOutput().type,
				fetchInitialPlotOutput
			),
			takeLatest(
				MillDuc.creators.appendPlotInputDocuments().type,
				appendPlotInputDocuments
			),
			takeLatest(
				MillDuc.creators.fetchDocumentListing().type,
				fetchDocumentListing
			),
			takeLatest(
				MillDuc.creators.fetchQualityListing().type,
				fetchQualityListing
			),
			takeLatest(
				MillDuc.creators.fetchEntityInfoforFFBQualityReport().type,
				fetchEntityInfoforFFBQualityReport
			),
			takeLatest(MillDuc.creators.fetchDOList().type, fetchDOList),
			takeLatest(
				MillDuc.creators.createFFBQualityReport().type,
				createFFBQualityReport
			),
			takeLatest(
				MillDuc.creators.initiateFFBQualityEdit().type,
				initiateFFBQualityEdit
			),
			takeLatest(
				MillDuc.creators.editFFBQualityReport().type,
				editFFBQualityReport
			),
			takeLatest(
				MillDuc.creators.createCPOQualityReport().type,
				createCPOQualityReport
			),
			takeLatest(
				MillDuc.creators.initiateCPOQualityEdit().type,
				initiateCPOQualityEdit
			),
			takeLatest(
				MillDuc.creators.editCPOQualityReport().type,
				editCPOQualityReport
			),
			takeLatest(
				MillDuc.creators.fetchSingleCPOQualityReport().type,
				fetchSingleCPOQualityReport
			),
			takeLatest(
				MillDuc.creators.fetchSingleFFBQualityReport().type,
				fetchSingleFFBQualityReport
			),
			takeLatest(
				MillDuc.creators.fetchEntityInfoForCPOQualityReport().type,
				fetchEntityInfoForCPOQualityReport
			),
			takeLatest(
				MillDuc.creators.deleteTraceGroupID().type,
				deleteTraceGroupID
			),
		])
	} catch (e) {
		logger.error(e)
	}
}

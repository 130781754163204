import React from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ErrorBlock, NewErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { getInfoAddressLink } from 'ui-tdm-app/config'

const ErrorSegment = ({ type = 401, message, buttonProps }) => {
	return (
		<ErrorBlock status={type} message={message} buttonProps={buttonProps} />
	)
}

const NotFoundBlock = () => {
	const { t } = useTranslation()

	return (
		<NewErrorBlock
			status={404}
			title={t('common.error404Title')}
			subtitle={t('common.error404Subtitle')}
		/>
	)
}

const Link = styled.a`
	&:hover {
		text-decoration: underline;
	}
`

const NotVerifiedBlock = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	return (
		<>
			<NewErrorBlock
				status="not-verified"
				title={
					<p style={{ color: '#2D2D2D' }}>
						{t('common.congratulation')}
					</p>
				}
				subtitle={
					<>
						<p style={{ color: '#555454' }}>
							Your account is being reviewed internally and
							subject to approval.
						</p>
						<p style={{ color: '#555454' }}>
							We shall communicate within 72 hours via registered
							email once approved. Please write to &nbsp;
							<Link
								href="mailto:info@dibizglobal.com"
								style={{ color: 'hsl(235deg 100% 62%)' }}
							>
								{getInfoAddressLink()}
							</Link>
							, for further info.
						</p>
					</>
				}
			/>
			<Box
				style={{
					backgroundColor: '#F8F8F8',
					paddingBottom: '20px',
				}}
				alignItems="center"
			>
				<Button
					label={t('common.close')}
					primary
					extendStyles={{
						width: '10%',
						borderRadius: '4px',
						fontSize: '16px',
						background: '#2A2364',
						padding: '6px',
						textAlign: 'center',
						textDecoration: 'solid',
					}}
					plain
					onClick={() => dispatch(AuthDuc.creators.signOutUser())}
				/>
			</Box>
		</>
	)
}

const ComingSoonFeature = () => {
	const { t } = useTranslation()

	return (
		<>
			<NewErrorBlock
				status="coming-soon"
				subtitle={t('common.comingSoonFeature', {
					infoAddressLink: getInfoAddressLink(),
				})}
			/>
		</>
	)
}

const UnexpectedErrorBlock = ({
	message,
	status = 500,
	onClick,
	shouldLogOut,
}) => {
	const dispatch = useDispatch()

	return (
		<ErrorBlock
			status={status}
			messageType="error"
			message={message}
			buttonProps={{
				label: shouldLogOut ? 'Retry' : 'Go Back',
				onClick: () => {
					if (onClick) onClick()
					else if (shouldLogOut)
						dispatch(
							AuthDuc.creators.signOutUser({
								redirectTo: window.location.href,
							})
						)
					else window.location.reload()
				},
			}}
		/>
	)
}

export {
	ErrorSegment,
	NotFoundBlock,
	UnexpectedErrorBlock,
	NotVerifiedBlock,
	ComingSoonFeature,
}

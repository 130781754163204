import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn, omit, setIn, merge } from 'timm'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { getTargetFilterQueries } from 'ui-tdm-app/modules/TradeDocumentManager/helpers'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { DocumentInformationCard } from 'ui-tdm-app/modules/TradeDocumentManager/containers/AuditReport/Shared/DocumentLeadCard'
import { getRandomNumber } from 'ui-tdm-app/utils/helpers'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { Label } from 'ui-lib/components/Typography'
import {
	ButtonIconWrapper,
	Button,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { DatePicker } from 'ui-lib/components/Datepicker'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { Icon } from 'ui-lib/icons/components/Icon'
import GraphUp from 'ui-lib/icons/graph-up.svg'

const AuditReportDoc = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.auditReport'),
			name: 'audit-reports',
			isActive: false,
		},
	]

	const location = useSelector(TradeDocDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const currentOrg = useSelector(AuthDuc.selectors.getCurrentOrganization)
	const activeFilters = useSelector(
		TradeDocDuc.selectors.getAuditActiveFilters
	)
	const activeDocuments = useSelector(TradeDocDuc.selectors.getAuditReports)
	const activeDateFilters = getIn(activeFilters, ['createdAt']) || {}
	const paginationConfig = useSelector(
		TradeDocDuc.selectors.getAuditReportListing
	)
	const { total } = paginationConfig
	const disableShowMore = total === activeDocuments.length
	let activeStartDate = getIn(activeDateFilters, ['gte', 0]) || null
	if (activeStartDate) activeStartDate = new Date(activeStartDate)
	let activeEndDate = getIn(activeDateFilters, ['lte', 0]) || null
	if (activeEndDate) activeEndDate = new Date(activeEndDate)

	const [startDate, setStartDate] = useState(activeStartDate)
	const [endDate, setEndDate] = useState(activeEndDate || new Date())

	const certificateListing = useSelector(
		TradeDocDuc.selectors.getCertificateDetails
	)

	const disableCTA = total === 0
	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}
	const { primaryAddress = {} } = currentOrg

	const {
		building = '',
		line1 = '',
		city = '',
		state = '',
		postalCode = '',
	} = primaryAddress

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'remove_filter': {
					setStartDate()
					setEndDate(new Date())

					dispatch(
						TradeDocDuc.creators.fetchAuditReport(
							'audit-report',
							location,
							true
						)
					)

					break
				}

				case 'apply_filter': {
					const {
						filterType, // the root filter key e.g status
						filterValue,
						prefix,
					} = meta

					const currentQuery = getIn(location, ['query']) || {}
					const filterQueries = omit(currentQuery, ['sort', 'q']) // exclude sort and search convention

					const targetQueries = getTargetFilterQueries(
						'',
						'date_range',
						filterValue,
						prefix
					)

					const targetFilters = merge(
						currentQuery,
						merge(filterQueries, { [filterType]: targetQueries })
					)
					dispatch(
						TradeDocDuc.creators.fetchAuditReport(
							'audit-report',
							setIn(location, ['query'], targetFilters),
							true
						)
					)

					break
				}

				case 'paginate': {
					const { direction } = meta || {}
					let targetFilters

					const { activeIndex = 0, nextCursor } = paginationConfig
					const targetIndex = Math.max(
						direction === 'next'
							? activeIndex + 1
							: activeIndex - 1,
						0
					)
					if (activeStartDate && activeEndDate) {
						const filterValue = {
							startDate: new Date(activeEndDate).toISOString(),
							endDate: new Date(activeStartDate).toISOString(),
						}

						targetFilters = getTargetFilterQueries(
							'',
							'date_range',
							filterValue,
							''
						)
					}
					const nextIndex = nextCursor
					const docType = 'audit-report'
					const locationState = {
						query: {
							...location.query,
							createdAt: targetFilters,
							activeIndex: targetIndex,
							nextIndex,
						},
					}

					// retrigger the current route so new data is fetched again.
					dispatch(
						TradeDocDuc.creators.appendAuditReport(
							docType,
							locationState
						)
					)

					break
				}
				case 'download': {
					const docType = 'audit-report'
					let targetFilters
					let filterValue = {}
					if (activeStartDate && activeEndDate) {
						filterValue = {
							startDate: new Date(activeEndDate).toISOString(),
							endDate: new Date(activeStartDate).toISOString(),
						}

						targetFilters = getTargetFilterQueries(
							'',
							'date_range',
							filterValue,
							''
						)
					}
					const locationState = {
						query: {
							...location.query,
							createdAt: targetFilters,
						},
					}
					// retrigger the current route so new data is fetched again.
					dispatch(
						TradeDocDuc.creators.saveAuditReportAsPDF(
							docType,
							locationState,
							filterValue
						)
					)

					break
				}

				default:
					break
			}
		},
		[dispatch, location, paginationConfig, activeStartDate, activeEndDate]
	)

	return (
		<Box padding={8} width="100%" height="100%">
			{/* <ComingSoonFeature />s */}

			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box
				row={!isMobile}
				justifyContent="space-between"
				alignItems="baseline"
			>
				<Title
					title={t('auditReport.title')}
					note={t('auditReport.showingAuditReport')}
					icon={GraphUp}
				/>
			</Box>
			<Box
				row={!isMobile}
				justifyContent="space-between"
				alignItems="baseline"
			>
				<Box alignSelf="flex-end" margin="1px">
					<Button
						label={t('auditReport.saveAsPDF')}
						disabled={
							activeStartDate === null || activeEndDate === null
						}
						rounded
						onClick={() => {
							handleChange('download')
						}}
					/>
				</Box>

				<Box
					row={!isMobile}
					alignItems={isMobile ? 'baseline' : 'flex-end'}
				>
					<Box row>
						<Box
							alignItems="flex-start"
							width={isMobile ? 160 : 140}
							margin="0px 5px"
						>
							<Label small>{t('auditReport.from')}</Label>
							<div style={{ fontSize: theme.fontSize.s }}>
								<DatePicker
									name="startDate"
									placeholder={t('auditReport.choose')}
									hideError
									value={startDate}
									maxDate={endDate}
									onChange={value => {
										setStartDate(value)
									}}
								/>
							</div>
						</Box>
						<Box
							alignItems="flex-start"
							width={isMobile ? 160 : 140}
							margin="0px 5px"
						>
							<Label small>{t('auditReport.to')}</Label>
							<div style={{ fontSize: theme.fontSize.s }}>
								<DatePicker
									name="toDate"
									placeholder={t('auditReport.choose')}
									hideError
									minDate={startDate}
									maxDate={new Date()}
									value={endDate}
									onChange={value => {
										setEndDate(value)
									}}
									toDate
								/>
							</div>
						</Box>
					</Box>
					<Spacer size={10} horizontal={!isMobile} />

					<Box alignItems="flex-end" margin="0 5px" row>
						<Button
							label={t('auditReport.view')}
							type="submit"
							extendStyles={{
								padding: '3px 5px',
							}}
							primary
							rounded
							disabled={!startDate}
							onClick={() => {
								handleChange('apply_filter', {
									type: 'audit-report',
									filterSegment: 'end_date',
									filterType: 'createdAt',
									filterValue: {
										startDate: new Date(
											startDate
										).toISOString(),
										endDate: new Date(
											endDate
										).toISOString(),
									},
								})
							}}
						/>
						<Spacer size={10} horizontal={!isMobile} />

						<ButtonWithNoBorder
							label={t('auditReport.clear')}
							style={{
								fontSize: theme.fontSize.xl,
							}}
							onClick={() => {
								handleChange('remove_filter')
							}}
						/>
					</Box>
				</Box>
			</Box>
			<Spacer size={16} />
			{disableCTA && (
				<Box
					style={{
						backgroundColor: 'rgb(236, 238, 243)',
						height: 'auto',
					}}
				>
					<ErrorBlock
						hideButton
						status="empty"
						message={
							<p>
								<p
									style={{
										fontWeight: 500,
										paddingBottom: 5,
									}}
								>
									{t('auditReport.reportsNotfound')}
								</p>
								{t('auditReport.reportsNotfoundNote')}
							</p>
						}
					/>
				</Box>
			)}

			{!disableCTA && (
				<>
					<DocumentInformationCard
						title={t('auditReport.companyInfo')}
						issuedBy={currentOrg.name}
						address={
							`${building || ''}` +
								`${building && line1 ? ', ' : ''}` +
								`${line1 || ''}` +
								`${line1 && city ? ', ' : ''}` +
								`${city || ''}` +
								`${city && state ? ', ' : ''}` +
								`${state || ''}` +
								`${state && postalCode ? ', ' : ''}` +
								`${postalCode || ''}` || '---'
						}
						location={
							`${getIn(currentOrg, [
								'primaryAddress',
								'location',
								'lat',
							])}` +
								', ' +
								`${getIn(currentOrg, [
									'primaryAddress',
									'location',
									'long',
								])}` || '---'
						}
						certificateInformation={certificateListing}
						additionalMeta={[
							{
								groupCertificationNumber:
									getIn(currentOrg, [
										'groupCertificationNumber',
									]) || '---',
							},
							{
								yield: getIn(currentOrg, ['yield']) || '---',
							},
							{
								totalArea:
									getIn(currentOrg, ['totalArea']) || '---',
							},
						]}
					/>
					<Spacer size={16} />
					{activeDocuments.map((document, index) => (
						<Box key={getRandomNumber()}>
							<Box overflow>
								<DocumentInformationCard
									title={`${t(
										'auditReport.transaction'
									)} - ${index + 1}`}
									issuedTo={document.issuedTo}
									category={document.orgCategory}
									additionalMeta={document.transaction}
									transporterDetails={document.transporter}
									productDetails={document.product}
								/>
							</Box>
							<Spacer size={16} />
						</Box>
					))}
					{!disableShowMore && (
						<Box center>
							<ButtonWithNoBorder
								label={t('auditReport.showMore')}
								style={{
									fontSize: theme.fontSize.xl,
								}}
								onClick={() => {
									handleChange('paginate', {
										direction: 'next',
									})
								}}
							/>
						</Box>
					)}
				</>
			)}
			<Box padding="25px 2px" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DASHBOARD
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { AuditReportDoc }

import React, { useMemo } from 'react'
import { AdditionalMetaBlock } from '../../../components/AdditionalMetaBlock'

const ALIASES_KEYS = {
	accountNumber: 'Bank Account Number',
	bank: 'Bank Name',
	branch: 'Branch Name',
	ifscCode: 'Bank IFSC Code',
}

const BankDetailStaticBlock = ({ values, newDocView = false }) => {
	const meta = useMemo(
		() =>
			Object.keys(ALIASES_KEYS).map(key => ({
				name: ALIASES_KEYS[key],
				description: values[key] || '---',
			})),
		[values]
	)

	return <AdditionalMetaBlock newDocView={newDocView} meta={meta} />
}

BankDetailStaticBlock.defaultProps = {
	values: {},
}

export { BankDetailStaticBlock }

import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn, merge, setIn, addLast, mergeIn } from 'timm'
import { useFormik } from 'formik'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { WebTourDuc } from 'ui-tdm-app/modules/WebTour/duc'
import { WebTour } from 'ui-tdm-app/modules/WebTour'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { extractNonStandardAttributes } from 'ui-tdm-app/modules/TradeDocumentManager/helpers'
import { isEmptyObject } from 'ui-tdm-app/utils/helpers'
import { Modal } from 'ui-lib/components/Modal'
import { getCoreEndPoint } from 'ui-tdm-app/config'
import {
	NAME_ALIASES,
	INITIAL_TYPES,
	getSchemaDefinition,
	plantationDocSources,
} from 'ui-tdm-app/modules/TradeDocumentManager/config'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { NumberIndicator } from 'ui-tdm-app/modules/TradeDocumentManager/components/Fragments'
import {
	ShippingStaticSection,
	ShippingEditSection,
} from 'ui-tdm-app/modules/TradeDocumentManager/containers/DocumentControl/Shared/ShippingSection'
import {
	UnCoreStaticSection,
	CoreSection,
	CoreStaticSection,
} from 'ui-tdm-app/modules/TradeDocumentManager/containers/DocumentControl/Shared/CoreSection'
import {
	AdditionalInfoStaticBlock,
	AdditionalInfoBlock,
} from 'ui-tdm-app/modules/TradeDocumentManager/containers/DocumentControl/Shared/AdditionalInformation'
import { AuthorizationBlock } from 'ui-tdm-app/modules/TradeDocumentManager/containers/DocumentControl/Shared/AuthorizationBlock'
import { ProductsSection } from 'ui-tdm-app/modules/TradeDocumentManager/containers/DocumentControl/Shared/ProductsSection'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { H3, P } from 'ui-lib/components/Typography'
import { Label } from 'ui-lib/components/Label'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { Input } from 'ui-lib/components/Input'
import { FieldText } from 'ui-lib/components/FieldText'
import { Select, SelectSearch } from 'ui-lib/components/Select'
import { FileUpload } from 'ui-lib/components/FileUpload'

import {
	ButtonIconWrapper,
	Button,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { ModuleIconMap } from 'ui-lib/utils/config'
import { Icon } from 'ui-lib/icons/components/Icon'
import theme from 'ui-lib/utils/base-theme'
import CheckmarkIcon from 'ui-lib/icons/check-mark.svg'
import FileIcon from 'ui-lib/icons/file-icon.svg'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import { getSupplyChainModelsWithId } from 'ui-tdm-app/modules/Admin/config'

const getInitialValues = valuesList => {
	const {
		type,
		parentType,
		parentDocID,
		fullName,
		orgRole,
		parentDocRef,
		_partnerID,
		actor,
		source = {},
	} = valuesList
	const base = {
		activePartner: _partnerID || '',
		meta: {
			entityReference: '',
			issueDate: '',
			expectedDeliveryDate: '',
			shipping: {
				address: {},
				shipThrough: '',
			},
			referenceName: parentType,
			referenceValue: parentDocID,
			transporter: {},
			authorization: {
				reportedBy: fullName,
				reportedByRole: orgRole,
				authorisedSignatory: fullName,
			},
		},
		products: [],
		targetProducts: [],
	}
	// if from plantation.
	if (plantationDocSources.includes(parentType)) {
		if (parentType === 'plantation') {
			base.meta.binDetails = {
				ticketGroupID: getIn(parentDocRef, ['id']) || '',
				ticketGroupMeta: getIn(parentDocRef, ['meta']) || {},
				storageUnitID: getIn(parentDocRef, ['name']) || '',
				createdAt: getIn(parentDocRef, ['createdAt']) || '',
			}
		}
		if (parentType === 'storage') {
			base.traceGroupID = parentDocID
		}
	}

	if (type === 'invoice') {
		base.bankDetails = {}
	}

	if (type === 'delivery-order') {
		base.meta.shipping = {
			modeOfTransport: {},
		}
	}

	const _source = mergeIn(
		source,
		['meta', 'authorization'],
		base.meta.authorization
	)

	let binDetails = {}
	let details = {}
	if (parentType === 'plantation') {
		binDetails = mergeIn(
			_source,
			['meta', 'binDetails'],
			base.meta.binDetails
		)
		details = merge(base, binDetails)
	} else {
		details = merge(base, _source)
	}
	if (
		actor &&
		actor.length > 0 &&
		actor[0].includes('palmoil_plantation') &&
		parentDocRef &&
		parentDocRef.id
	) {
		details.products = []
	}

	return details
}

const DetailsEntry = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const locationState = useSelector(TradeDocDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const loggedInUserProfile = useSelector(AuthDuc.selectors.getUserProfile)
	const actor = useSelector(AuthDuc.selectors.getActor)
	const isDealer =
		actor && actor.length > 0 && actor[0].includes('palmoil_ffbdealer')
	const isSubDealer =
		actor && actor.length > 0 && actor[0].includes('palmoil_ffbsubdealer')
	const isPlantation =
		actor && actor.length > 0 && actor[0].includes('palmoil_plantation')

	const { fullName, orgRole } = loggedInUserProfile

	const [showModal, setShowModal] = useState(false)
	const [showDeleteDraftModal, setShowDeleteDraftModal] = useState(false)
	const [uplodedEntities, setUplodedEntities] = useState([])
	const [currentPartner, setCurrentPartner] = useState('')
	const [language, setLanguage] = useState(
		loggedInUserProfile &&
			loggedInUserProfile.meta &&
			loggedInUserProfile.meta.language
			? loggedInUserProfile.meta.language
			: ''
	)

	const { payload = {}, query = {} } = locationState
	const { parentDocRef: _query = '' } = query
	const [parentType] = _query.split('~')
	// eslint-disable-next-line no-unused-vars
	const { rootModule, documentStatus } = payload
	const TitleIcon = ModuleIconMap[rootModule]
	/** Handlers */

	const breadCrumRootModule = getIn(locationState, [
		'prev',
		'payload',
		'rootModule',
	])
		? getIn(locationState, ['prev', 'payload', 'rootModule'])
		: null

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		} else if (target === 'trade-doc-dashboard') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule:
							breadCrumRootModule === 'incoming' ||
							breadCrumRootModule === 'outgoing' ||
							breadCrumRootModule === 'all'
								? getIn(locationState, [
										'prev',
										'payload',
										'rootModule',
								  ])
								: 'incoming',
					}
				)
			)
		}
	}

	const getBreadCrumbsList = useCallback(
		({ title }) => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.tradeDocManager'),
				name: 'trade-doc-dashboard',
				isActive: true,
			},
			{
				label: `${t('breadcrumb.new')} ${t(
					actor &&
						actor.length > 0 &&
						actor[0].includes('palmoil_tdm') &&
						rootModule === 'purchase-order'
						? t('breadcrumb.salesOrder')
						: title
				)} - ${t('breadcrumb.details')}`,
				name: 'document-details',
				isActive: false,
			},
		],
		[actor, rootModule, t]
	)

	const title = NAME_ALIASES[rootModule]
	const breadCrumbs = useMemo(() => getBreadCrumbsList({ title }), [
		getBreadCrumbsList,
		title,
	])
	const { isLoading, isSaving } = useSelector(
		TradeDocDuc.selectors.getDocumentLoadingState
	)
	const {
		isShippingReadOnly,
		isProductsReadOnly,
		isAdditionalInformationReadOnly,
	} = useSelector(TradeDocDuc.selectors.getDocumentReadOnlySecAsFlags)

	const activeSections = useSelector(
		TradeDocDuc.selectors.getDocumentActiveSections
	)

	const activeDocument = useSelector(
		TradeDocDuc.selectors.getDocumentActiveRecord
	)

	const parentDocRef = useSelector(
		TradeDocDuc.selectors.getDocumentParentDocRef
	)
	const parentDocType = parentDocRef.type
	const parentDocID = parentDocRef.traceGroupID
	const issuingParty = useSelector(state =>
		AuthDuc.selectors.getOrganizationByID(
			state,
			parentDocRef.initiatingPartyID || parentDocRef.organizationID
		)
	)
	const receivingParty = useSelector(state =>
		AuthDuc.selectors.getOrganizationByID(
			state,
			parentDocRef.receivingPartyID
		)
	)
	const fromAddress = getIn(issuingParty, ['primaryAddress']) || {}

	const toAddress = getIn(receivingParty, ['primaryAddress']) || {}

	const partnerList = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const partners = []

	// eslint-disable-next-line array-callback-return
	partnerList.map(list => {
		const orgType = getIn(list, ['categories', 0, 'id']) || ''
		if (rootModule === 'purchase-order' || rootModule === 'invoice') {
			if (
				orgType !== 'palmoil-ffbDealer' &&
				orgType !== 'palmoil-ffbSubDealer' &&
				orgType !== 'rice-dealer'
			) {
				partners.push({
					id: list.id,
					label: list.name,
				})
			}
		} else {
			partners.push({
				id: list.id,
				label: list.name,
			})
		}
	})

	useEffect(() => {
		setLanguage(
			loggedInUserProfile &&
				loggedInUserProfile.meta &&
				loggedInUserProfile.meta.language
				? loggedInUserProfile.meta.language
				: ''
		)
	}, [loggedInUserProfile])

	const { id: _partnerID } =
		useSelector(TradeDocDuc.selectors.getDocumentActivePartner) || ''
	const customParentDocOrg = useSelector(state =>
		AuthDuc.selectors.getOrganizationByID(
			state,
			parentDocRef.organizationID
		)
	)
	const initialValues = useMemo(() => {
		const valuesList = {
			type: rootModule,
			parentType: parentDocType,
			parentDocID,
			fullName,
			orgRole,
			parentDocRef,
			_partnerID,
			actor,
			source: activeDocument,
		}

		return getInitialValues(valuesList)
	}, [
		activeDocument,
		rootModule,
		parentDocType,
		parentDocID,
		fullName,
		orgRole,
		parentDocRef,
		_partnerID,
		actor,
	])
	const validationSchema = useMemo(
		() => getSchemaDefinition(t, rootModule, parentDocType),
		[t, rootModule, parentDocType]
	)

	const isParentAStandardDoc = INITIAL_TYPES.includes(parentDocType)

	const allowProductsAddition =
		!_query ||
		(actor && actor.length > 0 && actor[0].includes('palmoil_plantation'))

	const onlyAllowMinimalProductEdit = [
		'delivery-order',
		'invoice',
		'purchase-order',
	].includes(rootModule)

	const {
		handleSubmit,
		values,
		errors,
		setFieldValue,
		isSubmitting,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnMount: true,
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			const data = { ..._values }

			if (isPlantation) {
				const plantationPayloadMeta = { ...data.meta }
				plantationPayloadMeta.uomNonMetric = 'Nos'

				plantationPayloadMeta.quantityNonMetric = getIn(_values, [
					'products',
					0,
					'quantity',
				])
				data.meta = plantationPayloadMeta
			}

			setSubmitting(false)
			dispatch(
				TradeDocDuc.creators.setActiveDocument({
					rootModule,
					baseSchema: _values,
				})
			)
			if (documentStatus && documentStatus === 'draft') {
				data.fromDraft = true
			}
			if (documentStatus && documentStatus === 'clone') {
				dispatch(
					MainRouteDuc.creators.switchPage(
						locationState.type,
						{
							...locationState.payload,
							action: 'confirm',
							documentStatus: 'edit',
							baseValueSchema: data,
							forceScrolltoTop: true,
						},
						locationState.query
					)
				)
			} else {
				dispatch(
					MainRouteDuc.creators.switchPage(
						locationState.type,
						{
							...locationState.payload,
							action: 'confirm',
							baseValueSchema: data,
							forceScrolltoTop: true,
						},
						locationState.query
					)
				)
			}
			dispatch(WebTourDuc.creators.setActiveTourModule(''))
		},
	})
	const canSave = !isEmptyObject(values.meta)
	const shouldHideSave = payload.documentStatus === 'clone'
	const additionalCoreMeta = useMemo(() => {
		let meta = []

		if (values.meta) {
			if (values.meta.entityReference) {
				meta = addLast(meta, {
					name: 'Reference Number',
					description: values.meta.entityReference,
				})
			}
			if (values.meta.transporter) {
				if (values.meta.transporter.vehicleNumber) {
					meta = addLast(meta, {
						name: 'Vehicle Number',
						description: values.meta.transporter.vehicleNumber,
					})
				}
				if (values.meta.transporter.driverName) {
					meta = addLast(meta, {
						name: 'Driver Name',
						description: values.meta.transporter.driverName,
					})
				}
			}
		}

		return meta
	}, [values.meta])

	const nonStandardDocAttributes = useMemo(() => {
		return extractNonStandardAttributes(parentDocRef)
	}, [parentDocRef])

	const handleFormikChange = useCallback(
		(key, _values) => {
			setFieldValue(key, _values)
		},
		[setFieldValue]
	)

	const handleShippingSelection = useCallback(
		activeAddress => {
			setFieldValue(
				'meta',
				setIn(values.meta, ['shipping', 'address'], activeAddress)
			)
		},
		[setFieldValue, values.meta]
	)

	const handleShippingInformation = useCallback(
		keyValue => {
			setFieldValue(
				'meta',
				setIn(
					values.meta,
					['shipping', getIn(Object.keys(keyValue), [0])],
					getIn(Object.values(keyValue), [0])
				)
			)
		},
		[setFieldValue, values.meta]
	)

	const needsTransportDetails = [
		'delivery-order',
		'weighbridge-slip',
		'invoice',
	].includes(rootModule)
	const productSelect = values.products || []

	const isUnitPriceAvailable =
		productSelect.length &&
		productSelect.filter(products => products.metricUnitPrice)
	const isTaxAvailable =
		productSelect.length &&
		productSelect.filter(products => (products.tax || []).length > 0)

	const productUnitTaxCheck =
		rootModule === 'invoice' &&
		isUnitPriceAvailable.length === 0 &&
		isTaxAvailable.length === 0

	const handleProductChange = useCallback(
		(_activeProducts, productsRequestPayload) => {
			if (currentPartner && currentPartner !== values.activePartner) {
				setFieldValue('products', [])
				setCurrentPartner(
					values && values.activePartner ? values.activePartner : ''
				)
			} else {
				setFieldValue('products', _activeProducts)
				setFieldValue('targetProducts', productsRequestPayload)

				setCurrentPartner(
					values && values.activePartner ? values.activePartner : ''
				)
			}
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setFieldValue]
	)

	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	useEffect(() => {
		if (tourModule === 'DOCreate') {
			if (rootModule === 'purchase-order') {
				dispatch(WebTourDuc.creators.setActiveTourModule('POCreate'))
			}
			if (rootModule === 'delivery-order') {
				dispatch(WebTourDuc.creators.setActiveTourModule('DOCreate2'))
			}
			if (rootModule === 'invoice') {
				dispatch(
					WebTourDuc.creators.setActiveTourModule('invoiceCreate')
				)
			}
		}
	}, [tourModule, dispatch, rootModule])
	const disableIndex = partners.findIndex(x => x.id === values.activePartner)
	const disableArrayIndex = [0]
	partners.forEach((_, index) => {
		if (index !== disableIndex) {
			disableArrayIndex.push(index + 1)
		}
	})

	const actorName = getIn(actor, [0]) || ''

	const useSupplyChainFilters =
		actorName.includes('palmoil_mill') ||
		actorName.includes('palmoil_kernelmill') ||
		actorName.includes('palmoil_refinery') ||
		actorName.includes('palmoil_trader') ||
		actorName.includes('palmoil_manufacturer')

	const shouldUpdateInventoryUsingFilters =
		!isEmptyObject(parentDocRef) &&
		rootModule === 'delivery-order' &&
		values.targetProducts?.length === 0 &&
		useSupplyChainFilters

	const collapsableSegmentMessage = () => {
		if (errors.products) {
			return t('validation.addAtleast1Product')
		}
		if (shouldUpdateInventoryUsingFilters) {
			return 'Please edit this section. Use supply chain model and certification filters when entering the type of inventory you plan to deliver.'
		}

		return t('validation.collapsableSegmentSuccess')
	}

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Modal
					forceCloseviaButton
					size="large"
					show={showDeleteDraftModal}
					heading={t('tdmDetailsEntry.confirm')}
					body={
						<P large bold>
							{t('tdmDetailsEntry.confDraftMessage')}
						</P>
					}
					closelabel={t('common.no')}
					confirmlabel={t('common.yes')}
					onClose={() => setShowDeleteDraftModal(false)}
					onConfirm={() => {
						setShowDeleteDraftModal(false)
						dispatch(
							TradeDocDuc.creators.deleteDraftEntity(
								values.id,
								t('tdmDetailsEntry.deleteDraftToast')
							)
						)
					}}
				/>

				<Modal
					forceCloseviaButton
					size="large"
					show={showModal}
					heading={
						uplodedEntities.length > 0
							? t('tdmDetailsEntry.draftSuccess')
							: t('tdmDetailsEntry.uploadFile')
					}
					subHeading={t('tdmDetailsEntry.uploadFileSubHeading')}
					body={
						<>
							<FileUpload
								type="private"
								dropzone
								size="5242880"
								modalTriggerBtnLabel={t('common.upload')}
								buttonLabel={t('common.uploadBtnLabel')}
								required
								currentUploads={uplodedEntities}
								supportedFileType=".pdf"
								fileUploadUrl={`${getCoreEndPoint()}entities/${rootModule}/parse`}
								onDocumentChange={documents => {
									setUplodedEntities(documents)
								}}
								onUploadFailure={({ message }) => {
									dispatch(
										AppDuc.creators.showToast({
											messageType: 'error',
											message,
										})
									)
								}}
								dropLabel={t('common.dropFile')}
								afterFileUploadText={t(
									'common.afterFileUploadText'
								)}
							/>
						</>
					}
					confirmlabel={t('common.proceed')}
					isDisabled={uplodedEntities.length === 0}
					secondaryButtonLabel={t('tdmDetailsEntry.addAnotherFile')}
					closelabel={t('common.close')}
					onClose={() => setShowModal(false)}
					secondaryButtonClick={() => setUplodedEntities([])}
					onConfirm={() => {
						const entityID =
							getIn(uplodedEntities, [0, 'entity', 'id']) || ''
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE,
								{
									rootModule,
									action: 'details',
									documentReference: entityID,
									documentStatus: 'draft',
								}
							)
						)
					}}
				/>
				{isLoading && <TransparentBgSpinner lockToScreen />}
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				{!(isDealer || isSubDealer) && (
					<Box
						row
						justifyContent="space-between"
						alignItems="baseline"
					>
						<Title
							title={`${t('tdmDetailsEntry.new')} ${t(
								actor &&
									actor.length > 0 &&
									actor[0].includes('palmoil_tdm') &&
									rootModule === 'purchase-order'
									? t('breadcrumb.salesOrder')
									: title
							)}`}
							icon={TitleIcon}
						/>
					</Box>
				)}
				<Spacer size={8} />
				<Box row>
					<NumberIndicator>1</NumberIndicator>
					<Spacer size={10} horizontal />
					<H3>{t('common.enterDetails')}</H3>
				</Box>
				{documentStatus === 'draft' && (
					<Box row justifyContent="flex-end">
						<Button
							label={t('common.delete')}
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={DeleteIcon} />
								</ButtonIconWrapper>
							}
							onClick={() => setShowDeleteDraftModal(true)}
						/>
					</Box>
				)}

				<Spacer size={16} />

				<form onSubmit={handleSubmit}>
					<div style={{ display: 'flex' }}>
						<Box style={{ width: '72%' }}>
							<Box Box id="input-partner-picker">
								{actor &&
								actor.length > 0 &&
								actor[0].includes('palmoil_smallholder') ? (
									<Label required>
										{t('tdmDetailsEntry.choosePartner')}
									</Label>
								) : (
									<>
										<Label required>{`${t(
											title
										)} Recipient`}</Label>
									</>
								)}
								<SelectSearch
									name="activePartner"
									key={values.activePartner}
									value={values.activePartner}
									placeholder={t(
										'tdmDetailsEntry.choosePartnerPlaceholder'
									)}
									options={partners}
									onChange={value => {
										dispatch(
											TradeDocDuc.creators.setActivePartner(
												value
											)
										)
										setFieldValue('activePartner', value.id)
									}}
									labelKey="label"
									valueKey="id"
									defaultLabel={t(
										'common.selectDefaultLabel'
									)}
									style={{
										width: '350px',
										border: '1px solid #0b20aa',
										borderRadius: 4,
									}}
									returnOnlyValue
									disabledIndexArr={
										(!!_query &&
											[
												'delivery-order',
												'invoice',
												'purchase-order',
											].includes(parentDocType)) ||
										(documentStatus &&
											(documentStatus === 'edit' ||
												documentStatus === 'clone'))
											? disableArrayIndex
											: []
									}
								/>
							</Box>
							<Spacer size={20} />
							{rootModule !== 'delivery-order' && (
								<Box
									width={350}
									title={
										isParentAStandardDoc &&
										t('tdmDetailsEntry.scModelTooltip')
									}
								>
									<Select
										required
										name="supplyChainModel"
										label={t(
											'tdmDetailsEntry.supplyChainModel'
										)}
										options={getSupplyChainModelsWithId(t)}
										labelKey="name"
										valueKey="id"
										disabled={isParentAStandardDoc}
										key={getIn(values, [
											'meta',
											'supplyChainModel',
											'id',
										])}
										value={getIn(values, [
											'meta',
											'supplyChainModel',
											'id',
										])}
										onChange={value => {
											setFieldValue(
												'meta',
												setIn(
													values.meta,
													['supplyChainModel'],
													value
												)
											)
										}}
										returnOnlyValue
									/>
								</Box>
							)}
						</Box>
						<Box
							style={{
								width: '271px',
								height: '254px',
								padding: '32px',
								borderRadius: '6px',
								backgroundColor: theme.color.blue13,
							}}
						>
							<Box
								style={{
									fontWeight: 500,
									fontSize:
										language === 'ta' ? '20px' : '24px',
									color: theme.color.white,
								}}
							>
								{`${t('common.upload')} & ${t(
									'common.create'
								)}`}
							</Box>
							<Box
								style={{
									fontSize:
										language === 'ta' ? '12px' : '14px',
									paddingTop:
										language === 'ta' ? '10px' : '15px',
									paddingBottom:
										language === 'ta' || language === 'id'
											? '25px'
											: '45px',
									color: theme.color.themeBlue1,
								}}
							>
								{t('tdmDetailsEntry.attachButtonText')}
							</Box>
							<Box
								style={{
									borderRadius: '6px',
									color: theme.color.blue13,
									backgroundColor: theme.color.white,
								}}
							>
								<Button
									label={t('common.uploadAndSend')}
									type="reset"
									extendStyles={{
										opacity: 'unset',
										color: theme.color.blue13,
									}}
									onClick={() => {
										if (
											locationState?.payload
												?.documentReference &&
											locationState?.payload
												?.documentStatus
										) {
											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types
														.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE,
													{
														rootModule,
														action: 'attachDetails',
														documentReference:
															locationState
																.payload
																.documentReference,
														documentStatus:
															locationState
																.payload
																.documentStatus,
													}
												)
											)
										} else {
											dispatch(
												MainRouteDuc.creators.switchPage(
													MainRouteDuc.types
														.TRADE_DOCUMENT_MANAGER$WACTION,
													{
														rootModule,
														action: 'attachDetails',
													},
													{
														...(locationState?.query
															?.parentDocRef && {
															parentDocRef: getIn(
																locationState,
																[
																	'query',
																	'parentDocRef',
																]
															),
														}),
													}
												)
											)
										}
									}}
									rounded
								/>
							</Box>
						</Box>
					</div>
					{parentDocType && isParentAStandardDoc ? (
						<>
							{(needsTransportDetails ||
								rootModule === 'invoice') && (
								<>
									<Spacer size={32} />
									<CoreSection
										rootModule={rootModule}
										title={title}
										values={values.meta}
										errors={errors.meta}
										hasActiveParent
										needsTransportDetails={
											needsTransportDetails
										}
										isEdit={
											documentStatus &&
											(documentStatus === 'clone' ||
												documentStatus === 'edit')
										}
										onChange={handleFormikChange}
									/>
								</>
							)}
							<Spacer size={32} />

							<CoreStaticSection
								isMobile={isMobile}
								DocumentKey={`${t(
									NAME_ALIASES[parentDocType]
								) || 'Document'} Number`}
								DocumentReference={parentDocRef.number}
								issuedBy={issuingParty.name || '---'}
								issuedOn={values.meta.issueDate}
								issuedTo={receivingParty.name || '---'}
								expectedOn={values.meta.expectedDeliveryDate}
								additionalMeta={
									!needsTransportDetails && additionalCoreMeta
								}
								toAddress={toAddress}
								fromAddress={fromAddress}
							/>
						</>
					) : (
						<>
							<Spacer size={20} />
							<CoreSection
								rootModule={rootModule}
								title={title}
								values={values.meta}
								errors={errors.meta}
								needsTransportDetails={needsTransportDetails}
								onChange={handleFormikChange}
								isEdit={
									documentStatus && documentStatus === 'clone'
								}
							/>
						</>
					)}
					{parentDocType && !isParentAStandardDoc && (
						<>
							<Spacer size={48} />
							<UnCoreStaticSection
								isMobile={isMobile}
								DocumentKey={`${t(
									NAME_ALIASES[parentDocType]
								)} Number`}
								DocumentReference={
									parentDocRef.name ||
									parentDocRef.batchReference
								}
								parentDocType={parentType}
								issuedOn={parentDocRef.createdAt}
								issuedBy={
									customParentDocOrg.name || issuingParty.name
								}
								additionalMeta={nonStandardDocAttributes}
							/>
						</>
					)}
					<Box id="input-additionalInfo">
						<>
							<Spacer size={48} />
							{activeSections.includes('products') && (
								<>
									<CollapsableSegment
										expand
										title={t('tdmDetailsEntry.products')}
										plain={isProductsReadOnly}
										hasError={
											errors.products ||
											productUnitTaxCheck ||
											shouldUpdateInventoryUsingFilters
										}
										message={collapsableSegmentMessage()}
									>
										<Box>
											<Spacer size={12} />
											{productUnitTaxCheck && (
												<FieldText show error>
													{t(
														'tdmDetailsEntry.noUnitPriceAvailable'
													)}
												</FieldText>
											)}
											{values &&
												values.products &&
												values.products.length > 1 &&
												rootModule ===
													'delivery-order' && (
													<FieldText show error>
														{t(
															'tdmDetailsEntry.addOneProduct'
														)}
													</FieldText>
												)}

											<Spacer size={12} />
											<div key={values?.products?.length}>
												<ProductsSection
													allowMinimalEdit={
														onlyAllowMinimalProductEdit
													}
													isParentDoc={isEmptyObject(
														parentDocRef
													)}
													canAddProducts={
														allowProductsAddition
													}
													parentDocType={parentType}
													activeProducts={
														values.products
													}
													individualProductEntry={
														values.individualProductEntry
													}
													targetProducts={
														values.targetProducts
													}
													isPricingMandatory={
														rootModule ===
															'invoice' ||
														rootModule ===
															'purchase-order'
													}
													isEntityDO={
														rootModule ===
														'delivery-order'
													}
													isEntityPO={
														rootModule ===
														'purchase-order'
													}
													isEntityInvoice={
														rootModule === 'invoice'
													}
													onProductsChange={
														handleProductChange
													}
													partner={
														values.activePartner
													}
													actor={actor}
												/>
											</div>
										</Box>
									</CollapsableSegment>
									<Spacer size={48} />
								</>
							)}
						</>

						{activeSections.includes('shipping') && (
							<>
								<Spacer size={24} />
								<CollapsableSegment
									expand
									plain={isShippingReadOnly}
									title={t(
										'tdmDetailsEntry.shippingAndDelivery'
									)}
									hasError={getIn(errors, [
										'meta',
										'shipping',
									])}
									message={
										getIn(errors, ['meta', 'shipping'])
											? t('validation.mandatoryFields')
											: t(
													'validation.collapsableSegmentSuccess'
											  )
									}
								>
									<Box>
										<Spacer size={24} />
										{isShippingReadOnly ? (
											<ShippingStaticSection
												isMobile={isMobile}
												activeRecord={
													getIn(activeDocument, [
														'meta',
														'shipping',
														'address',
													]) || {}
												}
												shipThrough={getIn(
													activeDocument,
													[
														'meta',
														'shipping',
														'shipThrough',
													]
												)}
												shipperNumber={getIn(
													activeDocument,
													[
														'meta',
														'shipping',
														'shipperNumber',
													]
												)}
											/>
										) : (
											<>
												<ShippingEditSection
													activeRow={getIn(values, [
														'meta',
														'shipping',
														'address',
													])}
													onChange={
														handleShippingSelection
													}
												/>
												<Spacer size={32} />
												<Box width={350}>
													<Input
														label={t(
															'tdmDetailsEntry.dispatchedThrough'
														)}
														name="shipThrough"
														value={getIn(values, [
															'meta',
															'shipping',
															'shipThrough',
														])}
														returnKeyValue
														onChange={
															handleShippingInformation
														}
													/>
												</Box>
												<Spacer size={20} />
												<Box width={350}>
													<Input
														label={t(
															'tdmDetailsEntry.shipperReferenceNumber'
														)}
														name="shipperNumber"
														value={getIn(values, [
															'meta',
															'shipping',
															'shipperNumber',
														])}
														returnKeyValue
														onChange={
															handleShippingInformation
														}
													/>
												</Box>
												{!isPlantation && (
													<>
														<Spacer size={20} />
														<Box width={350}>
															<Input
																label={t(
																	'tdmDetailsEntry.billOfLading'
																)}
																name="billOfLading"
																value={getIn(
																	values,
																	[
																		'meta',
																		'shipping',
																		'billOfLading',
																	]
																)}
																returnKeyValue
																onChange={
																	handleShippingInformation
																}
															/>
														</Box>
													</>
												)}
												{rootModule ===
													'delivery-order' && (
													<>
														<Spacer size={20} />
														<Box width={350}>
															<Select
																label={t(
																	'tdmDetailsEntry.modeOfTransport'
																)}
																key={getIn(
																	values,
																	[
																		'meta',
																		'shipping',
																		'modeOfTransport',
																		'id',
																	]
																)}
																options={[
																	{
																		name: t(
																			'tdmDetailsEntry.truck'
																		),
																		id:
																			'truck',
																	},
																	{
																		name: t(
																			'tdmDetailsEntry.sea'
																		),
																		id:
																			'sea',
																	},
																	{
																		name: t(
																			'tdmDetailsEntry.pipeline'
																		),
																		id:
																			'pipline',
																	},
																]}
																labelKey="name"
																valueKey="id"
																value={getIn(
																	values,
																	[
																		'meta',
																		'shipping',
																		'modeOfTransport',
																		'id',
																	]
																)}
																onChange={value => {
																	setFieldValue(
																		'meta',
																		setIn(
																			values.meta,
																			[
																				'shipping',
																				'modeOfTransport',
																			],
																			value
																		)
																	)
																}}
																returnOnlyValue
															/>
														</Box>
													</>
												)}
											</>
										)}
									</Box>
								</CollapsableSegment>
								<Spacer size={24} />
							</>
						)}

						{activeSections.includes('additionalInfo') && (
							<Box>
								<CollapsableSegment
									hasError={errors.additionalInformation}
									message={
										errors.additionalInformation
											? t('validation.mandatoryFields')
											: t(
													'validation.collapsableSegmentSuccess'
											  )
									}
									title={t(
										'tdmDetailsEntry.additionalInformation'
									)}
									plain={isAdditionalInformationReadOnly}
								>
									{isAdditionalInformationReadOnly ? (
										<AdditionalInfoStaticBlock
											values={values.bankDetails}
										/>
									) : (
										<AdditionalInfoBlock
											issuingPartyID={
												getIn(activeDocument, [
													'initiatingPartyID',
												]) || ''
											}
											receivingPartyID={
												getIn(activeDocument, [
													'receivingPartyID',
												]) || ''
											}
											onChange={handleFormikChange}
											values={values.additionalInfo}
											errors={errors.additionalInfo}
										/>
									)}
								</CollapsableSegment>
								<Spacer size={24} />
							</Box>
						)}

						<CollapsableSegment
							title={t('tdmDetailsEntry.authorization')}
							hasError={getIn(errors, ['meta', 'authorization'])}
							message={
								getIn(errors, ['meta', 'authorization'])
									? t('validation.mandatoryFields')
									: t('validation.collapsableSegmentSuccess')
							}
						>
							<AuthorizationBlock
								values={getIn(values, [
									'meta',
									'authorization',
								])}
								errors={getIn(errors, [
									'meta',
									'authorization',
								])}
								onChange={handleFormikChange}
							/>
						</CollapsableSegment>
					</Box>
					<Box padding="24px 0" row justifyContent="flex-end">
						<Box
							id="updateDODetails"
							row={!isMobile}
							style={{ padding: 2 }}
						>
							{!shouldHideSave && (
								<ButtonWithNoBorder
									label={t('tdmDetailsEntry.save')}
									disabled={!canSave}
									style={{
										fontSize: theme.fontSize.xl,
									}}
									isLoading={isSaving}
									customIcon={
										<ButtonIconWrapper lightBG>
											<Icon glyph={FileIcon} />
										</ButtonIconWrapper>
									}
									onClick={() => {
										const documentPropsList = {
											rootModule,
											document: values,
											currentDocId: values.id,
											successMsg:
												documentStatus &&
												documentStatus === 'draft'
													? t(
															'tdmDetailsEntry.draftUpdatedSuccess'
													  )
													: t(
															'tdmDetailsEntry.draftCreatedSuccess'
													  ),
											t,
											poSuccessMsg: t('common.poSuccess'),
											invoiceSuccessMsg: t(
												'common.invoiceSuccess'
											),
											doSuccessMsg: t('common.doSuccess'),
											actionType: 'save',
										}
										if (canSave)
											dispatch(
												TradeDocDuc.creators.initiateDocumentSave(
													documentPropsList
												)
											)
									}}
								/>
							)}
							<Spacer size={16} horizontal={!isMobile} />
							<Button
								label={t('tdmDetailsEntry.previewDetails')}
								type="submit"
								disabled={
									!isEmptyObject(errors) ||
									productUnitTaxCheck ||
									shouldUpdateInventoryUsingFilters
								}
								isLoading={isSubmitting}
								primary
								rounded
								customIcon={
									<ButtonIconWrapper>
										<Icon glyph={CheckmarkIcon} />
									</ButtonIconWrapper>
								}
							/>
						</Box>
					</Box>
				</form>
			</Box>
			{(tourModule === 'DOCreate2' ||
				tourModule === 'POCreate' ||
				tourModule === 'invoiceCreate') && <WebTour />}
		</>
	)
}

export { DetailsEntry }

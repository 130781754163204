import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DashboardStatusWidget } from 'ui-lib/components/DashboardStatusWidget'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { useTranslation } from 'react-i18next'
import Incoming from 'ui-lib/icons/incoming.svg'
import Outgoing from 'ui-lib/icons/outgoing.svg'
import { Box } from 'ui-lib/utils/Box'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { Icon } from 'ui-lib/icons/components/Icon'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { NAME_ALIASES, LabelsMap } from '../config'
import { TradeDocDuc } from '../duc'
import { Title } from '../components/Title'

const getBreadCrumbsList = (label, t) => [
	{
		label: t('breadcrumb.home'),
		name: 'home',
		isActive: true,
	},
	{
		label: t(label),
		name: `${label}-dashboard`,
		isActive: false,
	},
]

const SubDashboard = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { payload = {} } = useSelector(TradeDocDuc.selectors.location)

	const { rootModule } = payload

	const stats = useSelector(
		rootModule === 'incoming'
			? TradeDocDuc.selectors.getSubDashboardIncomingStats
			: TradeDocDuc.selectors.getSubDashboardOutgoingStats
	)

	const titleIcon = rootModule === 'incoming' ? Incoming : Outgoing

	const onClick = (action, meta = {}) => {
		switch (action) {
			case 'cta_clicked': {
				const { name } = meta
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$LISTING,
						{
							rootModule,
							submodule: name,
						}
					)
				)
				break
			}
			case 'status_clicked': {
				const { name, status } = meta

				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$LISTING,
						{
							rootModule,
							submodule: name,
						},
						{
							status,
						}
					)
				)

				break
			}
			default:
				break
		}
	}

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const title = LabelsMap[rootModule]
	const breadCrumbs = useMemo(() => getBreadCrumbsList(title, t), [title, t])
	const keys = Object.keys(stats)

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 10px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Title title={t(title)} icon={titleIcon} />
			<Box row wrap style={{ padding: 8 }}>
				{keys.map(key => {
					const _title = NAME_ALIASES[key] || key
					const statuses = stats[key] || {}
					let ctaKey = `${t(_title)}s`

					if (ctaKey.indexOf('Copys') > -1) {
						ctaKey = ctaKey.replace('Copys', 'Copies')
					}

					return (
						<DashboardStatusWidget
							key={key}
							name={key}
							label={t(_title)}
							statuses={statuses}
							onClick={(action, meta) => {
								onClick(action, { ...meta, key, stats })
							}}
							ctaLabel={`View all ${ctaKey}`}
						/>
					)
				})}
				{!keys.length && (
					<Box style={{ minHeight: 300, width: '100%' }}>
						<ErrorBlock
							status="empty"
							hideButton
							message={t('common.errorBlockMessage')}
						/>
					</Box>
				)}
			</Box>
			<Box padding="25px 0" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DASHBOARD
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { SubDashboard }

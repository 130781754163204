import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import eng from 'date-fns/locale/en-US'
import may from 'date-fns/locale/ms'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'

/** Date Locales */
const locales = {
	eng,
	may,
}

import { isDateValid } from 'ui-lib/utils/helpers'

export const getDateByFormat = (date, _format, locale) => {
	if (!isDateValid(date)) return '- - -'

	return format(new Date(date), _format || 'd LLL yyyy', {
		locale: locales[locale] || locales.eng,
	})
}

export const getDateWithTimeByFormat = (date, _format, locale) => {
	if (!isDateValid(date)) return '- - -'

	return format(new Date(date), _format || 'd LLL yyyy hh:mm a', {
		locale: locales[locale] || locales.eng,
	})
}

/**
 * React Hook helpers
 */

/** The hooks to use in react components */

export const useDateByFormat = (date, _format) => {
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	return useMemo(() => getDateByFormat(date, _format, activeLocale), [
		date,
		_format,
		activeLocale,
	])
}

export { isDateValid }

export const getDayMonthYearFormat = dateString => {
	const date = new Date(dateString)
	const day = String(date.getDate()).padStart(2, '0')
	const month = String(date.getMonth() + 1).padStart(2, '0')
	const year = date.getFullYear()

	return `${day}/${month}/${year}`
}

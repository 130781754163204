import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { merge } from 'timm'
import { getIAMEndPoint } from 'ui-tdm-app/config'
import request from 'ui-tdm-app/utils/request'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { TextArea } from 'ui-lib/components/TextArea'
import { FileUpload, FileLists } from 'ui-lib/components/FileUpload'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { KeyValueSegment } from '../../../components/KeyValueSegment'

const ALIASES_KEYS = {
	remarks: 'tdmDetailsEntry.enterRemarks',
	attachments: 'tdmDetailsEntry.attachments',
}

const STATIC_ALIASES_KEYS = {
	remarks: 'tdmPreviewDetails.remarks',
	attachments: 'tdmPreviewDetails.attachments',
}

const AdditionalInfoStaticBlock = ({ values, newDocView = false }) => {
	const { t } = useTranslation()

	return (
		<Box>
			{values.remarks && (
				<KeyValueSegment
					newDocView={newDocView}
					name={t(STATIC_ALIASES_KEYS.remarks)}
					description={values.remarks}
				/>
			)}
			{values.attachments && !!values.attachments.length && (
				<KeyValueSegment
					newDocView={newDocView}
					name={t(STATIC_ALIASES_KEYS.attachments)}
					description={
						<FileLists documents={values.attachments} isReadOnly />
					}
				/>
			)}
		</Box>
	)
}

AdditionalInfoStaticBlock.defaultProps = {
	values: {},
}

const AdditionalInfoBlock = ({
	onChange,
	values,
	issuingPartyID,
	receivingPartyID,
	errors,
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const sharePrivateFile = async docs => {
		if (!docs || docs.length === 0) {
			return
		}
		const fileIDs = (docs || []).map(d => d.id)
		const json = {
			fileIDs,
			shareWith: [issuingPartyID, receivingPartyID],
		}
		const options = {
			method: 'PUT',
			body: JSON.stringify(json),
		}
		const requestUrl = `${getIAMEndPoint()}files/share`
		const { data = {} } = await request(requestUrl, options)

		return data
	}

	return (
		<>
			<Spacer size={8} />
			<Box style={{ padding: 4 }}>
				<TextArea
					name="remarks"
					label={t(ALIASES_KEYS.remarks)}
					placeholder={t('tdmDetailsEntry.typeHere')}
					value={values.remarks}
					returnKeyValue
					onChange={keyValue => {
						onChange('additionalInfo', merge(values, keyValue))
					}}
					error={values.remarks && errors.remarks}
					customStyles={{ height: 250 }}
				/>
			</Box>
			<FileUpload
				label={t(ALIASES_KEYS.attachments)}
				modalTriggerBtnLabel={t('common.upload')}
				type="private"
				name="attachments"
				size="5242880"
				supportedFileType=".jpg,.png,.pdf"
				currentUploads={values.attachments}
				onDocumentChange={async documents => {
					await sharePrivateFile(documents)
					onChange(['meta', 'files'], documents)
				}}
				onUploadFailure={({ message }) => {
					dispatch(
						AppDuc.creators.showToast({
							messageType: 'error',
							message,
						})
					)
				}}
			/>
		</>
	)
}

AdditionalInfoBlock.defaultProps = {
	isMobile: false,
	issuingPartyID: '',
	receivingPartyID: '',
	values: {},
	errors: {},
	touched: {},
	onChange: () => {},
}

export { AdditionalInfoStaticBlock, AdditionalInfoBlock }

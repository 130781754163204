import React from 'react'
import { getRandomNumber } from 'ui-tdm-app/utils/helpers'
import { KeyValueSegment } from 'ui-tdm-app/modules/TradeDocumentManager/components/KeyValueSegment'
import { chunkArrayInGroups } from 'ui-lib/utils/helpers'
import { Box } from 'ui-lib/utils/Box'

const AdditionalMetaBlock = ({ meta, newDocView = false }) => {
	return (
		<Box
			row
			style={{
				flexWrap: 'wrap',
				paddingBottom: '16px',
				paddingTop: '16px',
			}}
		>
			{chunkArrayInGroups(meta, 3).map(segment => {
				return (
					<Box
						style={{ paddingLeft: 15, paddingRight: 60 }}
						key={getRandomNumber()}
					>
						{segment.map(({ name = '', description = '' }) => (
							<KeyValueSegment
								key={name}
								newDocView={newDocView}
								name={name}
								description={description}
							/>
						))}
					</Box>
				)
			})}
		</Box>
	)
}

AdditionalMetaBlock.defaultProps = {
	meta: [],
}

export { AdditionalMetaBlock }

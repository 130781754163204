import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { useTranslation } from 'react-i18next'
import { merge } from 'timm'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Input } from 'ui-lib/components/Input'
import { KeyValueSegment } from '../../../components/KeyValueSegment'

const AuthorizationStaticBlock = ({ values }) => {
	const { t } = useTranslation()

	return (
		<Box>
			<KeyValueSegment
				name={t('tdmPreviewDetails.reportedBy')}
				description={values.reportedBy}
			/>
			<KeyValueSegment
				name={t('tdmPreviewDetails.role')}
				description={values.reportedByRole}
			/>
			<KeyValueSegment
				name={t('tdmPreviewDetails.authorisedSignatory')}
				description={values.authorisedSignatory}
			/>
		</Box>
	)
}

const AuthorizationBlock = ({ onChange, values, errors }) => {
	const { t } = useTranslation()

	return (
		<>
			<Spacer size={24} />
			<Box overflow width={250}>
				<Input
					required
					label={t('tdmDetailsEntry.reportedBy')}
					name="reportedBy"
					value={values.reportedBy}
					error={values.reportedBy && errors.reportedBy}
					returnKeyValue
					onChange={keyValue =>
						onChange(
							['meta', 'authorization'],
							merge(values, keyValue)
						)
					}
				/>
				<Spacer size={24} horizontal />
				<Input
					required
					label={t('tdmDetailsEntry.role')}
					name="reportedByRole"
					value={values.reportedByRole}
					error={values.reportedByRole && errors.reportedByRole}
					returnKeyValue
					onChange={keyValue =>
						onChange(
							['meta', 'authorization'],
							merge(values, keyValue)
						)
					}
				/>
				<Spacer size={24} horizontal />
				<Input
					required
					label={t('tdmDetailsEntry.authorisedSignatory')}
					name="authorisedSignatory"
					value={values.authorisedSignatory}
					error={
						values.authorisedSignatory && errors.authorisedSignatory
					}
					returnKeyValue
					onChange={keyValue =>
						onChange(
							['meta', 'authorization'],
							merge(values, keyValue)
						)
					}
				/>
			</Box>
		</>
	)
}

AuthorizationBlock.defaultProps = {
	values: {},
	errors: {},
	touched: {},
	onChange: () => {},
}

export { AuthorizationStaticBlock, AuthorizationBlock }

import React from 'react'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { useSelector } from 'react-redux'
import { UnexpectedErrorBlock } from 'ui-tdm-app/modules/App/components/Error'
import { AuditReportDoc } from './AuditReport'
import { GeneralReport } from './GeneralReport'

const AuditReport = () => {
	const activeModule = useSelector(TradeDocDuc.selectors.activeModule)

	if (activeModule === 'audit-report') {
		return <AuditReportDoc />
	}
	if (activeModule === 'general-report') {
		return <GeneralReport />
	}

	return <UnexpectedErrorBlock status={401} />
}

export { AuditReport }

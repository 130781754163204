import { intersectFlatArrays } from 'ui-tdm-app/utils/helpers'
import { routePath } from 'ui-lib/utils/routePathConfig'
import { MainRouteDuc } from './duc'
import MillThunk from './thunks/millThunk'
import TDMthunk from './thunks/tradeDocumentManagerThunk'
import feedbackThunk from './thunks/feedbackThunk'
import WeighBridgeThunk from './thunks/weighBridgeThunk'

export default {
	[MainRouteDuc.types.AUTH]: {
		path: routePath.AUTH,
	},
	[MainRouteDuc.types.POLICY]: {
		path: routePath.POLICY,
	},
	[MainRouteDuc.types.ONBOARD]: {
		path: routePath.ONBOARD,
	},
	[MainRouteDuc.types.ONBOARD$ACTION]: {
		path: routePath.ONBOARD$ACTION,
	},
	[MainRouteDuc.types.DASHBOARD]: routePath.DASHBOARD,
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER]: {
		path: routePath.TRADE_DOCUMENT_MANAGER,
		thunk: TDMthunk,
	},
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$SUBROOT]: {
		path: routePath.TRADE_DOCUMENT_MANAGER$SUBROOT,
		thunk: TDMthunk,
	},
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$LISTING]: {
		path: routePath.TRADE_DOCUMENT_MANAGER$LISTING,
		thunk: TDMthunk,
	},
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$WACTION]: {
		path: routePath.TRADE_DOCUMENT_MANAGER$WACTION,
		thunk: TDMthunk,
	},
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE]: {
		path: routePath.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE,
		thunk: TDMthunk,
	},
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE]: {
		path: routePath.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
		thunk: TDMthunk,
	},
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$COMPLETETRADEREPORT]: {
		path: routePath.TRADE_DOCUMENT_MANAGER$COMPLETETRADEREPORT,
		thunk: TDMthunk,
	},

	[MainRouteDuc.types.PRODUCT_TRACE]: {
		path: routePath.PRODUCT_TRACE,
	},
	[MainRouteDuc.types.MESSAGES]: {
		path: routePath.MESSAGES,
	},
	[MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_V1]: {
		path: routePath.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_V1,
	},
	[MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE]: {
		path: routePath.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE,
	},
	[MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP]: {
		path: routePath.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP,
	},
	[MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP_V2]: {
		path: routePath.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP_V2,
	},
	[MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_MAP]: {
		path: routePath.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_MAP,
	},
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$AUDIT_REPORT]: {
		path: routePath.TRADE_DOCUMENT_MANAGER$AUDIT_REPORT,
		thunk: TDMthunk,
	},
	[MainRouteDuc.types.WEIGHBRIDGE]: {
		path: routePath.WEIGHBRIDGE,
		thunk: WeighBridgeThunk,
	},
	[MainRouteDuc.types.WEIGHBRIDGE$WACTION]: {
		path: routePath.WEIGHBRIDGE$WACTION,
		thunk: WeighBridgeThunk,
	},
	[MainRouteDuc.types.WEIGHBRIDGE$DOCUMENTREFERENCE]: {
		path: routePath.WEIGHBRIDGE$DOCUMENTREFERENCE,
		thunk: WeighBridgeThunk,
	},
	[MainRouteDuc.types.PLANTATION]: {
		path: routePath.PLANTATION,
	},
	[MainRouteDuc.types.PLANTATION$SUBROOT]: {
		path: routePath.PLANTATION$SUBROOT,
	},
	[MainRouteDuc.types.PLANTATION$WACTION]: {
		path: routePath.PLANTATION$WACTION,
	},
	[MainRouteDuc.types.PLANTATION$DOCUMENTREFERENCE]: {
		path: routePath.PLANTATION$DOCUMENTREFERENCE,
	},
	[MainRouteDuc.types.PLANTATION$DOCUMENTREFERENCETYPE]: {
		path: routePath.PLANTATION$DOCUMENTREFERENCETYPE,
	},
	[MainRouteDuc.types.MILL]: {
		path: routePath.MILL,
		thunk: MillThunk,
	},
	[MainRouteDuc.types.MILL$SUBROOT]: {
		path: routePath.MILL$SUBROOT,
		thunk: MillThunk,
	},
	[MainRouteDuc.types.MILL$WACTION]: {
		path: routePath.MILL$WACTION,
		thunk: MillThunk,
	},
	[MainRouteDuc.types.MILL$WDOCREFERENCE]: {
		path: routePath.MILL$WDOCREFERENCE,
		thunk: MillThunk,
	},
	[MainRouteDuc.types.MILL$LISTING]: {
		path: routePath.MILL$LISTING,
		thunk: MillThunk,
	},
	[MainRouteDuc.types.REFINERY]: {
		path: routePath.REFINERY,
	},
	[MainRouteDuc.types.REFINERY$SUBROOT]: {
		path: routePath.REFINERY$SUBROOT,
	},
	[MainRouteDuc.types.REFINERY$WACTION]: {
		path: routePath.REFINERY$WACTION,
	},
	[MainRouteDuc.types.REFINERY$WDOCREFERENCE]: {
		path: routePath.REFINERY$WDOCREFERENCE,
	},
	[MainRouteDuc.types.REFINERY$LISTING]: {
		path: routePath.REFINERY$LISTING,
	},
	[MainRouteDuc.types.ADMIN]: {
		path: routePath.ADMIN,
	},
	[MainRouteDuc.types.ADMIN$SUBROOT]: {
		path: routePath.ADMIN$SUBROOT,
	},
	[MainRouteDuc.types.ADMIN$WACTION]: {
		path: routePath.ADMIN$WACTION,
	},
	[MainRouteDuc.types.ADMIN$SUBMODULEWACTION]: {
		path: routePath.ADMIN$SUBMODULEWACTION,
	},
	[MainRouteDuc.types.ADMIN$WDOCREFERENCE]: {
		path: routePath.ADMIN$WDOCREFERENCE,
	},
	[MainRouteDuc.types.ADMIN$SUBMODULEWDOCREFERENCE]: {
		path: routePath.ADMIN$SUBMODULEWDOCREFERENCE,
	},
	[MainRouteDuc.types.PARTNER]: {
		path: routePath.PARTNER,
	},
	[MainRouteDuc.types.MARKETPLACE]: {
		path: routePath.MARKETPLACE,
	},
	[MainRouteDuc.types.MARKETPLACE$ACTION]: {
		path: routePath.MARKETPLACE$ACTION,
	},
	[MainRouteDuc.types.MARKETPLACE$CONTRACTS]: {
		path: routePath.MARKETPLACE$CONTRACTS,
	},
	[MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE]: {
		path: routePath.MARKETPLACE$CONTRACTSWDOCTYPE,
	},
	[MainRouteDuc.types.MARKETPLACE$ACTIONSTATUS]: {
		path: routePath.MARKETPLACE$ACTIONSTATUS,
	},
	[MainRouteDuc.types.PARTNER$ACTION]: {
		path: routePath.PARTNER$ACTION,
	},
	[MainRouteDuc.types.PARTNER$WREQREFERENCE]: {
		path: routePath.PARTNER$WREQREFERENCE,
	},
	[MainRouteDuc.types.SETTINGS]: {
		path: routePath.SETTINGS,
	},
	[MainRouteDuc.types.SETTINGS$SUBROOT]: {
		path: routePath.SETTINGS$SUBROOT,
	},
	[MainRouteDuc.types.FEEDBACK]: {
		path: routePath.FEEDBACK,
		thunk: feedbackThunk,
	},
	[MainRouteDuc.types.STORAGE_COMPANY]: {
		path: routePath.STORAGE_COMPANY,
	},
	[MainRouteDuc.types.STORAGE_COMPANY$LISTING]: {
		path: routePath.STORAGE_COMPANY$LISTING,
	},
	[MainRouteDuc.types.STORAGE_COMPANY$SUBMODULELISTING]: {
		path: routePath.STORAGE_COMPANY$SUBMODULELISTING,
	},
	[MainRouteDuc.types.STORAGE_COMPANY$SUBROOT]: {
		path: routePath.STORAGE_COMPANY$SUBROOT,
	},
	[MainRouteDuc.types.TRADER]: {
		path: routePath.TRADER,
	},
	[MainRouteDuc.types.END_MANUFACTURER]: {
		path: routePath.END_MANUFACTURER,
	},
	[MainRouteDuc.types.DEALER]: {
		path: routePath.DEALER,
	},
	[MainRouteDuc.types.DEALER$SUBROOT]: {
		path: routePath.DEALER$SUBROOT,
	},
	[MainRouteDuc.types.DEALER$WACTION]: {
		path: routePath.DEALER$WACTION,
	},
	[MainRouteDuc.types.DEALER$WDOCREFERENCE]: {
		path: routePath.DEALER$WDOCREFERENCE,
	},
	[MainRouteDuc.types.TRIPS]: {
		path: routePath.TRIPS,
	},
	[MainRouteDuc.types.TRIPS$WDOCREFERENCE]: {
		path: routePath.TRIPS$WDOCREFERENCE,
	},
	[MainRouteDuc.types.WBTICKET]: {
		path: routePath.WBTICKET,
	},
	[MainRouteDuc.types.WBTICKET$WACTION]: {
		path: routePath.WBTICKET$WACTION,
	},
	[MainRouteDuc.types.WBTICKET$WDOCREFERENCE]: {
		path: routePath.WBTICKET$WDOCREFERENCE,
	},
	[MainRouteDuc.types.GENERAL_SCHEDULER]: {
		path: routePath.GENERAL_SCHEDULER,
	},
	[MainRouteDuc.types.GENERAL_SCHEDULER$WDOCREFERENCE]: {
		path: routePath.GENERAL_SCHEDULER$WDOCREFERENCE,
	},
	[MainRouteDuc.types.DEALER]: {
		path: routePath.DEALER,
	},
	[MainRouteDuc.types.DEALER$SUBROOT]: {
		path: routePath.DEALER$SUBROOT,
	},
	[MainRouteDuc.types.WEBTOUR]: {
		path: routePath.WEBTOUR,
	},
	[MainRouteDuc.types.WEBTOUR$SUBROOT]: {
		path: routePath.WEBTOUR$SUBROOT,
	},
	[MainRouteDuc.types.PRODUCTS]: {
		path: routePath.PRODUCTS,
	},
	[MainRouteDuc.types.PRODUCTS$WACTION]: {
		path: routePath.PRODUCTS$WACTION,
	},
	[MainRouteDuc.types.PRODUCTS$WDOCREFERENCE]: {
		path: routePath.PRODUCTS$WDOCREFERENCE,
	},
}

export const defaultAccessGroup = ['fe.core.minimal.r']

export const featureAccessBasedOnType = {
	[MainRouteDuc.types.DASHBOARD]: ['fe.core.dashboard.r'],
	[MainRouteDuc.types.ONBOARD]: ['iam.clients.access.r'],
	[MainRouteDuc.types.POLICY]: ['iam.clients.access.r'],
	[MainRouteDuc.types.ONBOARD$ACTION]: ['iam.clients.access.r'],
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER]: ['fe.tdm.dashboard.r'],
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$SUBROOT]: [
		'fe.tdm.incoming.r',
		'fe.tdm.outgoing.r',
	],
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$LISTING]: [
		'fe.tdm.purchase-order.r',
		'fe.tdm.invoice.r',
		'fe.tdm.delivery-order.r',
		'fe.tdm.weight-bridge-slip.r',
	],
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE]: [
		'fe.tdm.purchase-order.r',
		'fe.tdm.invoice.r',
		'fe.tdm.delivery-order.r',
		'fe.tdm.weight-bridge-slip.r',
	],
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE]: [
		'fe.tdm.purchase-order.r',
		'fe.tdm.invoice.r',
		'fe.tdm.delivery-order.r',
		'fe.tdm.weight-bridge-slip.r',
	],
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$WACTION]: [
		'fe.tdm.purchase-order.r',
		'fe.tdm.invoice.r',
		'fe.tdm.delivery-order.r',
		'fe.tdm.weight-bridge-slip.r',
	],
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$COMPLETETRADEREPORT]: [
		'fe.tdm.purchase-order.r',
		'fe.tdm.invoice.r',
		'fe.tdm.delivery-order.r',
		'fe.tdm.weight-bridge-slip.r',
	],

	[MainRouteDuc.types.PRODUCT_TRACE]: ['fe.tdm.trace.r'],
	[MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_V1]: [
		'fe.tdm.trace.r',
	],
	[MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE]: [
		'fe.tdm.trace.r',
	],
	[MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP]: [
		'fe.tdm.trace.r',
	],
	[MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP_V2]: [
		'fe.tdm.trace.r',
	],
	[MainRouteDuc.types.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_MAP]: [
		'fe.tdm.trace.r',
	],
	[MainRouteDuc.types.MESSAGES]: ['fe.messages.dashboard.r'],
	[MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$AUDIT_REPORT]: [
		'fe.tdm.audit-report.r',
		'fe.dealer.general-report.r',
	],
	[MainRouteDuc.types.WEIGHBRIDGE]: ['fe.tdm.weight-bridge-slip.r'],
	[MainRouteDuc.types.WEIGHBRIDGE$WACTION]: ['fe.tdm.weight-bridge-slip.r'],
	[MainRouteDuc.types.WEIGHBRIDGE$DOCUMENTREFERENCE]: [
		'fe.tdm.weight-bridge-slip.r',
	],

	[MainRouteDuc.types.PLANTATION]: ['fe.plantation.dashboard.r'],
	[MainRouteDuc.types.PLANTATION$SUBROOT]: [
		'fe.plantation.tickets.r',
		'fe.plantation.bins.r',
		'fe.sidebar.plantation-records.r',
	],
	[MainRouteDuc.types.PLANTATION$WACTION]: [
		'fe.plantation.tickets.c',
		'fe.plantation.bins.c',
		'fe.plantation.tickets.r',
		'fe.plantation.bins.r',
	],
	[MainRouteDuc.types.PLANTATION$DOCUMENTREFERENCE]: [
		'fe.plantation.tickets.c',
		'fe.plantation.tickets.r',
		'fe.plantation.tickets.u',
		'fe.plantation.bins.c',
		'fe.plantation.bins.r',
		'fe.plantation.bins.u',
	],
	[MainRouteDuc.types.PLANTATION$DOCUMENTREFERENCETYPE]: [
		'fe.plantation.records.r',
	],
	[MainRouteDuc.types.MILL]: ['fe.mill.dashboard.r'],
	[MainRouteDuc.types.MILL$SUBROOT]: [
		'fe.mill.quality-cpo.r',
		'fe.mill.quality-ffb.r',
		'fe.mill.production-plot-input.r',
		'fe.mill.production-plot-output.r',
	],
	[MainRouteDuc.types.MILL$WACTION]: [
		'fe.mill.quality-cpo.r',
		'fe.mill.quality-ffb.r',
		'fe.mill.production-plot-input.r',
		'fe.mill.production-plot-output.r',
	],
	[MainRouteDuc.types.MILL$WDOCREFERENCE]: [
		'fe.mill.quality-cpo.r',
		'fe.mill.quality-ffb.r',
		'fe.mill.production-plot-input.r',
		'fe.mill.production-plot-output.r',
	],
	[MainRouteDuc.types.MILL$LISTING]: [
		'fe.mill.quality-cpo.r',
		'fe.mill.quality-ffb.r',
		'fe.mill.production-plot-input.r',
		'fe.mill.production-plot-output.r',
	],
	[MainRouteDuc.types.REFINERY]: ['fe.refinery.dashboard.r'],
	[MainRouteDuc.types.REFINERY$SUBROOT]: [
		'fe.refinery.production-plot-input.r',
		'fe.refinery.production-plot-output.r',
	],
	[MainRouteDuc.types.REFINERY$WACTION]: [
		'fe.refinery.production-plot-input.r',
		'fe.refinery.production-plot-input.c',
		'fe.refinery.production-plot-output.r',
		'fe.refinery.production-plot-output.c',
	],
	[MainRouteDuc.types.REFINERY$WDOCREFERENCE]: [
		'fe.refinery.production-plot-input.r',
		'fe.refinery.production-plot-input.c',
		'fe.refinery.production-plot-output.r',
		'fe.refinery.production-plot-output.c',
	],
	[MainRouteDuc.types.REFINERY$LISTING]: [
		'fe.mill.quality-cpo.r',
		'fe.mill.quality-ffb.r',
		'fe.refinery.production-plot-input.r',
		'fe.refinery.production-plot-output.r',
	],
	[MainRouteDuc.types.ADMIN]: ['fe.admin.dashboard.r'],
	[MainRouteDuc.types.ADMIN$SUBROOT]: [
		'fe.admin.assets.r',
		'fe.admin.employee-management.r',
		'fe.admin.company-information.r',
	],
	[MainRouteDuc.types.ADMIN$WACTION]: [
		'fe.admin.assets.r',
		'fe.admin.employee-management.r',
		'fe.admin.company-information.r',
	],
	[MainRouteDuc.types.ADMIN$SUBMODULEWACTION]: [
		'fe.admin.assets.r',
		'fe.admin.employee-management.r',
		'fe.admin.company-information.r',
	],
	[MainRouteDuc.types.ADMIN$WDOCREFERENCE]: [
		'fe.admin.assets.r',
		'fe.admin.employee-management.r',
		'fe.admin.company-information.r',
	],
	[MainRouteDuc.types.ADMIN$SUBMODULEWDOCREFERENCE]: [
		'fe.admin.assets.r',
		'fe.admin.employee-management.r',
		'fe.admin.company-information.r',
	],
	[MainRouteDuc.types.PARTNER]: ['fe.partner.dashboard.r'],
	[MainRouteDuc.types.PARTNER$ACTION]: ['fe.partner.management.r'],
	[MainRouteDuc.types.PARTNER$WREQREFERENCE]: ['fe.partner.management.r'],
	[MainRouteDuc.types.SETTINGS]: ['fe.settings.dashboard.r'],
	[MainRouteDuc.types.FEEDBACK]: ['fe.tdm.delivery-order.r'],
	[MainRouteDuc.types.SETTINGS$SUBROOT]: [
		'fe.settings.change-password.r',
		'fe.settings.change-language.r',
		'fe.settings.notifications.r',
	],
	[MainRouteDuc.types.STORAGE_COMPANY]: ['fe.storage-company.dashboard.r'],
	[MainRouteDuc.types.WEBTOUR]: ['fe.storage-company.dashboard.r'],
	[MainRouteDuc.types.STORAGE_COMPANY$LISTING]: [
		'fe.storage-company.dashboard.r',
	],
	[MainRouteDuc.types.STORAGE_COMPANY$SUBMODULELISTING]: [
		'fe.storage-company.dashboard.r',
	],
	[MainRouteDuc.types.STORAGE_COMPANY$SUBROOT]: [
		'fe.storage-company.dashboard.r',
	],
	[MainRouteDuc.types.TRADER]: ['fe.trader.dashboard.r'],
	[MainRouteDuc.types.END_MANUFACTURER]: ['fe.end-manufacturer.dashboard.r'],
	[MainRouteDuc.types.INVENTORY]: ['fe.inventory.dashboard.r'],
	[MainRouteDuc.types.INVENTORY$ACTION]: ['fe.inventory.dashboard.r'],
	[MainRouteDuc.types.INVENTORY$WDOCREFERENCE]: ['fe.inventory.dashboard.r'],
	[MainRouteDuc.types.DEALER]: ['fe.dealer.dashboard.r'],
	[MainRouteDuc.types.DEALER$SUBROOT]: ['fe.dealer.work.r'],
	[MainRouteDuc.types.DEALER$WACTION]: ['fe.dealer.work.r'],
	[MainRouteDuc.types.DEALER$WDOCREFERENCE]: ['fe.dealer.work.r'],
	[MainRouteDuc.types.TRIPS]: ['fe.dealer.trips.r'],
	[MainRouteDuc.types.TRIPS$WDOCREFERENCE]: ['fe.dealer.trips.r'],
	[MainRouteDuc.types.WBTICKET]: ['fe.dealer.weighbridge-slip.r'],
	[MainRouteDuc.types.WBTICKET$WDOCREFERENCE]: [
		'fe.dealer.weighbridge-slip.r',
	],
	[MainRouteDuc.types.WBTICKET$WACTION]: ['fe.dealer.weighbridge-slip.r'],
	[MainRouteDuc.types.GENERAL_SCHEDULER]: ['fe.general-scheduler.listing.r'],
	[MainRouteDuc.types.GENERAL_SCHEDULER$WDOCREFERENCE]: [
		'fe.general-scheduler.listing.r',
	],
	[MainRouteDuc.types.PRODUCTS]: ['fe.products.listing.r'],
	[MainRouteDuc.types.PRODUCTS$WACTION]: ['fe.products.listing.r'],
	[MainRouteDuc.types.PRODUCTS$WDOCREFERENCE]: ['fe.products.listing.r'],
	[MainRouteDuc.types.MARKETPLACE]: ['fe.marketplace.dashboard.r'],
	[MainRouteDuc.types.MARKETPLACE$ACTION]: ['fe.marketplace.dashboard.r'],
	[MainRouteDuc.types.MARKETPLACE$CONTRACTS]: ['fe.marketplace.dashboard.r'],
	[MainRouteDuc.types.MARKETPLACE$CONTRACTSWDOCTYPE]: [
		'fe.marketplace.dashboard.r',
	],
	[MainRouteDuc.types.MARKETPLACE$ACTIONSTATUS]: [
		'fe.marketplace.dashboard.r',
	],
}

export const navigationBaseReference = [
	{
		title: 'sideBar.home',
		icon: 'home',
		action: MainRouteDuc.types.PLANTATION,
		featureAccess: ['fe.sidebar.plantation-dashboard.r'],
	},
	{
		title: 'sideBar.home',
		icon: 'home',
		action: MainRouteDuc.types.REFINERY,
		featureAccess: ['fe.sidebar.oleochemicals-dashboard.r'],
	},
	{
		title: 'sideBar.home',
		icon: 'home',
		action: MainRouteDuc.types.MILL,
		featureAccess: ['fe.sidebar.mill-dashboard.r'],
	},
	{
		title: 'sideBar.home',
		icon: 'home',
		action: MainRouteDuc.types.REFINERY,
		featureAccess: ['fe.sidebar.refinery-dashboard.r'],
	},
	{
		title: 'sideBar.home',
		icon: 'home',
		action: MainRouteDuc.types.STORAGE_COMPANY,
		featureAccess: ['fe.sidebar.storage-company-dashboard.r'],
	},
	{
		icon: 'incoming',
		title: 'sideBar.storage',
		action: MainRouteDuc.types.STORAGE_COMPANY$LISTING,
		featureAccess: ['fe.sidebar.storage-company-listing.r'],
	},
	{
		title: 'sideBar.home',
		icon: 'home',
		action: MainRouteDuc.types.TRADER,
		featureAccess: ['fe.sidebar.trader-dashboard.r'],
	},
	{
		title: 'sideBar.home',
		icon: 'home',
		action: MainRouteDuc.types.END_MANUFACTURER,
		featureAccess: ['fe.sidebar.end-manufacturer-dashboard.r'],
	},
	{
		title: 'sideBar.home',
		icon: 'home',
		action: MainRouteDuc.types.DEALER,
		featureAccess: ['fe.sidebar.dealer-dashboard.r'],
		subRoutes: [
			{
				icon: 'work',
				title: 'sideBar.work',
				action: MainRouteDuc.types.DEALER$SUBROOT,
				featureAccess: ['fe.sidebar.dealer-work-listing.r'],
				payload: { rootModule: 'work' },
			},
		],
	},
	{
		title: 'sideBar.marketPlace',
		icon: 'marketPlace',
		action: MainRouteDuc.types.MARKETPLACE,
		featureAccess: ['fe.sidebar.marketplace-owner.r'],
		payload: { rootModule: 'buyer' },
		subRoutes: [
			{
				icon: 'marketPlaceBuy',
				title: 'sideBar.buy',
				action: MainRouteDuc.types.MARKETPLACE,
				featureAccess: ['fe.sidebar.marketplace-owner-buyer.r'],
				payload: { rootModule: 'buyer' },
			},
			{
				icon: 'marketPlaceSell',
				title: 'sideBar.sell',
				action: MainRouteDuc.types.MARKETPLACE,
				featureAccess: ['fe.sidebar.marketplace-owner-seller.r'],
				payload: { rootModule: 'seller' },
			},
		],
	},
	{
		icon: 'marketPlaceBuy',
		title: 'sideBar.createRFQ',
		action: MainRouteDuc.types.MARKETPLACE,
		featureAccess: ['fe.sidebar.demo-tdm.r'],
		payload: { rootModule: 'buyer' },
	},
	{
		icon: 'marketPlaceBuy',
		title: 'sideBar.marketPlace',
		action: MainRouteDuc.types.MARKETPLACE,
		featureAccess: ['fe.sidebar.marketplace-buyer.r'],
		payload: { rootModule: 'buyer' },
	},
	{
		icon: 'marketPlaceSell',
		title: 'sideBar.marketPlace',
		action: MainRouteDuc.types.MARKETPLACE,
		featureAccess: ['fe.sidebar.marketplace-seller.r'],
		payload: { rootModule: 'seller' },
	},
	{
		title: 'sideBar.tdm',
		icon: 'trade',
		action: MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
		featureAccess: ['fe.sidebar.trade-document-manager.r'],
		payload: { rootModule: 'incoming' },
		subRoutes: [
			{
				icon: 'incoming',
				title: 'sideBar.incoming',
				action: MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				featureAccess: ['fe.sidebar.tdm-incoming.r'],
				payload: { rootModule: 'incoming' },
			},
			{
				icon: 'outgoing',
				title: 'sideBar.outgoing',
				action: MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				featureAccess: ['fe.sidebar.tdm-outgoing.r'],
				payload: { rootModule: 'outgoing' },
			},
		],
	},
	{
		title: 'sideBar.messages',
		icon: 'feedback',
		action: MainRouteDuc.types.MESSAGES,
		featureAccess: ['fe.sidebar.messages.r'],
	},
	{
		title: 'sideBar.scheduler',
		icon: 'scheduler',
		action: MainRouteDuc.types.GENERAL_SCHEDULER,
		featureAccess: ['fe.sidebar.general-scheduler.r'],
	},
	{
		title: 'sideBar.trips',
		icon: 'trips',
		action: MainRouteDuc.types.TRIPS,
		featureAccess: ['fe.sidebar.trips-listing.r'],
	},
	{
		title: 'sideBar.wbTickets',
		icon: 'receipt',
		action: MainRouteDuc.types.WBTICKET,
		featureAccess: ['fe.sidebar.dealer-wbtickets.r'],
	},
	{
		title: 'sideBar.productTrace',
		icon: 'trust',
		action: MainRouteDuc.types.PRODUCT_TRACE,
		featureAccess: ['fe.sidebar.product-trace.r'],
	},
	{
		title: 'sideBar.production',
		icon: 'production',
		action: MainRouteDuc.types.REFINERY$SUBROOT,
		payload: { rootModule: 'production' },
		featureAccess: ['fe.sidebar.refinery-production.r'],
	},
	{
		title: 'sideBar.production',
		icon: 'production',
		action: MainRouteDuc.types.MILL$SUBROOT,
		payload: { rootModule: 'production' },
		featureAccess: ['fe.sidebar.mill-production.r'],
	},
	{
		title: 'sideBar.records',
		icon: 'records',
		action: MainRouteDuc.types.PLANTATION$SUBROOT,
		payload: { rootModule: 'document' },
		featureAccess: ['fe.sidebar.plantation-records.r'],
	},
	{
		title: 'sideBar.products',
		icon: 'products',
		action: MainRouteDuc.types.PRODUCTS,
		featureAccess: ['fe.sidebar.products.r'],
	},

	{
		title: 'sideBar.partnetMgnt',
		icon: 'partner',
		action: MainRouteDuc.types.PARTNER,
		featureAccess: ['fe.sidebar.partner-management.r'],
	},

	{
		title: 'sideBar.auditReports',
		icon: 'graphUp',
		action: MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$AUDIT_REPORT,
		featureAccess: ['fe.sidebar.tdm-audit-report.r'],
		payload: { rootModule: 'audit-report' },
	},
	{
		title: 'sideBar.administrator',
		icon: 'admin',
		action: MainRouteDuc.types.ADMIN,
		featureAccess: ['fe.sidebar.admin-dashboard.r'],
	},
]

/** The hierarchy of dashboard where the user should land based on his access
 *  We could get the backend control it later on too.
 *
 */
export const DASHBOARD_ACCESS_HIERARCHY = [
	MainRouteDuc.types.PLANTATION,
	MainRouteDuc.types.MILL,
	MainRouteDuc.types.REFINERY,
	MainRouteDuc.types.STORAGE_COMPANY,
	MainRouteDuc.types.TRADER,
	MainRouteDuc.types.END_MANUFACTURER,
	MainRouteDuc.types.DEALER,
	MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
]

export const getActiveUserDashboardAction = (
	roles = [],
	payload = {},
	redirect = true
) => {
	const activeCreator = redirect
		? MainRouteDuc.creators.redirect
		: MainRouteDuc.creators.switchPage

	// if user has access to main dashboard don't work going further
	if (
		intersectFlatArrays(
			featureAccessBasedOnType[MainRouteDuc.types.DASHBOARD],
			roles
		).length
	) {
		return activeCreator(MainRouteDuc.types.DASHBOARD, payload)
	}

	const targetAction = DASHBOARD_ACCESS_HIERARCHY.find(type => {
		const actionRoles = featureAccessBasedOnType[type] || []

		const matchingRoles = intersectFlatArrays(actionRoles, roles)

		return !!matchingRoles.length
	})

	return activeCreator(targetAction, payload)
}

import Duck from 'extensible-duck'
import { setIn, getIn, merge, addLast, omit } from 'timm'
import { createSelector } from 'reselect'
import { isIframeForTeamsApp } from 'ui-lib/utils/helpers'
import {
	initialState,
	INITIAL_TYPES,
	STATS_TYPE_ALIAS,
	DASHBOARD_STATS_ALIASES,
	plantationDocSources,
	TABS_CONFIG,
	TEAMS_APP_TABS,
} from './config'

const tdmTabConfig = isIframeForTeamsApp() ? TEAMS_APP_TABS : TABS_CONFIG

const activeSections = (rootModule = '', parentDocType = '') => {
	let allSections = ['meta', 'shipping', 'products', 'additionalInfo']

	if (['delivery-order'].includes(rootModule)) {
		allSections = addLast(allSections, 'supplyChainModel')

		if (plantationDocSources.includes(parentDocType)) {
			allSections = addLast(allSections, 'plantation')
		}
	}

	if (rootModule === 'invoice') {
		allSections = addLast(allSections, 'bankDetails')
	}

	return allSections
}

export const TradeDocDuc = new Duck({
	namespace: 'trade-docs',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_DASHBOARD_STATS',
		'FETCH_DASHBOARD_STATS_SUCCESS',
		'FETCH_DASHBOARD_DOCUMENTS',
		'FETCH_DASHBOARD_DOCUMENTS_SUCCESS',
		'FETCH_DASHBOARD_LISTING',
		'FETCH_DASHBOARD_LISTING_SUCCESS',
		'FETCH_DASHBOARD_LISTING_LOADING',
		'TDM_LOADING_STATUS',
		'SET_ACTIVE_SORTS',
		'SET_ACTIVE_LISTING_SORTS',
		'SET_ACTIVE_LISTING_FILTERS',
		'SET_DASHBOARD_LOADING_STATUS',
		'RESET_DASHBOARD_LOADING_STATUS',
		'CHANGE_DOC_STATUS',
		'SET_MODULE_AS_ERRORED',
		'SET_PAGINATION_ENTRIES',
		'SET_TRACE_PAGINATION_ENTRIES',
		'FETCH_CURRENT_ACTIVE_DOCUMENT',
		'INITIATE_DOCUMENT_STEP',
		'FETCH_DOCUMENT_ATTACHMENTS',
		'INITIATE_DOCUMENT_VIEW',
		'SET_ACTIVE_DOCUMENT_IN_STORE',
		'SET_ACTIVE_BASE_PRODUCTS',
		'DOCUMENT_CREATION_LOADING',
		'DOCUMENT_CREATION_ACTIVE_PARTNER',
		'FETCH_PRODUCTS',
		'FETCH_PRODUCTS_STATUS',
		'FETCH_PRODUCT',
		'FETCH_PRODUCT_SUCCESS',
		'INITIATE_DOCUMENT_SAVE',
		'DOCUMENT_UPDATE_PARTICPANTS',
		'DOCUMENT_FETCH_PARENT',
		'DOCUMENT_BASE_ACTIVE_SECTIONS',
		'DOCUMENT_INITIATE_ACTIVE_ACTION',
		'DOCUMENT_FLUSH_STATE',
		'ADD_NEW_REMARK',
		'FETCH_TIMELINE_DATA',
		'ADD_REMARKS_IN_ACTIVE_DOCUMENT',
		'SET_UPLOAD_DO_ACCEPT_LIST',
		'SET_UPLOAD_DO_DRAFT_ACCEPT_LIST',
		'SET_DO_ACCEPT_ALL_LOADING',
		'SET_DO_DRAFT_ACCEPT_ALL_LOADING',
		'SET_TIMELINE_DATA',
		'SET_ACTIVE_TDM_RULES',
		'GET_ACTIVE_TDM_RULES',
		'FETCH_PRODUCT_TRACE_LISTING',
		'SET_TRACE_LISTING_STATUS',
		'SET_TRACE_LISTING_PRODUCTID',
		'HANDLE_PRODUCT_SELECTION_MODAL',
		'PRODUCT_TRACE_DOCUMENT_FLUSH_STATE',
		'SET_ACTIVE_PRODUCT_LISTING',
		'FETCH_AUDIT_REPORT',
		'FETCH_AUDIT_REPORT_SUCCESS',
		'SET_AUDIT_ACTIVE_FILTERS',
		'SET_AUDIT_ACTIVE_SORT',
		'SET_AUDIT_PAGINATION_ENTRIES',
		'APPEND_AUDIT_REPORT',
		'FETCH_AUDIT_REPORT_LISTING',
		'AUDIT_REPORT_FLUSH_STATE',
		'SET_BACKWARD_TRACE',
		'GET_BACKWARD_TRACE',
		'SET_DOCUMENT_TRACE',
		'SET_ATTACHMENT_DOCUMENTS',
		'SET_ACTIVE_PRODUCT_ID',
		'SET_ACTIVE_INCOMING_DOCUMENTS',
		'SET_ACTIVE_OUTGOING_DOCUMENTS',
		'SET_ACTIVE_ASSOCIATED_DOC',
		'FETCH_DEALER_DASHBOARD_REPORTS',
		'SET_DEALER_DASHBOARD_REPORTS',
		'SET_DOCUMENT_ACTIVE_TAB',
		'SET_LISTING_ACTIVE_TAB',
		'DOCUMENT_LISTING_FLUSH_STATE',
		'CREATE_ENTITY_ATTACHMENT',
		'UPDATE_ENTITY_ATTACHMENT',
		'DELETE_ENTITY_ATTACHMENT',
		'DELETE_DRAFT_ENTITY',
		'FETCH_PRODUCT_DETAILS_BASED_ON_SUPPLY_CHAIN_MODEL',
		'SET_PRODUCT_DETAILS_BASED_ON_SUPPLY_CHAIN_MODEL',
		'FLUSH_PRODUCT_DETAILS_BASED_ON_SUPPLY_CHAIN_MODEL',
		'SEARCH_ENTITY_REF',
		'SET_ENTITY_REF_DETAILS',
		'SET_STORAGE_TANK_LOADING_STATUS',
		'SET_ENTITY_RECEIVER_TRACE_IDS',
		'GET_HEDERA_MESSAGES',
		'SET_HEDERA_MESSAGES',
		'SET_CERTIFICATE_DETAILS',
		'CHECK_IF_SUPPLYCHAIN_IS_ENFORCED',
		'IS_SUPPLYCHAINMODEL_ENFORCED',
		'CREATE_WB_SLIP',
		'UPLOAD_DOCUMENT_FILE',
		'FETCH_MIRROR_NODE',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_TDM_RULES: {
				const { modules, types } = action

				return setIn(state, ['rules'], {
					activeModules: modules,
					allowedTypes: types,
				})
			}
			case duck.types.TDM_LOADING_STATUS: {
				const { status } = action

				return setIn(state, ['loading'], status)
			}
			case duck.types.SET_MODULE_AS_ERRORED:
				return setIn(state, ['error'], action.status)

			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.FETCH_DASHBOARD_DOCUMENTS_SUCCESS: {
				const { activeDocuments, module: _module } = action
				const root = ['modules', 'mainDashboard', 'activeDocuments']
				const currentDocs = getIn(state, [...root, _module]) || {}

				return setIn(
					state,
					[...root, _module],
					merge(currentDocs, activeDocuments)
				)
			}

			case duck.types.SET_ACTIVE_SORTS: {
				const { sortMap } = action

				let incoming = {}
				;(sortMap.incoming || []).forEach(key => {
					const arr = key.split('|')

					const lastVal = arr.pop()

					incoming = setIn(incoming, arr, lastVal)
				})
				let outgoing = {}
				;(sortMap.outgoing || []).forEach(key => {
					const arr = key.split('|')

					const lastVal = arr.pop()

					outgoing = setIn(outgoing, arr, lastVal)
				})

				const newState = setIn(
					state,
					['modules', 'mainDashboard', 'activeSorts', 'incoming'],
					incoming
				)

				return setIn(
					newState,
					['modules', 'mainDashboard', 'activeSorts', 'outgoing'],
					outgoing
				)
			}

			case duck.types.SET_ACTIVE_INCOMING_DOCUMENTS: {
				const { tab } = action

				return setIn(
					state,
					['modules', 'mainDashboard', 'activeTab', 'incoming'],
					tab
				)
			}
			case duck.types.SET_DOCUMENT_ACTIVE_TAB: {
				const { tab } = action

				return setIn(state, ['modules', 'document', 'activeTab'], tab)
			}

			case duck.types.SET_ACTIVE_OUTGOING_DOCUMENTS: {
				const { tab } = action

				return setIn(
					state,
					['modules', 'mainDashboard', 'activeTab', 'outgoing'],
					tab
				)
			}

			case duck.types.SET_ACTIVE_LISTING_SORTS: {
				const { sortMap } = action

				let sorts = {}
				;(sortMap.sort || []).forEach(key => {
					const arr = key.split('|')
					arr.shift() // remove the first fragment since type is already decided
					const lastVal = arr.pop()

					sorts = setIn(sorts, arr, lastVal)
				})

				return setIn(
					state,
					['modules', 'listing', 'activeSorts'],
					sorts
				)
			}

			case duck.types.SET_ACTIVE_LISTING_FILTERS: {
				const { filters = {} } = action

				return setIn(
					state,
					['modules', 'listing', 'activeFilters'],
					filters
				)
			}

			case duck.types.SET_ENTITY_REF_DETAILS: {
				const { entityList } = action

				return setIn(
					state,
					['modules', 'listing', 'entityList'],
					entityList
				)
			}

			case duck.types.LOGIN: {
				return state
			}

			case duck.types.SET_DASHBOARD_LOADING_STATUS: {
				const { root, _modules } = action
				const rootPath = ['modules', 'mainDashboard', 'loading']

				return setIn(state, [...rootPath, root], _modules)
			}

			case duck.types.RESET_DASHBOARD_LOADING_STATUS: {
				const rootPath = ['modules', 'mainDashboard', 'loading']
				const newState = setIn(state, [...rootPath, 'incoming'], [])

				return setIn(newState, [...rootPath, 'outgoing'], [])
			}

			case duck.types.FETCH_DASHBOARD_STATS_SUCCESS: {
				const { stats = {}, rootModule } = action
				const rootPath = ['modules', 'listing']
				const allowedTypes =
					getIn(state, ['rules', 'allowedTypes']) || []

				const mappedStats = Object.keys(stats).reduce((agg, key) => {
					const aggregator = agg

					const targetKey = STATS_TYPE_ALIAS[key] || key
					if (allowedTypes.includes(targetKey)) {
						const _stats = stats[key] || {}
						aggregator[targetKey] = Object.keys(_stats).reduce(
							(_agg, _key) => {
								const _aggregator = _agg
								_aggregator[
									DASHBOARD_STATS_ALIASES[_key] || _key
								] = _stats[_key]

								return _aggregator
							},
							{}
						)
					}

					return aggregator
				}, {})

				return setIn(
					state,
					[...rootPath, 'status', rootModule],
					mappedStats
				)
			}

			case duck.types.FETCH_DASHBOARD_LISTING_SUCCESS: {
				const { activeDocuments } = action

				return setIn(
					state,
					['modules', 'listing', 'activeDocuments'],
					activeDocuments
				)
			}

			case duck.types.FETCH_DASHBOARD_LISTING_LOADING: {
				return setIn(
					state,
					['modules', 'listing', 'loading'],
					action.status || false
				)
			}

			case duck.types.SET_PAGINATION_ENTRIES: {
				const {
					activeIndex,
					limit,
					total,
					nextCursor,
					currentCursor,
				} = action
				const root = ['modules', 'listing', 'pagination']
				let nextState = state

				nextState = setIn(
					nextState,
					[...root, 'activeIndex'],
					activeIndex || 0
				)

				if (limit)
					nextState = setIn(nextState, [...root, 'limit'], limit)

				if (total)
					nextState = setIn(nextState, [...root, 'total'], total)

				if (nextCursor)
					nextState = setIn(
						nextState,
						[...root, 'nextCursor'],
						nextCursor
					)

				if (currentCursor)
					nextState = setIn(
						nextState,
						[...root, 'currentCursor'],
						currentCursor
					)

				return nextState
			}

			case duck.types.SET_TRACE_PAGINATION_ENTRIES: {
				const {
					activeIndex,
					limit,
					total,
					nextCursor,
					currentCursor,
				} = action
				const root = ['modules', 'productTrace', 'pagination']
				let nextState = state

				nextState = setIn(
					nextState,
					[...root, 'activeIndex'],
					activeIndex || 0
				)

				if (limit)
					nextState = setIn(nextState, [...root, 'limit'], limit)

				if (total)
					nextState = setIn(nextState, [...root, 'total'], total)

				if (nextCursor)
					nextState = setIn(
						nextState,
						[...root, 'nextCursor'],
						nextCursor
					)

				if (currentCursor)
					nextState = setIn(
						nextState,
						[...root, 'currentCursor'],
						currentCursor
					)

				return nextState
			}

			case duck.types.TRACE_LISTING_PAGINATION_MAP: {
				const { sortsMap } = action
				let sorts = {}
				;(sortsMap.sort || []).forEach(key => {
					const arr = key.split('|')
					arr.shift() // remove the first fragment since type is already decided
					const lastVal = arr.pop()

					sorts = setIn(sorts, arr, lastVal)
				})

				return setIn(
					state,
					['modules', 'productTrace', 'activeSorts'],
					sorts
				)
			}

			case duck.types.DOCUMENT_CREATION_LOADING: {
				const rootKey = ['modules', 'document']
				const currentState = getIn(state, rootKey) || {}

				return setIn(
					state,
					rootKey,
					merge(currentState, {
						loading: action.isLoading || false,
						isSaving: action.isSaving || false,
						hasError: action.hasError || false,
					})
				)
			}

			case duck.types.DOCUMENT_CREATION_ACTIVE_PARTNER: {
				const { details } = action

				return setIn(
					state,
					['modules', 'document', 'activePartner'],
					details
				)
			}

			case duck.types.FETCH_PRODUCTS_STATUS: {
				const newState = setIn(
					state,
					['products', 'error'],
					action.isError
				)

				return setIn(
					newState,
					['products', 'loading'],
					action.isLoading
				)
			}

			case duck.types.FETCH_PRODUCT_SUCCESS: {
				const { productID, productData, prices, taxes } = action

				if (!productID) return state

				return setIn(
					state,
					['products', 'details', productID],
					merge(productData, {
						prices: prices || [],
						taxes: taxes || [],
					})
				)
			}

			case duck.types.SET_ACTIVE_BASE_PRODUCTS: {
				const { product } = action

				return setIn(
					state,
					['modules', 'document', 'baseProducts'],
					product
				)
			}

			case duck.types.SET_ACTIVE_DOCUMENT_IN_STORE: {
				const { rootModule, baseSchema, readOnlySections } = action

				const documentRootKey = ['modules', 'document']
				let newState = state

				const { parentDocRef } = baseSchema

				const targetParentDocType = getIn(parentDocRef, ['type'])

				if (rootModule) {
					newState = setIn(
						newState,
						[...documentRootKey, 'docType'],
						rootModule
					)
				}

				// set base docuemnt
				newState = setIn(
					newState,
					[...documentRootKey, 'activeRecord'],
					omit(baseSchema, ['parentDocRef'])
				)

				// set the parentDocRef
				if (parentDocRef)
					newState = setIn(
						newState,
						[...documentRootKey, 'parentDocRef'],
						parentDocRef
					)

				// set the read-only sections
				const currentReadOnlySections =
					getIn(newState, [...documentRootKey, 'readOnlySections']) ||
					[]

				newState = setIn(
					newState,
					[...documentRootKey, 'readOnlySections'],
					readOnlySections || currentReadOnlySections
				)

				// set the active sections
				newState = setIn(
					newState,
					[...documentRootKey, 'activeSections'],
					activeSections(rootModule, targetParentDocType)
				)

				return newState
			}

			case duck.types.ADD_REMARKS_IN_ACTIVE_DOCUMENT: {
				const { documentID, remarks = [] } = action
				const rootKey = ['modules', 'document', 'activeRecord']
				const activeDocument = getIn(state, rootKey) || {}
				if (documentID === activeDocument.id) {
					return setIn(state, [...rootKey, 'remarks'], remarks)
				}

				return state
			}

			case duck.types.SET_UPLOAD_DO_ACCEPT_LIST: {
				const { entity, checkBoxData } = action
				const rootKey = ['modules', 'document', 'uploadDOAcceptedList']
				const activeDocument = getIn(state, rootKey) || []
				let list = [...activeDocument]
				if (entity?.id) {
					if (checkBoxData[entity.id]) {
						list.push(entity)
					} else if (list.length > 0) {
						list = list.filter(item => item.id !== entity.id)
					}
				} else {
					list = []
				}

				return setIn(
					state,
					['modules', 'document', 'uploadDOAcceptedList'],
					list
				)
			}

			case duck.types.SET_UPLOAD_DO_DRAFT_ACCEPT_LIST: {
				const { entity, checkBoxData, replace } = action
				const rootKey = [
					'modules',
					'document',
					'uploadDODraftAcceptedList',
				]
				const activeDocument = getIn(state, rootKey) || []
				let list = replace ? entity : [...activeDocument]
				if (!replace) {
					if (entity?.id) {
						if (checkBoxData[entity.id]) {
							list.push(entity)
						} else if (list.length > 0) {
							list = list.filter(item => item.id !== entity.id)
						}
					} else {
						list = []
					}
				}

				return setIn(
					state,
					['modules', 'document', 'uploadDODraftAcceptedList'],
					list
				)
			}

			case duck.types.SET_DO_ACCEPT_ALL_LOADING: {
				const { loading } = action

				return setIn(
					state,
					['modules', 'document', 'DOAcceptAllLoading'],
					loading
				)
			}

			case duck.types.SET_DO_DRAFT_ACCEPT_ALL_LOADING: {
				const { loading } = action

				return setIn(
					state,
					['modules', 'document', 'DODraftAcceptAllLoading'],
					loading
				)
			}

			case duck.types.DOCUMENT_UPDATE_PARTICPANTS: {
				const documentRootKey = ['modules', 'document']
				const currentProfiles =
					getIn(state, [...documentRootKey, 'participantingUsers']) ||
					{}

				return setIn(
					state,
					[...documentRootKey, 'participantingUsers'],
					merge(currentProfiles, action.profiles)
				)
			}

			case duck.types.DOCUMENT_BASE_ACTIVE_SECTIONS: {
				const { rootModule } = action

				return setIn(
					state,
					['modules', 'document', 'activeSections'],
					activeSections(rootModule)
				)
			}

			case duck.types.DOCUMENT_FLUSH_STATE: {
				return setIn(
					state,
					['modules', 'document'],
					initialState.document
				)
			}
			case duck.types.DOCUMENT_LISTING_FLUSH_STATE: {
				return setIn(
					state,
					['modules', 'mainDashboard', 'activeDocuments'],
					{
						incoming: {},
						outgoing: {},
					}
				)
			}

			case duck.types.SET_TRACE_LISTING_STATUS: {
				const { status } = action

				return setIn(
					state,
					['modules', 'productTrace', 'loading'],
					status
				)
			}

			case duck.types.HANDLE_PRODUCT_SELECTION_MODAL: {
				const { status } = action

				return setIn(
					state,
					['modules', 'productTrace', 'showModal'],
					status
				)
			}

			case duck.types.SET_HEDERA_MESSAGES: {
				const { data } = action

				return setIn(
					state,
					['modules', 'document', 'hederaMessages'],
					data
				)
			}

			case duck.types.PRODUCT_TRACE_DOCUMENT_FLUSH_STATE: {
				return setIn(
					state,
					['modules', 'productTrace'],
					initialState.modules.productTrace
				)
			}

			case duck.types.SET_ACTIVE_PRODUCT_LISTING: {
				const { listing } = action

				return setIn(
					state,
					['modules', 'productTrace', 'activeProducts'],
					listing
				)
			}

			case duck.types.FETCH_AUDIT_REPORT_SUCCESS: {
				const { report } = action
				const currentDocs = getIn(state, [
					'modules',
					'reports',
					'activeDocuments',
				])

				return setIn(
					state,
					['modules', 'reports', 'activeDocuments'],
					currentDocs.concat(report)
				)
			}

			case duck.types.SET_AUDIT_ACTIVE_FILTERS: {
				const { filters = {} } = action

				return setIn(
					state,
					['modules', 'reports', 'activeFilters'],
					filters
				)
			}

			case duck.types.SET_AUDIT_ACTIVE_SORT: {
				const { sortMaps } = action

				let sorts = {}
				;(sortMaps.sort || []).forEach(key => {
					const arr = key.split('|')
					arr.shift() // remove the first fragment since type is already decided
					const lastVal = arr.pop()

					sorts = setIn(sorts, arr, lastVal)
				})

				return setIn(
					state,
					['modules', 'reports', 'activeSorts'],
					sorts
				)
			}

			case duck.types.SET_AUDIT_PAGINATION_ENTRIES: {
				const {
					activeIndex,
					limit,
					total,
					nextCursor,
					currentCursor,
				} = action
				const root = ['modules', 'reports', 'pagination']
				let nextState = state

				nextState = setIn(
					nextState,
					[...root, 'activeIndex'],
					activeIndex || 0
				)

				if (limit)
					nextState = setIn(nextState, [...root, 'limit'], limit)

				if (total)
					nextState = setIn(nextState, [...root, 'total'], total)

				if (nextCursor)
					nextState = setIn(
						nextState,
						[...root, 'nextCursor'],
						nextCursor
					)

				if (currentCursor)
					nextState = setIn(
						nextState,
						[...root, 'currentCursor'],
						currentCursor
					)

				return nextState
			}

			case duck.types.AUDIT_REPORT_FLUSH_STATE: {
				return setIn(
					state,
					['modules', 'reports'],
					initialState.modules.reports
				)
			}

			case duck.types.SET_BACKWARD_TRACE: {
				const { trace } = action

				return setIn(
					state,
					['modules', 'document', 'backwardTrace'],
					trace
				)
			}

			case duck.types.SET_DOCUMENT_TRACE: {
				const { docTrace } = action

				return setIn(
					state,
					['modules', 'document', 'documentTrace'],
					docTrace
				)
			}

			case duck.types.SET_ATTACHMENT_DOCUMENTS: {
				const { attachment } = action

				return setIn(
					state,
					['modules', 'document', 'attachments'],
					attachment
				)
			}

			case duck.types.SET_TIMELINE_DATA: {
				const { responseData } = action

				return setIn(
					state,
					['modules', 'document', 'timeline'],
					responseData
				)
			}

			case duck.types.SET_ACTIVE_ASSOCIATED_DOC: {
				return setIn(
					state,
					['modules', 'document', 'parentDocRef'],
					action.activeAssociatedDoc
				)
			}

			case duck.types.SET_DEALER_DASHBOARD_REPORTS: {
				return setIn(
					state,
					['modules', 'generalReports', 'ffbDealer', 'details'],
					action.details
				)
			}

			case duck.types.SET_LISTING_ACTIVE_TAB: {
				const { tab } = action

				return setIn(state, ['modules', 'listing', 'activeTab'], tab)
			}

			case duck.types.SET_PRODUCT_DETAILS_BASED_ON_SUPPLY_CHAIN_MODEL: {
				const { details } = action

				return setIn(
					state,
					['productsBasedonSupplyChainModel'],
					details
				)
			}

			case duck.types.SET_STORAGE_TANK_LOADING_STATUS: {
				const { status } = action

				return setIn(state, ['storageTankLoadingStatus'], status)
			}

			case duck.types.SET_ENTITY_RECEIVER_TRACE_IDS: {
				const { receiverTraceIDs } = action

				return setIn(state, ['receiverTraceIDs'], receiverTraceIDs)
			}

			case duck.types.FLUSH_PRODUCT_DETAILS_BASED_ON_SUPPLY_CHAIN_MODEL: {
				return setIn(state, ['productsBasedonSupplyChainModel'], [])
			}

			case duck.types.SET_CERTIFICATE_DETAILS: {
				const { details } = action

				return setIn(
					state,
					['modules', 'reports', 'certificateDetails'],
					details
				)
			}

			case duck.types.IS_SUPPLYCHAINMODEL_ENFORCED: {
				const { status } = action

				return setIn(
					state,
					['modules', 'isSupplyChainModelEnforced'],
					status
				)
			}

			default:
				return state
		}
	},
	helpers: {
		extractDocuments: documents =>
			['draft', ...INITIAL_TYPES].reduce((agg, key) => {
				const aggregator = agg
				aggregator[key] = getIn(documents, [key, 'list']) || []

				return aggregator
			}, {}),
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		tradeDocState: state => getIn(state, ['tradeDocumentManager']),
		getLoadingStatus: new Duck.Selector(selectors =>
			createSelector(selectors.tradeDocState, tradeDoc =>
				getIn(tradeDoc, ['loading'])
			)
		),
		getSupplyChainModelEnforcedStatus: state =>
			getIn(state, [
				'tradeDocumentManager',
				'modules',
				'isSupplyChainModelEnforced',
			]),
		modules: new Duck.Selector(selectors =>
			createSelector(selectors.tradeDocState, tradeDoc =>
				getIn(tradeDoc, ['modules'])
			)
		),
		userRules: new Duck.Selector(selectors =>
			createSelector(selectors.tradeDocState, tradeDoc =>
				getIn(tradeDoc, ['rules'])
			)
		),
		activeModule: state =>
			getIn(state, ['tradeDocumentManager', 'activeModule']) || 'Error',
		getModuleStatus: state => ({
			error: getIn(state, ['tradeDocumentManager', 'error']),
		}),
		mainDashboardState: state =>
			getIn(state, [
				'tradeDocumentManager',
				'modules',
				'mainDashboard',
			]) || {},
		subDashboardState: state =>
			getIn(state, ['tradeDocumentManager', 'modules', 'subDashboard']) ||
			{},
		listingState: state =>
			getIn(state, ['tradeDocumentManager', 'modules', 'listing']) || {},
		documentState: state =>
			getIn(state, ['tradeDocumentManager', 'modules', 'document']) || {},
		productsState: state =>
			getIn(state, ['tradeDocumentManager', 'products']),
		auditReportState: state =>
			getIn(state, ['tradeDocumentManager', 'modules', 'reports']) || {},
		getHederaMessages: state =>
			getIn(state, [
				'tradeDocumentManager',
				'modules',
				'document',
				'hederaMessages',
			]) || {},
		getUploadDOAcceptedList: state =>
			getIn(state, [
				'tradeDocumentManager',
				'modules',
				'document',
				'uploadDOAcceptedList',
			]) || [],
		getUploadDODraftAcceptedList: state =>
			getIn(state, [
				'tradeDocumentManager',
				'modules',
				'document',
				'uploadDODraftAcceptedList',
			]) || [],
		getDOAcceptedLoading: state =>
			getIn(state, [
				'tradeDocumentManager',
				'modules',
				'document',
				'DOAcceptAllLoading',
			]) || false,
		getDODraftAcceptedLoading: state =>
			getIn(state, [
				'tradeDocumentManager',
				'modules',
				'document',
				'DODraftAcceptAllLoading',
			]) || false,
		getActiveTimeOffset: state =>
			getIn(state, [
				'tradeDocumentManager',
				'modules',
				'mainDashboard',
				'activeTimeOffset',
			]),
		getEntityRefResults: state =>
			getIn(state, [
				'tradeDocumentManager',
				'modules',
				'listing',
				'entityList',
			]) || [],
		getActiveRootModules: new Duck.Selector(selectors =>
			createSelector(selectors.userRules, rules =>
				getIn(rules, ['activeModules'])
			)
		),
		getActiveSubModules: new Duck.Selector(selectors =>
			createSelector(selectors.userRules, rules =>
				getIn(rules, ['allowedTypes'])
			)
		),
		getMainDashboardDocuments: new Duck.Selector(selectors =>
			createSelector(
				selectors.mainDashboardState,
				dashboard => getIn(dashboard, ['activeDocuments']) || {}
			)
		),
		getMainDashboardActiveIncomingDocuments: new Duck.Selector(selectors =>
			createSelector(selectors.getMainDashboardDocuments, documents => {
				const incomingDocuments = getIn(documents, ['incoming']) || {}

				return TradeDocDuc.options.helpers.extractDocuments(
					incomingDocuments
				)
			})
		),
		getMainDashboardActiveOutgoingDocuments: new Duck.Selector(selectors =>
			createSelector(selectors.getMainDashboardDocuments, documents => {
				const outgoingDocuments = getIn(documents, ['outgoing']) || {}

				return TradeDocDuc.options.helpers.extractDocuments(
					outgoingDocuments
				)
			})
		),
		getMainDashBoardActiveTabs: new Duck.Selector(selectors =>
			createSelector(selectors.getActiveSubModules, types =>
				tdmTabConfig.filter(({ code }) => types.includes(code))
			)
		),

		getMainDashBoardActiveTab: new Duck.Selector(selectors =>
			createSelector(
				selectors.mainDashboardState,
				mainDashboard => getIn(mainDashboard, ['activeTab']) || {}
			)
		),

		getDocumentActiveTab: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				document => getIn(document, ['activeTab']) || ''
			)
		),
		getMainDashboardActiveCounts: new Duck.Selector(selectors =>
			createSelector(
				selectors.getMainDashboardActiveIncomingDocuments,
				selectors.getMainDashboardActiveOutgoingDocuments,
				(incoming, outgoing) => {
					return {
						incoming: Object.keys(incoming).reduce((agg, key) => {
							const aggregator = agg
							const entityType = incoming[key]
							const isRecieved = entityType.filter(entity => {
								const status =
									getIn(entity, ['status', 'state']) || ''

								return status === 'submitted'
							})
							aggregator[key] = (isRecieved || []).length

							return aggregator
						}, {}),
						outgoing: Object.keys(outgoing).reduce((agg, key) => {
							const aggregator = agg
							const entityType = outgoing[key]

							const isSubmitted =
								key !== 'draft'
									? entityType.filter(entity => {
											const status =
												getIn(entity, [
													'status',
													'state',
												]) || ''

											return status === 'submitted'
									  })
									: outgoing[key]
							aggregator[key] = (isSubmitted || []).length

							return aggregator
						}, {}),
					}
				}
			)
		),
		getMainDashboardActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.mainDashboardState,
				dashboard => getIn(dashboard, ['activeSorts']) || {}
			)
		),
		getDocumentListingActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => getIn(listing, ['activeSorts']) || {}
			)
		),
		getListingActiveTab: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => getIn(listing, ['activeTab']) || ''
			)
		),
		getMainDashboardLoadingStates: new Duck.Selector(selectors =>
			createSelector(
				selectors.mainDashboardState,
				dashboard => getIn(dashboard, ['loading']) || {}
			)
		),
		getSubDashboardIncomingStats: new Duck.Selector(selectors =>
			createSelector(
				selectors.subDashboardState,
				subDashboardState =>
					getIn(subDashboardState, ['status', 'incoming']) || {}
			)
		),
		getSubDashboardOutgoingStats: new Duck.Selector(selectors =>
			createSelector(
				selectors.subDashboardState,
				subDashboardState =>
					getIn(subDashboardState, ['status', 'outgoing']) || {}
			)
		),
		getListingIncomingStats: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listingState =>
					getIn(listingState, ['status', 'incoming']) || {}
			)
		),
		getListingOutgoingStats: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listingState =>
					getIn(listingState, ['status', 'outgoing']) || {}
			)
		),
		getListingDocuments: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => getIn(listing, ['activeDocuments', 'list']) || []
			)
		),
		getListingLoadingStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => listing.loading || false
			)
		),
		getListingPaginationEntries: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => listing.pagination
			)
		),
		getListingActiveFilters: new Duck.Selector(selectors =>
			createSelector(
				selectors.listingState,
				listing => listing.activeFilters
			)
		),
		getDocumentLoadingState: new Duck.Selector(selectors =>
			createSelector(selectors.documentState, (documentState = {}) => ({
				isLoading: documentState.loading,
				isSaving: documentState.isSaving,
				hasError: documentState.hasError,
			}))
		),
		getDocumentActivePartner: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				documentState => documentState.activePartner || {}
			)
		),
		getDocumentParentDocRef: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				documentState => documentState.parentDocRef || {}
			)
		),
		getDocumentActiveSections: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				documentState => documentState.activeSections || []
			)
		),
		getDocumentReadOnlySections: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				documentState => documentState.readOnlySections || []
			)
		),
		getDocumentReadOnlySecAsFlags: new Duck.Selector(selectors =>
			createSelector(
				selectors.getDocumentReadOnlySections,
				readOnlySections => {
					let isShippingReadOnly = false
					let isProductsReadOnly = false
					let isPlantationReadOnly = false
					let isAdditionalInformationReadOnly = false

					for (const section of readOnlySections) {
						if (section === 'shipping') isShippingReadOnly = true
						if (section === 'products') isProductsReadOnly = true
						if (section === 'plantation')
							isPlantationReadOnly = true
						if (section === 'additionalInfo')
							isAdditionalInformationReadOnly = true
					}

					return {
						isShippingReadOnly,
						isProductsReadOnly,
						isPlantationReadOnly,
						isAdditionalInformationReadOnly,
					}
				}
			)
		),
		getDocumentActiveRecord: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				documentState =>
					documentState.activeRecord ||
					initialState.modules.document.activeRecord
			)
		),
		getBaseProduct: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				documentState => documentState.baseProducts || {}
			)
		),
		getDocumentShippingAddress: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				documentState => documentState.shippingAddress || {}
			)
		),
		getDocumentParticipants: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				documentState => documentState.participantingUsers
			)
		),

		getAuditReports: new Duck.Selector(selectors =>
			createSelector(
				selectors.auditReportState,
				report => report.activeDocuments
			)
		),
		getAuditActiveFilters: new Duck.Selector(selectors =>
			createSelector(
				selectors.auditReportState,
				report => report.activeFilters
			)
		),
		getAuditReportListing: new Duck.Selector(selectors =>
			createSelector(
				selectors.auditReportState,
				report => report.pagination
			)
		),
		getBackwardTrace: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				documentState => documentState.backwardTrace || {}
			)
		),
		getDocumentTrace: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				trace => trace.documentTrace || []
			)
		),
		getAttachmentDoc: new Duck.Selector(selectors =>
			createSelector(
				selectors.documentState,
				trace => trace.attachments || false
			)
		),
		getDealerGeneralReports: state =>
			getIn(state, [
				'tradeDocumentManager',
				'modules',
				'generalReports',
				'ffbDealer',
				'details',
			]) || {},
		getProductDetailsBasedOnSupplyChainModel: state =>
			getIn(state, [
				'tradeDocumentManager',
				'productsBasedonSupplyChainModel',
			]),
		getTimelineData: state =>
			getIn(state, [
				'tradeDocumentManager',
				'modules',
				'document',
				'timeline',
			]) || {},
		getStorageTankLoadingStatus: state =>
			getIn(state, ['tradeDocumentManager', 'storageTankLoadingStatus']),
		getReceiverTraceIDs: state =>
			getIn(state, ['tradeDocumentManager', 'receiverTraceIDs']) || [],
		getCertificateDetails: state =>
			getIn(state, [
				'tradeDocumentManager',
				'modules',
				'reports',
				'certificateDetails',
			]),
	},
	creators: duck => ({
		setModuleAsErrored: (status = false) => ({
			type: duck.types.SET_MODULE_AS_ERRORED,
			status,
		}),
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		getDashboardActiveTDMRules: () => ({
			type: duck.types.GET_ACTIVE_TDM_RULES,
		}),
		setDashboardActiveTDMRules: (modules = [], types = []) => ({
			type: duck.types.SET_ACTIVE_TDM_RULES,
			modules,
			types,
		}),
		tdmLoadingStatus: status => ({
			type: duck.types.TDM_LOADING_STATUS,
			status,
		}),
		fetchDashboardDocuments: (
			docType,
			rootModules,
			locationState,
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_DASHBOARD_DOCUMENTS,
			docType,
			rootModules,
			locationState,
			skipGlobalLoader,
		}),
		fetchDashboardDocumentsSuccess: (module, activeDocuments) => ({
			type: duck.types.FETCH_DASHBOARD_DOCUMENTS_SUCCESS,
			activeDocuments,
			module,
		}),
		setActiveSorts: sortMap => ({
			type: duck.types.SET_ACTIVE_SORTS,
			sortMap,
		}),
		setActiveListingSorts: sortMap => ({
			type: duck.types.SET_ACTIVE_LISTING_SORTS,
			sortMap,
		}),
		setActiveListingFilters: filters => ({
			type: duck.types.SET_ACTIVE_LISTING_FILTERS,
			filters,
		}),
		setDashboardLoading: (root, _modules) => ({
			type: duck.types.SET_DASHBOARD_LOADING_STATUS,
			root,
			_modules,
		}),
		resetDashboardLoading: () => ({
			type: duck.types.RESET_DASHBOARD_LOADING_STATUS,
		}),
		changeDocumentStatus: documentStatusPropsList => ({
			type: duck.types.CHANGE_DOC_STATUS,
			documentStatusPropsList,
		}),
		searchEntityRef: (query, docType) => ({
			type: duck.types.SEARCH_ENTITY_REF,
			query,
			docType,
		}),
		setEntityRefDetails: entityList => ({
			type: duck.types.SET_ENTITY_REF_DETAILS,
			entityList,
		}),
		fetchDashboardStats: (rootModule, submodule, query) => ({
			type: duck.types.FETCH_DASHBOARD_STATS,
			rootModule,
			submodule,
			query,
		}),
		fetchDashboardStatsSuccess: (rootModule, stats) => ({
			type: duck.types.FETCH_DASHBOARD_STATS_SUCCESS,
			rootModule,
			stats,
		}),
		fetchDocumentListing: (
			docType,
			rootModules = [],
			locationState = {},
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_DASHBOARD_LISTING,
			rootModule: rootModules[0],
			submodule: docType,
			locationState,
			skipGlobalLoader,
		}),
		fetchDocumentLoading: status => ({
			type: duck.types.FETCH_DASHBOARD_LISTING_LOADING,
			status,
		}),
		fetchDocumentListingSuccess: activeDocuments => ({
			type: duck.types.FETCH_DASHBOARD_LISTING_SUCCESS,
			activeDocuments,
		}),
		setPaginationEntries: (
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor
		) => ({
			type: duck.types.SET_PAGINATION_ENTRIES,
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor,
		}),
		setTracePaginationEntries: (
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor
		) => ({
			type: duck.types.SET_TRACE_PAGINATION_ENTRIES,
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor,
		}),
		initiateDocumentStep: (rootModule, action) => ({
			type: duck.types.INITIATE_DOCUMENT_STEP,
			rootModule,
			action,
		}),
		fetchDocumentAttachments: action => ({
			type: duck.types.FETCH_DOCUMENT_ATTACHMENTS,
			action,
		}),
		initiateDocumentView: (
			rootModule,
			documentReference,
			location,
			fromTraceReport = false,
			skipGlobalLoader = false
		) => ({
			type: duck.types.INITIATE_DOCUMENT_VIEW,
			rootModule,
			location,
			documentReference,
			fromTraceReport,
			skipGlobalLoader,
		}),
		documentLoading: (
			isLoading = false,
			isSaving = false,
			hasError = false
		) => ({
			type: duck.types.DOCUMENT_CREATION_LOADING,
			isLoading,
			isSaving,
			hasError,
		}),
		setActivePartner: (details = {}) => ({
			type: duck.types.DOCUMENT_CREATION_ACTIVE_PARTNER,
			details,
		}),
		fetchAProduct: (productID, fetchPrice = false, fetchTax = false) => ({
			type: duck.types.FETCH_PRODUCT,
			productID,
			fetchPrice,
			fetchTax,
		}),
		fetchAProductSuccess: (productID, productData, prices, taxes) => ({
			type: duck.types.FETCH_PRODUCT_SUCCESS,
			productID,
			productData,
			prices,
			taxes,
		}),

		initiateDocumentSave: documentPropsList => ({
			type: duck.types.INITIATE_DOCUMENT_SAVE,
			documentPropsList,
		}),
		setActiveDocument: ({ rootModule, baseSchema, readOnlySections }) => ({
			type: duck.types.SET_ACTIVE_DOCUMENT_IN_STORE,
			rootModule,
			baseSchema,
			readOnlySections,
		}),
		setBaseProduct: product => ({
			type: duck.types.SET_ACTIVE_BASE_PRODUCTS,
			product,
		}),
		fetchActiveDocument: (
			entityID,
			rootModule,
			fetchDraft,
			documentStatus,
			baseValueSchema
		) => ({
			type: duck.types.FETCH_CURRENT_ACTIVE_DOCUMENT,
			entityID,
			rootModule,
			fetchDraft,
			documentStatus,
			baseValueSchema,
		}),
		fetchParentSource: (rootModule, parentDocRef) => ({
			type: duck.types.DOCUMENT_FETCH_PARENT,
			rootModule,
			parentDocRef,
		}),
		setDocumentParticipants: profiles => ({
			type: duck.types.DOCUMENT_UPDATE_PARTICPANTS,
			profiles,
		}),
		initializeBaseActiveSections: rootModule => ({
			type: duck.types.DOCUMENT_BASE_ACTIVE_SECTIONS,
			rootModule,
		}),
		initiateDocumentActiveAction: rootModule => ({
			type: duck.types.DOCUMENT_INITIATE_ACTIVE_ACTION,
			rootModule,
		}),
		flushDocumentState: () => ({
			type: duck.types.DOCUMENT_FLUSH_STATE,
		}),
		flushDocumentListing: () => ({
			type: duck.types.DOCUMENT_LISTING_FLUSH_STATE,
		}),
		addNewRemark: (remarkType, remark, currentDocId) => ({
			type: duck.types.ADD_NEW_REMARK,
			remarkType,
			remark,
			currentDocId,
		}),
		fetchTimelineData: tradeId => ({
			type: duck.types.FETCH_TIMELINE_DATA,
			tradeId,
		}),
		setRemarksInActiveDocument: (documentID, remarks = []) => ({
			type: duck.types.ADD_REMARKS_IN_ACTIVE_DOCUMENT,
			documentID,
			remarks,
		}),
		setUploadDOAcceptedList: (entity = null, checkBoxData = {}) => ({
			type: duck.types.SET_UPLOAD_DO_ACCEPT_LIST,
			entity,
			checkBoxData,
		}),
		setUploadDODraftAcceptedList: (
			entity = null,
			checkBoxData = {},
			replace = false
		) => ({
			type: duck.types.SET_UPLOAD_DO_DRAFT_ACCEPT_LIST,
			entity,
			checkBoxData,
			replace,
		}),
		setDOAcceptAllLoading: (loading = false) => ({
			type: duck.types.SET_DO_ACCEPT_ALL_LOADING,
			loading,
		}),
		setDODraftAcceptAllLoading: (loading = false) => ({
			type: duck.types.SET_DO_DRAFT_ACCEPT_ALL_LOADING,
			loading,
		}),
		setTimelineData: responseData => ({
			type: duck.types.SET_TIMELINE_DATA,
			responseData,
		}),

		fetchTraceListing: (
			docType,
			locationState,
			skipGlobalLoader = false,
			productID = ''
		) => ({
			type: duck.types.FETCH_PRODUCT_TRACE_LISTING,
			rootModule: docType,
			locationState,
			skipGlobalLoader,
			productID,
		}),
		setTraceListingStatus: status => ({
			type: duck.types.SET_TRACE_LISTING_STATUS,
			status,
		}),
		setTraceListingProductID: productID => ({
			type: duck.types.SET_TRACE_LISTING_PRODUCTID,
			productID,
		}),
		handleProductModal: status => ({
			type: duck.types.HANDLE_PRODUCT_SELECTION_MODAL,
			status,
		}),
		flushProductTraceDocument: () => ({
			type: duck.types.PRODUCT_TRACE_DOCUMENT_FLUSH_STATE,
		}),
		setActiveProductListing: listing => ({
			type: duck.types.SET_ACTIVE_PRODUCT_LISTING,
			listing,
		}),
		setCertificateDetails: details => ({
			type: duck.types.SET_CERTIFICATE_DETAILS,
			details,
		}),
		fetchAuditReport: (docType, locationState) => ({
			type: duck.types.FETCH_AUDIT_REPORT,
			rootModule: docType,
			locationState,
		}),
		fetchAuditReportSuccess: report => ({
			type: duck.types.FETCH_AUDIT_REPORT_SUCCESS,
			report,
		}),
		setActiveAuditFilters: filters => ({
			type: duck.types.SET_AUDIT_ACTIVE_FILTERS,
			filters,
		}),
		setActiveAuditSorts: sortMaps => ({
			type: duck.types.SET_AUDIT_ACTIVE_SORT,
			sortMaps,
		}),
		setAuditPaginationEntries: (
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor
		) => ({
			type: duck.types.SET_AUDIT_PAGINATION_ENTRIES,
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor,
		}),
		appendAuditReport: (
			docType,
			locationState,
			skipGlobalLoader = false
		) => ({
			type: duck.types.APPEND_AUDIT_REPORT,
			rootModule: docType,
			locationState,
			skipGlobalLoader,
		}),
		saveAuditReportAsPDF: (
			docType,
			locationState,
			filterValue,
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_AUDIT_REPORT_LISTING,
			rootModule: docType,
			locationState,
			filterValue,
			skipGlobalLoader,
		}),
		flushAuditReport: () => ({
			type: duck.types.AUDIT_REPORT_FLUSH_STATE,
		}),
		setActiveAssociatedDoc: activeAssociatedDoc => ({
			type: duck.types.SET_ACTIVE_ASSOCIATED_DOC,
			activeAssociatedDoc,
		}),
		fetchDealerGeneralReports: filters => ({
			type: duck.types.FETCH_DEALER_DASHBOARD_REPORTS,
			filters,
		}),
		setDealerGeneralReports: details => ({
			type: duck.types.SET_DEALER_DASHBOARD_REPORTS,
			details,
		}),
		setBackwardTrace: trace => ({
			type: duck.types.SET_BACKWARD_TRACE,
			trace,
		}),
		// Product-Trace

		setDocumentTrace: docTrace => ({
			type: duck.types.SET_DOCUMENT_TRACE,
			docTrace,
		}),
		setAttachmentDoc: attachment => ({
			type: duck.types.SET_ATTACHMENT_DOCUMENTS,
			attachment,
		}),
		setIncomingActiveTab: tab => ({
			type: duck.types.SET_ACTIVE_INCOMING_DOCUMENTS,
			tab,
		}),
		setOutgoingActiveTab: tab => ({
			type: duck.types.SET_ACTIVE_OUTGOING_DOCUMENTS,
			tab,
		}),
		setActiveTab: tab => ({
			type: duck.types.SET_DOCUMENT_ACTIVE_TAB,
			tab,
		}),
		setListingActiveTab: tab => ({
			type: duck.types.SET_LISTING_ACTIVE_TAB,
			tab,
		}),
		createEntityAttachment: (
			rootModule,
			entityID,
			files,
			toastMessage,
			attachmentType = null
		) => ({
			type: duck.types.CREATE_ENTITY_ATTACHMENT,
			rootModule,
			entityID,
			files,
			toastMessage,
			attachmentType,
		}),
		updateEntityAttachment: (
			rootModule,
			entityID,
			attachmentID,
			files,
			toastMessage
		) => ({
			type: duck.types.UPDATE_ENTITY_ATTACHMENT,
			rootModule,
			entityID,
			attachmentID,
			files,
			toastMessage,
		}),
		deleteEntityAttachment: (
			rootModule,
			entityID,
			attachmentID,
			toastMessage
		) => ({
			type: duck.types.DELETE_ENTITY_ATTACHMENT,
			rootModule,
			entityID,
			attachmentID,
			toastMessage,
		}),
		deleteDraftEntity: (entityID, toastMessage) => ({
			type: duck.types.DELETE_DRAFT_ENTITY,
			entityID,
			toastMessage,
		}),
		fetchProductDetailsBasedOnSupplyChainModel: (
			productID,
			supplyChainModel,
			certificationType,
			reservedFor,
			actor,
			prodList = [],
			productOwner
		) => ({
			type: duck.types.FETCH_PRODUCT_DETAILS_BASED_ON_SUPPLY_CHAIN_MODEL,
			productID,
			supplyChainModel,
			certificationType,
			reservedFor,
			actor,
			prodList,
			productOwner,
		}),
		flushStorageDetailsBasedOnSupplyChainModel: () => ({
			type: duck.types.FLUSH_PRODUCT_DETAILS_BASED_ON_SUPPLY_CHAIN_MODEL,
		}),
		setEntityReceiverTraceIDs: receiverTraceIDs => ({
			type: duck.types.SET_ENTITY_RECEIVER_TRACE_IDS,
			receiverTraceIDs,
		}),
		setDetailsBasedOnSupplyChainModel: details => ({
			type: duck.types.SET_PRODUCT_DETAILS_BASED_ON_SUPPLY_CHAIN_MODEL,
			details,
		}),
		setStorageTankLoadingStatus: status => ({
			type: duck.types.SET_STORAGE_TANK_LOADING_STATUS,
			status,
		}),
		getHederaMessages: entityIDs => ({
			type: duck.types.GET_HEDERA_MESSAGES,
			entityIDs,
		}),
		setHederaMessages: data => ({
			type: duck.types.SET_HEDERA_MESSAGES,
			data,
		}),
		checkIfSupplyChainIsEnforced: () => ({
			type: duck.types.CHECK_IF_SUPPLYCHAIN_IS_ENFORCED,
		}),
		isSupplyChainModelEnforced: status => ({
			type: duck.types.IS_SUPPLYCHAINMODEL_ENFORCED,
			status,
		}),
		createWBSlip: (wbSlipDetails, isIncomingDO, actor, toastMessage) => ({
			type: duck.types.CREATE_WB_SLIP,
			wbSlipDetails,
			isIncomingDO,
			actor,
			toastMessage,
		}),
		uploadDocumentFile: (uploadPayload, successMsg) => ({
			type: duck.types.UPLOAD_DOCUMENT_FILE,
			uploadPayload,
			successMsg,
		}),
		fetchMirrorNodeData: (topicID, topicSequenceNumber) => ({
			type: duck.types.FETCH_MIRROR_NODE,
			topicID,
			topicSequenceNumber,
		}),
	}),
})

import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { addLast, merge } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useTranslation } from 'react-i18next'
import { Modal } from 'ui-lib/components/Modal'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { KeyValueSegment } from 'ui-tdm-app/modules/TradeDocumentManager/components/KeyValueSegment'
import { isEmptyObject } from 'ui-tdm-app/utils/helpers'
import { Address } from '../../../components/Fragments'
import { ShippingForm } from './ShippingForm'

const SORT_FIELD = 'shippingAddress'

const ShippingStaticSection = ({
	isMobile,
	activeRecord,
	shipThrough,
	shipperNumber,
	billOfLading,
	modeOfTransport,
	tripDetails = {},
	newDocView = false,
	transporter = {},
	preview = false,
	toBeShippedTo = {},
}) => {
	const { t } = useTranslation()
	const { name } = toBeShippedTo

	return (
		<Box row={!isMobile && !newDocView} justifyContent="space-between">
			{!isEmptyObject(activeRecord) && (
				<Box width={isMobile || newDocView ? '100%' : '50%'}>
					<KeyValueSegment
						newDocView
						name={t('tdmPreviewDetails.deliveryAddress')}
						description={
							<Address
								address={activeRecord}
								isMobile={isMobile}
							/>
						}
					/>
				</Box>
			)}
			{!isMobile && !isEmptyObject(activeRecord) && (
				<Spacer size={40} horizontal />
			)}
			<Box width={isMobile || newDocView ? '100%' : '50%'}>
				{shipThrough && (
					<KeyValueSegment
						newDocView
						name={t('tdmPreviewDetails.shipThrough')}
						description={shipThrough}
					/>
				)}
				{shipperNumber && (
					<KeyValueSegment
						newDocView
						name={t('tdmPreviewDetails.shipperNumber')}
						description={shipperNumber}
					/>
				)}
				{billOfLading && (
					<KeyValueSegment
						newDocView
						name={t('tdmPreviewDetails.billOfLading')}
						description={billOfLading}
					/>
				)}
				{modeOfTransport && (
					<KeyValueSegment
						newDocView
						name={t('tdmPreviewDetails.modeOfTransport')}
						description={modeOfTransport}
					/>
				)}
				{name && (
					<KeyValueSegment
						newDocView
						name="To be shipped to"
						description={name}
					/>
				)}
				{newDocView && transporter && transporter.vehicleNumber && (
					<KeyValueSegment
						newDocView
						name={t('tdmViewDocument.vehicleNumber')}
						description={transporter.vehicleNumber}
					/>
				)}
				{newDocView && transporter && transporter.driverName && (
					<KeyValueSegment
						newDocView
						name={t('tdmViewDocument.driverName')}
						description={transporter.driverName}
					/>
				)}
				{!preview &&
					newDocView &&
					tripDetails &&
					tripDetails.actaulDuration && (
						<KeyValueSegment
							newDocView
							name={t('tdmViewDocument.actualDuration')}
							description={`${tripDetails.actaulDuration} hr`}
						/>
					)}
				{!preview &&
					newDocView &&
					tripDetails &&
					tripDetails.estimatedDuration && (
						<KeyValueSegment
							newDocView
							name={t('tdmViewDocument.estimatedDuration')}
							description={`${tripDetails.estimatedDuration} hr`}
						/>
					)}
				{!preview &&
					newDocView &&
					tripDetails &&
					tripDetails.distance && (
						<KeyValueSegment
							newDocView
							name={t('tdmViewDocument.distance')}
							description={`${tripDetails.distance} miles`}
						/>
					)}
				{!preview &&
					newDocView &&
					tripDetails &&
					tripDetails.carbonEmission && (
						<KeyValueSegment
							newDocView
							name={t('tdmViewDocument.carbonEmission')}
							description={`${tripDetails.carbonEmission} per ton-mile`}
						/>
					)}
			</Box>
		</Box>
	)
}

const ShippingEditSection = ({ activeRow: _activeRow, onChange }) => {
	const dispatch = useDispatch()

	const locationState = useSelector(TradeDocDuc.selectors.location)
	const activePartner = useSelector(
		TradeDocDuc.selectors.getDocumentActivePartner
	)

	const { payload = {}, query = {} } = locationState
	const { rootModule } = payload

	const isInwardShipping = rootModule === 'purchase-order'
	const targetAddressSelector = isInwardShipping
		? AuthDuc.selectors.getCurrentOrganization
		: AuthDuc.selectors.getPartnerOrganizationByID

	const targetOrg = useSelector(state =>
		targetAddressSelector(state, activePartner.id)
	)

	const allAddresses = useMemo(() => {
		const { primaryAddress } = targetOrg || {}
		const addresses = primaryAddress ? [primaryAddress] : []

		return addLast(addresses, targetOrg.address || [])
	}, [targetOrg])

	const [activeRow, setActiveRow] = useState(_activeRow || {})
	const [activeSort, setActiveSort] = useState('')

	const [showAddShipping, setShowAddShipping] = useState(false)
	const handleChange = useCallback(
		(action, meta) => {
			if (action === 'initiate_sort') {
				const { type, payload: _payload, query: _query = {} } =
					locationState || {}

				const rootKey = `${rootModule}|${meta.field}`
				let key = `${rootModule}|${meta.field}|${meta.order}`

				if (meta.order === 'asc') {
					setActiveSort('asc')
				} else if (meta.order === 'desc') {
					setActiveSort('desc')
				} else {
					key = ''
					setActiveSort('')
				}

				// filter sorts
				let targetSorts = Array.isArray(_query.sort)
					? _query.sort
					: [_query.sort]
				targetSorts = targetSorts.filter(
					_key => _key && _key.indexOf(rootKey) < 0
				)
				if (key) targetSorts.push(key)

				const finalQuery = merge(_query, { sort: targetSorts })
				if (!targetSorts.length) delete finalQuery.sort

				dispatch(
					MainRouteDuc.creators.redirect(type, _payload, finalQuery, {
						skipRouteThunk: true,
					})
				)
			}

			if (action === 'set_active_selection') {
				const { row } = meta

				setActiveRow(row)
				onChange(row)
			}
		},
		[onChange, dispatch, rootModule, locationState]
	)

	useEffect(() => {
		if (isEmptyObject(activeRow) && allAddresses.length) {
			// if none select, then select primary address as the default
			setActiveRow(allAddresses[0])
			onChange(allAddresses[0])
		}
	}, [activeRow, allAddresses, onChange])

	useEffect(() => {
		if (!_activeRow || !_activeRow.id) onChange(activeRow)
	}, [_activeRow, activeRow, onChange])

	// extract if there is sort associated
	useEffect(() => {
		if (query.sort) {
			// extract the sort
			const rootKey = `${rootModule}|${SORT_FIELD}`
			let targetSorts = Array.isArray(query.sort)
				? query.sort
				: [query.sort]

			targetSorts = targetSorts.filter(key => key.indexOf(rootKey) > -1)
			const _target = targetSorts.pop()
			if (_target) {
				const [_rootModule, sortField, _value] = _target.split('|')
				if (
					sortField === SORT_FIELD &&
					_rootModule === rootModule &&
					_value !== activeSort
				) {
					// trigger the sort handle
					handleChange('initiate_sort', {
						rootModule,
						field: SORT_FIELD,
						order: _value,
					})
				}
			}
		}
	}, [activeSort, handleChange, rootModule, query.sort])

	return (
		<>
			<Modal
				closeable
				show={showAddShipping}
				noPadding
				size="large"
				body={
					<ShippingForm
						onSuccess={() => setShowAddShipping(false)}
						onClose={() => setShowAddShipping(false)}
					/>
				}
				hideButtons
				onClose={() => setShowAddShipping(false)}
			/>
		</>
	)
}

export { ShippingStaticSection, ShippingEditSection }

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NotFoundBlock } from 'ui-tdm-app/modules/App/components/Error'
import { WebTour } from 'ui-tdm-app/modules/WebTour'
import { WebTourDuc } from 'ui-tdm-app/modules/WebTour/duc'
import { MillDuc } from './duc'
import { QualityDocCreation } from './containers/QualityCreation'

const modulesMap = {
	'quality-cpo': QualityDocCreation,
	'quality-ffb': QualityDocCreation,
}

const Mill = () => {
	const dispatch = useDispatch()
	const activeModule = useSelector(MillDuc.selectors.activeModule)

	const Component = modulesMap[activeModule] || NotFoundBlock

	// if we are here, we have a submodule, route it to the module
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	useEffect(() => {
		if (tourModule === 'production') {
			dispatch(WebTourDuc.creators.setProductionVisit(true))
		}
		if (tourModule === 'createIpBatch') {
			dispatch(WebTourDuc.creators.setCreateIpBatch(true))
		}
	}, [tourModule, dispatch])

	return (
		<>
			<Component />
			{(tourModule === 'production' ||
				tourModule === 'createIpBatch') && <WebTour />}
		</>
	)
}

export default Mill

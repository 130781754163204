import { MillDuc } from 'ui-tdm-app/modules/Mill/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import saga from 'ui-tdm-app/modules/Mill/MillSaga'
import { getIn } from 'timm'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { ProductsDuc } from 'ui-tdm-app/modules/Products/duc'
import moment from 'moment'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload, query } = locationState

	const docReference = getIn(query, ['documentReference'])
	const attachmentID = getIn(locationState, ['payload', 'attachmentID'])

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('mill', { saga })

	const isMainDashboard = MainRouteDuc.types.MILL === type
	const isSubDashboard = MainRouteDuc.types.MILL$SUBROOT === type
	const isDocumentCreation = type === MainRouteDuc.types.MILL$WACTION
	const isDocumentUpdation = type === MainRouteDuc.types.MILL$WDOCREFERENCE
	const isDocumentListing = type === MainRouteDuc.types.MILL$LISTING

	const isDocumentTypeControl = isDocumentCreation || isDocumentUpdation
	let activeModule = ''
	dispatch(AuthDuc.creators.fetchActor())

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
		dispatch(MillDuc.creators.fetchInsights())
		dispatch(AuthDuc.creators.fetchActor())
	} else if (isSubDashboard) {
		activeModule = 'subDashboard'
		if (payload.rootModule === 'quality') {
			dispatch(MillDuc.creators.fetchQualityListing())
		} else if (payload.rootModule === 'production-v1') {
			dispatch(
				MillDuc.creators.fetchProductionDocuments(
					'production',
					payload.rootModule,
					locationState
				)
			)
		} else {
			const currentDate = new Date()
			const first = currentDate.getDate() - currentDate.getDay() + 1
			const weekNumber = moment(
				new Date(currentDate.setDate(first))
			).week()
			const year = new Date(currentDate).getFullYear()

			dispatch(MillDuc.creators.fetchProduction(weekNumber, year))
			dispatch(ProductsDuc.creators.fetchOutgoingStorageUnits('tank'))
			dispatch(AuthDuc.creators.fetchAllProducts())
		}
	} else if (isDocumentTypeControl) {
		activeModule = payload.rootModule
		if (activeModule === 'quality-cpo') {
			if (payload.action === 'create') {
				dispatch(MillDuc.creators.flushCPOQualityFormValues())
				dispatch(
					MillDuc.creators.fetchEntityInfoForCPOQualityReport(
						query.parentDocRef
					)
				)
			}
			if (payload.action === 'edit') {
				dispatch(
					MillDuc.creators.initiateCPOQualityEdit(
						docReference,
						attachmentID
					)
				)
			}
			if (payload.action === 'view') {
				dispatch(
					MillDuc.creators.fetchSingleCPOQualityReport(
						docReference,
						attachmentID
					)
				)
			}
		} else if (activeModule === 'quality-ffb') {
			if (payload.action === 'create') {
				dispatch(
					MillDuc.creators.fetchEntityInfoforFFBQualityReport(
						query.parentDocRef
					)
				)
			}
			if (payload.action === 'edit') {
				dispatch(
					MillDuc.creators.initiateFFBQualityEdit(
						docReference,
						attachmentID
					)
				)
			}
			if (payload.action === 'view') {
				dispatch(
					MillDuc.creators.fetchSingleFFBQualityReport(
						docReference,
						attachmentID
					)
				)
			}
		}
		if (activeModule === 'production-plot-input') {
			if (payload.action === 'create') {
				dispatch(MillDuc.creators.fetchInitialPlotInput())
			}
		}
		if (activeModule === 'production-plot-output') {
			if (payload.action === 'create') {
				dispatch(MillDuc.creators.fetchInitialPlotOutput())
			}
		}
		if (
			activeModule === 'sourced-batch' ||
			activeModule === 'produced-batch'
		) {
			dispatch(
				MillDuc.creators.viewPlotDocuments(payload.documentReference)
			)
			dispatch(AuthDuc.creators.fetchUserLists())
		}
		if (activeModule === 'inventory') {
			const currentDate = new Date()
			const first = currentDate.getDate() - currentDate.getDay() + 1
			const weekNumber = moment(
				new Date(currentDate.setDate(first))
			).week()
			const year = new Date(currentDate).getFullYear()

			dispatch(MillDuc.creators.fetchOutgoingInventory(weekNumber, year))
			dispatch(ProductsDuc.creators.fetchOutgoingProducts())
			dispatch(ProductsDuc.creators.fetchOutgoingStorageUnits())
		}
	} else if (isDocumentListing) {
		activeModule = 'documentListing'
		dispatch(
			MillDuc.creators.fetchDocumentListing(
				payload.submodule,
				[payload.rootModule],
				locationState
			)
		)
	}
	// load the dashboard of the core module
	dispatch(MillDuc.creators.setActiveModule(activeModule))

	// based on the payload or action, initiate saga's
}

import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { Box } from 'ui-lib/utils/Box'
import { merge } from 'timm'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useTranslation } from 'react-i18next'
import { RadioInput } from 'ui-lib/components/Radio'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { ModuleIconMap } from 'ui-lib/utils/config'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import KeyboardIcon from 'ui-lib/icons/keyboard.svg'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { H3, TableTitle, P } from 'ui-lib/components/Typography'
import { SortDown, SortReset } from 'ui-lib/components/Table'
import {
	useDebouncedCallback,
	getNextSortOrder,
	isEmptyObject,
	sortArray,
} from 'ui-tdm-app/utils/helpers'
import { SuggestionsInput } from 'ui-lib/components/Input'
import { NAME_ALIASES, TABLE_ALIAS } from '../../../config'
import { Title } from '../../../components/Title'
import {
	NumberIndicator,
	SearchWrapper,
	Address,
} from '../../../components/Fragments'
import { BoxShadowTable } from '../../../components/BoxShadowTable'

const SORT_FIELD = 'partner'

const PartnerSelection = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isFetching: isOrgFetchLoading } = useSelector(
		AuthDuc.selectors.getOrganizationFetchStatus
	)
	const { isLoading: isDocumentFetching } = useSelector(
		TradeDocDuc.selectors.getDocumentLoadingState
	)
	const activeDocuments = useSelector(
		AuthDuc.selectors.getTargetPartnersOfUser
	)
	const _activeRow = useSelector(
		TradeDocDuc.selectors.getDocumentActivePartner
	)
	const locationState = useSelector(TradeDocDuc.selectors.location)
	const { payload = {}, query = {}, type } = locationState
	const [showError, setShowError] = useState(0)

	const [activeRow, setActiveRow] = useState(_activeRow)
	const [activeSort, setActiveSort] = useState('')
	const [filteredDocuments, setFilteredDocuments] = useState(activeDocuments)
	const { rootModule } = payload

	useEffect(() => {
		setActiveRow(_activeRow)
	}, [_activeRow])

	useEffect(() => {
		setFilteredDocuments(activeDocuments)
	}, [activeDocuments])

	const getBreadCrumbsList = useCallback(
		({ title }) => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: `${t('breadcrumb.new')} ${t(title)} - ${t(
					'breadcrumb.partner'
				)}`,
				name: 'document-creation',
				isActive: false,
			},
		],
		[t]
	)

	const getColumnsConfig = (
		_rootModule,
		activeSelection,
		onChange,
		sortsMap
	) => [
		{
			property: 'id',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer' }}
					onClick={() => {
						// call onChange with appropriate invocation
						onChange('initiate_sort', {
							_rootModule,
							field: SORT_FIELD,
							order: getNextSortOrder(sortsMap[SORT_FIELD]),
						})
					}}
				>
					<IconWrapper
						width={10}
						margin="0 5px"
						sortUp={sortsMap[SORT_FIELD] === 'asc'}
						sortDown={sortsMap[SORT_FIELD] === 'desc'}
					>
						<Icon
							rotate180={sortsMap[SORT_FIELD] === 'asc'}
							glyph={sortsMap[SORT_FIELD] ? SortDown : SortReset}
						/>
					</IconWrapper>
					<TableTitle>{t(TABLE_ALIAS[SORT_FIELD])}</TableTitle>
				</Box>
			),
			render: datum => {
				return (
					<Box>
						<RadioInput
							hideError
							selected={
								datum.id === activeSelection.id ? datum.id : ''
							}
							label={datum.name}
							name={datum.id}
							link
							returnKeyValue
							onChange={() => {
								onChange('set_active_selection', { row: datum })
							}}
						/>
					</Box>
				)
			},
		},
		{
			property: 'address',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t('tdmColumnHeaders.partnerAddress')}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const { primaryAddress: address } = datum || {}
				if (!address) return ' - - - '

				return <Address address={address} />
			},
		},
		{
			property: 'void',
			header: <span> </span>,
			render: () => '',
		},
	]

	const title = NAME_ALIASES[rootModule]

	const breadCrumbs = useMemo(() => getBreadCrumbsList({ title }), [
		getBreadCrumbsList,
		title,
	])
	const TitleIcon = ModuleIconMap[rootModule]

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleSwitchPage = useCallback(
		() =>
			dispatch(
				MainRouteDuc.creators.switchPage(
					type,
					{
						...payload,
						action: 'details',
					},
					query
				)
			),
		[dispatch, payload, type, query]
	)

	const handleChange = useCallback(
		(action, meta) => {
			switch (action) {
				case 'initiate_sort': {
					const clonedDocs = [...activeDocuments]
					const {
						type: _type,
						payload: _payload,
						query: _query = {},
					} = locationState || {}

					let key = `${rootModule}|${meta.field}|${meta.order}`
					if (meta.order === 'asc') {
						setFilteredDocuments(
							sortArray(clonedDocs, 'asc', a => a.name)
						)
						setActiveSort('asc')
					} else if (meta.order === 'desc') {
						setFilteredDocuments(
							sortArray(clonedDocs, 'desc', a => a.name, true)
						)
						setActiveSort('desc')
					} else {
						setFilteredDocuments(activeDocuments)
						setActiveSort('')
						key = ''
					}

					const finalQuery = merge(_query, { sort: key })
					if (!key) delete finalQuery.sort

					dispatch(
						MainRouteDuc.creators.redirect(
							_type,
							_payload,
							finalQuery,
							{
								skipRouteThunk: true,
							}
						)
					)

					break
				}
				case 'apply_search': {
					const { searchValue } = meta
					setShowError(searchValue.length)
					dispatch(AuthDuc.creators.searchPartner(searchValue))
					break
				}

				case 'set_active_selection': {
					const { row } = meta

					setActiveRow(row)

					break
				}

				case 'process_selection': {
					dispatch(TradeDocDuc.creators.setActivePartner(activeRow))
					handleSwitchPage()
					break
				}

				default:
					break
			}
		},
		[
			activeDocuments,
			activeRow,
			dispatch,
			rootModule,
			locationState,
			handleSwitchPage,
		]
	)

	// extract if there is sort associated
	useEffect(() => {
		if (typeof query.sort === 'string') {
			// extract the sort
			// eslint-disable-next-line no-unused-vars
			const [_rootModule, _value] = query.sort.split('|')
			if (_value && _rootModule === rootModule && _value !== activeSort) {
				// trigger the sort handle
				if (activeDocuments.length)
					handleChange('initiate_sort', {
						rootModule,
						field: SORT_FIELD,
						order: _value,
					})
			}
		}
	}, [activeDocuments, activeSort, handleChange, rootModule, query.sort])

	const columnsConfig = getColumnsConfig(
		{ _rootModule: rootModule },
		activeRow,
		handleChange,
		{ [SORT_FIELD]: activeSort }
	)

	const handleSearch = useDebouncedCallback(value => {
		if (value.length > 3)
			handleChange('apply_search', {
				searchValue: value,
			})
		if (value.length === 0)
			dispatch(AuthDuc.creators.fetchPartnerOrgs(false, false))
	})

	const hasSelection = !isEmptyObject(activeRow)

	const __isLoading = isOrgFetchLoading || isDocumentFetching

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<Box row justifyContent="space-between" alignItems="baseline">
				<Title
					title={`${t('tdmPartnerSelection.new')} ${t(title)}`}
					icon={TitleIcon}
				/>
			</Box>
			<Spacer size={8} />
			<Box row>
				<NumberIndicator>1</NumberIndicator>
				<Spacer size={10} horizontal />
				<H3>{t('tdmPartnerSelection.choosePartner')}</H3>
			</Box>
			<Spacer size={16} />
			<SearchWrapper>
				<SuggestionsInput
					isLoading={__isLoading}
					error={
						showError < 4 && showError >= 1
							? t('validation.minSearchLengthRequired')
							: ''
					}
					placeholder={t('tdmPartnerSelection.searchPartner')}
					onChange={e => handleSearch(e.target.value)}
				/>
			</SearchWrapper>
			<Spacer size={16} />
			<Box padding="4px 10px" height={30}>
				<P small>
					{filteredDocuments.length > 0 &&
						filteredDocuments.length < activeDocuments.length &&
						`Showing ${filteredDocuments.length} of ${activeDocuments.length}`}
				</P>
			</Box>
			<BoxShadowTable
				isLoading={__isLoading}
				size="small"
				columnConfig={columnsConfig}
				rowData={filteredDocuments}
				onClickRow={({ datum }) => {
					setActiveRow(datum)
				}}
				maxHeight={248} // same as size medium
				minHeight={192}
				errorSize="small"
			/>
			<Spacer size={24} />
			<Box row justifyContent="space-between">
				<Box padding={2} style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
									{
										rootModule: 'incoming',
									}
								)
							)
						}
					/>
				</Box>
				<Box style={{ padding: 2 }} width="200px">
					<Button
						label={t('tdmPartnerSelection.enterDetails')}
						isLoading={__isLoading}
						disabled={!hasSelection}
						primary
						rounded
						customIcon={
							<ButtonIconWrapper>
								<Icon glyph={KeyboardIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							hasSelection && handleChange('process_selection')
						}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export { PartnerSelection }

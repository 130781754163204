/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux'
import reduceReducers from 'reduce-reducers'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { CookieDuc } from 'ui-tdm-app/modules/App/cookieDuc'
import { MillDuc } from 'ui-tdm-app/modules/Mill/duc'
import { MainRouteDuc as PageDuc } from 'ui-tdm-app/routes/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { ProductsDuc } from 'ui-tdm-app/modules/Products/duc'
import { WeighBridgeDuc } from 'ui-tdm-app/modules/WeighBridge/duc'
import { AdminDuc } from 'ui-tdm-app/modules/Admin/duc'
import { FeedbackDuc } from 'ui-tdm-app/modules/Feedback/duc'
import { UploadContentDuc } from 'ui-tdm-app/modules/UploadContent/duc'
import { MessagesDuc } from 'ui-tdm-app/modules/Messages/duc'
import { i18nextReducer } from 'i18next-redux-languagedetector'
import { WebTourDuc } from 'ui-tdm-app/modules/WebTour/duc'
import { DeviceDetectionDuc } from './deviceDetectionDuc'

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@5
 *
 */

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
	return combineReducers({
		i18next: i18nextReducer,
		app: reduceReducers(
			AppDuc.reducer,
			CookieDuc.reducer,
			DeviceDetectionDuc.reducer
		),
		auth: AuthDuc.reducer,
		page: PageDuc.reducer,
		tradeDocumentManager: TradeDocDuc.reducer,
		weighBridge: WeighBridgeDuc.reducer,
		admin: AdminDuc.reducer,
		feedback: FeedbackDuc.reducer,
		messages: MessagesDuc.reducer,
		mill: MillDuc.reducer,
		uploadContent: UploadContentDuc.reducer,
		webTour: WebTourDuc.reducer,
		products: ProductsDuc.reducer,
		...injectedReducers,
	})
}

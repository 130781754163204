import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'ui-tdm-app/modules/App/components/Error'
import { FeedbackDuc } from './duc'
import { FeedbackScreen } from './containers/FeedbackScreen'

const modulesMap = {
	feedback: FeedbackScreen,
}

const Feedback = () => {
	const activeModule = useSelector(FeedbackDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default Feedback

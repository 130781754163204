import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { MillDuc } from 'ui-tdm-app/modules/Mill/duc'
import { KeyValueSegment } from 'ui-tdm-app/modules/TradeDocumentManager/components/KeyValueSegment'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { getDateByFormat, useDateByFormat } from 'ui-tdm-app/utils/date'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'

const ViewCPOQuality = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	const cpoQualityDetails = useSelector(
		MillDuc.selectors.getSingleCPOQualityReport
	)

	const entityDate = getIn(cpoQualityDetails, ['entityDate'])
	const doNumber = getIn(cpoQualityDetails, ['doNumber'])
	const quantity = getIn(cpoQualityDetails, ['quantity'])
	const uom = getIn(cpoQualityDetails, ['uom'])
	const oer = getIn(cpoQualityDetails, ['oer'])
	const ffa = getIn(cpoQualityDetails, ['ffa'])
	const dobi = getIn(cpoQualityDetails, ['dobi'])
	const mni = getIn(cpoQualityDetails, ['mniValue'])
	const cpoCreationDate = getIn(cpoQualityDetails, ['cpoCreationDate'])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.quality'),
			name: 'quality',
			isActive: true,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'quality') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.MILL$SUBROOT,
					{
						rootModule: 'quality',
					}
				)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={t('viewCPOQuality.title')} />
			</Box>
			<Box>
				<BoxShadowWrapper
					style={{
						width: isMobile ? 325 : 960,
						maxWidth: 960,
						minWidth: 325,
					}}
				>
					<Box padding="0 15px">
						<Box padding="20px 0 ">
							<KeyValueSegment
								name={t('viewCPOQuality.issueDate')}
								description={
									getDateByFormat(
										entityDate,
										null,
										activeLocale
									) || '---'
								}
							/>
							<KeyValueSegment
								name={t('viewCPOQuality.doNumber')}
								description={doNumber || '---'}
							/>
							<KeyValueSegment
								name={t('viewCPOQuality.quantity')}
								description={`${quantity} ${uom}` || '---'}
							/>
						</Box>
					</Box>
					<Box style={{ borderTop: '1px solid #ddd' }}>
						<Box
							row
							style={{
								flexWrap: 'wrap',
								paddingBottom: '16px',
								paddingTop: '16px',
								paddingLeft: 15,
								paddingRight: 60,
							}}
						>
							<Box padding="0 12px 0 0">
								<KeyValueSegment
									name={t('viewCPOQuality.oer')}
									description={oer || '---'}
								/>
								<KeyValueSegment
									name={t('viewCPOQuality.ffa')}
									description={ffa || '0'}
								/>
								<KeyValueSegment
									name={t('viewCPOQuality.dobi')}
									description={dobi || '---'}
								/>
								<KeyValueSegment
									name={t('viewCPOQuality.mni')}
									description={mni || '---'}
								/>
							</Box>
						</Box>
					</Box>
					<Box
						style={{
							borderTop: '1px solid #ddd',
							flexWrap: 'wrap',
							paddingBottom: '16px',
							paddingTop: '16px',
							paddingLeft: 15,
							paddingRight: 60,
						}}
						row
					>
						<KeyValueSegment
							name={t('viewCPOQuality.createdAt')}
							description={useDateByFormat(
								cpoCreationDate,
								'd LLL yyyy, hh:mm a'
							)}
						/>
					</Box>
				</BoxShadowWrapper>
			</Box>

			<Box padding="25px 0" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MILL$SUBROOT,
								{
									rootModule: 'quality',
								}
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { ViewCPOQuality }

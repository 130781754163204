/* eslint-disable radix */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { MillDuc } from 'ui-tdm-app/modules/Mill/duc'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { KeyValueSegment } from 'ui-tdm-app/modules/TradeDocumentManager/components/KeyValueSegment'
import { NAME_ALIASES } from 'ui-tdm-app/modules/Mill/config'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Button } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useFormik } from 'formik'
import * as yup from 'yup'

const CreateFFBQuality = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)
	const locationState = useSelector(MillDuc.selectors.location)
	const { payload = {} } = locationState
	const { action } = payload

	const initialValues = useSelector(
		MillDuc.selectors.getInitialFFBQualityFormValues
	)

	const entityInfo = useSelector(MillDuc.selectors.getEntityInfoForFFBReport)
	const {
		doNumber,
		dispatchDate,
		entityID,
		totalQty,
		productUOM,
	} = entityInfo

	const productName = getIn(entityInfo, ['productInfo', 0, 'label'])

	const actionTitle = NAME_ALIASES[action]

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.tradeDocManager'),
			name: 'tradeDocManager',
			isActive: true,
		},
	]

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'tradeDocManager') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule: 'incoming',
					},
					{},
					{},
					true
				)
			)
		}
	}

	const [disableCTA, setDisableCTA] = useState(false)

	const validationSchema = yup
		.object()
		.shape({
			ripeBunches: yup.number().min(0),
			overripeBunches: yup.number().min(0),
			underripeBunches: yup.number().min(0),
			emptyBunches: yup.number().min(0),
			unripeBunches: yup.number().min(0),
			wetBunches: yup.number().min(0),
		})
		// eslint-disable-next-line func-names
		.test('sums', function(value) {
			if (!value) return true
			const {
				ripeBunches,
				emptyBunches,
				overripeBunches,
				underripeBunches,
				wetBunches,
				unripeBunches,
			} = value
			let valid = false

			valid =
				(parseInt(ripeBunches) || 0) +
					(parseInt(overripeBunches) || 0) +
					(parseInt(underripeBunches) || 0) +
					(parseInt(emptyBunches) || 0) +
					(parseInt(unripeBunches) || 0) +
					(parseInt(wetBunches) || 0) ===
				totalQty

			if (valid) {
				setDisableCTA(true)
			} else setDisableCTA(false)

			return (
				valid ||
				this.createError({
					path: 'ripeBunches',
					message: '',
				})
			)
		})

	const {
		handleSubmit,
		submitForm,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			const formValues = {
				ripeBunches:
					_values.ripeBunches === '' ? 0 : _values.ripeBunches,
				overripeBunches:
					_values.overripeBunches === ''
						? 0
						: _values.overripeBunches,
				underripeBunches:
					_values.underripeBunches === ''
						? 0
						: _values.underripeBunches,
				emptyBunches:
					_values.emptyBunches === '' ? 0 : _values.emptyBunches,
				unripeBunches:
					_values.unripeBunches === '' ? 0 : _values.unripeBunches,
				wetBunches: _values.wetBunches === '' ? 0 : _values.wetBunches,
				productID: getIn(entityInfo, ['productInfo', 0, 'id']),
				entityID,
			}

			switch (action) {
				case 'create': {
					dispatch(
						MillDuc.creators.createFFBQualityReport(
							formValues,
							t('common.ffbQualityRptSuccess')
						)
					)

					break
				}
				case 'edit': {
					const updatedValues = {
						...formValues,
						attachmentID: payload.attachmentID,
					}
					dispatch(
						MillDuc.creators.editFFBQualityReport(
							updatedValues,
							t('common.ffbQualityRptUpdate')
						)
					)

					break
				}

				default:
					break
			}
		},
	})

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Title
					title={`${t(actionTitle)} ${t('ffbQualityCreate.title')} `}
				/>
			</Box>
			<Spacer size={8} />
			<Box>
				<BoxShadowWrapper>
					<Box padding={16}>
						<KeyValueSegment
							name={t('ffbQualityCreate.consgnmntNum')}
							description={doNumber || '---'}
							widthProp="145px"
						/>
						<KeyValueSegment
							name={t('ffbQualityCreate.dispatchDate')}
							description={
								getDateByFormat(
									dispatchDate,
									null,
									activeLocale
								) || '---'
							}
							widthProp="145px"
						/>
						<Box
							style={{ borderTop: '1px solid #ddd' }}
							padding="16px 0"
						>
							<KeyValueSegment
								name={t('ffbQualityCreate.product')}
								description={productName || '---'}
								widthProp="145px"
							/>
							<KeyValueSegment
								name={t('ffbQualityCreate.quantity')}
								description={
									`${totalQty} ${productUOM}` || '---'
								}
								widthProp="145px"
							/>
						</Box>
					</Box>
				</BoxShadowWrapper>
			</Box>
			<Spacer size={24} />

			<CollapsableSegment
				expand
				title={t('ffbQualityCreate.ffbDetails')}
				hasError={!disableCTA}
				open
				message={
					disableCTA
						? t('validation.collapsableSegmentSuccess')
						: t('validation.ffbQualityCollapsableSegmentWarning')
				}
				onToggle={() => {}}
			>
				<form onSubmit={handleSubmit}>
					<Box row={!isMobile}>
						<Box width={300}>
							<Input
								label={t('ffbQualityCreate.ripeBunches')}
								name="ripeBunches"
								value={values.ripeBunches}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched.ripeBunches && errors.ripeBunches
								}
							/>
						</Box>
						<Spacer size={16} />
						<Box width={300}>
							<Input
								label={t('ffbQualityCreate.overripeBunches')}
								name="overripeBunches"
								value={values.overripeBunches}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched.overripeBunches &&
									errors.overripeBunches
								}
							/>
						</Box>
					</Box>
					<Box row={!isMobile}>
						<Box width={300}>
							<Input
								label={t('ffbQualityCreate.underripeBunches')}
								name="underripeBunches"
								value={values.underripeBunches}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched.underripeBunches &&
									errors.underripeBunches
								}
							/>
						</Box>
						<Spacer size={16} />
						<Box width={300}>
							<Input
								label={t('ffbQualityCreate.emptyBunches')}
								name="emptyBunches"
								value={values.emptyBunches}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched.emptyBunches && errors.emptyBunches
								}
							/>
						</Box>
					</Box>
					<Box row={!isMobile}>
						<Box width={300}>
							<Input
								label={t('ffbQualityCreate.wetBunches')}
								name="wetBunches"
								value={values.wetBunches}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.wetBunches && errors.wetBunches}
							/>
						</Box>
						<Spacer size={16} />
						<Box width={300}>
							<Input
								label={t('ffbQualityCreate.unripeBunches')}
								name="unripeBunches"
								value={values.unripeBunches}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched.unripeBunches &&
									errors.unripeBunches
								}
							/>
						</Box>
					</Box>
				</form>
			</CollapsableSegment>
			<Spacer size={16} />
			<Box row justifyContent="flex-end">
				<Box style={{ padding: 2 }} width="200px">
					<Button
						disabled={!disableCTA}
						label={t('common.submit')}
						primary
						rounded
						onClick={() => submitForm()}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export { CreateFFBQuality }

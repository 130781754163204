import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'ui-tdm-app/modules/App/components/Error'
import { WeighBridgeDuc } from '../duc'
import { CreateSlip } from './WeighBridgeControl/CreateSlip'
import { ViewSlip } from './WeighBridgeControl/ViewSlip'

const modulesMap = {
	create: CreateSlip,
	view: ViewSlip,
	edit: CreateSlip,
}

const SubDashboard = () => {
	const location = useSelector(WeighBridgeDuc.selectors.location)
	const { payload = {} } = location
	const activeModule = payload.action
	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default SubDashboard

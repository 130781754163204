import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import { getRandomNumber } from 'ui-tdm-app/utils/helpers'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { applyMediaQueries } from 'ui-lib/utils/helpers'
import { Label, P, Text } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'

const Line = styled.div(p => ({
	width: p.size || 'inherit',
	borderBottom: '1px solid #c9c9c9',
}))

const SummaryWrapper = styled(Box)({
	paddingRight: 40,
	'&:last-child': {
		paddingRight: 'initial',
	},
	...applyMediaQueries(['mobile'], {
		paddingTop: 4,
		paddingBottom: 4,
	}),
})
const ALIASES_KEYS = {
	issuedTo: 'auditReport.issuedTo',
	doNumber: 'auditReport.doNumber',
	productName: 'auditReport.productName',
	quantity: 'auditReport.quantity',
	binNumber: 'auditReport.binNumber',
	blockNo: 'auditReport.blockNo',
	fieldNo: 'auditReport.fieldNo',
	driverName: 'auditReport.driverName',
	vehicleNumber: 'auditReport.vehicleNumber',
	vehicleType: 'auditReport.vehicleType',
	dispatchTime: 'auditReport.dispatchTime',
	deliveredTime: 'auditReport.deliveredTime',
	orgCategory: 'auditReport.orgCategory',
	date: 'auditReport.date',
	contract: 'auditReport.contract',
	ticket: 'auditReport.ticket',
	certificateOrg: 'auditReport.certificateOrg',
	certificateDate: 'auditReport.certificateDate',
	certificateExpiryDate: 'auditReport.certificateExpiryDate',
	certificationNumber: 'auditReport.certificationNumber',
	groupCertificationNumber: 'auditReport.groupCertificationNumber',
	fieldNumber: 'auditReport.fieldNumber',
	fieldSize: 'auditReport.fieldSize',
	yield: 'auditReport.yield',
	totalArea: 'auditReport.totalArea',
}

const SummaryBlock = ({ name, detail, onClick, isLink }) => (
	<SummaryWrapper>
		<Label color="#263b97" small>
			{name}
		</Label>
		<Box height={40}>
			{typeof detail === 'string' ? (
				<P small link={isLink} onClick={onClick}>
					{detail}
				</P>
			) : (
				detail
			)}
		</Box>
	</SummaryWrapper>
)

SummaryBlock.defaultProps = {
	onClick: () => {},
}

const DocumentInformationCard = ({
	title,
	issuedTo,
	address,
	location,
	category,
	issuedBy,
	transporterDetails,
	productDetails,
	certificateInformation,
}) => {
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const certificates = certificateInformation.map(_certificate => {
		const certificate = getIn(_certificate, ['meta', 'certificate']) || {}

		return [
			{ certificateOrg: certificate.issuingBody || '' },
			{
				certificationNumber: getIn(_certificate, ['number']) || '',
			},
			{
				certificateExpiryDate:
					getDateByFormat(getIn(_certificate, ['expiryEndDate'])) ||
					'',
			},
			{
				certificateDate:
					getDateByFormat(getIn(_certificate, ['issueDate'])) || '',
			},
		]
	})

	return (
		<Box padding={5} overflow>
			<BoxShadowWrapper>
				<Box
					padding={8}
					style={{
						backgroundColor: theme.color.accent3,
					}}
				>
					<P bold color={theme.color.accent2}>
						{title}
					</P>
				</Box>

				<Box padding={16}>
					<Box row={!isMobile}>
						<Box
							width={isMobile ? '100%' : '50%'}
							alignItems="baseline"
							marginBottom="5px"
						>
							{issuedTo && (
								<SummaryBlock
									name={t('auditReport.issuedTo')}
									detail={issuedTo}
								/>
							)}

							{issuedBy && (
								<P bold color="#263b97">
									{issuedBy}
									<Spacer size={8} />
								</P>
							)}
							{address && (
								<SummaryBlock
									name={t('auditReport.address')}
									detail={address}
									height="40px"
								/>
							)}
							{location && (
								<SummaryBlock
									name={t('auditReport.location')}
									detail={location}
								/>
							)}
							{category && (
								<SummaryBlock
									name={t('auditReport.orgCategory')}
									detail={category}
								/>
							)}
						</Box>
					</Box>
					<Spacer size={20} />
					{certificateInformation.length > 0 && (
						<Box>
							<Box row alignItems="center">
								<Text>{t('auditReport.certificate')}</Text>
								<Spacer size={8} />
								<Line size={isMobile ? 210 : 850} />
							</Box>
							<Spacer size={8} />

							<Box
								width="100%"
								row={!isMobile}
								justifyContent="space-between"
								style={{ paddingTop: '8px' }}
							>
								<Box row wrap>
									{certificates.map(arritem => {
										return (
											<Box row key={getRandomNumber()}>
												{arritem.map(_key => (
													<SummaryBlock
														key={_key}
														name={t(
															ALIASES_KEYS[
																Object.keys(
																	_key
																)
															]
														)}
														detail={Object.values(
															_key
														)}
													/>
												))}
											</Box>
										)
									})}
								</Box>
							</Box>
						</Box>
					)}
					{productDetails.length > 0 && (
						<Box>
							<Box row alignItems="center">
								<Text>
									{t('auditReport.productInformation')}
								</Text>
								<Spacer size={8} />
								<Line size={isMobile ? 210 : 850} />
							</Box>
							<Spacer size={8} />

							<Box
								width="100%"
								row={!isMobile}
								justifyContent="space-between"
								style={{ paddingTop: '8px' }}
							>
								<Box row wrap>
									{productDetails.map(_key => (
										<SummaryBlock
											key={_key}
											name={t(
												ALIASES_KEYS[Object.keys(_key)]
											)}
											detail={Object.values(_key)}
										/>
									))}
								</Box>
							</Box>
						</Box>
					)}
					<Spacer size={8} />
					{transporterDetails.length > 0 && (
						<Box>
							<Box row alignItems="center">
								<Text>
									{t('auditReport.transportInformation')}
								</Text>
								<Spacer size={8} />
								<Line size={isMobile ? 200 : 830} />
							</Box>
							<Spacer size={8} />

							<Box
								width="100%"
								row={!isMobile}
								justifyContent="space-between"
								style={{ paddingTop: '8px' }}
							>
								<Box row wrap>
									{transporterDetails.map(_key => (
										<SummaryBlock
											key={_key}
											name={t(
												ALIASES_KEYS[Object.keys(_key)]
											)}
											detail={Object.values(_key)}
										/>
									))}
								</Box>
							</Box>
						</Box>
					)}
				</Box>
			</BoxShadowWrapper>
		</Box>
	)
}

DocumentInformationCard.defaultProps = {
	additionalMeta: [],
	transporterDetails: [],
	productDetails: [],
	certificateInformation: [],
}

export { DocumentInformationCard }

import React, { useMemo, useCallback } from 'react'
import { getIn, addLast } from 'timm'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { useTranslation } from 'react-i18next'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { Box } from 'ui-lib/utils/Box'
import { ModuleIconMap } from 'ui-lib/utils/config'
import { H3 } from 'ui-lib/components/Typography'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { Spacer } from 'ui-lib/utils/Spacer'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import SendSvg from 'ui-lib/icons/send.svg'
import { KeyValueSegment } from 'ui-tdm-app/modules/TradeDocumentManager/components/KeyValueSegment'

import { extractNonStandardAttributes } from 'ui-tdm-app/modules/TradeDocumentManager/helpers'
import { NAME_ALIASES, INITIAL_TYPES } from '../../../config'
import { Title } from '../../../components/Title'
import { NumberIndicator } from '../../../components/Fragments'
import { ShippingStaticSection } from '../Shared/ShippingSection'
import { ProductsSection } from '../Shared/ProductsSection'
import { BankDetailStaticBlock } from '../Shared/BankDetails'
import { CoreStaticSection, UnCoreStaticSection } from '../Shared/CoreSection'
import { AdditionalInfoStaticBlock } from '../Shared/AdditionalInformation'
import { AuthorizationStaticBlock } from '../Shared/AuthorizationBlock'

export const PreviewBlock = ({
	actor,
	isMobile,
	activeDocument,
	attachments,
}) => {
	const locationState = useSelector(TradeDocDuc.selectors.location)
	const { t } = useTranslation()
	const { query = {} } = locationState
	const { parentDocRef: _query = '' } = query
	const [parentType] = _query.split('~')

	return (
		<>
			{getIn(activeDocument, ['meta', 'binDetails']) && (
				<>
					<UnCoreStaticSection
						isMobile={isMobile}
						DocumentKey={t('createBin.binLabel')}
						DocumentReference={
							getIn(activeDocument, [
								'meta',
								'binDetails',
								'storageUnitID',
							]) || ''
						}
						parentDocType="plantation"
						issuedOn={
							getIn(activeDocument, [
								'meta',
								'binDetails',
								'createdAt',
							]) || ''
						}
					/>
					<Spacer size={24} />
				</>
			)}
			{attachments && !!attachments.length && (
				<>
					<CollapsableSegment
						expand
						plain
						title={t('tdmPreviewDetails.attachments')}
					>
						<Box>
							<Title
								title={t('tdmPreviewDetails.weighbridgeSlip')}
								icon={ModuleIconMap['delivery-order']}
								small
								link
							/>
							<Spacer size={16} />
							<Title
								title={t('tdmPreviewDetails.workEntry')}
								icon={ModuleIconMap['delivery-order']}
								small
								link
								onClick={() => {}}
							/>
						</Box>
					</CollapsableSegment>
					<Spacer size={24} />
				</>
			)}
			{getIn(activeDocument, ['meta', 'shipping']) && (
				<>
					<CollapsableSegment
						plain
						title={t('tdmPreviewDetails.shippingAndDelivery')}
					>
						<Box>
							<ShippingStaticSection
								isMobile={isMobile}
								activeRecord={
									getIn(activeDocument, [
										'meta',
										'shipping',
										'address',
									]) || {}
								}
								shipThrough={getIn(activeDocument, [
									'meta',
									'shipping',
									'shipThrough',
								])}
								shipperNumber={getIn(activeDocument, [
									'meta',
									'shipping',
									'shipperNumber',
								])}
								billOfLading={getIn(activeDocument, [
									'meta',
									'shipping',
									'billOfLading',
								])}
								modeOfTransport={getIn(activeDocument, [
									'meta',
									'shipping',
									'modeOfTransport',
									'name',
								])}
							/>
						</Box>
					</CollapsableSegment>
					<Spacer size={24} />
				</>
			)}

			{activeDocument.products && (
				<>
					<CollapsableSegment
						expand
						title={t('tdmPreviewDetails.products')}
						plain
					>
						<Box>
							<Spacer size={24} />
							<ProductsSection
								isReadOnly
								parentDocType={parentType}
								activeProducts={activeDocument.products}
								actor={actor}
							/>
						</Box>
					</CollapsableSegment>
					<Spacer size={24} />
				</>
			)}

			{getIn(activeDocument, ['meta', 'bankDetails']) && (
				<>
					<CollapsableSegment
						expand
						title={t('tdmPreviewDetails.plantationDetails')}
						plain
					>
						<BankDetailStaticBlock
							isMobile={isMobile}
							values={activeDocument.bankDetails}
						/>
					</CollapsableSegment>
					<Spacer size={24} />
				</>
			)}

			{!!(getIn(activeDocument, ['meta', 'files']) || []).length && (
				<>
					<CollapsableSegment
						expand
						title={t('tdmPreviewDetails.additionalInformation')}
						plain
					>
						<AdditionalInfoStaticBlock
							values={getIn(activeDocument, ['additionalInfo'])}
						/>
					</CollapsableSegment>
					<Spacer size={24} />
				</>
			)}
			{getIn(activeDocument, ['meta', 'supplyChainModel']) && (
				<>
					<CollapsableSegment
						plain
						title={t('tdmPreviewDetails.supplyChainModel')}
					>
						<Box>
							<KeyValueSegment
								name={t('tdmPreviewDetails.supplyChainModel')}
								description={getIn(activeDocument, [
									'meta',
									'supplyChainModel',
									'name',
								])}
							/>
						</Box>
					</CollapsableSegment>
					<Spacer size={24} />
				</>
			)}
			{getIn(activeDocument, ['meta', 'authorization']) && (
				<>
					<CollapsableSegment
						expand
						title={t('tdmPreviewDetails.authorizationDetails')}
						plain
					>
						<AuthorizationStaticBlock
							values={getIn(activeDocument, [
								'meta',
								'authorization',
							])}
						/>
					</CollapsableSegment>
					<Spacer size={24} />
				</>
			)}
		</>
	)
}

const PreviewAllDetails = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const locationState = useSelector(TradeDocDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { payload = {}, query = {} } = locationState
	const { parentDocRef: _query = '' } = query
	const [parentType] = _query.split('~')
	const { rootModule } = payload
	const TitleIcon = ModuleIconMap[rootModule]
	const actor = useSelector(AuthDuc.selectors.getActor)

	const getBreadCrumbsList = useCallback(
		({ title }) => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: `${t('breadcrumb.new')} ${t(title)} - ${t(
					'breadcrumb.details'
				)}`,
				name: 'document-details',
				isActive: true,
			},
			{
				label: `${t('breadcrumb.new')} ${t(title)} - ${t(
					'breadcrumb.confirm'
				)}`,
				name: 'document-confirm',
				isActive: false,
			},
		],
		[t]
	)

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}

		if (target === 'document-details') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					locationState.type,
					{
						...locationState.payload,
						action: 'details',
					},
					locationState.query
				)
			)
		}
	}

	const title = NAME_ALIASES[rootModule]
	const breadCrumbs = useMemo(() => getBreadCrumbsList({ title }), [
		getBreadCrumbsList,
		title,
	])
	const activeDocument = useSelector(
		TradeDocDuc.selectors.getDocumentActiveRecord
	)

	const { isLoading, isSaving } = useSelector(
		TradeDocDuc.selectors.getDocumentLoadingState
	)

	const parentDocRef = useSelector(
		TradeDocDuc.selectors.getDocumentParentDocRef
	)
	const parentDocType = parentDocRef.type
	const isParentAStandardDoc = INITIAL_TYPES.includes(parentDocType)

	const issuingParty = useSelector(AuthDuc.selectors.getCurrentOrganization)
	const receivingParty = useSelector(
		TradeDocDuc.selectors.getDocumentActivePartner
	)
	const receivingPartyDetails = useSelector(state =>
		AuthDuc.selectors.getPartnerOrganizationByID(state, receivingParty.id)
	)
	const initiatingPartyDetails = useSelector(state =>
		AuthDuc.selectors.getOrganizationByID(state, issuingParty.id)
	)

	const customParentDocOrg = useSelector(state =>
		AuthDuc.selectors.getOrganizationByID(
			state,
			parentDocRef.organizationID
		)
	)

	const fromAddress = getIn(initiatingPartyDetails, ['primaryAddress']) || {}

	const toAddress = getIn(receivingPartyDetails, ['primaryAddress']) || {}

	const additionalCoreMeta = useMemo(() => {
		let meta = []

		if (activeDocument.meta) {
			if (activeDocument.meta.entityReference) {
				meta = addLast(meta, {
					name: 'Reference Number',
					description: activeDocument.meta.entityReference,
				})
			}
			if (activeDocument.meta.transporter) {
				if (activeDocument.meta.transporter.vehicleNumber) {
					meta = addLast(meta, {
						name: 'Vehicle Number',
						description:
							activeDocument.meta.transporter.vehicleNumber,
					})
				}
				if (activeDocument.meta.transporter.driverName) {
					meta = addLast(meta, {
						name: 'Driver Name',
						description: activeDocument.meta.transporter.driverName,
					})
				}
			}
		}

		return meta
	}, [activeDocument.meta])

	const nonStandardDocAttributes = useMemo(() => {
		return extractNonStandardAttributes(parentDocRef)
	}, [parentDocRef])

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<Box row justifyContent="space-between" alignItems="baseline">
				<Title
					title={`${t('breadcrumb.new')} ${t(title)}`}
					icon={TitleIcon}
				/>
			</Box>
			<Spacer size={8} />
			<Box row>
				<NumberIndicator>2</NumberIndicator>
				<Spacer size={10} horizontal />
				<H3>{t('tdmPreviewDetails.confirmDetails')}</H3>
			</Box>
			<>
				<Spacer size={24} />
				<CoreStaticSection
					isMobile={isMobile}
					DocumentKey={`${
						parentDocType
							? t(NAME_ALIASES[parentDocType])
							: `${t(title)} Reference`
					} Number`}
					DocumentReference={
						parentDocType
							? parentDocRef.number
							: getIn(activeDocument, ['meta', 'entityReference'])
					}
					issuedBy={issuingParty.name || '---'}
					issuedOn={getIn(activeDocument, ['meta', 'issueDate'])}
					issuedTo={receivingPartyDetails.name || '---'}
					expectedOn={getIn(activeDocument, [
						'meta',
						'expectedDeliveryDate',
					])}
					additionalMeta={additionalCoreMeta}
					toAddress={toAddress}
					fromAddress={fromAddress}
				/>
				<Spacer size={24} />
				{parentDocType &&
					!isParentAStandardDoc &&
					parentDocType !== 'plantation' && (
						<>
							<UnCoreStaticSection
								isMobile={isMobile}
								DocumentKey={`${t(
									NAME_ALIASES[parentDocType]
								)} Number`}
								DocumentReference={
									parentDocRef.name ||
									parentDocRef.batchReference
								}
								parentDocType={parentType}
								issuedOn={parentDocRef.createdAt}
								issuedBy={
									customParentDocOrg.name || issuingParty.name
								}
								additionalMeta={nonStandardDocAttributes}
							/>
							<Spacer size={24} />
						</>
					)}
				<PreviewBlock
					actor={actor}
					isMobile={isMobile}
					activeDocument={activeDocument}
				/>
				<Box padding="24px 0" row justifyContent="space-between">
					<Box style={{ padding: 2 }}>
						<Button
							label={t('tdmPreviewDetails.goBack')}
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={LeftArrowIcon} />
								</ButtonIconWrapper>
							}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										locationState.type,
										{
											...locationState.payload,
											action: 'details',
										},
										locationState.query
									)
								)
							}
						/>
					</Box>
					<Box row={!isMobile} style={{ padding: 2 }}>
						<Button
							onClick={() => {
								const currentDoc = { ...activeDocument }
								if (activeDocument?.meta?.attachmentFlow) {
									const currentMeta = {
										...activeDocument.meta,
									}
									currentMeta.attachmentFlow = false
									currentDoc.meta = currentMeta
								}

								const documentPropsList = {
									rootModule,
									document: currentDoc,
									currentDocId: '',
									successMsg: t(
										'tdmDetailsEntry.entityCreatedMsg'
									),
									t,
									poSuccessMsg: t('common.poSuccess'),
									invoiceSuccessMsg: t(
										'common.invoiceSuccess'
									),
									doSuccessMsg: t('common.doSuccess'),
									fromPlantation: actor.some(str =>
										str.includes('palmoil_plantation')
									),
									actionType: 'submit',
								}
								dispatch(
									TradeDocDuc.creators.initiateDocumentSave(
										documentPropsList
									)
								)
							}}
							label={t('tdmPreviewDetails.submitDetails')}
							isLoading={isLoading || isSaving}
							primary
							rounded
							customIcon={
								<ButtonIconWrapper>
									<Icon glyph={SendSvg} />
								</ButtonIconWrapper>
							}
						/>
					</Box>
				</Box>
			</>
		</Box>
	)
}

export { PreviewAllDetails }

import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { DocumentTreeBlock } from 'ui-tdm-app/modules/TradeDocumentManager/components/DocumentTreeBlock'

const CollapsibleDocStacks = ({
	getExplorerLink,
	documents = [],
	activeDocumentKey = '',
	expandedDocumentKeys = [],
	activeDocument = {},
	hederaMessages = {},
	forTraceReport = false,
}) => {
	const dispatch = useDispatch()

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			if (actionType === 'open-document') {
				dispatch(TradeDocDuc.creators.setActiveTab('original'))

				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types
							.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
						{
							rootModule: meta.type,
							documentReference: meta.id,
							documentStatus: null,
						}
					)
				)
			}
		},
		[dispatch]
	)

	return (
		documents &&
		!!documents.length && (
			<>
				{documents.map(doc => (
					<DocumentTreeBlock
						key={forTraceReport ? doc.traceID : doc.id}
						isOpen={expandedDocumentKeys.includes(
							forTraceReport ? doc.traceID : doc.id
						)}
						config={doc}
						handleChange={handleOnChange}
						isActive={
							forTraceReport
								? doc.traceID === activeDocumentKey
								: doc.id === activeDocumentKey
						}
						activeDocument={activeDocument}
						hederaMessages={hederaMessages}
						currentEntityID={doc.component?.entity?.id || ''}
						forTraceReport={forTraceReport}
						getExplorerLink={getExplorerLink}
					>
						{doc.children && doc.children.length > 0 && (
							<CollapsibleDocStacks
								documents={doc.children}
								activeDocumentKey={activeDocumentKey}
								handleOnChange={
									!forTraceReport && handleOnChange
								}
								forTraceReport={forTraceReport}
								hederaMessages={hederaMessages}
								getExplorerLink={getExplorerLink}
							/>
						)}
					</DocumentTreeBlock>
				))}
			</>
		)
	)
}

export { CollapsibleDocStacks }

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { MillDuc } from 'ui-tdm-app/modules/Mill/duc'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { NAME_ALIASES } from 'ui-tdm-app/modules/Mill/config'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Label } from 'ui-lib/components/Typography'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import { Button } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useFormik } from 'formik'
import * as yup from 'yup'

const CreateCPOQuality = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const locationState = useSelector(MillDuc.selectors.location)
	const { payload = {}, query } = locationState
	const { action } = payload

	const entityID = getIn(query, ['parentDocRef'])

	const initialValues = useSelector(
		MillDuc.selectors.getInitialCPOQualityFormValues
	)

	const entityInfo = useSelector(
		MillDuc.selectors.getEntityInfoForCPOQualityReport
	)

	const quantity = getIn(entityInfo, ['quantity'])
	const uom = getIn(entityInfo, ['uom'])
	const entityDate = getIn(entityInfo, ['entityDate'])

	const actionTitle = NAME_ALIASES[action]

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.tradeDocManager'),
			name: 'tradeDocManager',
			isActive: true,
		},
	]

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'tradeDocManager') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule: 'outgoing',
					},
					{},
					{},
					true
				)
			)
		}
	}

	const validationSchema = yup.object().shape({
		ffa: yup.number().positive(),
		dobi: yup.number().positive(),
		mniValue: yup.number().positive(),
		acidity: yup.number().positive(),
		humidity: yup.number().positive(),
		impurities: yup.number().positive(),
		meltingPoint: yup.number().positive(),
		iodineIndex: yup.number().positive(),
		colour: yup.string(),
	})

	const {
		handleSubmit,
		submitForm,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			const formValues = {
				..._values,
				entityID,
			}
			switch (action) {
				case 'create': {
					dispatch(
						MillDuc.creators.createCPOQualityReport(
							formValues,
							t('common.cpoQualityRptSuccess')
						)
					)

					break
				}

				case 'edit': {
					const updatedValues = {
						...formValues,
						attachmentID: payload.attachmentID,
					}
					dispatch(
						MillDuc.creators.editCPOQualityReport(
							updatedValues,
							t('common.cpoQualityRptUpdate')
						)
					)

					break
				}

				default:
					break
			}
		},
	})

	const disableCTA = !(
		values.acidity ||
		values.humidity ||
		values.impurities ||
		values.meltingPoint ||
		values.iodineIndex ||
		values.colour ||
		values.ffa ||
		values.dobi ||
		values.mniValue
	)

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Title
					title={`${t(actionTitle)} ${t('cpoQualityCreate.title')} `}
				/>
			</Box>

			<Spacer size={8} />

			<CollapsableSegment
				expand
				title={t('cpoQualityCreate.cpoDetails')}
				body={
					<Box row>
						<Label>
							Produced Quantity:&nbsp;
							<Label bold>
								{quantity} {uom}
							</Label>
						</Label>
						<Spacer size={40} horizontal />
						<Label>{getDateByFormat(entityDate)}</Label>
					</Box>
				}
				hasError={disableCTA}
				open
				message={
					disableCTA
						? t('validation.collapsableSegmentWarning')
						: t('validation.collapsableSegmentSuccess')
				}
				onToggle={() => undefined}
			>
				<form onSubmit={handleSubmit}>
					<Box
						row={!isMobile}
						margin="16px 0"
						justifyContent="space-between"
					>
						<Box width={300}>
							<Input
								label={t('cpoQualityCreate.ffa')}
								name="ffa"
								value={values.ffa}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.ffa && errors.ffa}
							/>
						</Box>

						<Spacer size={24} />

						<Box width={300}>
							<Input
								label={t('cpoQualityCreate.dobi')}
								name="dobi"
								value={values.dobi}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.dobi && errors.dobi}
							/>
						</Box>

						<Spacer size={24} />

						<Box width={300}>
							<Input
								label={t('cpoQualityCreate.mni')}
								name="mniValue"
								value={values.mniValue}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.mniValue && errors.mniValue}
							/>
						</Box>
					</Box>
					<Box
						row={!isMobile}
						margin="16px 0"
						justifyContent="space-between"
					>
						<Box width={300}>
							<Input
								label={t('cpoQualityCreate.acidity')}
								name="acidity"
								value={values.acidity}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.acidity && errors.acidity}
							/>
						</Box>

						<Spacer size={24} />

						<Box width={300}>
							<Input
								label={t('cpoQualityCreate.humidity')}
								name="humidity"
								value={values.humidity}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.humidity && errors.humidity}
							/>
						</Box>

						<Spacer size={24} />

						<Box width={300}>
							<Input
								label={t('cpoQualityCreate.impurities')}
								name="impurities"
								value={values.impurities}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.impurities && errors.impurities}
							/>
						</Box>
					</Box>
					<Box
						row={!isMobile}
						margin="16px 0"
						justifyContent="space-between"
					>
						<Box width={300}>
							<Input
								label={t('cpoQualityCreate.meltingPoint')}
								name="meltingPoint"
								value={values.meltingPoint}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched.meltingPoint && errors.meltingPoint
								}
							/>
						</Box>

						<Spacer size={24} />

						<Box width={300}>
							<Input
								label={t('cpoQualityCreate.iodineIndex')}
								name="iodineIndex"
								value={values.iodineIndex}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched.iodineIndex && errors.iodineIndex
								}
							/>
						</Box>

						<Spacer size={24} />

						<Box width={300}>
							<Input
								label={t('cpoQualityCreate.color')}
								name="colour"
								value={values.colour}
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.colour && errors.colour}
							/>
						</Box>
					</Box>
				</form>
			</CollapsableSegment>
			<Spacer size={16} />
			<Box row justifyContent="flex-end">
				<Box style={{ padding: 2 }} width="200px">
					<Button
						disabled={disableCTA}
						label={t('common.submit')}
						primary
						rounded
						onClick={() => submitForm()}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export { CreateCPOQuality }

import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { merge, getIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useTranslation } from 'react-i18next'
import { Modal } from 'ui-lib/components/Modal'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import {
	getNextSortOrder,
	sortArray,
	supplyChainModels,
} from 'ui-tdm-app/utils/helpers'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { SortDown, SortReset } from 'ui-lib/components/Table'
import EditIcon from 'ui-lib/icons/edit.svg'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import PlusIcon from 'ui-lib/icons/plus.svg'
import { BoxShadowTable } from '../../../components/BoxShadowTable'
import { TABLE_ALIAS } from '../../../config'
import { ProductsForm } from './ProductsForm'

const SORTABLE_FIELDS = [
	'code',
	'name',
	'quantity',
	'uom',
	'count',
	'metricUnitPrice',
	'tax',
	'totalPrice',
	'actions',
]

const PRODUCT_NAMING_ALIASES = {
	code: 'productCode',
	name: 'productName',
}

const getColumnsConfig = columnList => {
	const {
		rootModule,
		onChange,
		sortsMap,
		isReadOnly,
		allowMinimalEdit,
		activeProducts,
		isEntityDO,
		_action,
		parentDocType,
		t,
		actor,
	} = columnList
	// const if readOnly, remove the action segment from trigger
	let targetFields = SORTABLE_FIELDS
	if (_action === 'details' || _action === 'confirm') {
		if (parentDocType === 'plantation') {
			targetFields = SORTABLE_FIELDS.filter(field => field !== 'uom')
		} else {
			targetFields = SORTABLE_FIELDS.filter(
				field =>
					field !== 'uom' &&
					field !== 'count' &&
					field !== 'defaultUOM'
			)
			if (rootModule === 'delivery-order') {
				targetFields = targetFields.filter(
					field =>
						field !== 'metricUnitPrice' &&
						field !== 'tax' &&
						field !== 'totalPrice'
				)
			}
		}
	} else if (isReadOnly) {
		if ((getIn(activeProducts, [0, 'count']) || 0) > 0) {
			targetFields = SORTABLE_FIELDS.filter(
				a => a !== 'actions' && a !== 'defaultUOM'
			)
		} else {
			targetFields = SORTABLE_FIELDS.filter(
				a => a !== 'actions' && a !== 'defaultUOM' && a !== 'count'
			)
		}
	}
	const canDelete = activeProducts && activeProducts.length > 1

	return targetFields.map(targetField => {
		const titleCode = PRODUCT_NAMING_ALIASES[targetField] || targetField

		return {
			property: targetField === 'code' ? 'id' : targetField,
			primary: targetField === 'code',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer' }}
					onClick={() => {
						// call onChange with appropriate invocation
						if (targetField !== 'actions')
							onChange('initiate_sort', {
								rootModule,
								field: targetField,
								order: getNextSortOrder(sortsMap[targetField]),
							})
					}}
				>
					{targetField !== 'actions' && (
						<>
							<IconWrapper
								width={10}
								margin="0 5px"
								sortUp={sortsMap[targetField] === 'asc'}
								sortDown={sortsMap[targetField] === 'desc'}
							>
								<Icon
									rotate180={sortsMap[targetField] === 'asc'}
									glyph={
										sortsMap[targetField]
											? SortDown
											: SortReset
									}
								/>
							</IconWrapper>
							<TableTitle>{t(TABLE_ALIAS[titleCode])}</TableTitle>
						</>
					)}
				</Box>
			),
			render: datum => {
				// return edit & deletable components here
				if (targetField === 'actions') {
					return (
						allowMinimalEdit &&
						parentDocType !== 'plantation' && (
							<Box row alignItems="center">
								<IconWrapper
									hoverEffect
									width={20}
									height={20}
									onClick={e => {
										e.stopPropagation()
										onChange('modify_product', {
											rootModule,
											row: datum,
											type: 'edit',
										})
									}}
								>
									<Icon glyph={EditIcon} />
								</IconWrapper>
								{canDelete && (
									<IconWrapper
										hoverEffect
										width={25}
										height={25}
										onClick={e => {
											e.stopPropagation()
											onChange('modify_product', {
												rootModule,
												row: datum,
												type: 'delete',
											})
										}}
									>
										<Icon glyph={DeleteIcon} />
									</IconWrapper>
								)}
							</Box>
						)
					)
				}
				if (targetField === 'quantity') {
					const uom =
						actor &&
						actor.length > 0 &&
						actor[0].includes('palmoil_plantation')
							? 'Nos'
							: getIn(datum, ['uom'])
					const quantity = getIn(datum, ['quantity'])
					const lcv = getIn(datum, ['lcv'])

					return (
						<Label small>
							{`${quantity} ${uom} (LCA:${parseFloat(lcv).toFixed(
								3
							)})`}
						</Label>
					)
				}
				if (targetField === 'uom') {
					const quantity = getIn(datum, ['quantity'])

					return <Label small>{`${quantity * 1000}kg`}</Label>
				}

				if (targetField === 'defaultUOM') {
					const defaultUOM =
						actor &&
						actor.length > 0 &&
						actor[0].includes('palmoil_plantation')
							? 'Nos'
							: getIn(datum, ['defaultUOM'])

					return <Label small>{defaultUOM}</Label>
				}

				if (targetField === 'supplyChainModel') {
					const scModel = getIn(datum, ['supplyChainModel'])

					return <Label small>{t(supplyChainModels[scModel])}</Label>
				}

				if (targetField === 'tax') {
					const tax = !isEntityDO
						? getIn(datum, ['tax', 0, 'value']) || '---'
						: '---'

					return <Label small>{tax}</Label>
				}

				if (targetField === 'metricUnitPrice') {
					const tax = !isEntityDO
						? getIn(datum, ['metricUnitPrice']) || '---'
						: '---'

					return <Label small>{tax}</Label>
				}
				// if the field is total, then return the sum
				if (targetField === 'totalPrice') {
					const tax = getIn(datum, ['tax', 0, 'value']) || ''
					const totalWithoutTax =
						(parseFloat(datum.metricUnitPrice, 10) || 0) *
						(parseFloat(datum.quantity, 10) || 0)

					let total = parseFloat(
						totalWithoutTax +
							((parseFloat(tax, 10) || 0) * totalWithoutTax) / 100
					)

					if (Number.isNaN(total)) {
						total = ' '
					} else {
						total = !isEntityDO ? total.toFixed(2) : '---'
					}

					return <Label small>{total}</Label>
				}

				return <Label small>{datum[targetField]}</Label>
			},
		}
	})
}

const ProductsSection = ({
	onProductsChange,
	targetProducts: targetProductsFromProps,
	activeProducts: activeProductsFromProps,
	isReadOnly,
	allowMinimalEdit,
	canAddProducts,
	isPricingMandatory,
	isParentDoc,
	isEntityDO,
	isEntityPO,
	parentDocType,
	isEntityInvoice,
	partner,
	actor,
	individualProductEntry,
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const locationState = useSelector(TradeDocDuc.selectors.location)
	const { payload = {}, query = {} } = locationState
	const { rootModule, action: _action } = payload

	const { isFetching: isOrgFetchLoading } = useSelector(
		AuthDuc.selectors.getOrganizationFetchStatus
	)
	const [activeSort, setActiveSort] = useState({})
	const [_isLoading, setLoading] = useState(false)
	const [activeProducts, setActiveProducts] = useState(
		activeProductsFromProps
	)
	const [productsPayload, setProductsRequestPayload] = useState(
		targetProductsFromProps
	)

	useEffect(() => {
		if (activeProducts && activeProducts.length === 0)
			setActiveProducts(activeProductsFromProps || [])
	}, [activeProductsFromProps, activeProducts])
	const [isEdit, setIsEdit] = useState(false)
	const [showAddProducts, setShowAddProducts] = useState(false)
	const [activeProductIndex, setActiveProductIndex] = useState(0)
	const [currentProductDetail, setCurrentProductDetail] = useState({})

	const isLoading = _isLoading || isOrgFetchLoading

	const handleChange = useCallback(
		(action, meta) => {
			switch (action) {
				case 'initiate_sort': {
					setLoading(true)
					const clonedDocs = [...activeProducts]
					const { type, payload: _payload, query: _query = {} } =
						locationState || {}

					let key = `${rootModule}|${meta.field}|${meta.order}`

					if (meta.order === 'asc') {
						setActiveProducts(
							sortArray(clonedDocs, 'asc', a => a[meta.field])
						)
						setActiveSort({ [meta.field]: meta.order })
					} else if (meta.order === 'desc') {
						setActiveProducts(
							sortArray(
								clonedDocs,
								'desc',
								a => a[meta.field],
								true
							)
						)
						setActiveSort({ [meta.field]: meta.order })
					} else {
						setActiveProducts(activeProductsFromProps)
						setActiveSort({})
						key = ''
					}

					// filter sorts
					let targetSorts = Array.isArray(_query.sort)
						? _query.sort
						: [_query.sort]

					targetSorts = targetSorts.filter(
						_key =>
							_key &&
							!SORTABLE_FIELDS.some(
								__key =>
									_key.indexOf(`${rootModule}|${__key}`) > -1
							)
					)

					if (key) targetSorts.push(key)

					const finalQuery = merge(_query, { sort: targetSorts })

					if (!targetSorts.length) {
						delete finalQuery.sort
					}

					dispatch(
						MainRouteDuc.creators.redirect(
							type,
							_payload,
							finalQuery,
							{
								skipRouteThunk: true,
							}
						)
					)
					setLoading(false)

					break
				}

				case 'modify_product': {
					const { row = {}, type } = meta
					if (type === 'delete') {
						dispatch(
							AppDuc.creators.showConfirmationModal({
								onAccept: () => {
									const deleteProduct = []

									// eslint-disable-next-line array-callback-return
									activeProducts.map((product, index) => {
										const _index =
											row.activeIndex > index
												? index
												: index - 1
										if (
											product.activeIndex !==
											row.activeIndex
										) {
											deleteProduct.push({
												...product,
												activeIndex: _index,
											})
										}
									})

									setActiveProducts(deleteProduct)
								},
								meta: {
									message: t(
										'tdmDetailsEntry.deleteConfirmation'
									),
								},
							})
						)
					} else if (type === 'edit') {
						setIsEdit(true)
						setShowAddProducts(true)
						setCurrentProductDetail(row)
						setActiveProductIndex(row.activeIndex)
					}

					break
				}

				default:
					break
			}
		},
		[
			activeProducts,
			activeProductsFromProps,
			dispatch,
			locationState,
			rootModule,
			t,
		]
	)

	const columnsConfig = useMemo(() => {
		const columnList = {
			rootModule,
			onChange: handleChange,
			sortsMap: activeSort,
			isReadOnly,
			allowMinimalEdit,
			activeProducts,
			isEntityDO,
			_action,
			parentDocType,
			t,
			actor,
		}

		return getColumnsConfig(columnList)
	}, [
		rootModule,
		handleChange,
		activeSort,
		isReadOnly,
		allowMinimalEdit,
		activeProducts,
		isEntityDO,
		_action,
		parentDocType,
		t,
		actor,
	])

	// pass the updates to parent
	useEffect(() => {
		if (onProductsChange) {
			onProductsChange(activeProducts, productsPayload)
		}
	}, [activeProducts]) // eslint-disable-line react-hooks/exhaustive-deps

	// extract if there is sort associated
	useEffect(() => {
		if (query.sort) {
			// extract the sort
			let targetSorts = Array.isArray(query.sort)
				? query.sort
				: [query.sort]
			// extract the sort
			targetSorts = targetSorts.filter(_query => {
				const [_rootModule, sortField] = _query.split('|')

				return (
					_rootModule === rootModule &&
					SORTABLE_FIELDS.includes(sortField)
				)
			})

			const _target = targetSorts.pop()

			if (_target) {
				const [_rootModule, sortField, _value] = _target.split('|')
				if (_value !== activeSort[sortField]) {
					// trigger the sort handle
					handleChange('initiate_sort', {
						_rootModule,
						field: sortField,
						order: _value,
					})
				}
			}
		}
	}, [activeProducts, activeSort, handleChange, rootModule, query.sort])

	const handleProductsChange = useCallback(
		(_activeProducts, productsRequestPayload) => {
			setActiveProducts(_activeProducts)
			setProductsRequestPayload(productsRequestPayload)
		},
		[setActiveProducts]
	)

	const handleActiveIndexChange = useCallback(activeIndex => {
		setActiveProductIndex(activeIndex)
	}, [])

	return (
		<>
			<Box>
				<BoxShadowTable
					isLoading={isLoading || isOrgFetchLoading}
					size="small"
					columnConfig={columnsConfig}
					rowData={activeProducts}
					onClickRow={({ datum }) => {
						if (!isReadOnly)
							handleChange('modify_product', {
								rootModule,
								row: datum,
								type: 'edit',
							})
					}}
					maxHeight="fit-content" // same as size medium
					minHeight="fit-content"
					errorSize="small"
				/>
				<Spacer size={32} />
				{!isReadOnly &&
					canAddProducts &&
					!(
						isEntityDO &&
						activeProducts &&
						activeProducts.length
					) && (
						<Box row>
							<Box overflow style={{ padding: 2 }}>
								<Button
									label={
										activeProducts &&
										activeProducts.length &&
										rootModule !== 'delivery-order'
											? t(
													'tdmDetailsEntry.addMoreProducts'
											  )
											: t('tdmDetailsEntry.addProducts')
									}
									isLoading={isLoading || isOrgFetchLoading}
									disabled={false}
									rounded
									customIcon={
										<ButtonIconWrapper lightBG>
											<Icon glyph={PlusIcon} />
										</ButtonIconWrapper>
									}
									onClick={() => {
										setActiveProductIndex(
											activeProducts &&
												activeProducts.length
										)
										setIsEdit(false)
										setShowAddProducts(true)
									}}
								/>
							</Box>
						</Box>
					)}
			</Box>
			<Modal
				closeable
				show={showAddProducts}
				noPadding
				size="large"
				body={
					<ProductsForm
						isEdit={isEdit}
						currentProductDetail={currentProductDetail}
						individualProductEntry={individualProductEntry}
						allowMinimalEdit={allowMinimalEdit}
						activeProducts={activeProducts}
						targetProductsFromProps={productsPayload}
						onClose={() => setShowAddProducts(false)}
						isParentDoc={isParentDoc}
						activeIndex={activeProductIndex}
						onProductsChange={handleProductsChange}
						onActiveIndexChange={handleActiveIndexChange}
						isPricingMandatory={isPricingMandatory}
						isEntityDO={isEntityDO}
						isEntityPO={isEntityPO}
						isEntityInvoice={isEntityInvoice}
						partner={partner}
						actor={actor}
					/>
				}
				hideButtons
				onClose={() => setShowAddProducts(false)}
			/>
		</>
	)
}

export { ProductsSection }

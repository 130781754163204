import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import getSagaInjectors from 'ui-tdm-app/store/injectSagaHelper'
import getReducerInjectors from 'ui-tdm-app/store/injectReducerHelper'
import { connectRoutes, NOT_FOUND } from 'redux-first-router'
import AuthSaga from 'ui-tdm-app/modules/Auth/AuthSaga'
import MessagesSaga from 'ui-tdm-app/modules/Messages/saga'
import UploadContentSaga from 'ui-tdm-app/modules/UploadContent/UploadContentSaga'
import { getIn } from 'timm'
import baseRoutes from 'ui-tdm-app/routes/base'
import routeOptions from 'ui-tdm-app/routes/options'
import createReducer from 'ui-tdm-app/store/combinedReducers'
import ProductsSaga from 'ui-tdm-app/modules/Products/ProductsSaga'
import analyticsMiddleware from './analyticsMiddleware'
import DeviceDetectionEnhancer from './deviceDetectionEnhancer'
import { DAEMON } from './constants'

const noOpSagaInjectors = () => ({
	injectSaga: () => {},
	ejectSaga: () => {},
})

const noOpReduxInjectors = () => ({
	injectReducer: () => {},
})

export const is404 = locationState =>
	(getIn(locationState, ['type']) || NOT_FOUND) === NOT_FOUND

export default function configureStore(initialState = {}) {
	let store
	const {
		reducer: routeReducer,
		middleware: routerMiddleware,
		enhancer: routerEnhancer,
		thunk: routerThunk,
		initialDispatch: routerInitialDispatch,
	} = connectRoutes(baseRoutes, {
		...{
			...routeOptions,
			initialDispatch: false,
			displayConfirmLeave(targetRoute, callback) {
				const actionType = getIn(store.getState(), ['location', 'type'])
				const stay = () => callback(false)
				const leave = () => callback(true)
				const handler =
					baseRoutes[actionType] &&
					baseRoutes[actionType].confirmLeaveHandler
				if (handler) handler(targetRoute, store, stay, leave)
			},
		},
		initialEntries: null,
		extra: {
			getStore() {
				return store
			},
			getSagaInjectors() {
				if (!store) return noOpSagaInjectors()

				return getSagaInjectors(store)
			},
			getReduxInjectors() {
				if (!store) return noOpReduxInjectors()

				return getReducerInjectors(store)
			},
		},
	})

	const sagaMiddleware = createSagaMiddleware({
		sagaMonitor: null,
	})

	// Create the store with two middlewares
	// 1. sagaMiddleware: Makes redux-sagas work
	// 2. routerMiddleware: Syncs the location/URL path to the state
	const middlewares = [
		sagaMiddleware,
		routerMiddleware,
		analyticsMiddleware(),
	]

	// use redux freeze in development mode
	if (__DEV__) {
		const reduxFreeze = require('redux-freeze')

		middlewares.push(reduxFreeze)
	}

	const enhancers = [
		routerEnhancer,
		DeviceDetectionEnhancer(),
		applyMiddleware(...middlewares),
	]

	// If Redux DevTools Extension is installed use it, otherwise use Redux compose
	/* eslint-disable no-underscore-dangle */
	const composeEnhancers =
		__DEV__ &&
		typeof window === 'object' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			: compose
	/* eslint-enable */

	store = createStore(
		createReducer({
			location: routeReducer,
		}),
		initialState,
		composeEnhancers(...enhancers)
	)

	// Extensions
	store.routerThunk = routerThunk
	store.runSaga = sagaMiddleware.run
	store.injectedReducers = {
		location: routeReducer,
	} // Reducer registry
	store.injectedSagas = {
		auth: { task: store.runSaga(AuthSaga), mode: DAEMON },
		messages: { task: store.runSaga(MessagesSaga), mode: DAEMON },
		uploadContent: { task: store.runSaga(UploadContentSaga), mode: DAEMON },
		products: { task: store.runSaga(ProductsSaga), mode: DAEMON },
	}

	// trigger redux first router context
	routerInitialDispatch()

	// Make reducers hot reloadable, see http://mxs.is/googmo
	/* istanbul ignore next */
	if (process.env.NODE_ENV === 'development' && module.hot) {
		module.hot.accept('./combinedReducers', () => {
			const createReducers = require('./combinedReducers').default
			const nextReducers = createReducers(store.injectedReducers)

			store.replaceReducer(nextReducers)
		})
	}

	return { store }
}

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NotFoundBlock } from 'ui-tdm-app/modules/App/components/Error'
import { WebTourDuc } from 'ui-tdm-app/modules/WebTour/duc'
import { WeighBridgeDuc } from './duc'
import MainDashboard from './containers/MainDashboard'
import SubDashboard from './containers/SubDashboard'

const modulesMap = {
	dashboard: MainDashboard,
	subDashboard: SubDashboard,
}

const WeighBridge = () => {
	const dispatch = useDispatch()
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}
	const activeModule = useSelector(WeighBridgeDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock
	// if we are here, we have a submodule, route it to the module

	return <Component />
}

export default WeighBridge

import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { FeedbackDuc } from 'ui-tdm-app/modules/Feedback/duc'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import FeedbackIcon from 'ui-lib/icons/mail-outline.svg'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { P } from 'ui-lib/components/Typography'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { SelectSearch } from 'ui-lib/components/Select'
import { TextArea } from 'ui-lib/components/TextArea'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Box } from 'ui-lib/utils/Box'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { isEmptyObject } from 'ui-tdm-app/utils/helpers'

const FeedbackScreen = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const topics = [
		{ name: 'work', label: t('feedback.work') },
		{ name: 'other', label: t('feedback.other') },
	]

	const validationSchema = yup.object().shape({
		topic: yup.string().required(t('validation.topicRequired')),
		message: yup.string().required(t('validation.msgRequired')),
	})

	const {
		handleSubmit,
		submitForm,
		values,
		handleBlur,
		errors,
		touched,
		isSubmitting,
		setFieldValue,
	} = useFormik({
		initialValues: {
			topic: 'Work',
			message: '',
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			dispatch(
				FeedbackDuc.creators.saveUserFeedback(
					_values,
					t('common.feedbkSbmtSuccess')
				)
			)
		},
	})
	const disableCTA = !isEmptyObject(errors) || !values.message

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.feedback'),
			name: 'feedback',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="center">
				<Title
					title={t('feedback.title')}
					icon={FeedbackIcon}
					note={t('feedback.feedbackNote')}
				/>
			</Box>
			<BoxShadowWrapper>
				<Box
					padding={8}
					style={{
						backgroundColor: '#D8DDEF',
					}}
				>
					<P bold color="#263b97">
						{t('feedback.message')}
					</P>
				</Box>
				<Box padding={8}>
					<form onSubmit={handleSubmit}>
						<SelectSearch
							label={t('feedback.dropdownLabel')}
							value={values.topic}
							name="topic"
							options={topics}
							onChange={value => {
								setFieldValue('topic', value.label)
							}}
							returnOnlyValue
							labelKey="label"
							valueKey="name"
							defaultLabel={t('common.selectDefaultLabel')}
							onBlur={handleBlur}
							error={touched.topic && errors.topic}
						/>

						<TextArea
							placeholder={t('feedback.feedbackDescription')}
							name="message"
							value={values.message}
							required
							onChange={e => {
								setFieldValue('message', e.target.value)
							}}
						/>
					</form>
				</Box>
			</BoxShadowWrapper>
			<Box padding="24px 0" row justifyContent="space-between">
				<Box style={{ padding: 2, maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.DASHBOARD
								)
							)
						}
					/>
				</Box>
				<Box style={{ padding: 2 }}>
					<Button
						label={t('common.submit')}
						isLoading={isSubmitting}
						disabled={disableCTA}
						primary
						rounded
						onClick={() => submitForm()}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export { FeedbackScreen }

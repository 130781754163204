import React from 'react'
import { MillDuc } from 'ui-tdm-app/modules/Mill/duc'
import { useSelector, useDispatch } from 'react-redux'
import { WebTourDuc } from 'ui-tdm-app/modules/WebTour/duc'
import { UnexpectedErrorBlock } from 'ui-tdm-app/modules/App/components/Error'
import { CreateCPOQuality } from './CreateCPOQuality'
import { CreateFFBQuality } from './CreateFFBQuality'
import { ViewCPOQuality } from './ViewCPOQuality'
import { ViewFFBQuality } from './ViewFFBQuality'

const QualityDocCreation = () => {
	const { payload = {} } = useSelector(MillDuc.selectors.location)
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const dispatch = useDispatch()
	if (tourModule !== '') {
		dispatch(WebTourDuc.creators.setActiveTourModule(''))
	}
	if (payload.rootModule === 'quality-cpo') {
		const { action } = payload

		if (action === 'create' || action === 'edit') {
			return <CreateCPOQuality />
		}
		if (action === 'view') {
			return <ViewCPOQuality />
		}
	} else if (payload.rootModule === 'quality-ffb') {
		const { action } = payload
		if (action === 'create' || action === 'edit') {
			return <CreateFFBQuality />
		}
		if (action === 'view') {
			return <ViewFFBQuality />
		}
	}

	return <UnexpectedErrorBlock status={401} />
}

export { QualityDocCreation }

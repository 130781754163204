import React, { useEffect, useRef } from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Box as GrommBox } from 'grommet'
import { Message } from './Message'

const MessagesGroup = ({
	msg,
	orgId,
	activeTab,
	threadIdentifier,
	handleClick,
	useDateByFormat,
}) => {
	const chatAreaRef = useRef(null)

	useEffect(() => {
		if (chatAreaRef && chatAreaRef.current) {
			chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight
		}
	}, [])

	return (
		<Box
			ref={chatAreaRef}
			style={{
				overflow: 'scroll',
				height: '100%',
				position: 'relative',
			}}
		>
			<GrommBox
				width="100%"
				pad="10px 0px"
				overflow="scroll"
				style={{ position: 'absolute' }}
			>
				{(msg[activeTab] || []).map(
					_message =>
						_message.type === 'text' &&
						_message.content.message !== '' && (
							<Message
								key={_message?.id}
								message={_message}
								canEditDelete={activeTab === threadIdentifier}
								orgId={orgId}
								handleClick={(id, message) => {
									handleClick(id, message)
								}}
								useDateByFormat={useDateByFormat}
							/>
						)
				)}
			</GrommBox>
		</Box>
	)
}

export { MessagesGroup }

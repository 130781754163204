import React, { useState, useCallback } from 'react'
import { Box } from 'ui-lib/utils/Box'
import { getIn } from 'timm'
import { Icon } from 'ui-lib/icons/components/Icon'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { useTranslation } from 'react-i18next'
import { TextArea } from 'ui-lib/components/TextArea'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import { useSelector } from 'react-redux'
import { P, Text } from 'ui-lib/components/Typography'
import SendSvg from 'ui-lib/icons/send.svg'
import { useDateByFormat } from 'ui-tdm-app/utils/date'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import theme from 'ui-lib/utils/base-theme'

const Message = ({ message }) => {
	const activeProfiles = useSelector(
		TradeDocDuc.selectors.getDocumentParticipants
	)

	const targetSenderName = getIn(activeProfiles, [message.userID, 'fullName'])

	const organizationDetail = useSelector(state =>
		AuthDuc.selectors.getOrganizationByID(state, message.organizationID)
	)

	const { id: currentOrgID } = useSelector(
		AuthDuc.selectors.getCurrentOrganization
	)

	const messageBlockColor =
		organizationDetail.id === currentOrgID
			? 'rgba(231,236,243,0.3) '
			: 'rgba(204,227,250,0.5)'

	const orgNameColor =
		organizationDetail.id === currentOrgID ? '#292929' : '#2E3C7C'

	return (
		<Box row padding="8px 0px">
			<Box
				padding="16px 10px"
				style={{
					background: messageBlockColor,
					borderTopLeftRadius: '10px',
					borderBottomLeftRadius: '10px',
				}}
			>
				<Text as="time" color="#292929">
					{useDateByFormat(message.createdAt)}
				</Text>
				<Spacer size={8} />
				<Text small as="time" color="#292929">
					{useDateByFormat(message.createdAt, 'h:mm a')}
				</Text>
				{message.type === 'state-change' && (
					<>
						<Spacer size={4} />
						<span
							style={{
								fontSize: theme.fontSize.s,
								color: '#999',
							}}
						>
							(State Change)
						</span>
					</>
				)}
			</Box>
			<Box
				padding="12px 10px"
				style={{
					background: messageBlockColor,
					borderTopRightRadius: '10px',
					borderBottomRightRadius: '10px',
				}}
				flex
			>
				<Box row alignItems="baseline">
					{targetSenderName ? (
						<P color={orgNameColor}>{targetSenderName}</P>
					) : (
						<P color={orgNameColor} bold>
							{organizationDetail.name || ''}
						</P>
					)}
					{targetSenderName && <Spacer size={5} horizontal />}
					{targetSenderName && organizationDetail.name && (
						<Text color={orgNameColor} bold>
							({organizationDetail.name})
						</Text>
					)}
				</Box>
				<Spacer size={4} />
				<Box row>
					<P color="#292929">{message.comment}</P>
				</Box>
			</Box>
		</Box>
	)
}

const MessagesBlock = ({ title, messages, onMessageSend, readOnly }) => {
	const [message, setMessage] = useState('')
	const [isLoading, setLoading] = useState(false)
	const { t } = useTranslation()

	const handleSubmit = useCallback(() => {
		const haltLoader = onMessageSend(message, { setLoading })
		if (!haltLoader) {
			setLoading(false)
			setMessage('')
		}
	}, [message, onMessageSend])

	return (
		<CollapsableSegment expand plain title={t(title)}>
			<Box>
				{!readOnly && (
					<Box>
						<TextArea
							name="remarks"
							label={t('tdmViewDocument.enterRemarks')}
							placeholder={t('tdmViewDocument.typeHere')}
							value={message}
							onChange={({ remarks }) => {
								setMessage(remarks)
							}}
							returnKeyValue
							customStyles={{ height: 100 }}
						/>
						<Box
							overflow
							width="100%"
							row
							justifyContent="flex-end"
							padding={2}
						>
							<Button
								disabled={!message}
								label={t('common.submit')}
								primary
								rounded
								isLoading={isLoading}
								customIcon={
									<ButtonIconWrapper>
										<Icon glyph={SendSvg} />
									</ButtonIconWrapper>
								}
								onClick={handleSubmit}
							/>
						</Box>
						<Spacer size={8} />
					</Box>
				)}
				{messages.map(_message =>
					_message.type === 'misc' && _message.comment ? (
						<Message key={message.comment} message={_message} />
					) : null
				)}
			</Box>
		</CollapsableSegment>
	)
}

MessagesBlock.defaultProps = {
	isLoading: false,
	title: 'tdmViewDocument.messages',
	onMessageSend: () => {},
}

export { MessagesBlock }

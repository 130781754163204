import React, { useState } from 'react'
import styled from 'styled-components'
import { getIn } from 'timm'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { CustomPieChart } from 'ui-tdm-app/utils/helpers'
import { KeyValueSegment } from 'ui-tdm-app/modules/Admin/components/KeyValueSegment'
import {
	mostQtyColors,
	leastQtyColors,
	ffbQualityKeys,
	FFB_QUALITY_ALIASES_KEYS,
} from 'ui-tdm-app/modules/TradeDocumentManager/config'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Card } from 'ui-lib/components/Card'
import { Button } from 'ui-lib/components/Button'
import { Label, P, Text, SmallText } from 'ui-lib/components/Typography'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import UpGreenArrow from 'ui-lib/icons/upGreenArrow.svg'
import UpYellowArrow from 'ui-lib/icons/upYellowArrow.svg'
import DownGreenArrow from 'ui-lib/icons/downGreenArrow.svg'
import DownYellowArrow from 'ui-lib/icons/downYellowArrow.svg'
import UpArrow from 'ui-lib/icons/up-arrow.svg'
import TimerIcon from 'ui-lib/icons/timer.svg'
import TimerOffIcon from 'ui-lib/icons/alarm_off.svg'

const Line = styled.div(p => ({
	borderRight: p.vertical ? '1px solid #c9c9c9' : 0,
	height: p.vertical ? '216px' : 0,
	margin: p.margin,
	borderBottom: p.horizontal ? '1px solid #c9c9c9' : 0,
}))

const KeyValueUOMSegment = ({ count, countUOM, errorMsg, label, icon }) => {
	return (
		<>
			<Box row alignItems="baseline" padding="28px 16px 18px">
				{count !== 0 && (
					<Text
						style={{
							fontSize: theme.fontSize.xxl,
							color: theme.color.accent2,
						}}
					>
						{count}
					</Text>
				)}
				{count !== 0 && (
					<Text
						style={{
							fontSize: theme.fontSize.s,
							color: theme.color.accent2,
						}}
					>
						&nbsp;{countUOM}
					</Text>
				)}
				{!count && (
					<Text
						style={{
							fontSize: theme.fontSize.s,
							color: theme.color.accent2,
						}}
					>
						{errorMsg}
					</Text>
				)}
			</Box>
			<Box row alignItems="baseline" padding="0 16px 18px">
				{icon && (
					<IconWrapper size={12}>
						<Icon glyph={icon} />
					</IconWrapper>
				)}
				<Text
					style={{
						fontSize: theme.fontSize.s,
						color: theme.color.accent2,
						marginLeft: icon ? 8 : 0,
					}}
				>
					{label}
				</Text>
			</Box>
		</>
	)
}

const DriverDetailsSegment = ({ name, quantity, icon, iconColor, down }) => {
	return (
		<Box row alignItems="baseline" padding="8px 4px">
			<IconWrapper size={12}>
				<Icon
					glyph={icon}
					style={{ color: iconColor }}
					rotate180={down}
				/>
			</IconWrapper>
			<Text
				style={{
					fontSize: theme.fontSize.m,
					color: theme.color.accent2,
					width: '110px',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					margin: '0 16px 0 8px',
					textAlign: 'left',
				}}
			>
				{name}
			</Text>
			<Text
				style={{
					fontSize: theme.fontSize.m,
					color: theme.color.accent2,
				}}
				bold
			>
				{quantity}
			</Text>
		</Box>
	)
}

const GeneralReport = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const { isLoading } = useSelector(
		TradeDocDuc.selectors.getDocumentLoadingState
	)

	const dealerReports = useSelector(
		TradeDocDuc.selectors.getDealerGeneralReports
	)

	const {
		name,
		address = {},
		certification = [],
		currentInventory,
		currentInventoryUOM,
		incomingTrade = {},
		outgoingTrade = {},
		attachmentCount = {},
		trips = {},
		ffbQuality = [],
		mostQuantity = [],
		leastQuantity = [],
	} = dealerReports

	const { today = {}, thisMonth, avgTripTiming, avgTripTimeUnit } = trips

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.generalReport'),
			name: 'general-report',
			isActive: false,
		},
		{
			label: t('breadcrumb.summary'),
			name: 'summary',
			isActive: false,
		},
	]

	const ffbQualityforPieChart =
		ffbQuality.length !== 0
			? ffbQualityKeys.map(key => {
					return {
						label: t(FFB_QUALITY_ALIASES_KEYS[key]),
						value: ffbQuality[key],
					}
			  })
			: ffbQuality

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	return (
		<>
			{isLoading && <TransparentBgSpinner lockToScreen />}

			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Label bold style={{ fontSize: '32px' }}>
				{t('dealerGeneralReports.title')}
			</Label>
			<P
				style={{
					fontSize: theme.fontSize.xl,
					color: theme.color.accent2,
				}}
			>
				{t('dealerGeneralReports.dateText')}
			</P>
			<Box row style={{ marginTop: '12px', fontSize: theme.fontSize.l }}>
				<Box alignItems="flex-start" width={304}>
					<Label style={{ fontSize: theme.fontSize.l }}>
						{t('dealerGeneralReports.from')}
					</Label>
					<DatePicker
						name="startDate"
						placeholder={t('dealerGeneralReports.datePlaceholder')}
						hideError
						value={startDate}
						maxDate={new Date()}
						onChange={value => setStartDate(value)}
					/>
				</Box>
				<Box alignItems="flex-start" width={304} margin="0 0 0 24px">
					<Label style={{ fontSize: theme.fontSize.l }}>
						{t('dealerGeneralReports.to')}
					</Label>
					<DatePicker
						name="toDate"
						placeholder={t('dealerGeneralReports.datePlaceholder')}
						hideError
						value={endDate}
						maxDate={new Date()}
						onChange={value => setEndDate(value)}
						toDate
					/>
				</Box>
				<Box width={168} margin="23px 0 0 24px">
					<Button
						label={t('dealerGeneralReports.searchReport')}
						rounded
						onClick={() => {
							const filters = {
								startDate,
								endDate,
							}
							dispatch(
								TradeDocDuc.creators.fetchDealerGeneralReports(
									filters
								)
							)
						}}
					/>
				</Box>
			</Box>
			<Box row margin="18px 0 0" justifyContent="flex-end">
				<Label
					link
					style={{
						cursor: 'pointer',
						color: '#2680EB',
					}}
				>
					{t('dealerGeneralReports.downloadRpt')}
				</Label>
			</Box>
			<Label style={{ color: '#707070' }}>
				{t('dealerGeneralReports.dataShowingtxt')}
			</Label>
			<Card
				height="200px"
				width="100%"
				style={{ margin: '8px 0 0', padding: '12px 0' }}
				textAlign="left"
			>
				<KeyValueSegment
					label={t('dealerGeneralReports.name')}
					value={name || '---'}
				/>
				<KeyValueSegment
					label={t('dealerGeneralReports.address')}
					value={address.line1 || '---'}
				/>
				<KeyValueSegment
					label={t('dealerGeneralReports.crtfctnType')}
					value={
						getIn(certification, [0, 'certificateType']) || '---'
					}
				/>
				<KeyValueSegment
					label={t('dealerGeneralReports.crtfctnNum')}
					value={
						getIn(certification, [0, 'certificationNumber']) ||
						'---'
					}
				/>
				<KeyValueSegment
					label={t('dealerGeneralReports.licence')}
					value={getIn(certification, [0, 'license']) || '---'}
				/>
			</Card>
			<Box row margin="26px 0 0">
				<Box width="44%">
					<Label
						style={{ fontSize: theme.fontSize.xl, marginBottom: 8 }}
					>
						{t('dealerGeneralReports.trades')}
					</Label>
					<Card height="216px" textAlign="left">
						<Box row>
							<Box width="116px">
								<KeyValueUOMSegment
									count={incomingTrade.tradeCount}
									countUOM={incomingTrade.tradeCountUOM}
									icon={DownGreenArrow}
									label={t('dealerGeneralReports.incoming')}
									errorMsg={t(
										'dealerGeneralReports.incmgErrorMsg'
									)}
								/>
								<Line horizontal margin="0 16px" />
								<KeyValueUOMSegment
									count={incomingTrade.quantity}
									countUOM={incomingTrade.quantityUOM}
									label={t('dealerGeneralReports.totlQty')}
									errorMsg={t(
										'dealerGeneralReports.totlQtyErrorMsg'
									)}
								/>
							</Box>

							<Line vertical />
							<Box width="116px">
								<KeyValueUOMSegment
									count={outgoingTrade.tradeCount}
									countUOM={outgoingTrade.tradeCountUOM}
									icon={UpGreenArrow}
									label={t('dealerGeneralReports.outgoing')}
									errorMsg={t(
										'dealerGeneralReports.outgngErrorMsg'
									)}
								/>
								<Line horizontal margin="0 16px" />
								<KeyValueUOMSegment
									count={getIn(outgoingTrade, ['quantity'])}
									countUOM={outgoingTrade.quantityUOM}
									label={t('dealerGeneralReports.totlQty')}
									errorMsg={t(
										'dealerGeneralReports.totlQtyErrorMsg'
									)}
								/>
							</Box>
							<Line vertical />
							<Box margin="28px 16px">
								<Card
									height="158px"
									style={{
										maxWidth: 152,
										padding: '24px 12px 0',
										background: currentInventory
											? '#DFFAF0'
											: '#FFDEDD',
									}}
								>
									<Text
										style={{
											fontSize: theme.fontSize.l,
											color: theme.color.accent2,
										}}
										bold
									>
										{t('dealerGeneralReports.currInventry')}
									</Text>
									<Box
										row
										alignItems="baseline"
										justifyContent="center"
										padding="8px 0"
									>
										<Text
											style={{
												fontSize: theme.fontSize.xxl,
												color: '#13835A',
											}}
											bold
										>
											{currentInventory}
										</Text>
										{!currentInventory && (
											<Text
												style={{
													fontSize: theme.fontSize.xs,
													color: theme.color.accent2,
												}}
												bold
											>
												{t(
													'dealerGeneralReports.noInventory'
												)}
											</Text>
										)}
										<Text
											style={{
												fontSize: theme.fontSize.s,
												color: '#13835A',
											}}
										>
											&nbsp;&nbsp;{currentInventoryUOM}
										</Text>
									</Box>
									<Text
										style={{
											color: '#AEAEAE',
											fontSize: theme.fontSize.xs,
										}}
									>
										{t(
											'dealerGeneralReports.inventoryhelper'
										)}
									</Text>
								</Card>
							</Box>
						</Box>
					</Card>
				</Box>
				<Box width="18%" margin="0 0 0 24px">
					<Label
						style={{ fontSize: theme.fontSize.xl, marginBottom: 8 }}
					>
						{t('dealerGeneralReports.weighbridge')}
					</Label>
					<Card height="216px">
						<Box>
							<KeyValueUOMSegment
								count={attachmentCount.incoming}
								icon={UpYellowArrow}
								label={t('dealerGeneralReports.incmgTkts')}
								errorMsg={t(
									'dealerGeneralReports.tktsErrorMsg'
								)}
							/>
							<Line horizontal margin="0 16px" />
							<KeyValueUOMSegment
								count={attachmentCount.outgoing}
								icon={DownYellowArrow}
								label={t('dealerGeneralReports.outgngTkts')}
								errorMsg={t(
									'dealerGeneralReports.tktsErrorMsg'
								)}
							/>
						</Box>
					</Card>
				</Box>
				<Box width="38%" margin="0 0 0 24px">
					<Label
						style={{ fontSize: theme.fontSize.xl, marginBottom: 8 }}
					>
						{t('dealerGeneralReports.ffbQuality')}
					</Label>
					<Card
						height="216px"
						textAlign="left"
						style={{ padding: '24px 0 0' }}
					>
						<CustomPieChart
							data={ffbQualityforPieChart}
							width={150}
							height={150}
							innerRadius={55}
							outerRadius={70}
							legendForReport
						/>
					</Card>
				</Box>
			</Box>
			<Box row margin="48px 0 0">
				<Box width="48%">
					<Label
						style={{ fontSize: theme.fontSize.xl, marginBottom: 8 }}
					>
						{t('dealerGeneralReports.trips')}
					</Label>
					<Card height="264px">
						<Box row>
							<Box margin="60px 40px 76px 32px">
								<Text
									style={{
										width: '168px',
										height: '24px',
										textAlign: 'left',
										fontSize: theme.fontSize.l,
									}}
								>
									{t('dealerGeneralReports.avgTrips')}
								</Text>
								<Text
									style={{
										width: '160px',
										marginTop: 8,
										padding: ' 12px 16px',
										background:
											'#FFAA0033 0% 0% no-repeat padding-box',
										borderRadius: '20px',
										opacity: 1,
										color: '#FFAA00',
									}}
								>
									{t('dealerGeneralReports.thisMonth')}:
									&nbsp;
									<b>{thisMonth || 0}</b>
								</Text>
								<Text
									style={{
										width: '160px',
										marginTop: 16,
										padding: ' 12px 16px',
										background:
											'#DFFAF0 0% 0% no-repeat padding-box',
										borderRadius: '20px',
										opacity: 1,
										color: '#13835A',
									}}
								>
									{t('dealerGeneralReports.today')}: &nbsp;
									<b>{today.completedTrips || 0}</b>
								</Text>
							</Box>
							<Box center>
								{avgTripTiming ? (
									<IconWrapper size={80}>
										<Icon
											glyph={TimerIcon}
											style={{
												color: '#13835A',
												padding: '16px',
												borderRadius: '50%',
												backgroundColor: '#DFFAF0',
											}}
										/>
									</IconWrapper>
								) : (
									<IconWrapper size={80}>
										<Icon
											glyph={TimerOffIcon}
											style={{
												color: '#F45E58',
												padding: '16px',
												borderRadius: '50%',
												backgroundColor: '#FFDEDD',
											}}
										/>
									</IconWrapper>
								)}

								{avgTripTiming ? (
									<Text
										style={{
											fontSize: theme.fontSize.xxxxl,
											margin: '12px 0',
										}}
									>
										{avgTripTiming}
										<SmallText>
											&nbsp;&nbsp;{avgTripTimeUnit}
										</SmallText>
									</Text>
								) : (
									<Box
										height="60px"
										center
										style={{
											color: '#08090C',
											fontSize: theme.fontSize.s,
										}}
									>
										{t('dealerGeneralReports.noRecords')}
									</Box>
								)}
								<Text
									style={{
										maxWidth: '200px',
										padding: '0 0 12px',
										color: theme.color.grey9,
									}}
								>
									{t('dealerGeneralReports.avgTripTime')}
								</Text>
								<SmallText
									style={{
										maxWidth: '200px',
										color: theme.color.grey10,
									}}
								>
									{t(
										'dealerGeneralReports.avgTripTimeSubheading'
									)}
								</SmallText>
							</Box>
						</Box>
					</Card>
				</Box>
				<Box width="52%" margin="0 0 0 24px">
					<Label
						style={{ fontSize: theme.fontSize.xl, marginBottom: 8 }}
					>
						{t('dealerGeneralReports.driverDetails')}
					</Label>
					<Card height="264px" style={{ minWidth: 360 }}>
						<Box row>
							<Box
								width={200}
								height={183}
								style={{
									margin: '24px 0 0 24px',
									border: '1px solid #00A505',
									borderRadius: '4px',
									position: 'relative',
									overflow: 'visible',
									opacity: 1,
									padding: '20px 16px 0',
								}}
							>
								<SmallText
									style={{
										position: 'absolute',
										fontSize: theme.fontSize.m,
										color: theme.color.accent2,
										top: '-10px',
										left: '20px',
										display: 'inline-block',
										backgroundColor: theme.color.white,
										padding: '0 8px',
									}}
								>
									Most Quantity in MT
								</SmallText>

								{mostQuantity.length ? (
									mostQuantity.map((d, idx) => (
										<DriverDetailsSegment
											key={d.name}
											name={d.name}
											quantity={d.quantity}
											icon={UpArrow}
											iconColor={mostQtyColors[idx]}
										/>
									))
								) : (
									<SmallText
										style={{
											margin: '80px 0 0',
											fontSize: theme.fontSize.xs,
											color: theme.color.accent2,
										}}
									>
										No Details available
									</SmallText>
								)}
							</Box>
							<Box
								width={200}
								height={183}
								style={{
									margin: '24px 0 0 32px',
									border: '1px solid #FF0000',
									borderRadius: '4px',
									position: 'relative',
									overflow: 'visible',
									opacity: 1,
									padding: '20px 16px 0',
								}}
							>
								<SmallText
									style={{
										position: 'absolute',
										fontSize: theme.fontSize.m,
										color: theme.color.accent2,
										top: '-10px',
										left: '20px',
										display: 'inline-block',
										backgroundColor: theme.color.white,
										padding: '0 8px',
									}}
								>
									Least Quantity in MT
								</SmallText>
								{mostQuantity.length ? (
									leastQuantity.map((d, idx) => (
										<DriverDetailsSegment
											name={d.name}
											key={d.name}
											quantity={d.quantity}
											icon={UpArrow}
											down
											iconColor={leastQtyColors[idx]}
										/>
									))
								) : (
									<SmallText
										style={{
											margin: '80px 0 0',
											fontSize: theme.fontSize.xs,
											color: theme.color.accent2,
										}}
									>
										No Details available
									</SmallText>
								)}
							</Box>
						</Box>
						<Box margin="12px 24px 0 ">
							<Text
								style={{
									fontSize: theme.fontSize.xs,
									textAlign: 'left',
									color: '#AEAEAE',
								}}
							>
								List of top 5 and bottom 5 drivers considering
								the total quantity carried for the total number
								of trips
							</Text>
						</Box>
					</Card>
				</Box>
			</Box>
		</>
	)
}

export { GeneralReport }

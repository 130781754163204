import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { getIn } from 'timm'
import Tour from 'reactour'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { Box } from 'ui-lib/utils/Box'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import forwardArrowIcon from 'ui-lib/icons/arrow_forward_black_24dp.svg'
import { P } from 'ui-lib/components/Typography'
import { tourModuleActorBased } from './TourSteps'
import { WebTourDuc } from './duc'
import './tourStyle.css'

export function WebTour() {
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const actor = useSelector(AuthDuc.selectors.getActor)
	const actorType = getIn(actor, [0]) || 'palmoil_mill'

	const userRole = tourModuleActorBased(t, actorType) || []
	const getSteps =
		tourModule === ''
			? userRole.find(obj => obj.name === 'dashboard').steps
			: userRole.find(obj => obj.name === tourModule).steps

	const tourStatus = useSelector(WebTourDuc.selectors.tourStatus)
	const [isTourOpen, setIsTourOpen] = useState(tourStatus)
	const createTourOpen = useSelector(WebTourDuc.selectors.isCreateTourOpen)
	useEffect(() => {
		if (createTourOpen) {
			document.documentElement.style.overflowX = 'inherit'
			document.documentElement.style.scrollBehavior = 'inherit'
		} else {
			document.documentElement.style.overflowX = 'hidden'
			document.documentElement.style.scrollBehavior = 'smooth'
		}
	}, [createTourOpen])

	useEffect(() => {
		dispatch(AuthDuc.creators.fetchActor())
	}, [dispatch])

	return (
		<>
			{!createTourOpen && (
				<Tour
					steps={getSteps}
					isOpen={isTourOpen}
					rounded={5}
					disableInteraction
					stepInteraction
					closeWithMask
					showNumber={false}
					accentColor="#5A3A75"
					prevButton={
						<Box
							style={{
								color: '#F6F6F6',

								transform: 'rotate(180deg)',
							}}
						>
							<IconWrapper
								size={20}
								style={{
									padding: '1%',
									color: 'inherit',
								}}
							>
								<Icon
									glyph={forwardArrowIcon}
									color="inherit"
								/>
							</IconWrapper>
						</Box>
					}
					nextButton={
						<Box
							style={{
								color: '#F6F6F6',
							}}
						>
							<IconWrapper
								size={20}
								style={{
									padding: '1%',
									color: 'inherit',
								}}
							>
								<Icon
									glyph={forwardArrowIcon}
									color="inherit"
								/>
							</IconWrapper>
						</Box>
					}
					lastStepNextButton={
						<P
							style={{
								color: '#F6F6F6',
								fontSize: '16px',
							}}
						>
							{t('login.done')}
						</P>
					}
					onRequestClose={() => {
						setIsTourOpen(false)
						dispatch(WebTourDuc.creators.setTourStatus(false))
						dispatch(WebTourDuc.creators.setTourPopupOpen(true))
						dispatch(WebTourDuc.creators.setActiveTourModule(''))
					}}
				/>
			)}
			{createTourOpen && (
				<Tour
					steps={getSteps}
					isOpen={isTourOpen}
					rounded={5}
					disableInteraction={false}
					backgroundColor="red"
					stepInteraction
					closeWithMask
					showNumber={false}
					prevButton={
						<P
							style={{
								color: '#F6F6F6',

								transform: 'rotate(180deg)',
							}}
						>
							<IconWrapper
								size={20}
								style={{
									padding: '1%',
									color: 'inherit',
								}}
							>
								<Icon
									glyph={forwardArrowIcon}
									color="inherit"
								/>
							</IconWrapper>
						</P>
					}
					nextButton={
						<Box
							style={{
								color: '#F6F6F6',
							}}
						>
							<IconWrapper
								size={20}
								style={{
									padding: '1%',
									color: 'inherit',
								}}
							>
								<Icon
									glyph={forwardArrowIcon}
									color="inherit"
								/>
							</IconWrapper>
						</Box>
					}
					accentColor="#5A3A75"
					disableFocusLock
					lastStepNextButton={
						<P
							style={{
								color: '#F6F6F6',
								fontSize: '16px',
							}}
						>
							Done
						</P>
					}
					onRequestClose={() => {
						setIsTourOpen(false)
						dispatch(WebTourDuc.creators.setTourStatus(false))
						dispatch(
							WebTourDuc.creators.setCreateTourOpenStatus(false)
						)
						dispatch(WebTourDuc.creators.setTourPopupOpen(true))
						dispatch(WebTourDuc.creators.setActiveTourModule(''))
					}}
				/>
			)}
		</>
	)
}
export default WebTour

import React from 'react'
import { getIn } from 'timm'
import { getDateWithTimeByFormat } from '../../../utils/date'

const getGpsInfo = address => {
	const info = []
	if (getIn(address, ['location', 'geoData'])) {
		const geoData = getIn(address, ['location', 'geoData'])
		if (getIn(geoData, ['shape', 'coordinates'])?.length > 0) {
			getIn(geoData, ['shape', 'coordinates']).forEach(item => {
				info.push(
					<span>
						{`${item[0] || ''}, 
			${item[1] || ''}`}
					</span>
				)
			})
		}
	} else if (getIn(address, ['location'])) {
		info.push(
			<span>
				{`${getIn(address, ['location', 'lat']) || ''}, 
		${getIn(address, ['location', 'long']) || ''}`}
			</span>
		)
	}

	return info
}

const getVerificationStatus = (
	activeDocument = {},
	hederaMessages = {},
	currentEntityID = null,
	secondaryTrace = false
) => {
	let status = !secondaryTrace
	if (
		getIn(activeDocument, ['docStatus']) === 'submitted' &&
		!secondaryTrace
	) {
		status = false
	} else if (
		hederaMessages &&
		hederaMessages.data &&
		hederaMessages.data.length > 0
	) {
		if (currentEntityID && secondaryTrace) {
			const currentItem = hederaMessages.data.find(
				item => item.referenceID === currentEntityID
			)

			if (currentItem && currentItem.transactionStatus === 'SUCCESS') {
				status = true
			}
		} else if (!secondaryTrace) {
			hederaMessages.data.forEach(item => {
				if (item.transactionStatus !== 'SUCCESS') {
					status = false
				}
			})
		}
	}

	return status
}

const getHeader = (activeDocument, traceData, t) => {
	let header = ''
	let headerId = ''

	const keyID = traceData?.keyID
	const masterOrg =
		keyID === traceData?.destinationOrg?.id
			? traceData.destinationOrg
			: traceData.originatingOrg
	if (getIn(activeDocument, ['docStatus']) === 'submitted') {
		if (getIn(traceData, ['keyName'])) {
			header = getIn(traceData, ['keyName'])
			headerId = keyID
		} else {
			header = getIn(masterOrg, ['name'])
			headerId = getIn(masterOrg, ['id'])
		}
	} else {
		header = getIn(masterOrg, ['name'])
		headerId = getIn(masterOrg, ['id'])
	}

	const virtualTraceData = !(
		getIn(masterOrg, ['status', 'state']) === 'verified' ||
		getIn(masterOrg, ['status', 'state']) === 'pending'
	)

	if (header === 'Unknown Organization') {
		header = `${header} (${getIn(traceData, ['unknownPercentage'])}%)`
	} else if (virtualTraceData) {
		header = `${header} (${t('traceReport.unregisteredOrganization')})`
	}

	return { name: header, id: headerId, virtualTraceData }
}

const getUOM = data => {
	let uom = ''

	if (
		getIn(data, ['entity', 'receiverTraceIDs']) &&
		getIn(data, ['entity', 'receiverTraceIDs']).length > 0
	) {
		uom = 'MT'
	} else if (getIn(data, ['entity', 'meta', 'uomNonMetric'])) {
		uom = getIn(data, ['entity', 'meta', 'uomNonMetric'])
	} else {
		uom = getIn(data, ['entity', 'products', 0, 'uom']) || 'MT'
	}

	return uom
}

const getQuantity = (activeDocument, data) => {
	let quantity = ''
	if (getIn(activeDocument, ['docStatus']) === 'submitted') {
		if (
			getIn(data, ['entity', 'initiatorTraceIDs']) &&
			getIn(data, ['entity', 'initiatorTraceIDs']).length > 0
		) {
			let totalQty = 0
			getIn(data, ['entity', 'initiatorTraceIDs']).forEach(item => {
				totalQty += item.quantityUtilized
			})
			quantity = totalQty.toString()
		}
	} else if (
		getIn(data, ['entity', 'receiverTraceIDs']) &&
		getIn(data, ['entity', 'receiverTraceIDs']).length > 0
	) {
		quantity = getIn(data, [
			'entity',
			'receiverTraceIDs',
			0,
			'availableQty',
		])
	} else if (getIn(data, ['entity', 'meta', 'quantityNonMetric'])) {
		quantity = getIn(data, ['entity', 'meta', 'quantityNonMetric'])
	} else {
		quantity = getIn(data, ['entity', 'products', 0, 'quantity']) || ''
	}

	return quantity
}

const traceDetails = (activeDocument, tracedata, secondary = false) => {
	const dataObj = {
		traceId: getIn(tracedata, ['traceID']) || '',
		updatedDate: secondary
			? getIn(tracedata, ['traceIDCreatedAt']) || ''
			: getIn(tracedata, ['updatedAt']) ||
			  getIn(tracedata, ['traceIDCreatedAt']) ||
			  '',
		deliveryOrder: getIn(tracedata, ['entity', 'number']) || '',
		productName:
			getIn(tracedata, ['entity', 'products', 0, 'name']) ||
			getIn(tracedata, ['product', 'name']) ||
			'',
		virtualTraceData: getIn(tracedata, ['virtualTraceData']) || false,
		quantity: (
			<div>
				<span>{getQuantity(activeDocument, tracedata)}</span>
				<span
					style={{
						marginLeft: '5px',
					}}
				>
					{getIn(tracedata, ['entity', 'products', 0, 'quantity']) ||
					(getIn(tracedata, ['entity', 'receiverTraceIDs']) &&
						getIn(tracedata, ['entity', 'receiverTraceIDs'])
							.length > 0)
						? getUOM(tracedata) || ''
						: ''}
				</span>
			</div>
		),
	}

	return dataObj
}

const getSustainabilityCertification = (data, orgName = '') => {
	const certificateArray = []
	if (data && data.length > 0) {
		data.forEach(item => {
			const dataObj = {}
			dataObj.name = getIn(item, ['meta', 'certificate', 'issuingBody'])
			dataObj.validTillDate = getDateWithTimeByFormat(
				getIn(item, ['expiryEndDate'])
			)
			dataObj.URL = getIn(item, ['files', 0, 'meta', 'fullURL']) || ''
			if (orgName) {
				dataObj.orgName = orgName
			}

			if (dataObj.name !== 'Not Certified') {
				certificateArray.push(dataObj)
			}
		})
	}

	return certificateArray
}

const getOriginatingSustainabilityCertification = data => {
	let orgListArray = []

	if (
		data &&
		data.orgTraceGroupMap &&
		Object.keys(data.orgTraceGroupMap).length > 0
	) {
		Object.keys(data.orgTraceGroupMap).forEach(key => {
			if (
				data.orgTraceGroupMap[key] &&
				data.orgTraceGroupMap[key].sourceTraces &&
				data.orgTraceGroupMap[key].sourceTraces.length > 0
			) {
				data.orgTraceGroupMap[key].sourceTraces.forEach(item => {
					if (
						item &&
						item.orgCertificates &&
						item.orgCertificates.length > 0
					) {
						orgListArray = orgListArray.concat(
							getSustainabilityCertification(
								item.orgCertificates,
								getIn(item, ['destinationOrg', 'name'])
							)
						)
					}
				})
			}
		})
	}

	return orgListArray
}

const originatingOrganizationDetails = traceData => {
	const dataObj = {
		originatingOrganisationName:
			getIn(traceData, ['originatingOrg', 'id']) === 'global-initiator'
				? `${getIn(traceData, [
						'originatingOrg',
						'name',
				  ])} (Unregistered Organisation)`
				: getIn(traceData, ['originatingOrg', 'name']) || '',
		originatingOrganisationId:
			getIn(traceData, ['originatingOrg', 'id']) || '',
		originatingSustainabilityCertification: getOriginatingSustainabilityCertification(
			traceData
		),
		originatingGpsInfo: getGpsInfo(
			getIn(traceData, ['originatingOrg', 'primaryAddress'])
		),
	}

	return dataObj
}

const destinationOrganizationDetails = traceData => {
	const dataObj = {
		destinationOrganisationName:
			getIn(traceData, ['destinationOrg', 'id']) === 'global-initiator'
				? `${getIn(traceData, [
						'destinationOrg',
						'name',
				  ])} (Unregistered Organisation)`
				: getIn(traceData, ['destinationOrg', 'name']) || '',
		destinationOrganisationId:
			getIn(traceData, ['destinationOrg', 'id']) || '',
		destinationSustainabilityCertification:
			getSustainabilityCertification(
				getIn(traceData, ['orgCertificates'])
			) || '',
		destinationGpsInfo: getGpsInfo(
			getIn(traceData, ['destinationOrg', 'primaryAddress'])
		),
	}

	return dataObj
}

const handleCertificateData = certificateData => {
	if (getIn(certificateData, ['URL'])) {
		window.open(getIn(certificateData, ['URL']))
	}
}

export {
	getVerificationStatus,
	getHeader,
	getUOM,
	getQuantity,
	traceDetails,
	getSustainabilityCertification,
	getOriginatingSustainabilityCertification,
	originatingOrganizationDetails,
	destinationOrganizationDetails,
	handleCertificateData,
}

/* @flow */
import React from 'react'
import styled from 'styled-components'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { getIn } from 'timm'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import downArrow from 'ui-lib/icons/down.svg'
import upArrow from 'ui-lib/icons/upward-arrow.svg'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { useTranslation } from 'react-i18next'
import { getDateWithTimeByFormat } from 'ui-tdm-app/utils/date'

type Props = {
	header: string,
	receiverOrgId: String,
	headerBackGroundColor: string,
	showVerifiedText: boolean,
	forPdf: boolean,
	blockChainVerification: boolean,
	submittedState: boolean,
	traceDetails: Object,
	originatingOrganizationDetails: Object,
	destinationOrganizationDetails: Object,
	onCertificateclick: ({ datum: Object }) => void,
	getExplorerLink: ({ topicID: string, topicNumber: string }) => void,
}

const LinkWrap = styled.div`
	&:hover {
		text-decoration: underline;
	}
`
const Illustration = styled.img`
	width: 90%;
	align-self: center;
`

const MainContainer = styled.div`
	margin-top: 50px;
`

const IconBox = styled.div`
	display: flex;
	cursor: pointer;
`

const ExpandCollapseContainer = styled.div`
	padding: 6px;
	display: flex;
	cursor: pointer;
	border-radius: 0 0 5px 5px;
	border-bottom: 1px solid ${theme.color.grey15};
	border-left: 1px solid ${theme.color.grey15};
	border-right: 1px solid ${theme.color.grey15};
`

const TraceReportTable = ({
	header,
	virtualTraceData,
	receiverOrgId,
	showVerifiedText,
	traceDetails,
	headerBackGroundColor = theme.color.themeBlue12,
	originatingOrganizationDetails,
	destinationOrganizationDetails,
	onCertificateclick,
	blockChainVerification,
	getExplorerLink,
	forPdf = false,
	submittedState = false,
	isMobile = false,
	secondaryTrace = false,
	isOpen = false,
	hasChildren = false,
	hederaMessages = {},
	onCollapseAction = () => {},
}: Props) => {
	const { t } = useTranslation()
	const initiatorId =
		getIn(destinationOrganizationDetails, ['destinationOrganisationId']) ===
		receiverOrgId
			? getIn(originatingOrganizationDetails, [
					'originatingOrganisationId',
			  ])
			: getIn(destinationOrganizationDetails, [
					'destinationOrganisationId',
			  ])

	const receiverReferenceData = hederaMessages?.data?.find(
		item =>
			getIn(item, ['payload', 'number']) &&
			traceDetails?.deliveryOrder &&
			item?.organizationID === receiverOrgId &&
			getIn(item, ['payload', 'number']) === traceDetails.deliveryOrder
	)
	const initiatorReferenceData = hederaMessages?.data?.find(
		item =>
			getIn(item, ['payload', 'number']) &&
			traceDetails?.deliveryOrder &&
			item?.organizationID === initiatorId &&
			getIn(item, ['payload', 'number']) === traceDetails.deliveryOrder
	)
	const getCertificates = data => {
		const certificateList = []
		if (data && data.length > 0) {
			data.forEach(item => {
				certificateList.push(
					<>
						<div
							style={{
								display: 'flex',
							}}
						>
							<Box
								style={{
									width: forPdf ? '35%' : '40%',
									fontWeight: 500,
									fontSize: forPdf ? '12px' : '14px',
									cursor: 'pointer',
									padding: '2px 0px 2px 0px',
									color: theme.color.black3,
									textDecoration: 'underline',
								}}
								onClick={() => {
									onCertificateclick(item)
								}}
							>
								{getIn(item, ['name'])}
							</Box>
							<Box
								style={{
									width: forPdf ? '65%' : '60%',
									fontSize: forPdf ? '12px' : '14px',
									padding: '2px 0px 2px 0px',
									color: theme.color.black3,
								}}
							>
								{`${t('traceReport.validTill')} - ${getIn(
									item,
									['validTillDate']
								)}`}
							</Box>
						</div>
					</>
				)
			})
		}

		return (
			<div
				style={{
					paddingBottom: '20px',
				}}
			>
				{certificateList}
			</div>
		)
	}

	const getOriginatingCertificate = data => {
		const fullComponentArray = []
		const nameArray = []
		if (data && data.length > 0) {
			data.forEach(item => {
				if (!nameArray.includes(item.orgName)) {
					nameArray.push(item.orgName)
				}
			})
		}
		if (nameArray && nameArray.length > 0) {
			nameArray.forEach(ele => {
				const finalList = []
				const listForOrg = data.filter(item => item.orgName === ele)
				if (listForOrg && listForOrg.length > 0) {
					listForOrg.forEach(item => {
						if (finalList && finalList.length > 0) {
							if (
								!finalList.find(key => key.name === item.name)
							) {
								finalList.push(item)
							}
						} else {
							finalList.push(item)
						}
					})
				}
				fullComponentArray.push(
					<div>
						{/* add org name for certificate here if needed */}
						<div>{getCertificates(finalList)}</div>
					</div>
				)
			})
		}

		return fullComponentArray
	}

	const getWidth = () => {
		let width = forPdf ? '58%' : '62%'
		if (virtualTraceData) {
			width = forPdf ? '38%' : '45%'
		} else if (submittedState) {
			width = forPdf ? '68%' : '69%'
		} else if (!blockChainVerification) {
			width = forPdf ? '51%' : '57%'
		}

		return width
	}

	const verificationText = () => {
		let text
		if (virtualTraceData) {
			text = t('traceReport.virtualInventoryText')
		} else if (submittedState) {
			text = ''
		} else if (blockChainVerification) {
			text = t('tdmViewDocument.ALLDETAILSVERIFIEDFROMBLOCKCHAIN')
		} else {
			text = t('tdmViewDocument.ALLDETAILSNOTVERIFIEDFROMBLOCKCHAIN')
		}

		return text
	}

	const CollapsibleIcon = (
		<Box
			center
			height="auto"
			onClick={e => {
				e.stopPropagation()
				if (hasChildren && onCollapseAction) onCollapseAction()
			}}
		>
			<IconBox>
				<div style={{ color: theme.color.blue1 }}>
					{`${
						isOpen
							? t('traceReport.collapseSecondaryTrace')
							: t('traceReport.expandForSecondaryTrace')
					}`}
				</div>
				{isOpen ? (
					<IconWrapper
						size={25}
						style={{
							marginTop: '-2px',
							marginLeft: '8px',
							marginBottom: '-3px',
						}}
					>
						<Icon glyph={upArrow} />
					</IconWrapper>
				) : (
					<IconWrapper
						size={13}
						style={{
							margin: '4px 5px 3px 13px',
						}}
					>
						<Icon glyph={downArrow} />
					</IconWrapper>
				)}
			</IconBox>
		</Box>
	)

	return (
		<MainContainer>
			<BoxShadowWrapper
				style={{
					borderRadius: '0px',
					border: `1px solid ${theme.color.grey15}`,
				}}
			>
				<div
					style={{
						display: 'flex',
						backgroundColor: forPdf
							? theme.color.white
							: headerBackGroundColor,
						borderBottom: `1px solid ${theme.color.grey15}`,
					}}
				>
					<Box
						style={{
							width: getWidth(),
							fontSize: '16px',
							fontWeight: '500',
							color: theme.color.accent2,
							padding: '13px 30px 10px 30px',
						}}
					>
						{header}
					</Box>
					{showVerifiedText && (
						<div
							style={{
								width: forPdf ? '60%' : '55%',
								float: 'right',
								display: 'flex',
								paddingTop: '11px',
								paddingRight: '25px',
								justifyContent: 'flex-end',
							}}
						>
							<div
								style={{
									fontSize: submittedState ? '14px' : '12px',
									paddingTop: '3px',
									color: theme.color.accent2,
								}}
							>
								{verificationText()}
							</div>
							<div
								style={{
									paddingLeft: '16px',
								}}
							>
								{blockChainVerification && !virtualTraceData ? (
									<Illustration
										src="/images/success-round.svg"
										alt="dibizTour"
									/>
								) : (
									<Illustration
										src="/images/error-round.svg"
										alt="dibizTour"
									/>
								)}
							</div>
						</div>
					)}
				</div>
				<div
					style={{
						display: isMobile ? 'block' : 'flex',
						borderBottom: `1px solid ${theme.color.grey15}`,
					}}
				>
					<Box
						style={{
							width: isMobile ? '100%' : '33%',
							padding: '15px 30px',
							borderRight: isMobile
								? 'none'
								: `1px solid ${theme.color.grey15}`,
							borderBottom: isMobile
								? `1px solid ${theme.color.grey15}`
								: 'none',
						}}
					>
						<Box>
							<Box
								style={{
									fontSize: forPdf ? '12px' : '14px',
									color: theme.color.grey15,
								}}
							>
								{`${t('productTrace.trace')} ID`}
							</Box>
							<Box
								style={{
									fontSize: forPdf ? '12px' : '14px',
									padding: '8px 0px',
									color: theme.color.black3,
								}}
							>
								{getIn(traceDetails, ['traceId'])}
							</Box>
							<Box
								style={{
									fontSize: forPdf ? '12px' : '14px',
									color: theme.color.black3,
								}}
							>
								{getDateWithTimeByFormat(
									getIn(traceDetails, ['updatedDate'])
								)}
							</Box>
						</Box>
					</Box>
					<Box
						style={{
							width: isMobile ? '100%' : '33%',
							padding: '15px 30px',
							borderRight: isMobile
								? 'none'
								: `1px solid ${theme.color.grey15}`,
							borderBottom: isMobile
								? `1px solid ${theme.color.grey15}`
								: 'none',
						}}
					>
						<Box
							style={{
								fontSize: forPdf ? '12px' : '14px',
								color: theme.color.grey15,
							}}
						>
							{t('tdmColumnHeaders.delivery-order')}
						</Box>
						<Box
							style={{
								fontSize: forPdf ? '12px' : '14px',
								padding: '8px 0px',
								color: theme.color.black3,
							}}
						>
							{getIn(traceDetails, ['deliveryOrder']) ||
								t('common.notAvailable')}
						</Box>
					</Box>
					<Box
						style={{
							width: isMobile ? '100%' : '33%',
							padding: '15px 30px',
						}}
					>
						<Box
							style={{
								fontSize: forPdf ? '12px' : '14px',
								color: theme.color.grey15,
							}}
						>
							{t('ffbQualityCreate.product')}
						</Box>
						<Box
							style={{
								fontSize: forPdf ? '12px' : '14px',
								padding: '8px 0px',
								color: theme.color.black3,
							}}
						>
							{getIn(traceDetails, ['productName'])}
						</Box>
						<Box
							style={{
								fontSize: forPdf ? '12px' : '14px',
								color: theme.color.black3,
							}}
						>
							{getIn(traceDetails, ['quantity'])}
						</Box>
					</Box>
				</div>
				<div
					style={{
						display: isMobile ? 'block' : 'flex',
						borderBottom: `1px solid ${theme.color.grey15}`,
					}}
				>
					<Box
						style={{
							width: isMobile ? '100%' : '50%',
							padding: '15px 30px',
							borderRight: isMobile
								? 'none'
								: `1px solid ${theme.color.grey15}`,
							borderBottom: isMobile
								? `1px solid ${theme.color.grey15}`
								: 'none',
						}}
					>
						<Box
							style={{
								fontSize: forPdf ? '12px' : '14px',
								color: theme.color.grey15,
							}}
						>
							{`${t(
								'traceReport.transactionReferenceFromBlockchain'
							)} - ${t('plantationRecordsListing.initiator')}`}
						</Box>
						<Box
							style={{
								fontSize: forPdf ? '12px' : '14px',
								padding: '8px 0px',
								color: theme.color.primary,
								cursor: getIn(initiatorReferenceData, [
									'topicID',
								])
									? 'pointer'
									: 'default',
							}}
							onClick={() => {
								if (
									getIn(initiatorReferenceData, [
										'topicID',
									]) &&
									getIn(initiatorReferenceData, [
										'topicSequenceNumber',
									])
								) {
									getExplorerLink(
										getIn(initiatorReferenceData, [
											'topicID',
										]),
										getIn(initiatorReferenceData, [
											'topicSequenceNumber',
										])
									)
								}
							}}
						>
							<LinkWrap>{`${getIn(initiatorReferenceData, [
								'topicID',
							]) ||
								t(
									'common.notAvailable'
								)} / ${getIn(initiatorReferenceData, [
								'topicSequenceNumber',
							]) || t('common.notAvailable')}`}</LinkWrap>
						</Box>
					</Box>
					<Box
						style={{
							width: isMobile ? '100%' : '50%',
							padding: '15px 30px',
							borderRight: isMobile
								? 'none'
								: `1px solid ${theme.color.grey15}`,
							borderBottom: isMobile
								? `1px solid ${theme.color.grey15}`
								: 'none',
						}}
					>
						<Box
							style={{
								fontSize: forPdf ? '12px' : '14px',
								color: theme.color.grey15,
							}}
						>
							{`${t(
								'traceReport.transactionReferenceFromBlockchain'
							)} - ${t('plantationRecordsListing.receiver')}`}
						</Box>
						<Box
							style={{
								fontSize: forPdf ? '12px' : '14px',
								padding: '8px 0px',
								color: theme.color.primary,
								cursor: getIn(receiverReferenceData, [
									'topicID',
								])
									? 'pointer'
									: 'default',
							}}
							onClick={() => {
								if (
									getIn(receiverReferenceData, ['topicID']) &&
									getIn(receiverReferenceData, [
										'topicSequenceNumber',
									])
								) {
									getExplorerLink(
										getIn(receiverReferenceData, [
											'topicID',
										]),
										getIn(receiverReferenceData, [
											'topicSequenceNumber',
										])
									)
								}
							}}
						>
							<LinkWrap>{`${getIn(receiverReferenceData, [
								'topicID',
							]) ||
								t(
									'common.notAvailable'
								)} / ${getIn(receiverReferenceData, [
								'topicSequenceNumber',
							]) || t('common.notAvailable')}`}</LinkWrap>
						</Box>
					</Box>
				</div>
				<div
					style={{
						minHeight:
							getIn(originatingOrganizationDetails, [
								'originatingSustainabilityCertification',
							]).length > 0 ||
							getIn(destinationOrganizationDetails, [
								'destinationSustainabilityCertification',
							]).length > 0
								? '275px'
								: '150px',
						display: isMobile ? 'block' : 'flex',
					}}
				>
					<Box
						style={{
							width: isMobile ? '100%' : '50%',
							position: 'relative',
							padding: '15px 30px',
							borderRight: isMobile
								? 'none'
								: `1px solid ${theme.color.grey15}`,
							borderBottom: isMobile
								? `1px solid ${theme.color.grey15}`
								: 'none',
						}}
					>
						<Box>
							<Box
								style={{
									fontSize: forPdf ? '12px' : '14px',
									color: theme.color.grey15,
								}}
							>
								{t('traceReport.originatingOrganisationName')}
							</Box>
							<Box
								style={{
									fontWeight: 500,
									fontSize: forPdf ? '12px' : '14px',
									padding: '8px 0px',
									color: theme.color.black3,
								}}
							>
								{getIn(originatingOrganizationDetails, [
									'originatingOrganisationName',
								])}
							</Box>
							{/* <Box
							style={{
								fontSize: '14px',
								color: theme.color.black3,
							}}
						>
							(
							{getIn(originatingOrganizationDetails, [
								'originatingOrganisationId',
							])}
							)
						</Box> */}
						</Box>
						<Box
							style={{
								marginTop: '16px',
								minHeight: forPdf ? '250px' : '100px',
							}}
						>
							{getIn(originatingOrganizationDetails, [
								'originatingSustainabilityCertification',
							]) &&
							getIn(originatingOrganizationDetails, [
								'originatingSustainabilityCertification',
							]).length > 0 ? (
								<div>
									<Box
										style={{
											fontSize: forPdf ? '12px' : '14px',
											marginBottom: '6px',
											color: theme.color.grey15,
										}}
									>
										{t(
											'traceReport.sustainabilityCertification'
										)}
									</Box>
									{getOriginatingCertificate(
										getIn(originatingOrganizationDetails, [
											'originatingSustainabilityCertification',
										])
									)}
								</div>
							) : (
								''
							)}
						</Box>

						{getIn(originatingOrganizationDetails, [
							'originatingGpsInfo',
						])?.length > 0 ? (
							<Box
								style={{
									marginTop: '16px',
									position: 'absolute',
									bottom: '15px',
									display: forPdf ? 'block' : 'contents',
								}}
							>
								<Box
									style={{
										fontSize: forPdf ? '12px' : '14px',
										color: theme.color.grey15,
									}}
								>
									{t(
										'traceReport.GPSCoordinatesWithLat&Long'
									)}
								</Box>
								<Box
									style={{
										fontSize: forPdf ? '12px' : '14px',
										padding: '8px 0px',
										color: theme.color.black3,
									}}
								>
									{getIn(originatingOrganizationDetails, [
										'originatingGpsInfo',
									])}
								</Box>
							</Box>
						) : (
							''
						)}
					</Box>
					<Box
						style={{
							width: isMobile ? '100%' : '50%',
							position: 'relative',
							padding: '15px 30px',
						}}
					>
						<Box>
							<Box>
								<Box
									style={{
										fontSize: forPdf ? '12px' : '14px',
										color: theme.color.grey15,
									}}
								>
									{`${t(
										'traceReport.destinationOrganisationName'
									)} (ID)`}
								</Box>
								<Box
									style={{
										fontWeight: 500,
										fontSize: forPdf ? '12px' : '14px',
										padding: '8px 0px',
										color: theme.color.black3,
									}}
								>
									{getIn(destinationOrganizationDetails, [
										'destinationOrganisationName',
									])}
								</Box>
							</Box>
							<Box
								style={{
									marginTop: '16px',
									minHeight: forPdf ? '250px' : '100px',
								}}
							>
								{getIn(destinationOrganizationDetails, [
									'destinationSustainabilityCertification',
								]) &&
								getIn(destinationOrganizationDetails, [
									'destinationSustainabilityCertification',
								]).length > 0 ? (
									<div>
										<Box
											style={{
												fontSize: forPdf
													? '12px'
													: '14px',
												marginBottom: '6px',
												color: theme.color.grey15,
											}}
										>
											{t(
												'traceReport.sustainabilityCertification'
											)}
										</Box>
										{getCertificates(
											getIn(
												destinationOrganizationDetails,
												[
													'destinationSustainabilityCertification',
												]
											)
										)}
									</div>
								) : (
									''
								)}
							</Box>

							{getIn(destinationOrganizationDetails, [
								'destinationGpsInfo',
							])?.length > 0 ? (
								<Box
									style={{
										marginTop: '16px',
										position: 'absolute',
										bottom: '15px',
										display: forPdf ? 'block' : 'contents',
									}}
								>
									<Box
										style={{
											fontSize: forPdf ? '12px' : '14px',
											color: theme.color.grey15,
										}}
									>
										{t(
											'traceReport.GPSCoordinatesWithLat&Long'
										)}
									</Box>
									<Box
										style={{
											fontSize: forPdf ? '12px' : '14px',
											padding: '8px 0px',
											color: theme.color.black3,
										}}
									>
										{getIn(destinationOrganizationDetails, [
											'destinationGpsInfo',
										])}
									</Box>
								</Box>
							) : (
								''
							)}
						</Box>
					</Box>
				</div>
			</BoxShadowWrapper>
			{secondaryTrace && hasChildren && (
				<div
					style={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<div
						style={{
							borderLeft: isOpen
								? `2px dotted ${theme.color.grey18}`
								: '',
							marginLeft: 28,
						}}
					/>
					<div>
						<ExpandCollapseContainer>
							{CollapsibleIcon}
						</ExpandCollapseContainer>
					</div>
				</div>
			)}
		</MainContainer>
	)
}

export { TraceReportTable }

import Duck from 'extensible-duck'
import { getIn, setIn, merge } from 'timm'
import { createSelector } from 'reselect'
import { isEmptyObject, getKeysByRegex, pick } from 'ui-tdm-app/utils/helpers'

const initialState = {
	clientID: null,
	actor: [],
	profile: {},
	roles: {
		allowed: [],
		denied: [],
		additionalMeta: {},
		timeStamp: null,
	},
	profileStatus: {
		isFetching: false,
		isError: false,
	},
	currentOrganization: {},
	organizations: {},
	partners: {},
	users: {},
	usersList: {},
	orgAssets: {},
	firstTimeSignInStats: {},
	modalStatus: false,
	modalStatusLogin: false,
	modalRoute: [],
	orgSearchResults: {},
	productSearchResults: [],
	organizationsStatus: {
		isFetching: false,
		isError: false,
	},
	products: {
		loading: false,
		list: [],
		details: {},
	},
	storage: {
		list: [],
		details: {},
	},
	signIn: {
		email: '',
	},
	forgot: {
		loading: false,
		status: 'initiate', // can be initiate | success
		message: '',
	},
	resetPassword: {
		loading: false,
		status: 'initiate', // can be initiate or success
	},
	notifications: {},
	incompleteCertificate: [],
	usersData: [],
	guidedTourCount: '',
}

export const AuthDuc = new Duck({
	namespace: 'auth',
	store: 'global',
	types: [
		'SET_CLIENT_ID',
		'SET_USER_PROFILE',
		'VERIFY_OTP',
		'INITIATE_OTP',
		'SET_CURRENT_USER_ORGANIZATION',
		'SIGN_IN',
		'SIGN_OUT',
		'SIGN_OUT_ON_REGISTER',
		'FORGOT_PASSWORD',
		'GET_USER_PROFILE',
		'REFRESH_TOKEN',
		'REFRESH_TOKEN_SUCCESS',
		'REFRESH_TOKEN_FAILURE',
		'FORGOT_PASSWORD_LOADING',
		'FORGOT_PASSWORD_SUCCESS',
		'FLUSH_FORGOT_PASSWORD_STATE',
		'GET_USER_PROFILE_FAILURE',
		'GET_USER_PROFILE_LOADING',
		'FETCH_ORG_DETAILS',
		'UPDATE_ORG_DETAILS',
		'UPDATE_ORG_FETCH_STATUS',
		'ADD_ORG_ADDRESS_FETCH',
		'FETCH_LOGGED_IN_USER_ORG',
		'SET_USER_ROLES',
		'CHECK_USER_ROUTE_CHANGE',
		'FLUSH_STATE',
		'FETCH_PRODUCTS',
		'FETCH_PRODUCTS_SUCCESS',
		'FETCH_PRODUCTS_STATUS',
		'FETCH_NOTIFICATIONS',
		'SET_NOTIFICATIONS',
		'UPDATE_STATUS_SINGLE_NOTIFICATION',
		'FETCH_PARTNER_ORG_DETAILS',
		'FETCH_GLOBAL_ORG_DETAILS',
		'FETCH_ORG_USER_DETAILS',
		'FETCH_ALL_PARTNERS',
		'SET_ALL_PARTNERS',
		'SET_ALL_GLOBAL_ORGS',
		'SET_PARTNER_ORG_DETAILS',
		'SET_ORG_USER_DETAILS',
		'SET_ORG_SEARCH_DETAILS',
		'SET_PRODUCT_SEARCH_DETAILS',
		'SEARCH_PARTNER',
		'SEARCH_ORGANIZATION',
		'READ_SELECTED_PARTNER_DETAILS',
		'FETCH_ACTOR',
		'SET_ACTOR',
		'FETCH_IS_SWITCH_PROFILE_ENABLED',
		'SET_IS_SWITCH_PROFILE_ENABLED',
		'FETCH_STORAGETANK',
		'FETCH_STORAGETANK_SUCCESS',
		'FETCH_STORAGEUNITS',
		'FETCH_STORAGEUNITS_SUCCESS',
		'RESET_PASSWORD',
		'RESET_PASSWORD_LOADING',
		'RESET_PASSWORD_SUCCESS',
		'FLUSH_RESET_PASSWORD_STATE',
		'SEARCH_PARTNER_ORGANIZATION',
		'FETCH_ORG_ASSETS_SUCCESS',
		'GUIDED_TOUR_STATUS',
		'GUIDED_TOUR_COUNTER',
		'SET_FIRST_TIME_SIGNIN_STATS',
		'SET_FIRST_TIME_SIGNIN_MODAL',
		'SET_FIRST_TIME_SIGNIN_MODAL_STATUS',
		'SET_FIRST_TIME_SIGNIN_MODAL_STATUS_LOGIN',
		'SET_LANGUAGE',
		'SET_LANGUAGE_LOCAL',
		'FETCH_USERLIST_COUNT_SUCCESS',
		'SEARCH_PRODUCT',
		'SET_INCOMPLETE_CERTIFICATE',
		'SET_VIRTUAL_ACCESS',
		'GET_SUPPLYBASE',
		'SET_SUPPLYBASE',
		'FETCH_USER_LISTS',
		'FETCH_USER_LISTS_SUCCESS',
		'FETCH_CERTIFICATES',
		'SET_CERTIFICATES',
		'CHECK_IF_INITIAL_SETUP_IS_REQUIRED',
	],
	initialState,
	helpers: {
		/**
		 * A helper to get the flags per feature specified. The response will usually be variables tha
		 * @param {*} type feature name associated in the iam ex. delivery-order (from fe.tdm.delivery-order-c)
		 * @param {*} roleFlags key value pair that can be used as reference. Example: const ROLE_ACCESSES
		 *    {
		 *     canReadDeliveryOrder: 'fe.tdm.delivery-order.r',
		 *     canControlDeliveryOrder: 'fe.tdm.delivery-order.c'
		 *   }
		 */
		getAccessFlagsPerFeature(type = '*', roleFlags = {}) {
			return pick(
				roleFlags,
				getKeysByRegex(roleFlags, type.replace(/-/g, ''))
			)
		},
		getAllowedAccesses(
			allowedRoles = [],
			deniedRoles = [],
			referenceFlags = {}
		) {
			return Object.keys(referenceFlags).reduce((agg, key) => {
				const aggregator = agg

				const feature = referenceFlags[key]
				aggregator[key] =
					!deniedRoles.includes(feature) &&
					allowedRoles.includes(feature)

				return aggregator
			}, {})
		},
	},
	reducer: (state, action, duck) => {
		if (!action) return state // will be used for ssr initial state

		switch (action.type) {
			case duck.types.FLUSH_STATE: {
				return initialState
			}

			case duck.types.SIGN_IN: {
				let newState = setIn(state, ['profileStatus'], {
					isFetching: false,
					isError: false,
				})

				newState = setIn(newState, ['profile'], {})

				return setIn(newState, ['clientID'], null)
			}

			case duck.types.SET_CLIENT_ID: {
				return setIn(state, ['clientID'], action.clientID)
			}

			case duck.types.SET_USER_PROFILE: {
				return setIn(state, ['profile'], action.profile)
			}

			case duck.types.FORGOT_PASSWORD_SUCCESS: {
				const newState = setIn(state, ['forgot', 'status'], 'success')

				return setIn(
					newState,
					['forgot', 'message'],
					action.message || ''
				)
			}

			case duck.types.FLUSH_FORGOT_PASSWORD_STATE: {
				return setIn(state, ['forgot'], initialState.forgot)
			}

			case duck.types.FORGOT_PASSWORD_LOADING: {
				return setIn(
					state,
					['forgot', 'loading'],
					action.loading || false
				)
			}

			case duck.types.RESET_PASSWORD_LOADING: {
				return setIn(
					state,
					['resetPassword', 'loading'],
					action.loading || false
				)
			}

			case duck.types.RESET_PASSWORD_SUCCESS: {
				const newState = setIn(
					state,
					['resetPassword', 'status'],
					'success'
				)

				return setIn(
					newState,
					['resetPassword', 'message'],
					action.message || ''
				)
			}

			case duck.types.FLUSH_RESET_PASSWORD_STATE: {
				return setIn(
					state,
					['resetPassword'],
					initialState.resetPassword
				)
			}

			case duck.types.GET_USER_PROFILE_LOADING: {
				const nextStatus = action.status || false

				return setIn(state, ['profileStatus'], {
					isFetching: nextStatus,
					isError: false,
				})
			}

			case duck.types.GET_USER_PROFILE_FAILURE: {
				const nextStatus = action.status || false
				const currentLoadingStatus =
					getIn(state, ['profileStatus', 'isFetching']) || false

				return setIn(state, ['profileStatus'], {
					isFetching: nextStatus ? false : currentLoadingStatus,
					isError: action.status,
				})
			}

			case duck.types.UPDATE_ORG_DETAILS: {
				const { orgDetailMap } = action

				const currentOrgs = getIn(state, ['organizations'] || {})

				return setIn(
					state,
					['organizations'],
					merge(currentOrgs, orgDetailMap)
				)
			}
			case duck.types.SET_ALL_PARTNERS: {
				const { list } = action

				return setIn(state, ['allpartners'], list)
			}
			case duck.types.SET_ALL_GLOBAL_ORGS: {
				const { list } = action

				return setIn(state, ['globalOrgs'], list)
			}
			case duck.types.SET_PARTNER_ORG_DETAILS: {
				const { orgDetailMap } = action

				return setIn(state, ['partners'], orgDetailMap)
			}
			case duck.types.SET_ORG_USER_DETAILS: {
				const { orgDetailMap } = action

				return setIn(state, ['users'], orgDetailMap)
			}
			case duck.types.FETCH_USERLIST_COUNT_SUCCESS: {
				const { userList } = action

				return setIn(state, ['usersList'], userList)
			}

			case duck.types.SET_ORG_SEARCH_DETAILS: {
				const { orgSearchDetails } = action

				return setIn(state, ['orgSearchResults'], orgSearchDetails)
			}
			case duck.types.SET_PRODUCT_SEARCH_DETAILS: {
				const { productList } = action

				return setIn(state, ['productSearchResults'], productList)
			}

			case duck.types.UPDATE_ORG_FETCH_STATUS: {
				const { isFetching, hasError } = action

				return setIn(state, ['organizationsStatus'], {
					isFetching,
					isError: hasError,
				})
			}

			case duck.types.SET_CURRENT_USER_ORGANIZATION: {
				const { orgDetails = {} } = action

				return setIn(state, ['currentOrganization'], orgDetails)
			}

			case duck.types.FETCH_ORG_ASSETS_SUCCESS: {
				const { values } = action

				return setIn(state, ['orgAssets'], values)
			}

			case duck.types.SET_USER_ROLES: {
				const { roles = {} } = action

				const { allowedFeatures = {}, deniedFeatures = {} } = roles

				let newState = setIn(
					state,
					['roles', 'allowed'],
					Object.keys(allowedFeatures)
				)

				newState = setIn(
					newState,
					['roles', 'denied'],
					Object.keys(deniedFeatures)
				)

				// get all the features with their filters and apply them
				const allFeatures = merge(deniedFeatures, allowedFeatures)
				const filters = Object.keys(allFeatures).reduce((agg, key) => {
					const aggregator = agg

					let dataFilters = getIn(allFeatures, [key, 'dataFilter'])
					if (dataFilters) {
						dataFilters = Array.isArray(dataFilters)
							? dataFilters
							: [dataFilters]
						aggregator[key] = dataFilters.reduce((_agg, data) => {
							if (!isEmptyObject(data)) return merge(_agg, data)

							return _agg
						}, {})
					}

					return aggregator
				}, {})

				newState = setIn(
					newState,
					['roles', 'timeStamp'],
					new Date().toISOString()
				)

				return setIn(newState, ['roles', 'additionalMeta'], filters)
			}

			case duck.types.FETCH_PRODUCTS_SUCCESS: {
				return setIn(state, ['products', 'list'], action.products)
			}
			case duck.types.FETCH_STORAGETANK_SUCCESS: {
				return setIn(state, ['storage', 'list'], action.tanks)
			}
			case duck.types.FETCH_STORAGEUNITS_SUCCESS: {
				return setIn(state, ['storageunits', 'list'], action.units)
			}
			case duck.types.FETCH_PRODUCTS_STATUS: {
				const newState = setIn(
					state,
					['products', 'error'],
					action.isError
				)

				return setIn(
					newState,
					['products', 'loading'],
					action.isLoading
				)
			}

			case duck.types.SET_NOTIFICATIONS: {
				return setIn(state, ['notifications'], action.notifications)
			}

			case duck.types.SET_ACTOR: {
				return setIn(state, ['actor'], action.actor)
			}

			case duck.types.SET_FIRST_TIME_SIGNIN_STATS: {
				return setIn(state, ['firstTimeSignInStats'], action.stats)
			}

			case duck.types.SET_FIRST_TIME_SIGNIN_MODAL: {
				return setIn(state, ['modalRoute'], action.stats)
			}

			case duck.types.SET_FIRST_TIME_SIGNIN_MODAL_STATUS: {
				return setIn(state, ['modalStatus'], action.status)
			}

			case duck.types.SET_FIRST_TIME_SIGNIN_MODAL_STATUS_LOGIN: {
				return setIn(state, ['modalStatusLogin'], action.status)
			}

			case duck.types.SET_INCOMPLETE_CERTIFICATE: {
				return setIn(
					state,
					['incompleteCertificate'],
					action.certificate
				)
			}
			case duck.types.SET_VIRTUAL_ACCESS: {
				return setIn(state, ['virtualAccess'], action.access)
			}
			case duck.types.SET_SUPPLYBASE: {
				return setIn(state, ['supplyBase'], action.supplyBase)
			}

			case duck.types.FETCH_USER_LISTS_SUCCESS: {
				return setIn(state, ['usersData'], action.list)
			}

			case duck.types.SET_CERTIFICATES: {
				const { certificates } = action

				return setIn(state, ['certificates'], certificates)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		userSignInEmail: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth =>
				getIn(auth, ['signIn', 'email'])
			)
		),
		getClientID: state => getIn(state, ['app', 'cookies', 'clientID']),
		getUserProfile: new Duck.Selector(selectors =>
			createSelector(
				selectors.auth,
				auth => getIn(auth, ['profile']) || {}
			)
		),
		getForgotPasswordState: new Duck.Selector(selectors =>
			createSelector(
				selectors.auth,
				auth => getIn(auth, ['forgot']) || {}
			)
		),
		getResetPasswordState: new Duck.Selector(selectors =>
			createSelector(
				selectors.auth,
				auth => getIn(auth, ['resetPassword']) || {}
			)
		),
		getProfileFetchStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.auth,
				auth => getIn(auth, ['profileStatus']) || {}
			)
		),
		getUserOrgId: new Duck.Selector(
			selectors =>
				createSelector(selectors.getUserProfile, profile =>
					getIn(profile, ['organization', 'id'])
				) || null
		),
		getAvailableOrgs: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth => auth.organizations || {})
		),
		getTargetOrgsOfUser: new Duck.Selector(selectors =>
			createSelector(
				selectors.getAvailableOrgs,
				selectors.getUserOrgId,
				(orgs = {}, currentOrgId = '') =>
					Object.values(orgs).filter(org => org.id !== currentOrgId)
			)
		),
		getAvailablePartners: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth => auth.partners || {})
		),
		getAvailableOrgUsers: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth => auth.users || {})
		),
		getAvailableOrgUserLists: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth => auth.usersList || {})
		),

		getTargetPartnersOfUser: new Duck.Selector(selectors =>
			createSelector(
				selectors.getAvailablePartners,
				(partners = {}) =>
					Object.values(partners).filter(partner => partner) || []
			)
		),
		getOrgUsers: new Duck.Selector(selectors =>
			createSelector(selectors.getAvailableOrgUsers, (users = {}) =>
				Object.values(users).filter(user => user)
			)
		),
		getOrgSearchResults: state =>
			getIn(state, ['auth', 'orgSearchResults']) || {},

		getCurrentOrgDetails: state =>
			getIn(state, ['auth', 'currentOrganization']) || {},

		getProductSearchResults: state =>
			getIn(state, ['auth', 'productSearchResults']) || [],
		getOrganizationFetchStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.auth,
				auth => getIn(auth, ['organizationsStatus']) || {}
			)
		),
		getCurrentOrganization: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth =>
				getIn(auth, ['currentOrganization'])
			)
		),
		checkVerified: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth =>
				getIn(auth, ['currentOrganization', 'status', 'state'])
			)
		),
		getOrgAssets: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth => getIn(auth, ['orgAssets']))
		),

		getOrganizationByID: new Duck.Selector(selectors =>
			createSelector(
				selectors.getAvailableOrgs,
				(_, targetOrgId) => targetOrgId,
				(allOrgs = {}, targetOrgId = '') => allOrgs[targetOrgId] || {}
			)
		),
		getPartnerOrganizationByID: new Duck.Selector(selectors =>
			createSelector(
				selectors.getAvailablePartners,
				(_, targetOrgId) => targetOrgId,
				(allOrgs = {}, targetOrgId = '') => allOrgs[targetOrgId] || {}
			)
		),
		getCurrentUserRoles: new Duck.Selector(selectors =>
			createSelector(
				selectors.auth,
				auth => auth.roles || initialState.roles
			)
		),
		getProducts: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth => auth.products.list || [])
		),
		getTanks: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth => auth.storage.list || [])
		),
		getStorageUnits: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth =>
				auth.storageunits ? auth.storageunits.list : []
			)
		),
		getProductsFetchStatus: new Duck.Selector(selectors =>
			createSelector(selectors.auth, ({ isLoading, isError }) => ({
				isLoading,
				isError,
			}))
		),
		getNotifications: state => getIn(state, ['auth', 'notifications']),
		getActor: state => getIn(state, ['auth', 'actor']) || [],
		getModalStatusRoute: state =>
			getIn(state, ['auth', 'modalRoute']) || [],
		getModalStatus: state => getIn(state, ['auth', 'modalStatus']) || false,
		getModalStatusLogin: state =>
			getIn(state, ['auth', 'modalStatusLogin']) || false,
		getFirstTimeStats: state =>
			getIn(state, ['auth', 'firstTimeSignInStats']) || {},
		getIncompleteCertificate: state =>
			getIn(state, ['auth', 'incompleteCertificate']) || [],
		getVirtualAccess: state =>
			getIn(state, ['auth', 'virtualAccess']) || false,
		getSupplybase: state => getIn(state, ['auth', 'supplyBase']) || [],
		getUsersData: state => getIn(state, ['auth', 'usersData']) || [],
		getAllPartners: state => getIn(state, ['auth', 'allpartners']) || [],
		getGlobalOrgs: state => getIn(state, ['auth', 'globalOrgs']) || [],
		getCertificates: state => getIn(state, ['auth', 'certificates']) || [],
	},
	creators: duck => ({
		flushState: () => ({
			type: duck.types.FLUSH_STATE,
		}),
		loginUser: (credentials, helpers) => ({
			type: duck.types.SIGN_IN,
			credentials,
			helpers,
		}),
		guidedTourStatus: clientID => ({
			type: duck.types.GUIDED_TOUR_STATUS,
			clientID,
		}),
		guidedTourCounter: clientID => ({
			type: duck.types.GUIDED_TOUR_COUNTER,
			clientID,
		}),
		verifyOtp: (currentUserEmail, secret) => ({
			type: duck.types.VERIFY_OTP,
			currentUserEmail,
			secret,
		}),
		initiateOTP: (currentUserEmail, successToast) => ({
			type: duck.types.INITIATE_OTP,
			currentUserEmail,
			successToast,
		}),
		setClientID: clientID => ({
			type: duck.types.SET_CLIENT_ID,
			clientID,
		}),
		setUserProfile: profile => ({
			type: duck.types.SET_USER_PROFILE,
			profile,
		}),
		fetchUserProfile: clientID => ({
			type: duck.types.GET_USER_PROFILE,
			clientID,
		}),
		fetchCertificates: () => ({
			type: duck.types.FETCH_CERTIFICATES,
		}),
		setCertificates: certificates => ({
			type: duck.types.SET_CERTIFICATES,
			certificates,
		}),
		fetchLoggedInUserOrg: () => ({
			type: duck.types.FETCH_LOGGED_IN_USER_ORG,
		}),
		initiateRefreshToken: () => ({
			type: duck.types.REFRESH_TOKEN,
		}),
		errorRefreshToken: () => ({
			type: duck.types.REFRESH_TOKEN_FAILURE,
		}),
		successRefreshToken: () => ({
			type: duck.types.REFRESH_TOKEN_SUCCESS,
		}),
		switchToSuccessOnForgotPassword: message => ({
			type: duck.types.FORGOT_PASSWORD_SUCCESS,
			message,
		}),
		flushForgotPasswordState: () => ({
			type: duck.types.FLUSH_FORGOT_PASSWORD_STATE,
		}),
		handleForgotPasswordLoading: loading => ({
			type: duck.types.FORGOT_PASSWORD_LOADING,
			loading,
		}),
		initiateForgotPassword: email => ({
			type: duck.types.FORGOT_PASSWORD,
			email,
		}),
		setProfileErrorStatus: status => ({
			type: duck.types.GET_USER_PROFILE_FAILURE,
			status,
		}),
		setProfileLoadingStatus: status => ({
			type: duck.types.GET_USER_PROFILE_LOADING,
			status,
		}),
		signOutUser: redirectTo => ({
			type: duck.types.SIGN_OUT,
			redirectTo,
		}),
		fetchOrgDetails: (
			orgIDs = [],
			fetchAll = false,
			skipLoading = false
		) => ({
			type: duck.types.FETCH_ORG_DETAILS,
			orgIDs,
			fetchAll,
			skipLoading,
		}),
		updateOrgDetails: orgDetailMap => ({
			type: duck.types.UPDATE_ORG_DETAILS,
			orgDetailMap,
		}),
		fetchPartnerOrgs: (fetchAll = false, skipLoading = false) => ({
			type: duck.types.FETCH_PARTNER_ORG_DETAILS,
			fetchAll,
			skipLoading,
		}),
		fetchGlobalOrgs: () => ({
			type: duck.types.FETCH_GLOBAL_ORG_DETAILS,
		}),
		fetchOrgsUser: (
			userIDs = [],
			fetchAll = false,
			skipLoading = false
		) => ({
			type: duck.types.FETCH_ORG_USER_DETAILS,
			userIDs,
			fetchAll,
			skipLoading,
		}),
		fetchAllPartners: () => ({
			type: duck.types.FETCH_ALL_PARTNERS,
		}),
		setAllPartners: list => ({
			type: duck.types.SET_ALL_PARTNERS,
			list,
		}),
		setGlobalOrgs: list => ({
			type: duck.types.SET_ALL_GLOBAL_ORGS,
			list,
		}),
		setPartnerDetails: orgDetailMap => ({
			type: duck.types.SET_PARTNER_ORG_DETAILS,
			orgDetailMap,
		}),
		setOrgUserDetails: orgDetailMap => ({
			type: duck.types.SET_ORG_USER_DETAILS,
			orgDetailMap,
		}),
		fetchUserListsCountSuccess: userList => ({
			type: duck.types.FETCH_USERLIST_COUNT_SUCCESS,
			userList,
		}),
		setOrganizationSearchDetails: orgSearchDetails => ({
			type: duck.types.SET_ORG_SEARCH_DETAILS,
			orgSearchDetails,
		}),
		setProductSearchDetails: productList => ({
			type: duck.types.SET_PRODUCT_SEARCH_DETAILS,
			productList,
		}),
		readSelectedOrg: id => ({
			type: duck.types.READ_SELECTED_PARTNER_DETAILS,
			id,
		}),
		searchPartner: query => ({
			type: duck.types.SEARCH_PARTNER,
			query,
		}),
		searchOrganization: (query, virtualAccess = false) => ({
			type: duck.types.SEARCH_ORGANIZATION,
			query,
			virtualAccess,
		}),
		searchProducts: query => ({
			type: duck.types.SEARCH_PRODUCT,
			query,
		}),
		searchPartnerOrganization: query => ({
			type: duck.types.SEARCH_PARTNER_ORGANIZATION,
			query,
		}),
		handleOrgFetchStatuses: (isFetching = false, hasError = false) => ({
			type: duck.types.UPDATE_ORG_FETCH_STATUS,
			isFetching,
			hasError,
		}),
		setCurrentOrg: (orgDetails = {}) => ({
			type: duck.types.SET_CURRENT_USER_ORGANIZATION,
			orgDetails,
		}),
		addOrgShippingAddress: (address, successToast, helpers = {}) => ({
			type: duck.types.ADD_ORG_ADDRESS_FETCH,
			address,
			successToast,
			helpers,
		}),
		validateUserRouteChange: routeActionType => ({
			type: duck.types.CHECK_USER_ROUTE_CHANGE,
			routeActionType,
		}),
		setActiveUserRoles: (roles = {}) => ({
			type: duck.types.SET_USER_ROLES,
			roles,
		}),
		fetchAllProducts: (rootModule = '') => ({
			type: duck.types.FETCH_PRODUCTS,
			rootModule,
		}),
		fetchProductsSuccess: (products = []) => ({
			type: duck.types.FETCH_PRODUCTS_SUCCESS,
			products,
		}),
		fetchAllStorageTank: () => ({
			type: duck.types.FETCH_STORAGETANK,
		}),
		fetchAllStorageTankSuccess: (tanks = []) => ({
			type: duck.types.FETCH_STORAGETANK_SUCCESS,
			tanks,
		}),
		fetchAllStorageUnits: () => ({
			type: duck.types.FETCH_STORAGEUNITS,
		}),
		fetchAllStorageUnitsSuccess: (units = []) => ({
			type: duck.types.FETCH_STORAGEUNITS_SUCCESS,
			units,
		}),
		fetchProductsStatus: (isLoading = false, isError = false) => ({
			type: duck.types.FETCH_PRODUCTS_STATUS,
			isLoading,
			isError,
		}),
		fetchNotifications: () => ({
			type: duck.types.FETCH_NOTIFICATIONS,
		}),
		setNotifications: notifications => ({
			type: duck.types.SET_NOTIFICATIONS,
			notifications,
		}),
		singleNotificationUpdateStatus: (id, status) => ({
			type: duck.types.UPDATE_STATUS_SINGLE_NOTIFICATION,
			id,
			status,
		}),
		fetchActor: () => ({
			type: duck.types.FETCH_ACTOR,
		}),
		setActor: actor => ({
			type: duck.types.SET_ACTOR,
			actor,
		}),
		resetPassword: (values, helpers) => ({
			type: duck.types.RESET_PASSWORD,
			values,
			helpers,
		}),
		handleResetPasswordLoading: loading => ({
			type: duck.types.RESET_PASSWORD_LOADING,
			loading,
		}),
		switchToSuccessOnResetPassword: message => ({
			type: duck.types.RESET_PASSWORD_SUCCESS,
			message,
		}),
		flushResetPasswordState: () => ({
			type: duck.types.FLUSH_RESET_PASSWORD_STATE,
		}),
		fetchOrgAssetsSuccess: values => ({
			type: duck.types.FETCH_ORG_ASSETS_SUCCESS,
			values,
		}),
		setFirstTimeSignInStats: stats => ({
			type: duck.types.SET_FIRST_TIME_SIGNIN_STATS,
			stats,
		}),
		setFirstTimeSignInModal: stats => ({
			type: duck.types.SET_FIRST_TIME_SIGNIN_MODAL,
			stats,
		}),
		setFirstTimeSignInModalStatus: status => ({
			type: duck.types.SET_FIRST_TIME_SIGNIN_MODAL_STATUS,
			status,
		}),
		setFirstTimeSignInModalStatusLogin: status => ({
			type: duck.types.SET_FIRST_TIME_SIGNIN_MODAL_STATUS_LOGIN,
			status,
		}),
		setLanguage: language => ({
			type: duck.types.SET_LANGUAGE,
			language,
		}),
		setLanguageonLocal: language => ({
			type: duck.types.SET_LANGUAGE_LOCAL,
			language,
		}),
		setIncompleteCertificate: certificate => ({
			type: duck.types.SET_INCOMPLETE_CERTIFICATE,
			certificate,
		}),
		setVirtualAccess: (access = false) => ({
			type: duck.types.SET_VIRTUAL_ACCESS,
			access,
		}),
		getSupplubase: millName => ({
			type: duck.types.GET_SUPPLYBASE,
			millName,
		}),
		setSupplubase: supplyBase => ({
			type: duck.types.SET_SUPPLYBASE,
			supplyBase,
		}),
		fetchUserLists: () => ({
			type: duck.types.FETCH_USER_LISTS,
		}),
		fetchUserListsSuccess: list => ({
			type: duck.types.FETCH_USER_LISTS_SUCCESS,
			list,
		}),
		checkIfInitialSetupIsRequired: () => ({
			type: duck.types.CHECK_IF_INITIAL_SETUP_IS_REQUIRED,
		}),
	}),
})

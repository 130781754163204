/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux'
import { getIn } from 'timm'
import { WebTourDuc } from './duc'
import * as mainTours from './TourSteps2'
import * as subTours from './TourSteps3'
import * as createTours from './TourSteps4'
// import * as viewTours from './TourSteps5'

export const getTourModuleActorBased = t => {
	return {
		palmoil_plantation: [
			{
				name: 'dashboard',
				steps: mainTours.plantationDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},

			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},

			{
				name: 'records',
				steps: mainTours.recordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.recordsVisit),
			},
			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},

			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],

		palmoil_plantation_administrator: [
			{
				name: 'dashboard',
				steps: mainTours.plantationDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},

			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},

			{
				name: 'records',
				steps: mainTours.recordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.recordsVisit),
			},
			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},

			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],

		palmoil_plantation_officemgmt: [
			{
				name: 'dashboard',
				steps: mainTours.plantationDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},

			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},

			{
				name: 'records',
				steps: mainTours.recordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.recordsVisit),
			},
			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminStepsOfficeMgmt(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},

			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],

		palmoil_mill: [
			{
				name: 'dashboard',
				steps: mainTours.refineryDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'production',
				steps: mainTours.productionMillSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.productionVisit),
			},
			{
				name: 'createIpBatch',
				steps: createTours.ipBatchCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},

			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createIpBatch2',
				steps: createTours.createIpBatchSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_mill_administrator: [
			{
				name: 'dashboard',
				steps: mainTours.refineryDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'production',
				steps: mainTours.productionMillSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.productionVisit),
			},
			{
				name: 'createIpBatch',
				steps: createTours.ipBatchCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},

			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createIpBatch2',
				steps: createTours.createIpBatchSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_mill_officemgmt: [
			{
				name: 'dashboard',
				steps: mainTours.refineryDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'production',
				steps: mainTours.productionMillSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.productionVisit),
			},
			{
				name: 'createIpBatch',
				steps: createTours.ipBatchCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminStepsOfficeMgmt(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createIpBatch2',
				steps: createTours.createIpBatchSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_kernelmill: [
			{
				name: 'dashboard',
				steps: mainTours.refineryDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'production',
				steps: mainTours.productionMillSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.productionVisit),
			},
			{
				name: 'createIpBatch',
				steps: createTours.ipBatchCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createIpBatch2',
				steps: createTours.createIpBatchSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_kernelmill_administrator: [
			{
				name: 'dashboard',
				steps: mainTours.refineryDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'production',
				steps: mainTours.productionMillSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.productionVisit),
			},
			{
				name: 'createIpBatch',
				steps: createTours.ipBatchCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createIpBatch2',
				steps: createTours.createIpBatchSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_kernelmill_officemgmt: [
			{
				name: 'dashboard',
				steps: mainTours.refineryDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'production',
				steps: mainTours.productionMillSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.productionVisit),
			},
			{
				name: 'createIpBatch',
				steps: createTours.ipBatchCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminStepsOfficeMgmt(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createIpBatch2',
				steps: createTours.createIpBatchSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},

			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_refinery: [
			{
				name: 'dashboard',
				steps: mainTours.refineryDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},
			// / {
			// 	name: 'tdmView',
			// 	steps: viewTours.tdmSelectViewSteps(t),
			// 	visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			// },
			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'production',
				steps: mainTours.productionRefinerySteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.productionVisit),
			},
			{
				name: 'createIpBatch',
				steps: createTours.ipBatchCreateRefinerySteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createIpBatch2',
				steps: createTours.createIpBatchSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_refinery_administrator: [
			{
				name: 'dashboard',
				steps: mainTours.refineryDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'production',
				steps: mainTours.productionRefinerySteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.productionVisit),
			},
			{
				name: 'createIpBatch',
				steps: createTours.ipBatchCreateRefinerySteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createIpBatch2',
				steps: createTours.createIpBatchSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],

		palmoil_refinery_officemgmt: [
			{
				name: 'dashboard',
				steps: mainTours.refineryDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'production',
				steps: mainTours.productionRefinerySteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.productionVisit),
			},
			{
				name: 'createIpBatch',
				steps: createTours.ipBatchCreateRefinerySteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'admin',
				steps: mainTours.adminStepsOfficeMgmt(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createIpBatch2',
				steps: createTours.createIpBatchSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createIpBatch),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],

		palmoil_storage: [
			{
				name: 'dashboard',
				steps: mainTours.dashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_storage_administrator: [
			{
				name: 'dashboard',
				steps: mainTours.dashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_storage_officemgmt: [
			{
				name: 'dashboard',
				steps: mainTours.dashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminStepsOfficeMgmt(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_trader: [
			{
				name: 'dashboard',
				steps: mainTours.dashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_trader_administrator: [
			{
				name: 'dashboard',
				steps: mainTours.dashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_trader_officemgmt: [
			{
				name: 'dashboard',
				steps: mainTours.dashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},

			{
				name: 'admin',
				steps: mainTours.adminStepsOfficeMgmt(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_manufacturer: [
			{
				name: 'dashboard',
				steps: mainTours.dashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_manufacturer_administrator: [
			{
				name: 'dashboard',
				steps: mainTours.dashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_manufacturer_officemgmt: [
			{
				name: 'dashboard',
				steps: mainTours.dashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'productTrace',
				steps: mainTours.traceSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.traceVisit),
			},

			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'admin',
				steps: mainTours.adminStepsOfficeMgmt(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'POCreate',
				steps: createTours.POCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'invoiceCreate',
				steps: createTours.invoiceCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_ffbdealer: [
			{
				name: 'dashboard',
				steps: mainTours.dealerDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'workCreate',
				steps: createTours.workCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.workCreate),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'scheduleCreate',
				steps: createTours.dealerScheduleCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.scheduleCreate),
			},
			{
				name: 'trips',
				steps: mainTours.tripsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tripsVisit),
			},

			{
				name: 'wbTickets',
				steps: mainTours.wbTicketsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.wbtktVisit),
			},

			{
				name: 'uploadWBTkt',
				steps: subTours.dealerUploadWBTktSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.uploadWBTkt),
			},
			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'workCreate2',
				steps: createTours.workCreateSteps2(t),
				visitStatus: useSelector(WebTourDuc.selectors.workCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'scheduleCreate2',
				steps: createTours.scheduleCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.scheduleCreate),
			},

			{
				name: 'uploadWBTkt2',
				steps: subTours.uploadWBTktSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.uploadWBTkt),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_ffbdealer_administrator: [
			{
				name: 'dashboard',
				steps: mainTours.dealerDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'workCreate',
				steps: createTours.workCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.workCreate),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'scheduleCreate',
				steps: createTours.dealerScheduleCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.scheduleCreate),
			},
			{
				name: 'trips',
				steps: mainTours.tripsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tripsVisit),
			},

			{
				name: 'wbTickets',
				steps: mainTours.wbTicketsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.wbtktVisit),
			},

			{
				name: 'uploadWBTkt',
				steps: subTours.dealerUploadWBTktSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.uploadWBTkt),
			},
			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'workCreate2',
				steps: createTours.workCreateSteps2(t),
				visitStatus: useSelector(WebTourDuc.selectors.workCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'scheduleCreate2',
				steps: createTours.scheduleCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.scheduleCreate),
			},

			{
				name: 'uploadWBTkt2',
				steps: subTours.uploadWBTktSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.uploadWBTkt),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_ffbdealer_officemgmt: [
			{
				name: 'dashboard',
				steps: mainTours.dealerDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'workCreate',
				steps: createTours.workCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.workCreate),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'scheduleCreate',
				steps: createTours.dealerScheduleCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.scheduleCreate),
			},
			{
				name: 'trips',
				steps: mainTours.tripsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tripsVisit),
			},

			{
				name: 'wbTickets',
				steps: mainTours.wbTicketsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.wbtktVisit),
			},

			{
				name: 'uploadWBTkt',
				steps: subTours.dealerUploadWBTktSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.uploadWBTkt),
			},
			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'admin',
				steps: mainTours.adminStepsOfficeMgmt(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'workCreate2',
				steps: createTours.workCreateSteps2(t),
				visitStatus: useSelector(WebTourDuc.selectors.workCreate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'scheduleCreate2',
				steps: createTours.scheduleCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.scheduleCreate),
			},

			{
				name: 'uploadWBTkt2',
				steps: subTours.uploadWBTktSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.uploadWBTkt),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_smallholder: [
			{
				name: 'dashboard',
				steps: mainTours.smallholderDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_smallholder_administrator: [
			{
				name: 'dashboard',
				steps: mainTours.smallholderDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'admin',
				steps: mainTours.adminSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader',
				steps: createTours.companyAdminCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'companyInfoHeader2',
				steps: createTours.companyCreateSteps(t),
				visitStatus: useSelector(
					WebTourDuc.selectors.companyInfoUpdate
				),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
		palmoil_smallholder_officemgmt: [
			{
				name: 'dashboard',
				steps: mainTours.smallholderDashboardSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.dashboardVisit),
			},
			{
				name: 'tdm',
				steps: mainTours.tdmSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.tdmVisit),
			},

			{
				name: 'DOCreate',
				steps: createTours.tdmDOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'partnerMgmt',
				steps: mainTours.partnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.partnerVisit),
			},

			{
				name: 'createPartner',
				steps: createTours.partnerCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'admin',
				steps: mainTours.adminStepsOfficeMgmt(t),
				visitStatus: useSelector(WebTourDuc.selectors.adminVisit),
			},
			{
				name: 'assetMgmtHeader',
				steps: createTours.assetAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader',
				steps: createTours.employeeAdminCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'settings',
				steps: mainTours.settingsSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.settingsVisit),
			},
			{
				name: 'passwordUpdate',
				steps: subTours.settingsPasswordSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate',
				steps: subTours.settingsLangSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
			{
				name: 'createPartner2',
				steps: createTours.createPartnerSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.createPartner),
			},
			{
				name: 'assetMgmtHeader2',
				steps: createTours.assetCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.assetsUpdate),
			},
			{
				name: 'employeeMgmtHeader2',
				steps: createTours.employeeCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.employeeUpdate),
			},
			{
				name: 'DOCreate2',
				steps: createTours.DOCreateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.deliveryCreate),
			},
			{
				name: 'passwordUpdate2',
				steps: subTours.passwordUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.passwordUpdate),
			},
			{
				name: 'languageUpdate2',
				steps: subTours.langUpdateSteps(t),
				visitStatus: useSelector(WebTourDuc.selectors.languageUpdate),
			},
		],
	}
}

export const tourModuleActorBased = (t, actor) => {
	let tourArray = []
	if (
		actor === 'rice_trader' ||
		actor === 'rice_packingcompany' ||
		actor === 'rice_packer'
	) {
		tourArray = getTourModuleActorBased(t).palmoil_trader
	} else if (actor === 'rice_mill') {
		tourArray = getTourModuleActorBased(t).palmoil_mill
	} else if (actor === 'rice_dealer') {
		tourArray = getTourModuleActorBased(t).palmoil_ffbdealer
	} else if (
		actor === 'rice_wholesaler' ||
		actor === 'rice_importer' ||
		actor === 'rice_endbuyer'
	) {
		tourArray = getTourModuleActorBased(t).palmoil_manufacturer
	} else {
		tourArray = getIn(getTourModuleActorBased(t), [actor])
	}

	return tourArray
}

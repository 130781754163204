import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { getIn } from 'timm'
import { getDateByFormat } from 'ui-tdm-app/utils/date'

const TABLE_ALIAS = {
	createdAt: 'weighbridge.createdAt',
	partner: 'weighbridge.partner',
	ticketNumber: 'weighbridge.ticketNumber',
	docReference: 'weighbridge.docReference',
	vehicleNumber: 'weighbridge.vehicleNumber',
	driver: 'weighbridge.driver',
}

export const getColumnConfig = ({ onChange, t }) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.createdAt)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'ticketNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.ticketNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const ticketNumber =
					getIn(datum, ['meta', 'weighbridge', 'ticketNumber']) ||
					'---'

				return <Label as="span">{ticketNumber}</Label>
			},
		},
		{
			property: 'docReference',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.docReference)}</TableTitle>
				</Box>
			),
			render: datum => {
				const docReference =
					getIn(datum, ['meta', 'entityNumber']) || '---'

				return <Label as="span">{docReference}</Label>
			},
		},
		{
			property: 'vehicleNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.vehicleNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const vehicleNumber =
					getIn(datum, [
						'meta',
						'other',
						'vehicleDetails',
						'number',
					]) || '---'

				return <Label as="span">{vehicleNumber}</Label>
			},
		},
		{
			property: 'driver',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.driver)}</TableTitle>
				</Box>
			),
			render: datum => {
				const driverName =
					getIn(datum, [
						'meta',
						'other',
						'vehicleDetails',
						'driver',
					]) || '---'

				return <Label as="span">{driverName}</Label>
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label="VIEW"
						onClick={() =>
							onChange('open_document', { row: datum })
						}
					/>
				)
			},
		},
	]
}

import React, { useState, useEffect, useCallback } from 'react'
import { Box } from 'ui-lib/utils/Box'
import * as yup from 'yup'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { storageUnitColumnConfig } from 'ui-tdm-app/modules/TradeDocumentManager/components/Columns'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { useFormik } from 'formik'
import { replaceAt, getIn, addLast, removeAt } from 'timm'
import { Input } from 'ui-lib/components/Input'
import { Table } from 'ui-lib/components/Table'
import { SelectSearch, Select } from 'ui-lib/components/Select'
import { ErrorSegment } from 'ui-tdm-app/modules/App/components/Error'
import { isEmptyObject, getCertificateTypes } from 'ui-tdm-app/utils/helpers'
import { P, H2, Label, Text } from 'ui-lib/components/Typography'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import {
	Button,
	ButtonWithNoBorder,
	ButtonIconWrapper,
} from 'ui-lib/components/Button'
import { getSupplyChainModelsWithId } from 'ui-tdm-app/modules/Admin/config'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import RightArrowIcon from 'ui-lib/icons/right-arrow.svg'
import { PaginatorIndexBlock } from 'ui-lib/components/Paginator'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Spacer } from 'ui-lib/utils/Spacer'
import PlusIcon from 'ui-lib/icons/plus.svg'
import { FormHeader, FormBody, FormFooter } from '../../../components/Fragments'

const DescriptionWrapper = styled(Box)({
	background: '#f1f3fb',
})

const validationSchema = validationSchemaList => {
	const {
		isPricingMandatory,
		isParentDoc,
		quantity,
		selectedProdQty,
		currentAvailableQty,
		isEntityInvoice,
		isEntityDO,
		isEntityPO,
		isNotSupplyChainFlow,
		isSupplyChainFlowWithoutTank,
		actor,
		t,
	} = validationSchemaList
	const shape = yup.object().shape({
		id: yup.string().required(t('validation.productRequired')),
		quantity: yup.number().positive(t('validation.positivequantity')),
		metricUnitPrice: yup
			.number(t('validation.unitPriceRequired'))
			.positive(t('validation.positivequantity')),
		taxRate: yup.number(t('validation.taxRequired')),
		supplyChainModel: yup.string(),
		certification: yup.string(),
	})

	if (isEntityDO) {
		if (actor[0].includes('palmoil_plantation')) {
			shape.fields.quantity = shape.fields.quantity.required(
				t('validation.quantityRequired')
			)
		}
		if (
			isSupplyChainFlowWithoutTank ||
			actor[0].includes('palmoil_ffbdealer')
		) {
			shape.fields.quantity = shape.fields.quantity
				.max(currentAvailableQty, t('validation.lessthanQuantity'))
				.required(t('validation.quantityRequired'))
		}
		if (!isNotSupplyChainFlow) {
			shape.fields.certification = shape.fields.certification.required()
			shape.fields.supplyChainModel = shape.fields.supplyChainModel.required()
		}
	}

	if (isEntityInvoice) {
		shape.fields.quantity = shape.fields.quantity.required(
			t('validation.quantityRequired')
		)
	}

	if (isEntityPO) {
		shape.fields.quantity = shape.fields.quantity.required(
			t('validation.quantityRequired')
		)
	}

	if (isPricingMandatory) {
		shape.fields.metricUnitPrice = shape.fields.metricUnitPrice.required(
			t('validation.unitPriceRequired')
		)
		shape.fields.taxRate = shape.fields.taxRate.required(
			t('validation.taxRequired')
		)
	}

	if (!isParentDoc) {
		shape.fields.quantity = quantity
			? shape.fields.quantity.max(quantity)
			: shape.fields.quantity.max(selectedProdQty)
	}

	return shape
}

const ProductsForm = ({
	onClose,
	onProductsChange,
	onActiveIndexChange,
	activeProducts = [],
	targetProductsFromProps = [],
	activeIndex: _activeIndex = 0,
	allowMinimalEdit,
	isPricingMandatory,
	isParentDoc,
	isEntityDO,
	isEntityPO,
	isEntityInvoice,
	partner,
	actor,
	isEdit,
	currentProductDetail,
	individualProductEntry,
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const productDetailsBasedOnSupplyChainModel =
		useSelector(
			TradeDocDuc.selectors.getProductDetailsBasedOnSupplyChainModel
		) || []

	const storageUnits = useSelector(AuthDuc.selectors.getStorageUnits)

	const { isLoading, isError } = useSelector(
		AuthDuc.selectors.getProductsFetchStatus
	)
	const storageTankLoadingStatus = useSelector(
		TradeDocDuc.selectors.getStorageTankLoadingStatus
	)
	const baseProduct = useSelector(TradeDocDuc.selectors.getBaseProduct)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [activeIndex, setActiveIndex] = useState(_activeIndex)
	const [_activeProducts, setActiveProducts] = useState(activeProducts)
	const [productsPayload, setProductsPayload] = useState(
		targetProductsFromProps
	)
	const [currentAvailableQty, setCurrentAvailableQty] = useState(0)

	const [storageUnitData, setStorageUnitData] = useState([])
	const [quantityErrorMessage, setQuantityErrorMessage] = useState(false)
	const activeProduct = _activeProducts[activeIndex] || {} // if present use that, if not empty
	const startIndex = 0
	const endIndex = _activeProducts.length || 0

	const isNotSupplyChainFlow =
		actor[0].includes('palmoil_plantation') ||
		actor[0].includes('palmoil_ffbdealer')

	const isSupplyChainFlowWithoutTank =
		actor[0].includes('palmoil_trader') ||
		actor[0].includes('palmoil_manufacturer')

	const isSupplyChainFlowWithTank =
		actor[0].includes('palmoil_mill') ||
		actor[0].includes('palmoil_kernelmill') ||
		actor[0].includes('palmoil_refinery')

	const {
		id = '',
		quantity,
		metricUnitPrice,
		tax,
		selectedProdQty: _selectedProdQty,
		certification,
		supplyChainModel,
	} = activeProduct
	const [selectedProdQty, setSelectedProdQty] = useState(_selectedProdQty)
	const activeTaxProduct = getIn(tax, [0, 'value']) || ''
	const [activeTrigger, setActiveTrigger] = useState('submit')

	useEffect(() => {
		// use is intending to close, so let the parent know of the selection
		onProductsChange(_activeProducts, productsPayload)
	}, [onProductsChange, _activeProducts, productsPayload])

	useEffect(() => {
		// use is intending to close, so let the parent know of the selection
		onActiveIndexChange(activeIndex)
	}, [onActiveIndexChange, activeIndex])

	const initialValues = {
		id,
		quantity,
		metricUnitPrice,
		taxRate: activeTaxProduct,
		_submitted: false,
		supplyChainModel,
		certification,
		inventoryType: '',
	}

	const validationSchemaList = {
		isPricingMandatory,
		isParentDoc,
		quantity,
		selectedProdQty,
		currentAvailableQty,
		isEntityInvoice,
		isEntityDO,
		isEntityPO,
		isNotSupplyChainFlow,
		isSupplyChainFlowWithoutTank,
		actor,
		t,
	}

	const {
		handleSubmit,
		submitForm,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		setFieldValue,
		isSubmitting,
		setFieldError,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema: validationSchema(validationSchemaList),
		onSubmit: (_values, { setSubmitting }) => {
			const productValue = {
				id: _values.id,
				quantity: _values.quantity,
				taxRate: _values.taxRate,
				supplyChainModel: _values.supplyChainModel,
			}

			const totalQty = storageUnitData.reduce((prev, current) => {
				return prev + current.quantity
			}, 0)

			if (_values.metricUnitPrice) {
				productValue.metricUnitPrice = _values.metricUnitPrice
			}

			if (_values.id) {
				const activeProductDetail = allProducts.find(
					prod => _values.id === prod.id
				)

				productValue.lcv =
					(productValue.quantity || totalQty) *
					activeProductDetail.lcv

				if (isEntityDO) {
					productValue.quantity = totalQty
					productValue.certification = values.certification
					productValue.inventoryType = 'outgoing'
				}
				if (activeProductDetail) {
					if (activeTrigger === 'update') {
						const targetProducts = replaceAt(
							_activeProducts,
							activeIndex,
							{
								...activeProductDetail,
								...productValue,
								activeIndex,
								selectedProdQty,

								tax: [
									{
										value: productValue.taxRate || null,
									},
								],
								_submitted: true,
							},
							{}
						)

						setActiveProducts(targetProducts)
						onClose(targetProducts)

						dispatch(
							AppDuc.creators.showToast({
								messageType: 'success',
								message: t('common.successUpdate'),
							})
						)
					} else if (activeTrigger === 'add') {
						const targetProducts = replaceAt(
							_activeProducts,
							activeIndex,
							{
								...activeProductDetail,
								...productValue,
								activeIndex,
								selectedProdQty,
								tax: [
									{
										value: productValue.taxRate || null,
									},
								],
								_submitted: true,
							},
							{}
						)

						setActiveProducts(targetProducts)
						setActiveIndex(Math.max(0, activeIndex + 1))
					} else {
						let replaceIndex = activeIndex
						if (isEdit) {
							replaceIndex = _activeProducts.findIndex(
								item => item.id === activeProductDetail.id
							)
						}
						const targetProducts = replaceAt(
							_activeProducts,
							replaceIndex,
							{
								...activeProductDetail,
								...productValue,
								activeIndex: replaceIndex,
								selectedProdQty,
								tax: [
									{
										value: productValue.taxRate || null,
									},
								],
								_submitted: true,
								lcv:
									(productValue.quantity || totalQty) *
									activeProductDetail.lcv,
							},
							{}
						)

						if (isEntityDO && isSupplyChainFlowWithTank) {
							const productsArray = storageUnitData.map(
								storage => {
									return {
										...activeProductDetail,
										...productValue,
										activeIndex,
										selectedProdQty,
										tax: [
											{
												value:
													productValue.taxRate ||
													null,
											},
										],
										_submitted: true,
										certification: values.certification,
										inventoryType: 'outgoing',
										quantity: storage.quantity,
										storageUnitID: storage.storageUnitID,
										lcv: totalQty * activeProductDetail.lcv,
									}
								}
							)
							setProductsPayload(productsArray)
							setActiveProducts(targetProducts)
							onClose(targetProducts)
						} else if (isEntityDO && isSupplyChainFlowWithoutTank) {
							const traderOrEndManufacturerDetails = [
								{
									...activeProductDetail,
									...productValue,
									activeIndex,
									selectedProdQty,
									tax: [
										{
											value: productValue.taxRate || null,
										},
									],
									_submitted: true,
									inventoryType: 'incoming',
									storageUnitID: 'none',
									quantity: values.quantity,
								},
							]
							setProductsPayload(traderOrEndManufacturerDetails)
							setActiveProducts(traderOrEndManufacturerDetails)
							onClose(targetProducts)
						} else if (isEntityDO && isNotSupplyChainFlow) {
							const plantationDealerProductDetails = [
								{
									...activeProductDetail,
									...productValue,
									activeIndex,
									selectedProdQty,
									tax: [
										{
											value: productValue.taxRate || null,
										},
									],
									_submitted: true,
									supplyChainModel: 'not-applicable',
									inventoryType: 'incoming',
									storageUnitID: 'plantation-yard',
									quantity: values.quantity,
								},
							]
							setProductsPayload(plantationDealerProductDetails)
							setActiveProducts(plantationDealerProductDetails)
							onClose(targetProducts)
						} else {
							setActiveProducts(targetProducts)
							onClose(targetProducts)
						}
					}
				}
			}
			setSubmitting(false)
		},
	})

	useEffect(() => {
		if (selectedProdQty !== undefined) {
			setCurrentAvailableQty(
				getIn(baseProduct, [0, 'quantity']) && isEdit && isParentDoc
					? getIn(baseProduct, [0, 'quantity']) + selectedProdQty
					: selectedProdQty
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedProdQty])

	const activeProductDetail =
		allProducts.find(prod => values.id === prod.id) || {}

	const {
		description = '',
		uom = '',
		hsnsac = '',
		rawMaterials = [],
		defaultUOM = '',
		availableQty,
	} = activeProductDetail

	useEffect(() => {
		setSelectedProdQty(availableQty)
	}, [availableQty])

	useEffect(() => {
		if (values.supplyChainModel && values.certification) {
			dispatch(
				TradeDocDuc.creators.fetchProductDetailsBasedOnSupplyChainModel(
					activeProductDetail.id,
					values.supplyChainModel,
					values.certification,
					partner,
					actor[0],
					individualProductEntry
				)
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		activeProductDetail.id,
		actor,
		dispatch,
		partner,
		values.certification,
		values.supplyChainModel,
	])

	const handleProductSelection = productSelection => {
		const productID = getIn(productSelection, ['id']) || ''
		const activeSelection =
			allProducts.find(prod => prod.id === productID) || {}
		if (activeSelection.id) {
			setFieldValue('id', activeSelection.id)
			setSelectedProdQty(activeSelection.availableQty)
		} else {
			// set error.
			setFieldError('id', t('validation.doesNotExist'))
		}
	}

	useEffect(() => {
		if (isEdit) {
			handleProductSelection({ id: currentProductDetail.id })
			setFieldValue(
				'supplyChainModel',
				currentProductDetail.supplyChainModel
			)
			setFieldValue('certification', currentProductDetail.certification)
			const units = []
			if (individualProductEntry && individualProductEntry.length > 0) {
				individualProductEntry.forEach(item => {
					const storageUnit = storageUnits.filter(
						unit => unit.id === item.storageUnitID
					)
					units.push({
						productID: item.id,
						storageUnitID: item.storageUnitID,
						availableQty:
							storageUnit.length > 0
								? getIn(storageUnit[0].meta.storedProducts, [
										0,
										'availableQty',
								  ])
								: 0,
						quantity: item.quantity,
					})
				})
			}
			setStorageUnitData(units)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onChange = useCallback(
		(action, meta) => {
			if (action === 'storage_unit') {
				const existingStorageUnit = storageUnitData.filter(
					storage => storage.storageUnitID === meta.storageUnitID
				)

				const rowIndex = storageUnitData.findIndex(
					st => st.storageUnitID === meta.storageUnitID
				)

				if (existingStorageUnit.length === 0 && meta.quantity !== 0) {
					const addRow = addLast(storageUnitData, meta)
					setStorageUnitData(addRow)
					setQuantityErrorMessage(false)
				}
				if (existingStorageUnit.length > 0 && meta.quantity === 0) {
					setStorageUnitData(removeAt(storageUnitData, rowIndex))
					setQuantityErrorMessage(false)
				} else if (existingStorageUnit.length > 0) {
					const targetArray = replaceAt(
						storageUnitData,
						rowIndex,
						meta
					)

					setStorageUnitData(targetArray)
					setQuantityErrorMessage(false)
				}

				if (meta.availableQty < meta.quantity) {
					setQuantityErrorMessage(true)
				}
			}
		},
		[storageUnitData]
	)

	const disableCTA =
		isEntityDO && isSupplyChainFlowWithTank
			? storageUnitData.length === 0 || quantityErrorMessage === true
			: !isEmptyObject(errors) || !values.id

	if (isError)
		return (
			<ErrorSegment
				status="empty"
				message={t('common.errorSegmentMessage')}
			/>
		)

	return (
		<Box>
			{isLoading && <TransparentBgSpinner />}
			<FormHeader
				row
				justifyContent="space-between"
				alignItems="flex-end"
			>
				<H2 primary>{t('common.selectProducts')}</H2>
				{startIndex !== endIndex && (
					<Box row style={{ marginRight: 20 }}>
						<IconWrapper
							size={20}
							disabled={activeIndex === startIndex}
							onClick={() => {
								if (activeIndex > startIndex)
									setActiveIndex(
										Math.max(startIndex, activeIndex - 1)
									)
							}}
						>
							<Icon glyph={LeftArrowIcon} />
						</IconWrapper>
						<PaginatorIndexBlock
							startIndex={activeIndex + 1}
							totalCount={endIndex}
						/>
						<IconWrapper
							size={20}
							disabled={activeIndex === Math.max(0, endIndex - 1)}
							onClick={() => {
								if (activeIndex < endIndex)
									setActiveIndex(
										Math.min(
											Math.max(0, endIndex - 1),
											activeIndex + 1
										)
									)
							}}
						>
							<Icon glyph={RightArrowIcon} />
						</IconWrapper>
					</Box>
				)}
			</FormHeader>
			{storageTankLoadingStatus && <TransparentBgSpinner />}
			<form onSubmit={handleSubmit}>
				<FormBody padding="20px 0px">
					<Box overflow width="inherit" padding="0 20px">
						<Box overflow width={300}>
							<SelectSearch
								key={activeIndex}
								name="productSelection"
								placeholder={t('common.selectProduct')}
								options={allProducts.map(
									({ code: _code, name, id: _id }) => {
										return {
											name,
											id: _id,
											label: `${name} (#${_code})`,
										}
									}
								)}
								value={values.id}
								error={touched.code && errors.code}
								labelKey="label"
								valueKey="id"
								defaultLabel={t('common.selectDefaultLabel')}
								onChange={({ productSelection = '' }) => {
									handleProductSelection(productSelection)
								}}
							/>
						</Box>
					</Box>
					{activeProductDetail.id && (
						<>
							{isEntityDO && !isNotSupplyChainFlow && (
								<>
									<Box
										row={!isMobile}
										overflow
										width={300}
										margin="20px 0"
										padding="0 20px"
									>
										<Select
											required
											label={t(
												'tdmDetailsEntry.supplyChainModel'
											)}
											value={values.supplyChainModel}
											key={values.supplyChainModel}
											name="supplyChainModel"
											options={getSupplyChainModelsWithId(
												t
											)}
											onChange={value => {
												setFieldValue(
													'supplyChainModel',
													value.id
												)
											}}
											returnOnlyValue
											labelKey="name"
											valueKey="id"
											onBlur={handleBlur}
											error={
												touched.supplyChainModel &&
												errors.supplyChainModel
											}
										/>
										<Spacer
											size={100}
											horizontal={!isMobile}
										/>
										<Select
											required
											label="Certification Type"
											value={values.certification}
											key={values.certification}
											name="certification"
											options={getCertificateTypes()}
											onChange={value => {
												setFieldValue(
													'certification',
													value.id
												)
											}}
											returnOnlyValue
											labelKey="label"
											valueKey="id"
											onBlur={handleBlur}
											error={
												touched.certification &&
												errors.certification
											}
										/>
									</Box>
								</>
							)}
							<Box
								row={!isMobile}
								overflow
								width={240}
								padding="0 20px"
							>
								{isEntityDO && isNotSupplyChainFlow && (
									<Input
										label={t('tdmDetailsEntry.quantity')}
										name="quantity"
										type="number"
										disabled={
											!actor[0].includes(
												'palmoil_plantation'
											) &&
											isEntityDO &&
											currentAvailableQty === 0
										}
										required
										value={values.quantity}
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.quantity && errors.quantity
										}
										placeholder="0"
									/>
								)}
								{isEntityDO &&
									isSupplyChainFlowWithoutTank &&
									productDetailsBasedOnSupplyChainModel.length >
										0 &&
									values.supplyChainModel &&
									values.certification &&
									isEntityDO && (
										<>
											<Input
												label={t(
													'tdmDetailsEntry.quantity'
												)}
												name="quantity"
												type="number"
												disabled={
													isEntityDO &&
													currentAvailableQty === 0
												}
												required
												value={values.quantity}
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.quantity &&
													errors.quantity
												}
												placeholder="0"
											/>
											<Spacer size={128} horizontal />
											<Box padding={20}>
												<Label bold>
													{t(
														'tdmDetailsEntry.availableQty'
													)}
													:
												</Label>
												<P>
													{`${currentAvailableQty}
												${defaultUOM || uom}`}
												</P>
												<Spacer size={8} />
											</Box>
										</>
									)}
								{!isEntityDO && (
									<>
										<Input
											label={t(
												'tdmDetailsEntry.quantity'
											)}
											name="quantity"
											type="number"
											disabled={
												isEntityDO &&
												selectedProdQty === 0
											}
											required
											value={values.quantity}
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.quantity &&
												errors.quantity
											}
											placeholder="0"
										/>
										<Spacer
											size={10}
											horizontal={!isMobile}
										/>
										<Input
											label={t(
												'tdmDetailsEntry.unitPrice'
											)}
											name="metricUnitPrice"
											type="number"
											required={isPricingMandatory}
											value={values.metricUnitPrice}
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.metricUnitPrice &&
												errors.metricUnitPrice
											}
											placeholder="0"
										/>
										<Spacer
											size={10}
											horizontal={!isMobile}
										/>
										<Input
											label={t(
												'tdmDetailsEntry.taxPrice'
											)}
											name="taxRate"
											type="number"
											required={isPricingMandatory}
											value={values.taxRate}
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.taxRate &&
												errors.taxRate
											}
											placeholder="0"
										/>
									</>
								)}
							</Box>
							{currentAvailableQty === 0 &&
								isEntityDO &&
								!actor[0].includes('palmoil_plantation') && (
									<Box margin="0px 0px 20px 20px">
										<Text
											style={{
												color: 'red',
											}}
										>
											No inventory to raise a DO
										</Text>
									</Box>
								)}
							{!allowMinimalEdit &&
								activeIndex === Math.max(0, endIndex - 1) && (
									<Box
										row
										justifyContent="flex-end"
										padding={4}
										overflow
									>
										<ButtonWithNoBorder
											disabled={disableCTA}
											label={t(
												'tdmDetailsEntry.addAnother'
											)}
											type="submit"
											customIcon={
												<ButtonIconWrapper lightBG>
													<Icon glyph={PlusIcon} />
												</ButtonIconWrapper>
											}
											onClick={() => {
												setActiveTrigger('add')
												submitForm()
											}}
										/>
									</Box>
								)}
							{values.supplyChainModel &&
								values.certification &&
								productDetailsBasedOnSupplyChainModel.length ===
									0 &&
								isEntityDO &&
								!storageTankLoadingStatus &&
								isSupplyChainFlowWithTank && (
									<Box center margin="0 0 8px 0">
										<h5 style={{ color: 'red' }}>
											We couldn't find any tanks for this
											combination.
										</h5>
										<p
											style={{
												marginTop: 8,
											}}
										>
											Please try a different combination.
										</p>
									</Box>
								)}
							{productDetailsBasedOnSupplyChainModel.length > 0 &&
								isEntityDO &&
								isSupplyChainFlowWithTank && (
									<Box
										height="auto"
										width="auto"
										style={{
											margin: '28px 0',
										}}
									>
										<Text
											style={{
												textAlign: 'center',
												padding: 8,
											}}
										>
											Only such inventory matching the
											filter criteria will be displayed.
										</Text>
										<Table
											columnConfig={storageUnitColumnConfig(
												storageUnits,
												onChange,
												storageUnitData,
												isEdit,
												baseProduct,
												individualProductEntry
											)}
											rowData={
												productDetailsBasedOnSupplyChainModel
											}
											noRoundedBorder
										/>
									</Box>
								)}
							<DescriptionWrapper>
								<Box padding={20}>
									{isEntityDO && isNotSupplyChainFlow && (
										<>
											<Label bold>
												{t(
													'tdmDetailsEntry.availableQty'
												)}
												:
											</Label>
											<P>
												{`${availableQty}
												${actor[0].includes('palmoil_plantation') ? 'Nos' : defaultUOM || uom}`}
											</P>
											<Spacer size={8} />
										</>
									)}

									{description && (
										<>
											<Label bold>
												{t(
													'tdmDetailsEntry.description'
												)}
												:
											</Label>
											<P>{description}</P>
											<Spacer size={8} />
										</>
									)}
									{(defaultUOM || uom) && isEntityPO && (
										<>
											<Label bold>
												{t('tdmDetailsEntry.uom')}:
											</Label>
											<P>
												{actor[0].includes(
													'palmoil_plantation'
												)
													? 'Nos'
													: defaultUOM || uom}
											</P>
											<Spacer size={8} />
										</>
									)}
									{hsnsac && (
										<>
											<Label bold>
												{t('tdmDetailsEntry.hsnsac')}:
											</Label>
											<P>{hsnsac}</P>
											<Spacer size={8} />
										</>
									)}
									{!!rawMaterials.length && (
										<>
											<Label bold>
												{t(
													'tdmDetailsEntry.rawMaterials'
												)}
												:
											</Label>
											<P>{rawMaterials.join(', ')}</P>
										</>
									)}
								</Box>
							</DescriptionWrapper>
						</>
					)}
					{/** Space to justify for absolute bottom */}
					<Spacer size={64} />
				</FormBody>
				<FormFooter
					row
					justifyContent="flex-end"
					style={{ width: '100%' }}
				>
					<Box center row style={{ maxWidth: 375 }}>
						<ButtonWithNoBorder
							label={t('common.cancel')}
							tabIndex="-1"
							onClick={() => {
								onClose()
							}}
						/>
						<Spacer size={10} horizontal />
						<Box
							justifyContent="center"
							width={150}
							padding={1}
							height="inherit"
							overflow
						>
							<Button
								label={
									<P color="white">
										<strong>
											{activeProduct.length > 0
												? t('common.update')
												: t('common.submit')}
										</strong>
									</P>
								}
								onClick={() => {
									if (activeProduct.length > 0) {
										// trying to update existing index
										setActiveTrigger('update')
									} else {
										setActiveTrigger('submit')
									}

									submitForm()
								}}
								isLoading={isSubmitting}
								disabled={disableCTA}
								primary
							/>
						</Box>
					</Box>
				</FormFooter>
			</form>
		</Box>
	)
}

export { ProductsForm }

import React from 'react'
import { useSelector } from 'react-redux'
import { back } from 'redux-first-router'
import { UnexpectedErrorBlock } from 'ui-tdm-app/modules/App/components/Error'
import { TradeDocDuc } from '../duc'
import { ViewDocument } from './DocumentControl/View'
import { CreateDocument } from './DocumentControl/Creation'

const DocumentsControl = () => {
	const { payload = {} } = useSelector(TradeDocDuc.selectors.location)

	const { hasError } = useSelector(
		TradeDocDuc.selectors.getDocumentLoadingState
	)

	// if errored, then return the status back.
	if (hasError) {
		return <UnexpectedErrorBlock status={401} onClick={back} />
	}

	const { documentReference, documentStatus } = payload

	// if errored, then return the respective error doc
	if (documentReference && !documentStatus) {
		// flow the control to the view container
		return <ViewDocument />
	}

	return <CreateDocument />
}

export { DocumentsControl }

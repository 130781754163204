import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { WeighBridgeDuc } from 'ui-tdm-app/modules/WeighBridge/duc'
import { KeyValueSegment } from 'ui-tdm-app/modules/TradeDocumentManager/components/KeyValueSegment'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { getDateByFormat, useDateByFormat } from 'ui-tdm-app/utils/date'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { FieldText } from 'ui-lib/components/FieldText'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Button, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon } from 'ui-lib/icons/components/Icon'
import EditIcon from 'ui-lib/icons/edit.svg'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'

const ViewSlip = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)
	const location = useSelector(WeighBridgeDuc.selectors.location)
	const { payload = {}, query = {} } = location
	const { attachmentID } = payload
	const { documentReference } = query

	const slipInformation = useSelector(
		WeighBridgeDuc.selectors.getWBSlipInformation
	)

	const editable = getIn(slipInformation, ['editable']) || false
	const doNumber = getIn(slipInformation, ['doNumber'])
	const dispatchDate = getIn(slipInformation, ['dispatchDate'])
	const driverName = getIn(slipInformation, ['driverName'])
	const vehicleNumber = getIn(slipInformation, ['vehicleNumber'])
	const netWeight = getIn(slipInformation, ['netWeight'])
	const grossWeight = getIn(slipInformation, ['grossWeight'])
	const tareWeight = getIn(slipInformation, ['tareWeight'])
	const ticketNumber = getIn(slipInformation, ['ticketNumber'])
	const checkedBy = getIn(slipInformation, ['checkedBy'])
	const createdAt = getIn(slipInformation, ['createdAt'])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.wbSlips'),
			name: 'weighbridge',
			isActive: true,
		},
		{
			label: t('breadcrumb.viewWb'),
			name: 'view-weighBridge',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'weighbridge') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.WEIGHBRIDGE)
			)
		}
	}

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={t('viewWeighBridgeSlip.title')} />
				{editable && (
					<Box style={{ padding: 2 }} width="250px">
						<Button
							label={t('viewWeighBridgeSlip.editWb')}
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={EditIcon} />
								</ButtonIconWrapper>
							}
							rounded
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.WEIGHBRIDGE$DOCUMENTREFERENCE,
										{
											action: 'edit',
											attachmentID,
										},
										{
											parentDocRef: documentReference,
											productID:
												slipInformation.productID,
										}
									)
								)
							}
						/>
					</Box>
				)}
			</Box>
			<Box>
				<BoxShadowWrapper
					style={{
						width: isMobile ? 325 : 960,
						maxWidth: 960,
						minWidth: 325,
					}}
				>
					<Box padding="0 15px" row>
						<Box padding="20px 0 ">
							<KeyValueSegment
								name={t('viewWeighBridgeSlip.doNum')}
								description={doNumber || '---'}
							/>
							<KeyValueSegment
								name={t('viewWeighBridgeSlip.dispatchDate')}
								description={
									getDateByFormat(
										dispatchDate,
										null,
										activeLocale
									) || '---'
								}
							/>
							<KeyValueSegment
								name={t('viewWeighBridgeSlip.driver')}
								description={driverName || '---'}
							/>
							<KeyValueSegment
								name={t('viewWeighBridgeSlip.vehicleNumber')}
								description={vehicleNumber || '---'}
							/>
						</Box>
						<Box padding="20px 280px">
							<KeyValueSegment
								name={t('viewWeighBridgeSlip.netWeight')}
								description={netWeight || '0'}
							/>
						</Box>
					</Box>
					<Box style={{ borderTop: '1px solid #ddd' }}>
						<Box
							row
							style={{
								flexWrap: 'wrap',
								paddingBottom: '16px',
								paddingTop: '16px',
								paddingLeft: 15,
								paddingRight: 60,
							}}
						>
							<Box padding="0 12px 0 0">
								<KeyValueSegment
									name={t('viewWeighBridgeSlip.grossWeight')}
									description={grossWeight || '---'}
								/>
								<KeyValueSegment
									name={t('viewWeighBridgeSlip.tareWeight')}
									description={tareWeight || '0'}
								/>
								<KeyValueSegment
									name={t('viewWeighBridgeSlip.ticketNumber')}
									description={ticketNumber || '---'}
								/>
							</Box>
						</Box>
					</Box>
					<Box
						style={{
							borderTop: '1px solid #ddd',
							flexWrap: 'wrap',
							paddingBottom: '16px',
							paddingTop: '16px',
							paddingLeft: 15,
							paddingRight: 60,
						}}
						row
					>
						<KeyValueSegment
							name={t('viewWeighBridgeSlip.checkedBy')}
							description={checkedBy || '---'}
						/>
						<KeyValueSegment
							name={t('viewWeighBridgeSlip.createdOn')}
							description={useDateByFormat(
								createdAt,
								'd LLL yyyy, hh:mm a'
							)}
						/>
					</Box>
				</BoxShadowWrapper>
			</Box>
			<Spacer size={8} />
			<FieldText show error>
				{t('viewWeighBridgeSlip.canEditWBSlip')}
			</FieldText>
			<Box padding="25px 0" style={{ maxWidth: 150 }}>
				<Button
					label={t('common.back')}
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.WEIGHBRIDGE
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { ViewSlip }
